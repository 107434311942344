import React from 'react'
import TextField from '@material-ui/core/TextField'
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import { FormLabel, FormHelperText, FormControlLabel, Typography } from '@material-ui/core';

export const TextInput = ({
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => (
    <TextField
      error={touched && error ? true : false}
      placeholder={label}
      label={label}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );
  
  export const CheckboxInput = ({ input,
    label,
    meta: { touched, error },
    helperText,
    ...custom }) => (
    <React.Fragment>
    <FormControlLabel
        control={<Checkbox 
          checked={input.value ? true : false}
          onChange={input.onChange}
          {...input}
          {...custom} />}
        label={<Typography variant='caption'>{label}</Typography>}
      />
    {touched && error ? <FormHelperText error>{error}</FormHelperText> : <FormHelperText>{helperText}</FormHelperText>}
    </React.Fragment>
  );
  
  export const RadioGroupInput = ({ input, ...rest }) => (
    <RadioGroup
      {...input}
      {...rest}
      valueSelected={input.value}
      onChange={(event, value) => input.onChange(value)}
    />
  );
  
  export  const SelectInput = ({
    input,
    label,
    meta: { touched, error },
    children,
    helperText,
    ...custom
  }) => (
    <React.Fragment>
      <FormLabel className="MuiInputLabel-shrink">{label}</FormLabel>
      <Select
      label={label}
      error={touched && error ? true : false}
      {...input}
      onChange={value => input.onChange(value)}
      children={children}
      MenuProps={{MenuListProps:{disablePadding:true}}}
      {...custom}
    />
    {touched && error ? <FormHelperText error>{error}</FormHelperText> : <FormHelperText>{helperText}</FormHelperText>}
    </React.Fragment>
    
  );