import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import objectPath from "object-path";
import ReplayIcon from "@material-ui/icons/Replay";
import ComponentLoading from "../../../../components/ComponentLoading";
import PageHeading from "../../../../components/PageHeading";
import * as repeatingRosterActions from '../../../../actions/repeatingRoster';

import repeatingRosterConstants from "../../../../shared/constants/repeatingRoster";

import DateUtil from "../../../../shared/utils/general/DateUtil";
import UserUtil from "../../../../shared/utils/UserUtil";
import TranslatorUtil from "../../../../shared/utils/general/TranslatorUtil";
import ArrayUtil from "../../../../shared/utils/general/ArrayUtil";
import ParkingWeeksSelectionTable from "../../../../components/park/ParkingWeeksSelectionTable";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import RosterBulkActions from "../../../../components/park/RosterBulkActions";
import ParkingSecondaryActions from "../../../../components/park/ParkingSecondaryActions";
import CompanyUtil from "../../../../shared/utils/CompanyUtil";
import RepeatingRosterConfirmDialog from "../../../../components/RepeatingRosterConfirmDialog";
import RepeatingRosterUtil from "../../../../shared/utils/RepeatingRosterUtil";

class RepeatingRoster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmManualBooking: false,
      appLoading: true,
      frequencyManuallyChanged: false,
      frequency: repeatingRosterConstants.WEEKLY,
      days: [],
    };
  }

  componentDidMount() {
    let _this = this;
    this.getDays();
    Promise.all([])
      .then(() => {
        Promise.all([])
          .then(() => {
            console.log("loading done");
            _this.setState({ appLoading: false });

            //catch errors
          })
          .catch(function (err) {
            console.log("loading error", err);
          });
      })
      .catch(function (err) {
        console.log("loading error", err);
      });
  }

  getDays = () => {
    const { userInfo } = this.props;
    this.setState({
      days: DateUtil.getDaysOfWeeks(
        DateUtil.getFirstFutureMondayByVisibleDays(
          CompanyUtil.getVisibleDaysAhead(UserUtil.getCompany(userInfo))
        ),
        2,
        true,
        UserUtil.includeWeekend(userInfo)
      ), //DateUtil.getTwoWeeks(this.getRequestedMomentDate())
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    var date = objectPath.get(this, "props.match.params.date");
    var prevDate = objectPath.get(prevProps, "match.params.date");
    if (date !== prevDate) {
      this.getDays();
    }
  }

  allowConfirmChanges = () => {
    const { repeatingRoster } = this.props;
    return RepeatingRosterUtil.hasLastRunUnix(repeatingRoster) && objectPath.get(repeatingRoster,'unconfirmedUpdate',true);
  }

  getSelectedFrequency = () => {
    const {
      frequency,
      frequencyManuallyChanged
    } = this.state;
    const { repeatingRoster } = this.props;
    return frequencyManuallyChanged === true ? frequency || "" : objectPath.get(repeatingRoster,'frequency',frequency || "")
  }

  getStartingDayInfo = () => {
    const { repeatingRoster, userInfo } = this.props;
    if (
      RepeatingRosterUtil.hasLastDateProcessed(repeatingRoster)
    ) {
      var date = DateUtil.dbDateToMoment(repeatingRoster.lastDateProcessed);
      return DateUtil.formatDayWithMonthForCalendar(DateUtil.ifDisabledDayGetNextAvailableOne(date.clone().add(1, "day")), UserUtil.includeWeekend(userInfo));
      //+" (System has already done booking for "+DateUtil.formatDayWithMonthForCalendar(date)+")"
    }
    return DateUtil.formatDayWithMonthForCalendar(
      DateUtil.getFirstFutureInvisibleDay(
        CompanyUtil.getVisibleDaysAhead(UserUtil.getCompany(userInfo))
      )
    );
  };

  render() {
    const { companyParks } = this.props;
    const {
      appLoading,
      days,
      confirmManualBooking,
    } = this.state;

    return (
      <div className="app-container-wrap">
        <PageHeading
          icon={<ReplayIcon />}
          title={TranslatorUtil.t("Repeating roster")}
        />

        {appLoading ? (
          <ComponentLoading />
        ) : ArrayUtil.isNonEmptyArray(companyParks) ? (
          <React.Fragment>
            <ParkingSecondaryActions />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {TranslatorUtil.t("A repeating roster allows you to setup a roster to automatically book parks to selected users every week/fortnight.")}
                </Typography>
                <Typography variant="caption">
                  
                  You can choose the frequency of the roster and which users get assigned to each park and on what days in the week.
                  <br />
                  You can modify the roster at any time or simply remove your scheduled parks to turn this feature off.
                  <br />
                  Parks that have been assigned an owner can not be used within your roster and if a user unbooks their rostered park, this will become available for other users to book.
                  <br />
                  Parks that have been manually booked by an admin or manager, that are outside the current visible days setting, take priority over rostered parks.
                  <br />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
              <Box className={!this.allowConfirmChanges() ? 'hidden' : ''}>
                <Typography variant="body2">
                  {/* Your users can book <Typography component="strong" color="primary">
                    {CompanyUtil.getVisibleDaysAhead(
                      UserUtil.getCompany(userInfo)
                    )}
                  </Typography>  */}
                  This roster will take effect on <Typography component="strong" color="primary">
                    {this.getStartingDayInfo()}
                  </Typography> to not interrupt current bookings.
                  <br />
                  <br />
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.setState({ confirmManualBooking: true })}
                >
                  Set earlier starting date
                </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <RadioGroup
                style={{justifyContent: "flex-end"}}
                  row
                  value={this.getSelectedFrequency()}
                  onChange={(event) => {
                    this.setState(
                      {
                        frequency: Number(event.target.value),
                        frequencyManuallyChanged: true,
                      },
                      () => {
                        this.getDays();
                      }
                    );
                  }}
                >
                  {repeatingRosterConstants.FREQUENCY_OPTIONS.map((x) => (
                    <FormControlLabel
                      key={x.label}
                      value={x.value}
                      control={<Radio />}
                      label={x.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              
            </Grid>

            <RosterBulkActions
              startLoading={() => this.setState({ appLoading: true })}
              finishLoading={() => this.setState({ appLoading: false })}
              frequency={this.getSelectedFrequency()}
            />
            <div className={"frequency-" + this.getSelectedFrequency()}>
              <ParkingWeeksSelectionTable
                days={days}
                day={DateUtil.dbFormat(days[0].date)}
                companyId={null}
                frequency={this.getSelectedFrequency()}
              />
            </div>
          </React.Fragment>
        ) : (
          TranslatorUtil.t("We cannot find any parks for your company.")
        )}

        {confirmManualBooking ? (
          <RepeatingRosterConfirmDialog
            onConfirm={() => this.proceedManualBooking()}
            onCloseHandler={() => this.setState({ confirmManualBooking: false })}
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    companyParks: state.companyParks,
    companyInvitations: state.companyInvitations,
    companyProfiles: state.companyProfiles,
    rosterSelection: state.rosterSelection,
    repeatingRosterSetting: state.repeatingRosterSetting,
    repeatingRoster: state.repeatingRoster,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...repeatingRosterActions
    }, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepeatingRoster)
);
