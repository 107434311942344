import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreUtil from '../shared/utils/general/FirestoreUtil';


const collectionName = 'profiles';

export function getProfilesByCompanyId(companyId) {
  return function(dispatch) {
    console.log('getProfilesByCompanyId', companyId);
    return firestoreApi.collection(collectionName).where("companyId", "==", companyId).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_COMPANY_PROFILES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_PROFILES))
      );
  };
}

export function listenProfilesByCompanyId(companyId,callback = () => {}) {
  return function(dispatch) {
    console.log('listenParksByCompanyId');
    return firestoreApi.collection(collectionName).where("companyId", "==", companyId).onSnapshot(
        response => {
          callback();
          return dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response),  types.GET_COMPANY_PROFILES))
        },
          error => {
            callback();
            return dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_PROFILES))
          }
      );
  };
}

export function getProfiles() {
  return function(dispatch) {
    console.log('getProjects called');
    return firestoreApi.collection(collectionName).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_PROFILES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROFILES))
      );
  };
}

export function listenProfiles() {
  return function(dispatch) {
    console.log('listenProfiles called');
    return firestoreApi.collection(collectionName)
    .onSnapshot(
      response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response),  types.GET_PROFILES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROFILES))
      );
  };
}


  export function postProfile(data) {
    return function(dispatch) {
      console.log('postProfile');
      return firestoreApi.collection(collectionName).add(data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_PROFILE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_PROFILE))
        );
    };
  }

  export function putProfile(data) {
    return function(dispatch) {
      console.log('putProfile');
      return firestoreApi.collection(collectionName).doc(data.id).set(FirestoreUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(data, types.PUT_PROFILE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_PROFILE))
        );
    };
  }

  export function patchProfile(id,data) {
    return function(dispatch) {
      console.log('patchProfile');
      return firestoreApi.collection(collectionName).doc(id).update(data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(data, types.PUT_PROFILE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_PROFILE))
        );
    };
  }

  export function getCurrentUserProfile(id) {
    return function(dispatch) {
      console.log('getCurrentUserProfile');
      return firestoreApi.collection(collectionName).doc(id).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionItemData(response), types.GET_USER_PROFILE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_USER_PROFILE))
        );
    };
  }

  export function getProfile(id) {
    return function(dispatch) {
      console.log('getProfile');
      return firestoreApi.collection(collectionName).doc(id).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionItemData(response), types.GET_PROFILE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROFILE))
        );
    };
  }

  export function postFirestoreDefaultProfile(data) {
    console.log('postFirestoreDefaultProfile');
    return firestoreApi.collection(collectionName).add(data);
}

  export function getFirestoreProfileById(id) {
    console.log('getFirestoreProfileById');
    return firestoreApi.collection(collectionName).doc(id).get()
      .then(
        response => 
           FirestoreUtil.parseCollectionItemData(response)
      );
}


  export function getFirestoreProfileByEmail(email) {
      console.log('getFirestoreProfileByEmail');
      return firestoreApi.collection(collectionName).where("email", "==", email).limit(1).get()
        .then(
          response => FirestoreUtil.parseFirstItemFromCollectionData(response),
          error => error
        );
  }

 

