import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as firebaseUserActions from '../../actions/firebaseUser';
import * as captchaActions from '../../actions/captcha';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@material-ui/core';
import firebase from 'firebase';
import ComponentLoading from '../../components/ComponentLoading';
import Logo from '../../components/Logo';
import HowItWorksDialog from '../../components/about/HowItWorksDialog'

import HelpIcon from '@material-ui/icons/HelpOutline';

import urls from '../../shared/constants/urls';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';

class Welcome extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            captchaRendered: false,
            showHowItWorksDialog: false,
            formToDisplay: null
        }
    }

    toggleHowItWorkDialog = () => {
        this.setState({ showHowItWorksDialog: !this.state.showHowItWorksDialog })
    }

    shouldRenderCaptcha = () => {
        return false;
        //TODO Captcha
        // const { captchaVerified, router } = this.props;
        // var currentPath = objectPath.get(router,'location.pathname')
        
        //return [urls.CREATE_ACCOUNT_URL,urls.RESET_PASSWORD_URL].includes(currentPath) && captchaVerified !== true ? true : false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { actions } = this.props;
        console.log('Index componentDidUpdate', this.shouldRenderCaptcha());
        if (this.shouldRenderCaptcha()) {
            setTimeout(() => {
                window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-wrap', {
                    //'size': 'invisible',
                    'callback': () => {
                        actions.setCaptcha();
                    }
                }).render().then(() => {
                    document.body.classList.add("captchaRendered");//styling
                });
            }, 1000);

        }
    }


    render() {

        const { showHowItWorksDialog } = this.state;

        return (
            <div className="index-wrap">
                <div className="welcome-wrap graphics-bg-wrap">



                    <div className="main-wrap">
                        <Logo className="logo" />
                    </div>

                    <div className="text-right howItWorksIconWrap">

                        <IconButton aria-label={TranslatorUtil.t("how it works")} 
                        onClick={() => window.location.replace(urls.HOW_IT_WORKS_EXTERNAL_URL)}
                        >
                            <HelpIcon />
                        </IconButton>
                    </div>

                    {this.props.children}


                    {
                        showHowItWorksDialog ? <HowItWorksDialog
                            onClose={this.toggleHowItWorkDialog} /> : null
                    }
                </div>


                <Dialog open={this.shouldRenderCaptcha()} className="captchaDialog">
                    <DialogTitle disableTypography className="titleWrap">
                        <Typography variant="h3" className="title">{TranslatorUtil.t("Anti robot verification")}</Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="body1">{TranslatorUtil.t("To keep our app fit and healthy please confirm you are a real person.")}</Typography>
                        <ComponentLoading showText={false} />
                        <div id="recaptcha-wrap"></div>
                    </DialogContent>
                </Dialog>


            </div>
        );
    }
}



function mapStateToProps(state) {
    return {
        router: state.router,
        userInfo: state.userInfo,
        captchaVerified: state.captchaVerified,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...firebaseUserActions,
                ...captchaActions
            },
            dispatch
        )
    };
}


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Welcome));
