import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreUtil from '../shared/utils/general/FirestoreUtil';



const collectionName = 'billings';



export function getBillingsByCompanyId(companyId) {
  return function(dispatch) {
    console.log('getBillingsByCompanyId',companyId);
    return firestoreApi.collection(collectionName).where("companyId", "==", companyId).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_COMPANY_BILLINGS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_BILLINGS))
      );
  };
}


  export function getBilling(id) {
    return function(dispatch) {
      console.log('getBilling');
      return firestoreApi.collection(collectionName).doc(id).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionItemData(response), types.GET_BILLING)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_BILLING))
        );
    };
  }

 

