

import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function PageHeading(props) {



  return <Box className="page-heading">
  <Avatar className="avatar">{props.icon}</Avatar>
    <Typography variant="h1">{props.title}</Typography>
    {props.children}
  </Box>;
}
