import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import ComponentLoading from '../../../../components/ComponentLoading';

import PageHeading from '../../../../components/PageHeading';
import objectPath from 'object-path';
import AdminCompanyMenu from '../../../../components/admin/AdminCompanyMenu';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import { Button, Box } from '@material-ui/core';
import SnackBarUtil from '../../../../utils/SnackBarUtil';
import { withSnackbar } from "notistack";

import urls from '../../../../shared/constants/urls';
import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import CompanyUtil from '../../../../shared/utils/CompanyUtil';

class CompanyBlock extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
    }   
  }

  componentDidMount() {
    const { match } = this.props;
    let _this = this;
        this.mountCompany().then((response) => {
          var company = objectPath.get(response,'data');
        if(objectPath.get(match,'params.id') === objectPath.get(company,'id')){
          Promise.all([
          ]).then(()=>{
            console.log('loading done');
            _this.setState({listDataLoading: false});
  
            //catch errors
          }).catch(function(err) {
            console.log('loading error',err);
            _this.setState({listDataLoading: false});
          });
        } else {
          _this.setState({listDataLoading: false});
        }
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  mountCompany = () =>{
    const { actions, match, company } = this.props;
    if(objectPath.get(match,'params.id') && objectPath.get(match,'params.id') !== objectPath.get(company,'id')){
      return actions.getCompany(objectPath.get(match,'params.id'));
    } else {
      return Promise.resolve().then(() => {return {data: company}});
    }
  }

  handleResponse = response => {
    const { enqueueSnackbar, history, match } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = TranslatorUtil.t("Updated");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      var companyUrl = urls.ADMIN_COMPANY_URL(objectPath.get(match,'params.id'));
      history.push(companyUrl);
    }
  }

  disableCompany = () => {
    const { actions, company } = this.props;
    var values = company;
    values.disabled = true;

    return actions.putAdminCompany(values).then(response => this.handleResponse(response));
  }

  enableCompany = () => {
    const { actions, company } = this.props;
    var values = company;
    delete values.disabled;

    return actions.putAdminCompany(values).then(response => this.handleResponse(response));
  }


  
  render() {

    const { company } = this.props;
    const { listDataLoading } = this.state;

    return <div className="app-container-wrap app-admin">
        <PageHeading icon={<BlockIcon />} title={TranslatorUtil.t("Block company")} />
        <AdminCompanyMenu company={company} />

        { listDataLoading ? <ComponentLoading /> : <Box>
          {
            company.disabled !== true ? <Button variant="contained" color="primary" className="error-btn" size="large" startIcon={<BlockIcon />} onClick={this.disableCompany}>
            {TranslatorUtil.t("Confirm to disable company")+ " - " + CompanyUtil.getDisplayName(company)}
          </Button> : <Button variant="contained" color="default" size="large" startIcon={<CheckIcon />} onClick={this.enableCompany}>
            {TranslatorUtil.t("Confirm to enable company")+ " - " + CompanyUtil.getDisplayName(company)}
          </Button> 
          }
        </Box> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    company: state.company,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyBlock)));
