
import APP_CONFIG from '../config/appConfig';

export default {
  //APP
  APP_NAME: "Flex Parking",
  //GOOGLE_CAPTCHA_SITE_KEY : "6Lfi9tAUAAAAAP85u-djhL9xflsDvm9nQlM__Pa_",
  APP_DOMAIN: APP_CONFIG.APP_HOME_URL(),
  FIREBASE_PROJECT_ID: APP_CONFIG.PROJECT_ID(),
  SUPER_ADMINS: ["hkvapil@gmail.com", "flex.parking.nz@gmail.com","kyleandlaura2018@gmail.com"],
  CONTACT_EMAIL: "info@flexparking.co.nz",
  IMAGE_LOADER_PATH: "/assets/images/image_loader.svg",
  BROKEN_IMAGE_PATH: "/assets/images/broken_image.svg",
  API_KEY:"9uj78wGmZWBwF8Ra5skkcJ9wx7KhX3FBk3bS8ZUmcDbctMy9Gd7SB3ARnkQtUeQE",
  MIN_APP_VERSION: "1.0.1",


  DEFAULT_LOCALE: 'en-NZ',
  DEFAULT_TRANSLATION_LANG: 'en',
  DEFAULT_CURRENCY: 'NZD',
  TEXT_INPUT_MAX_LENGTH: 256,
  OTHERS_TEXT_INPUT_MAX_LENGTH: 100,
  POLLING_ENABLED: true,

  RERENDER_CALENDARS_INTERVAL: 60000,


  DEV_HOST: 'http://localhost:3000/',

  //ERRORS
  REDIRECTED_ERROR_STATUSES: [500],
  ERROR_CUSTUM_HANDLED_GET_REQUESTS: [],


  //FIELDS
  SINGLE_SELECT_FIELD : "single-select-field",
  MULTI_SELECT_FIELD : "multi-select-field",
  SINGLE_DROPDOWN_FIELD : "single-dropdown-field",
  MULTI_DROPDOWN_FIELD : "multi-dropdown-field",
  TEXT_FIELD : "text-field",
  OTHERS_OPTION_VALUE : "others",

  YES_NO_OPTIONS: [
    {
      value: "Yes",
      label: "Yes"
    },
    {
      value: "No",
      label: "No"
    }
  ],

  WEEK_TYPE_EVEN: "even",
  WEEK_TYPE_ODD: "odd",

  BOOLEAN_OPTIONS: [
    {
      value: true,
      label: "Yes"
    },
    {
      value: false,
      label: "No"
    }
  ],

  // REPORTING ITEM FIELDS TO OVERWRITE
  //UI
  UI_BREAKPOINTS: ['xs', 'sm', 'md', 'lg', 'xl'],

  

  //
  DISABLED_DAYS : ['Saturday','Sunday'],
  TOMORROW_CHECKER_DISABLED_DAYS : ['Friday','Saturday'],
  START_OF_THE_WEEK_DAY : 'Monday',
  FREE_PLAN_DAYS : 30,
  DEFAULT_PARKS_PER_USER_PER_PERIOD : 5,
  DEFAULT_PARKS_PER_USER_PER_PERIOD_INCLUDE_WEEKEND : 7,
  DEFAULT_VISIBLE_DAYS_AHEAD : 14,
  DEFAULT_VISIBLE_DAYTIME_AHEAD: 0,
  DEFAULT_DISABLE_CURRENT_DAY_AT: 0,
  DEFAULT_INCLUDE_WEEKEND: false,
  DEFAULT_BILLING_DAY: 14,
  DEFAULT_PRICE_PER_PARK: 3,
  
  // be careful here as this can break as if booking changed too ahead mobile app will not load this park state as it is loading by updatedUnix condition
  MANAGER_VISIBLE_DAYS_AHEAD : 90, 
  
  DEFAULT_TIMEZONE: "Pacific/Auckland",
  DEFAULT_BILLING_CURRENCY: "NZD",
  DEFAULT_LANG: "en",
  DISMISS_NOTIFICATIONS_FROM: 6,
  DISMISS_NOTIFICATIONS_UNTIL: 22,
  DEFAULT_LATEST_TIME_TO_UNLOCK: 0,
  DEFAULT_HIDE_BOOKED_BY_FOR_NORMAL_USERS: false,

  //notifications
  BOOKING_REJECTED_REASON_UNKNOWN: 0,
  BOOKING_REJECTED_REASON_APP_VERSION: 1,
  BOOKING_REJECTED_REASON_CONFLICT: 2,

  //notifications
  NOTIFICATION_NO_RESULT: 0,
  NOTIFICATION_SENT_TO_PRIORITY_USER: 1,
  NOTIFICATION_SENT_TO_ALL_USERS: 2,
  NOTIFICATION_PARK_NOT_AVAILABLE: 3,
  NOTIFICATION_NO_VALID_USERS: 4,
  NOTIFICATION_NO_USERS_WITHOUT_PARK: 5,
  NOTIFICATION_NOT_IN_BOOKABLE_TTIME: 6,

  NOTIFICATION_RESULTS : [
    'no results', // 0
    'sent to priority user', // 1
    'sent to all users without park', // 2
    'park not available', // 3
    'no valid users', // 4
    'no users without park', // 5
    'beyond normal users bookable time', // 6
  ],

  UNSET_PARKSTATE: {
    dateUnix: 0,
    updatedUnix: 0,
    day: "01",
    month: "01",
    year: "1970",
    companyId: null,
    date: "01-01-1970"
  },
  
}