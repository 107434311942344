// src/firebase.js
import firebase from 'firebase';
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

import EnvSetting from './shared/config/env';

let config = {};

if(EnvSetting.IS_PROD()){
    console.log('running prod env');
    config = {
        apiKey: "AIzaSyD9b8TD_gYm8lAikJ3bSd4MkGFAqg_OVbw",
        authDomain: "parkingappnz-dev.firebaseapp.com",
        databaseURL: "https://parkingappnz-dev.firebaseio.com",
        projectId: "parkingappnz-dev",
        storageBucket: "parkingappnz-dev.appspot.com",
        messagingSenderId: "439741821832",
        appId: "1:439741821832:web:29b7ad3d78d1e2c1d1003d",
        measurementId: "G-XP19ZHPXK2"
    }
}
else if(EnvSetting.IS_DEV()){
    console.log('running dev env');
    config = {
        apiKey: "AIzaSyA3sFtu8lQLokrluJUkIwbYXOybfPBGYHE",
        authDomain: "flexparking-dev.firebaseapp.com",
        databaseURL: "https://flexparking-dev.firebaseio.com",
        projectId: "flexparking-dev",
        storageBucket: "flexparking-dev.appspot.com",
        messagingSenderId: "591940643639"
    }
}
else if(EnvSetting.IS_LOCAL()){
    console.log('running local env');
    config = {
        apiKey: "AIzaSyA3sFtu8lQLokrluJUkIwbYXOybfPBGYHE",
        authDomain: "flexparking-dev.firebaseapp.com",
        databaseURL: "https://flexparking-dev.firebaseio.com",
        projectId: "flexparking-dev",
        storageBucket: "flexparking-dev.appspot.com",
        messagingSenderId: "591940643639",
        
        //local
        port: 8899 // this should be the same as firebase.json emulators.firestore.port
    }
};

const firebaseApp = firebase.initializeApp(config);
const firestoreApi = firebaseApp.firestore();
var _firebaseFunctions = firebase.functions();

if(EnvSetting.IS_PROD()){
    firebase.analytics();
}


if(EnvSetting.IS_LOCAL()){
    console.log('running local environment');
    firestoreApi.useEmulator("0.0.0.0", config.port);
    _firebaseFunctions.useEmulator("localhost", 5001);
}

export const firebaseFunctions = _firebaseFunctions;
export const firebaseStorage = firebase.storage();
export const auth = firebase.auth();
export const firedatabaseApi = firebaseApp.database();

export default firestoreApi;