import EnvSetting from '../../config/env';

class LogUtil {
  static devOnly(...o) {
    if(EnvSetting.IS_DEV() || EnvSetting.IS_LOCAL()){
      console.log(o);
    }
  }
}

export default LogUtil;

