
import * as types from "../constants/actionTypes";


export function setCaptcha(response){
    return function(dispatch) {
        document.body.classList.add("captchaVerified");//styling
        return dispatch({type: types.SET_CAPTCHA });
    };
}
