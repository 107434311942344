import * as types from "../constants/actionTypes";
import { auth } from '../firebase';
import * as ajaxCalls from "./ajaxCalls.js";



import urls from '../shared/constants/urls';


export function setUserInfo(info) {
  return function(dispatch) {
    if (info) {
      return dispatch(success(info));
    } else {
      return dispatch(error());
    }
  };
}

export function loginAnonym() {
  return function(dispatch) {
    return auth.signInAnonymously()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.USER_ANONYM_LOGIN)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}

export function loginWithEmail(user) {
  return function(dispatch) {
    return auth.signInWithEmailAndPassword(user.email, user.password)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.USER_LOGIN)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}

export function resetPassword (email) {
  
  var actionCodeSettings = {
    url: urls.ABSOLUTE_ROOT_URL()+urls.LOGIN_URL,
    handleCodeInApp: true
  };
  return function(dispatch) {
    return auth.sendPasswordResetEmail(email, actionCodeSettings)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.USER_RESET_PASSWORD)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}

export function logoutUser() {
  return function(dispatch) {
    return auth.signOut()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.USER_LOGOUT)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}

export function createUser(user) {
  return function(dispatch) {
    return auth.createUserWithEmailAndPassword(user.email, user.password)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.USER_SIGNIN)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}





function success(response) {
  return {
    type: types.SET_USER_INFO_SUCCESS,
    data: response
  };
}

function error() {
  return {
    type: types.SET_USER_INFO_ERROR,
    data: null
  };
}
