import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import * as formNames from "../../../constants/forms";
import {
  TextInput
} from "../../../components/form/inputs";


import validate from "./validate";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Box, CircularProgress } from "@material-ui/core";

const LoginForm = props => {
  const {
    handleSubmit,
    pristine,
    submitting,
    classes,
    proceedSubmit
  } = props;
  
  const disableSubmit = pristine || submitting;
  const submitButton = <Button variant="contained" color="primary" type="submit" size="large" fullWidth disabled={disableSubmit} startIcon={submitting ? <CircularProgress size={14} thickness={4} color="secondary" /> : null}>Login</Button>;


  return (
    <Box pb={2} pt={1}>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classes.customForm}>
           
          <div className="maxWidthPart">
          <Grid container spacing={2}>
            
            
            <Grid item xs={12}>
              <FormGroup>
                <Field name="email" component={TextInput} label="Email" required />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <Field name="password" type="password" component={TextInput} label="Password" required />
              </FormGroup>
            </Grid>

            
            
            <Grid item xs={12}>
            <Box pt={2}>
              { submitButton }
              </Box>
            </Grid>
          </Grid>
          </div>
        </form>
    </Box>
  );
};


export default withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.USER_LOGIN_FORM, // a unique identifier for this form
  validate,
  //asyncValidate
})(LoginForm),formNames.USER_LOGIN_FORM);
