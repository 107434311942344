import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Icon from '@material-ui/core/Icon';
import {withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button/Button';

import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import urls from '../../../../shared/constants/urls';

class NotFound extends React.Component {


  render() {
    const { history } = this.props;

    return <div className="public-container-wrap error-wrap"><Grid container spacing={4} alignContent="center">
   <Grid item xs={12}>
      <Typography  align="center" className="icon-wrap">
      <Icon style={{ fontSize: 100 }} color="error">error</Icon>
      </Typography>
      </Grid>
    <Grid item xs={12}>
    <div className="text-center"><Typography variant="h2" gutterBottom className="large-heading">
        {TranslatorUtil.t("Oh no.")}
        <br/>
        {TranslatorUtil.t("There is no parking here.")}
      </Typography>
      <Button style={{display: 'inline-block'}} variant="contained" color="primary" onClick={()=>{history.push(urls.INDEX_URL);}}>
        {TranslatorUtil.t("Back to homepage")}
      </Button>
      </div>
      </Grid>
      
      
      
    </Grid>
    </div>

  }
}

export default withRouter(NotFound);
