import * as types from '../constants/actionTypes';


export function getRepeatingRosterSetting(state = [], action) {
    if (action.type === types.GET_REPEATING_ROSTER_SETTING) {
        console.log(types.GET_REPEATING_ROSTER_SETTING+' set in store');
        return action.data;
    }
    return state;
}

export function getRepeatingRoster(state = [], action) {
    if (action.type === types.GET_REPEATING_ROSTER) {
        console.log(types.GET_REPEATING_ROSTER+' set in store');
        return action.data;
    }
    return state;
}


export function getParkRepeatingRosterSetting(state = [], action) {
    if (action.type === types.GET_PARK_REPEATING_ROSTER_SETTING) {
        console.log(types.GET_PARK_REPEATING_ROSTER_SETTING);
        return Array.isArray(action.data) ? action.data : [];
    }
    return state;
} 



