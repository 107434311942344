import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import * as profileActions from "../../../../actions/profile";
import * as parkActions from "../../../../actions/park";
import * as parkStatesActions from "../../../../actions/parkStates";
import ComponentLoading from '../../../../components/ComponentLoading';

import PageHeading from '../../../../components/PageHeading';
import objectPath from 'object-path';
import AdminCompanyMenu from '../../../../components/admin/AdminCompanyMenu';

import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../../../shared/utils/general/ArrayUtil';

import { BugReport, Check, CheckCircle, Clear, ReportProblem } from '@material-ui/icons';
import AppSortableTable from '../../../../components/table/AppSortableTable';
import DateUtil from '../../../../shared/utils/general/DateUtil';
import { Box, Button, FormControlLabel, Grid, Select } from '@material-ui/core';
import Calendar from 'react-calendar';
import moment from 'moment';
import { green, red } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import UIFormatterUtil from '../../../../utils/UIFormatterUtil';
import ParkStateUtil from '../../../../shared/utils/ParkStateUtil';
import { firedatabaseApi } from '../../../../firebase';
import constants from '../../../../shared/constants/constants';
import CompanyUtil from '../../../../shared/utils/CompanyUtil';

class BookingConsole extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
      dataLoading: true,
      company: null,
      duplicatesToRemove: [],
      duplicatesToKeep: [],
      showConflictsOnly: false,
      parkIdFilter: "",
        days: [],
        selectedDate: DateUtil.getTwoWeeksAgo(),
        endDate: moment(),
    }   
  }

  componentDidMount() {
    const { actions, match } = this.props;
    let _this = this;
        this.mountCompany().then((response) => {
          var company = objectPath.get(response,'data');
          _this.setState({company: company});
        if(objectPath.get(match,'params.id') === objectPath.get(company,'id')){
          Promise.all([
            actions.getParksByCompanyId(company.companyId),
            actions.getProfilesByCompanyId(company.companyId),
            this.getParkStatuses()
          ]).then(()=>{
            console.log('loading done');
            _this.setState({listDataLoading: false});
  
            //catch errors
          }).catch(function(err) {
            console.log('loading error',err);
            _this.setState({listDataLoading: false});
          });
        } else {
          _this.setState({listDataLoading: false});
        }
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  mountCompany = () =>{
    const { actions, match, company } = this.props;
    if(objectPath.get(match,'params.id') && objectPath.get(match,'params.id') !== objectPath.get(company,'id')){
      return actions.getCompany(objectPath.get(match,'params.id'));
    } else {
      return Promise.resolve().then(() => {return {data: company}});
    }
  }



  getParkStatuses = () => {
    const { actions } = this.props;
    const { company,selectedDate } = this.state;
    var days = DateUtil.getTwoWeeks(selectedDate, false, CompanyUtil.includeWeekend(company));
      return actions.getParksStatesByCompanyIdAndDates(
        company.companyId,
        days
      ).then(() => this.setState(
        ParkStateUtil.getBookingDuplicates(this.getFilteredParkStates())
        ));
    
  }

  getFilteredParkStates = () => {
    const { parkIdFilter } = this.state;
    const { parkStates } = this.props;
    return parkIdFilter ? parkStates.filter(x => x.parkId === parkIdFilter) : parkStates;
  }


  getTableRows = () => {
    const { companyProfiles, companyParks } = this.props;
    const { showConflictsOnly } = this.state;
    var filtered = this.getFilteredParkStates().filter(x => !showConflictsOnly || (showConflictsOnly && this.isConflict(x)));
    return ArrayUtil.isNonEmptyArray(filtered) ? filtered.map(parkState => {
      var park = companyParks.find(p => p.id === parkState.parkId);
      var doneBy = companyProfiles.find(profile => profile.id === parkState.proceededBy);
      return {
        item: {
          ...parkState,
          isDuplicate: this.isDuplicate(parkState)
        },
        items: [
          {
            text: objectPath.get(park,'parkId')
          },
          {
            text: this.isDuplicate(parkState) ? <ReportProblem style={{ color: red[500] }} /> : (showConflictsOnly ? <CheckCircle style={{ color: green[500] }} /> : '')
          },
          {
            text: parkState.date
          },
          {
            text: parkState.dateUnix
          },
          {
            text: doneBy ? doneBy.email : ' - '
          },
          {
            text: doneBy ? UIFormatterUtil.getUserAppDetails(doneBy,true) : ' - ',
            
          },
          {
            text: parkState.updatedUnix ? DateUtil.formatDateTimeSeconds(parkState.updatedUnix) : '-',
            
          },
          {
            text: parkState.lockType ? <Check /> : <Clear />
          },
          {
            text: parkState.id
          },
        ]
      }
    }) : [];
  }

  getTableHeaders = () => {
    return [
      { id: 'parkId', numeric: false,  text: TranslatorUtil.t("Park") },
      { id: 'isDuplicate', numeric: false,  text: TranslatorUtil.t("Duplicate") },
      { id: 'date', numeric: false,  text: TranslatorUtil.t("Date") },
      { id: 'dateUnix', numeric: true,  text: TranslatorUtil.t("DateUnix") },
      { id: 'proceededBy', numeric: false,  text: TranslatorUtil.t("Done by") },
      { id: 'appVersion', numeric: false,  text: TranslatorUtil.t("Version"),
       },
      { id: 'updatedUnix', numeric: true,  text: TranslatorUtil.t("Created") },
      { id: 'lockType', numeric: false,  text: TranslatorUtil.t("Lock type") },
      { id: 'id', numeric: false,  text: TranslatorUtil.t("Park state ID") },
      
    ];
  }

  

  changeDate = (date) => {
    
    this.setState({ 
      selectedDate: moment(date),
      endDate: moment(date).clone().add(13,'days').endOf('day')
    }, () => {
      this.getParkStatuses();
    });
  }


  isDuplicate = (ps) => {
    const { duplicatesToRemove } = this.state;
    return duplicatesToRemove.findIndex(x => x.id === ps.id) !== -1;
  }

  isConflict = (ps) => {
    const { duplicatesToRemove, duplicatesToKeep } = this.state;
    return duplicatesToRemove.findIndex(x => x.id === ps.id) !== -1 || duplicatesToKeep.findIndex(x => x.id === ps.id) !== -1;
  }

  countConflicts = () => {
    return this.getFilteredParkStates().filter(x=>this.isDuplicate(x)).length;
  }

  resolveConflicts = () => {
    const { duplicatesToRemove } = this.state;
    return Promise.all(duplicatesToRemove.filter(dtr => this.getFilteredParkStates().findIndex(x => x.id === dtr.id) !== -1).map(
      dtr => firedatabaseApi.doc(dtr.id).update(constants.UNSET_PARKSTATE)
    ))
    .then(() =>  {
      this.setState({
        showConflictsOnly: false, 
        duplicatesToKeep : [],
        duplicatesToRemove : [],
        parkIdFilter: ""
      });
      this.getParkStatuses();
    });
    
  }


  
  render() {

    const { company, companyParks } = this.props;
    const { listDataLoading, selectedDate, endDate, showConflictsOnly } = this.state;
    const calendarDateValue = selectedDate.clone().toDate();

    return <div className="app-container-wrap app-admin wide-table-wrap">
        <PageHeading icon={<BugReport />} title={TranslatorUtil.t("Conflicts")} />

        <AdminCompanyMenu company={company} />

        { listDataLoading ? <ComponentLoading /> : <React.Fragment>
        <Box mb={2}>
        
        <Calendar
        tileClassName={({ activeStartDate, date, view }) => {
          return moment(date).isBetween(selectedDate, endDate, 'days', '[]') ? 'inRange' : 'notInRange'
        }}
        onChange={this.changeDate}
        value={calendarDateValue}
      />
      </Box>

        <Grid container spacing={4} alignContent="center">
            <Grid item xs={12} sm={4}>
            { TranslatorUtil.t("Filter") } : <Select
          native
          value={this.state.parkIdFilter}
          onChange={(event) => this.setState({
            parkIdFilter: event.target.value
          })}
        >
          <option value="">{ TranslatorUtil.t("All") }</option>
          {companyParks.map(x => <option key={x.id} value={x.id}>{x.parkId}</option>)}
        </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
            <FormControlLabel
                control={
                  <Switch
                    checked={showConflictsOnly}
                    onChange={() => this.setState({showConflictsOnly: !showConflictsOnly})}
                    color="primary"
                  />
                }
                label={"Show conflicts only ("+this.countConflicts()+")"}
              />
              {
                showConflictsOnly ? <Button variant="contained" onClick={this.resolveConflicts}><ReportProblem style={{ color: red[500] }} /> Resolve conflicts automatically</Button> : null
              }
            </Grid>
            <Grid item xs={12} sm={4}>
            <Button variant='contained' onClick={() => this.changeDate(calendarDateValue)}>Refresh</Button>
            </Grid>
          </Grid>
          <Box mb={2}></Box>
        <AppSortableTable hasActions={false} headers={this.getTableHeaders()} rows={this.getTableRows()} defaultSortBy="date" />
          </React.Fragment> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    company: state.company,
    companyProfiles: state.companyProfiles,
    companyParks: state.companyParks,
    parkStates: state.parkStates,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...profileActions,
        ...parkActions,
        ...parkStatesActions,
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingConsole));
