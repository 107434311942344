import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';


function ComponentLoading(props) {
  return (
      <div className={"componentLoader"}>
      
      <div className="innerWrap">
        
  {props.showText === false ? null : <Typography variant="body2" className="text">{"Please wait"}</Typography> }
        <CircularProgress className="progress" />
      </div>
      
      
    </div>
  );
}


export default ComponentLoading;