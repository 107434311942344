import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import * as invitationActions from "../../../../actions/invitation";
import * as parkActions from "../../../../actions/park";
import * as profileActions from "../../../../actions/profile";
import * as billingActions from "../../../../actions/billing";
import ComponentLoading from '../../../../components/ComponentLoading';
import AppTable from '../../../../components/table/AppTable';
import BusinessIcon from '@material-ui/icons/Business';
import PageHeading from '../../../../components/PageHeading';
import objectPath from 'object-path';
import AdminCompanyMenu from '../../../../components/admin/AdminCompanyMenu';

import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import CompanyUtil from '../../../../shared/utils/CompanyUtil';
import ArrayUtil from '../../../../shared/utils/general/ArrayUtil';
import constants from '../../../../shared/constants/constants';
import firestoreApi from '../../../../firebase';
import FirestoreUtil from '../../../../shared/utils/general/FirestoreUtil';
import DateUtil from '../../../../shared/utils/general/DateUtil';
import { Box } from '@material-ui/core';

class CompanyDashboard extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
      billingRecords: [],
    }   
  }

  componentDidMount() {
    const { actions, match } = this.props;
    let _this = this;
        this.mountCompany().then((response) => {
          var company = objectPath.get(response,'data');
        if(objectPath.get(match,'params.id') === objectPath.get(company,'id')){
          Promise.all([
            actions.getBillingsByCompanyId(company.companyId),
            actions.getProfilesByCompanyId(company.companyId),
            actions.getParksByCompanyId(company.companyId),
            actions.getInvitationsByCompanyId(company.companyId),
          ]).then(()=>{
            console.log('loading done');
            _this.setState({listDataLoading: true});
            return firestoreApi.collection('billingRecords')
            .where("companyId", "==", company.companyId)
            .get()
            .then((data)=>
            _this.setState({
              billingRecords : FirestoreUtil.parseCollectionData(data),
              listDataLoading: false
            })
            )
            //catch errors
          }).catch(function(err) {
            console.log('loading error',err);
            _this.setState({listDataLoading: false});
          });
        } else {
          _this.setState({listDataLoading: false});
        }
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  mountCompany = () =>{
    const { actions, match } = this.props;
      return actions.getCompany(objectPath.get(match,'params.id'));
  }



  getTableRows = () => {
    const { company, companyParks, companyInvitations, companyProfiles } = this.props;
    const { billingRecords } = this.state;

    var rows = [];
    if(company){

      //name
      rows.push({
        items: [
          {
            text: TranslatorUtil.t("Display name")
          },
          {
            text: CompanyUtil.getDisplayName(company)
          },
        ]
      });
      //created
      rows.push({
        items: [
          {
            text: TranslatorUtil.t("Created")
          },
          {
            text: DateUtil.formatDateTimeShort(company.createdUnix)
          },
        ]
      });
      //plan
      rows.push({
        items: [
          {
            text: TranslatorUtil.t("Plan")
          },
          {
            text: CompanyUtil.getPlanName(company)
          },
        ]
      });
      //billing
      rows.push({
        items: [
          {
            text: TranslatorUtil.t("Billing estimated total")
          },
          {
            text: CompanyUtil.getBillingsTotal(billingRecords)
          },
        ]
      });
      //price per park
      rows.push({
        items: [
          {
            text: TranslatorUtil.t("Price per park")
          },
          {
            text: company.pricePerPark
          },
        ]
      });
      //park limit
      rows.push({
        items: [
          {
            text: TranslatorUtil.t("Park limit")
          },
          {
            text: company.parksLimit
          },
        ]
      });
      rows.push({
        items: [
          {
            text: TranslatorUtil.t("Admin")
          },
          {
            text: company.userEmail
          },
        ]
      });
      rows.push({
        items: [
          {
            text: TranslatorUtil.t("Managers")
          },
          {
            text: ArrayUtil.isNonEmptyArray(company.managerIds) ? company.managerIds.filter(mId => companyProfiles.findIndex(cp => cp.id === mId) !== -1).map(mId => companyProfiles.find(cp => cp.id === mId).email).join(', ') : ' - '
          },
        ]
      });
      //parks
        rows.push({
          items: [
            {
              text: TranslatorUtil.t("Parks")
            },
            {
              text: ArrayUtil.isNonEmptyArray(companyParks) ? companyParks.length : 0
            },
          ]
        });
      
      //profiles
        rows.push({
          items: [
            {
              text: TranslatorUtil.t("Users")
            },
            {
              text: ArrayUtil.isNonEmptyArray(companyProfiles) ? companyProfiles.length : 0
            },
          ]
        });

      //profiles
        rows.push({
          items: [
            {
              text: TranslatorUtil.t("Invitations")
            },
            {
              text: ArrayUtil.isNonEmptyArray(companyInvitations) ? companyInvitations.length : 0
            },
          ]
        });


        rows.push({
          items: [
            {
              text: <Box my={2}>{TranslatorUtil.t("Fair use")}</Box>
            },
            {
              text: ''
            },
          ]
        });

        rows.push({
          items: [
            {
              text: TranslatorUtil.t("Maximum parks per user")
            },
            {
              text: CompanyUtil.getMaxParksPerUserPerPeriod(company)
            },
          ]
        });
        rows.push({
          items: [
            {
              text: TranslatorUtil.t("Visible days for normal users")
            },
            {
              text: CompanyUtil.getVisibleDaysAhead(company) + (CompanyUtil.getVisibleDaysAhead(company) === constants.DEFAULT_VISIBLE_DAYS_AHEAD ? ' (DEFAULT VALUE)' : '')
            },
          ]
        });
        rows.push({
          items: [
            {
              text: TranslatorUtil.t("Priority user")
            },
            {
              text: CompanyUtil.getNextPriorityUserEmail(company)
            },
          ]
        });
        rows.push({
          items: [
            {
              text: TranslatorUtil.t("Priority queue")
            },
            {
              text: ArrayUtil.isNonEmptyArray(CompanyUtil.getPriorityQueue(company)) ? CompanyUtil.getPriorityQueue(company).join(', ') : ' - '
            },
          ]
        });
        
    }


    return rows;
  }


  
  render() {

    const { company } = this.props;
    const { listDataLoading } = this.state;

    return <div className="app-container-wrap app-admin">
        <PageHeading icon={<BusinessIcon />} title={TranslatorUtil.t("Company overview")} />

        <AdminCompanyMenu company={company} />
        { listDataLoading ? <ComponentLoading /> : <AppTable hasActions={true} rows={this.getTableRows()} /> 
        
        }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    company: state.company,
    companyProfiles: state.companyProfiles,
    companyInvitations: state.companyInvitations,
    companyParks: state.companyParks,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...billingActions,
        ...parkActions,
        ...profileActions,
        ...invitationActions,
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDashboard));
