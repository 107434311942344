
import ValidationUtil from '../../../utils/ValidationUtil';

const validate = values => {
  let errors = {};
  const requiredFields = [
    "email",
    "password"
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);

  errors = ValidationUtil.checkStringMaxLength(['email'],values,errors,150);

  errors = ValidationUtil.checkStringMinLength(['password'],values,errors,8);

  errors = ValidationUtil.isEmailFormat(['email'],values,errors);

  return errors;
};

export default validate;
