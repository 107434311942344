

import React from 'react';
import { Button, FormControlLabel, Switch } from '@material-ui/core';
import firestoreApi from '../../../../firebase.js';
import objectPath from 'object-path';
import FirestoreUtil from '../../../../shared/utils/general/FirestoreUtil';
import UserUtil from '../../../../shared/utils/UserUtil.js';

export default class MigrationInvitationsAccepted extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
          loading: false,

          validInputs: true,
          simulateResults: [],
          migrationTextResult: 'No result',
          simulate: true,


          migrationName: 'MigrationInvitationsAccepted',
          
        }   
      }

      revalidate = () => { 

        this.setState({
            validInputs : false
        })
        if(this.isValid()){
            this.setState({
                validInputs : true
            })
            return true;
        };
        return false;
      }


      isValid = () => { 
        return true;
      }


  runMigration = () =>{
    const { simulate } = this.state;

    var batch = firestoreApi.batch();
    var readCounts = 0;
    var updateCounts = 0;
    var fixedCounts = 0;
    var updateLimit = 250;
    var simulateResults = [];
    
    
    if(this.isValid()){
        //modify migration if needed
        Promise.all([
            firestoreApi.collection('invitations').get(),
            firestoreApi.collection('profiles').get()
        ])
        .then(data => {
            if (data[0] && data[1]) {
                var invitations = FirestoreUtil.parseCollectionData(data[0]);
                var profiles = FirestoreUtil.parseCollectionData(data[1]);

                invitations.forEach(invitation => {
                    readCounts++;
                    if(!objectPath.has(invitation,'accepted') && updateCounts < updateLimit){
                        var newAccpted = profiles.findIndex(x => UserUtil.compareProfilesByEmail(x,invitation)) !== -1;

                        if(simulate){
                            simulateResults.push(invitation.email + newAccpted ? ' invitation has been accepted' : ' invitation has never been accepted');
                            this.setState({
                                simulateResults: simulateResults
                            });
                        } else {
                            var updateRef = firestoreApi.collection("invitations").doc(invitation.id);
                            batch.update(updateRef, {"accepted": newAccpted});
                        }
                        updateCounts++;
                    } 
                    if(objectPath.has(invitation,'accepted')) {
                        fixedCounts++;
                    }
                });
            }
            var migrationTextResult = ((simulate ? '(Simulation ON) ' :'') +' Results count : ' + readCounts + ' / already fixed count : ' + fixedCounts + ' / updates count : ' + updateCounts + ' (limit : '+updateLimit+')');

            this.setState({
                migrationTextResult: migrationTextResult,
                simulateResults: simulateResults
            });

            if(simulate){
                return Promise.resolve();
            } else {
                // return Promise.resolve();
                return batch.commit().then((batchResult) => {
                    console.log(migrationTextResult);
                    return migrationTextResult;
                });
            }
            
        });
    }
    
  }


  render() {
    const { loading, validInputs, simulate, migrationTextResult, migrationName, simulateResults } = this.state;
    return <div className="innerWrap">
        <h2>{ migrationName }</h2>
        <p>Get all invitations an profiles and mark invitation as accepted to the ones which are missing this property.</p>
        <div>
            <div>
            <Button variant="contained" onClick={this.runMigration} color="primary" disabled={loading || !validInputs}>{loading ? "Processing" : "Run" }</Button> &nbsp;&nbsp;&nbsp;
                <FormControlLabel
                    control={<Switch checked={simulate} onChange={() => this.setState({ simulate: !simulate })} />}
                    label={simulate ? "Simulate" : "Running updates"}
                />
                
            </div>
            
        </div>

        <div>
            <div>{migrationTextResult}</div>
            <div>{simulateResults.map(x => <div>{x}<hr/></div>)}</div>
        </div>

    </div>
  }
}
