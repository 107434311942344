import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreUtil from '../shared/utils/general/FirestoreUtil';


const collectionName = 'repeatingRoster';
const rosterSettingCollectionName = 'setting';

  function settingCollection (companyId){
    return [collectionName,companyId,rosterSettingCollectionName].join("/");
  }

  export function listenRepeatingRosterSettingByCompanyId(companyId,callback) {
    return function(dispatch) {
      console.log('listenRepeatingRosterSettingByCompanyId called');
      return firestoreApi.collection(settingCollection(companyId))
      .onSnapshot(
        response => {
          callback();
          return dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response),  types.GET_REPEATING_ROSTER_SETTING))
        },
          error => {
            callback();
            return dispatch(ajaxCalls.ajaxCallError(error, types.GET_REPEATING_ROSTER_SETTING))
          }
        );
    };
  }

  export function addRepeatingRosterSetting(companyId, data) {
    return function(dispatch) {
      console.log('addRepeatingRosterSetting');
      return firestoreApi.collection(settingCollection(companyId)).add(FirestoreUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.ADD_REPEATING_ROSTER_SETTING)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.ADD_REPEATING_ROSTER_SETTING))
        );
    };
  }

  export function getParkRepeatingRosterSetting(companyId, parkId) {
    return function(dispatch) {
      console.log('getParkRepeatingRosterSetting by company and park');
      return firestoreApi.collection(settingCollection(companyId)).where('parkId',"==",parkId).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_PARK_REPEATING_ROSTER_SETTING)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PARK_REPEATING_ROSTER_SETTING))
      );
    };
  }


export function removeRepeatingRosterSetting(companyId, id) {
      
      return function(dispatch) {
        console.log('removeRepeatingRosterSetting');
        return firestoreApi.collection(settingCollection(companyId)).doc(id).delete().then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.REMOVE_REPEATING_ROSTER_SETTING)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.REMOVE_REPEATING_ROSTER_SETTING))
        );
      }
  }


  export function getRepeatingRoster(companyId) {
    return function(dispatch) {
      console.log('getParkRepeatingRoster');
      return firestoreApi.collection(collectionName).doc(companyId).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionItemData(response), types.GET_REPEATING_ROSTER)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_REPEATING_ROSTER))
        );
    };
  }

  export function listenRepeatingRoster(companyId,callback) {
    return function(dispatch) {
      console.log('listenParkRepeatingRoster called');
      return firestoreApi.collection(collectionName).doc(companyId)
      .onSnapshot(
        response => {
          callback();
          return dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionItemData(response),  types.GET_REPEATING_ROSTER))
        },
          error => {
            callback();
            return dispatch(ajaxCalls.ajaxCallError(error, types.GET_REPEATING_ROSTER))
          }
        );
    };
  }

  export function putRepeatingRoster(companyId, data) {
    return function(dispatch) {
      console.log('putParkRepeatingRoster');
      return firestoreApi.collection(collectionName).doc(companyId).update(FirestoreUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_REPEATING_ROSTER)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_REPEATING_ROSTER))
        );
    };
  }

  export function setRepeatingRoster(companyId, data) {
    return function(dispatch) {
      console.log('setRepeatingRoster');
      return firestoreApi.collection(collectionName).doc(companyId).set(FirestoreUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.SET_REPEATING_ROSTER)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.SET_REPEATING_ROSTER))
        );
    };
  }

  

 

