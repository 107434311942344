import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ComponentLoading from '../../../components/ComponentLoading';
import PageHeading from '../../../components/PageHeading';

import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
import { Code } from '@material-ui/icons';
import StoreUtil from '../../../utils/StoreUtil';
import * as types from "../../../constants/actionTypes";
import MigrationDateUnix from './migrations/MigrationDateUnix';
import LinkRef from '../../../components/LinkRef';
import MigrationDoubleBookingConflictResolver from './migrations/MigrationDoubleBookingConflictResolver';
import objectPath from 'object-path';
import { Button } from '@material-ui/core';
import MigrationTimezoneDateUnix from './migrations/MigrationTimezoneDateUnix';
import MigrationInvitationsAccepted from './migrations/MigrationInvitationsAccepted';


class Migrations extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
    }   
  }

  componentDidMount() {
    let _this = this;
    this.flushCompany();
      Promise.all([
      ]).then(() => {
        Promise.all([
          
        ]).then(()=>{
          console.log('loading done');
          _this.setState({listDataLoading: false});

          //catch errors
        }).catch(function(err) {
          console.log('loading error',err);
          _this.setState({listDataLoading: false});
        });
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  flushCompany = () => {
      StoreUtil.dispatchAction({data: null,  type: types.GET_COMPANY });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_PROFILES });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_PARKS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_BILLINGS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_INVITATIONS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_PARK_STATUSES });
      StoreUtil.dispatchAction({data: [],  type: types.GET_PARK_OWNERSHIPS });
    
  }

  allowedMigrations = () => {
    //should match name of the file
    return [
      //'MigrationDateUnix', // BUG DID NOT TAKE TIMEZONE INTO ACCOUNT!!!!
      'MigrationTimezoneDateUnix',
      'MigrationDoubleBookingConflictResolver',
      'MigrationInvitationsAccepted'
    ];
  }

  getCurrentMigrationName = () => {
    const { match } = this.props;
    const id = objectPath.get(match,'params.id');
    return this.allowedMigrations().includes(id) ? id : null;
  }

  renderCurrentMigration = () => {
    const id = this.getCurrentMigrationName();
    if(id){
      if(id==='MigrationDateUnix'){
        return <MigrationDateUnix />
      } else if(id==='MigrationTimezoneDateUnix'){
        return <MigrationTimezoneDateUnix />
      } else if(id==='MigrationDoubleBookingConflictResolver'){
        return <MigrationDoubleBookingConflictResolver />
      } else if(id==='MigrationInvitationsAccepted'){
        return <MigrationInvitationsAccepted />
      }
    }
    return null;
  }




  render() {

    const { listDataLoading } = this.state;
    

    return <div className="app-container-wrap app-admin wide-table-wrap">
        <PageHeading icon={<Code />} title={TranslatorUtil.t("Migrations")} />

        { listDataLoading ? <ComponentLoading /> : <React.Fragment>
            { this.renderCurrentMigration() }
           {
             this.getCurrentMigrationName() === null ? this.allowedMigrations().map(x => <Button key={x} component={LinkRef} to={'/admin/migrations/' + x}>{x}</Button>) : null
           }
        </React.Fragment> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Migrations));
