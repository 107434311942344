import * as types from '../constants/actionTypes';




export function setBookingSelection(state = false, action) {
    if (action.type === types.SET_BOOKING_SELECTION) {
        console.log(types.SET_BOOKING_SELECTION+' set in store');
        return action.data.enabled;
    }
    return state;
}

export function getBookingSelection(state = [], action) {
    if (action.type === types.ADD_BOOKING_SELECTION) {
        return [...state, action.data];
    }
    if (action.type === types.REMOVE_BOOKING_SELECTION) {
        let newState = [...state];
        newState.splice(action.data.index, 1);
        return newState;
    }
    if (action.type === types.REMOVE_BOOKING_SELECTIONS) {
        return [];
    }
    return state;
}

