import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Paper } from '@material-ui/core';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as invitationsActions from "../../../actions/invitation";
import * as profilesActions from "../../../actions/profile";
import AppLoading from '../../../components/AppLoading';
import Logo from '../../../components/Logo';
import objectPath from 'object-path';

import DateUtil from '../../../shared/utils/general/DateUtil';
import UserUtil from '../../../shared/utils/UserUtil';
class ProfileSetup extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      companyInvitation: null
    }
  }
  


  setProfileType = (isAdmin = false, companyInvitation = null) => {
    const { userInfo, user, actions } = this.props;
    
    if(userInfo){
      console.log('set profile call');
      this.setState({loading:true});
      var serverTime = DateUtil.getServerTime();
      var newProfile = {
        id:UserUtil.getId(userInfo),
        companyAdmin: isAdmin,
        provider: 'password',
        displayName: UserUtil.getDisplayName(userInfo),
        email: UserUtil.getEmail(userInfo),
        phoneNumber: UserUtil.getPhoneNumber(userInfo),
        photoURL: UserUtil.getPhotoURL(userInfo),
        createdUnix: serverTime,
        updatedUnix: serverTime
      };
      if(companyInvitation){
        newProfile.companyId = companyInvitation.companyId;
      }
      return actions.putProfile(newProfile).then(() => {
        if(isAdmin){
          user.sendEmailVerification().then(() => {
            window.location.reload();  
          }); 
        } else {
          window.location.reload();
        }
      });
    }
  }

  getCompanyInvitation = () => {
    const { actions, userInfo } = this.props;
    
      return actions.getInvitationByEmail(UserUtil.getEmail(userInfo)).then((result) => {
          console.log('getCompanyInvitation',result);
            var companyInvitation = objectPath.get(result,'data',null);
            if(companyInvitation){// invitation set as user
              this.setProfileType(false, companyInvitation);
            } else {// no invitation set company
              this.setProfileType(true, companyInvitation);
            }
      });
  }

  componentDidMount(){
    console.log('create profile did mount');
    Promise.all([
      this.getCompanyInvitation()
    ]).then(() => {
    });
  }


  render() {
    const { loading } = this.state;

    

    return (
      loading ? <AppLoading /> : <div className="profileWrap welcomeWrap bodyBgImageWrap companySelectWrap" >


        
      <div className="mainWrap">
      <div><br/><br/></div>
      <Logo className="logo" />
        
        <div class="formHeading">
        <Typography variant="display1">I want to </Typography>
        </div>
      
          <div className="public-container-wrap maxWidthCenterWrap text-center">
<Paper className="paper">
      <Grid container spacing={40} alignContent="center">
        
        <Grid item xs={12} >
        <Button variant="contained" fullWidth color="primary" onClick={() => this.setProfileType(true)}>register company</Button>
        </Grid>
        <Grid item xs={12} >
        <Button variant="fab" mini disabled >
        <CloseIcon />
      </Button>
        </Grid>
        <Grid item xs={12}>
        <Button variant="outlined" fullWidth color="primary" onClick={() => this.setProfileType(false)}>register user</Button>
        </Grid>
        
        </Grid>
        </Paper>
          </div>
          
      </div>


          
      </div> 
    );
  }
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo,
    servertimeOffset: state.servertimeOffset,
  });
  
  const mapDispatchToProps = dispatch =>
  ({
    actions: bindActionCreators(
      {
        ...invitationsActions,
        ...profilesActions,
      },
      dispatch
    )
  });
  
  
export default 
connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileSetup);


