import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Card, CardHeader, CardContent, ListItem, ListSubheader, List, Button, Box } from '@material-ui/core';
import objectPath from 'object-path';
import ContactMailIcon from '@material-ui/icons/ContactMail';

import DateUtil from '../../shared/utils/general/DateUtil';
import ParkUtil from '../../shared/utils/ParkUtil';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../shared/utils/general/ArrayUtil';
import UserUtil from '../../shared/utils/UserUtil';
export default class HistoryOwnershipCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        showHistory: false
    };
  }

  toggleHistory = () => {
    this.setState({showHistory: !this.state.showHistory});
}

  render() {
    const { showHistory } = this.state;
    const { users, parksOwnershipHistory, park } = this.props;

    const parkOwnershipHistory = ArrayUtil.isNonEmptyArray(parksOwnershipHistory) ? parksOwnershipHistory.filter(x => x.parkId === objectPath.get(park,'id')) : [];

        return ArrayUtil.isNonEmptyArray(parkOwnershipHistory) ? <Box className="park-ownership-history">
        {showHistory ? <div>
          <div>
          <Button fullWidth variant="contained" onClick={() => this.toggleHistory()}>
                {TranslatorUtil.t("Hide ownership history")}
            </Button>
            </div>
            <Card className="noShadowCard">
        <CardHeader
          title={TranslatorUtil.t("Ownership history")}
        />
        <CardContent>
        { parkOwnershipHistory.sort((a, b) => a.startedUnix - b.startedUnix).map(poh => {
            var owner = ParkUtil.getParkOwnerByOwnership(poh,users); 
            return (
          <List key={poh.id}>
              
              <ListItem divider disableGutters dense>
                <Typography component="div">
                  <strong>
                    started: { DateUtil.timeConverter(poh.startedUnix/1000) }
                  </strong>
                </Typography>
              </ListItem> 
              
              { owner ? 
              <React.Fragment><ListItem disableGutters dense>
                <ListSubheader component="div">{TranslatorUtil.t("Email")}</ListSubheader>
                
                <Typography variant="body2" component="div">{owner.email}</Typography>
              </ListItem>
              {UserUtil.isTempUserInvitationOnly(owner) ? <ListItem disableGutters dense>
                <Typography variant="caption" component="div"><ContactMailIcon color="secondary" size="small"/> &nbsp; {TranslatorUtil.t("Invitation only")}</Typography>
              </ListItem> : null}
              {
                poh.endedUnix ? <ListItem disableGutters dense>
                <ListSubheader component="div">{TranslatorUtil.t("end")}</ListSubheader>
                <Typography variant="body2">{DateUtil.timeConverter(poh.endedUnix/1000)}</Typography>
              </ListItem> : null
              }
              </React.Fragment>
              :  
              <ListItem disableGutters dense>
                <ListSubheader component="div">{TranslatorUtil.t("Owner not found")}</ListSubheader>
              </ListItem>}

              
              


            </List>)
            }
            )
          }
        </CardContent>
        
      </Card></div> : 
      <div>
      <Button fullWidth variant="contained" color="primary" onClick={() => this.toggleHistory()}>
      {TranslatorUtil.t("Show ownership history")}
        </Button>
        </div>
  }
        </Box> : null
      }
      
    }
    