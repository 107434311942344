import React from "react";
import { Route, Switch } from "react-router";

import Companies from "../containers/pages/admin/Companies";
import Migrations from "../containers/pages/admin/Migrations";

import CompanyDashboard from "../containers/pages/admin/company/Dashboard";
import CompanyBilling from "../containers/pages/admin/company/Billings";
import BookingConsole from "../containers/pages/admin/company/BookingConsole";
import NotificationConsole from "../containers/pages/admin/company/NotificationConsole";
import CompanyUsers from "../containers/pages/admin/company/Users";
import CompanyParks from "../containers/pages/admin/company/Parks";
import CompanyInvitations from "../containers/pages/admin/company/Invitations";
import CompanySettings from "../containers/pages/admin/company/Settings";
import CompanyBlock from "../containers/pages/admin/company/Block";


import NotFound from "../containers/pages/public/static/NotFound";
import ResetPassword from "../containers/pages/user/ResetPassword";
import Profile from "../containers/pages/user/Profile";

import HowItWorks from "../containers/pages/public/static/HowItWorks";
import TermsAndConditions from "../containers/pages/public/static/TermsAndConditions";
import PrivacyPolicy from "../containers/pages/public/static/PrivacyPolicy";

import urls from '../shared/constants/urls';
import Crm from "../containers/pages/admin/Crm";
import BookingDashboard from "../containers/pages/admin/company/BookingDashboard";
import TotalBilling from "../containers/pages/admin/TotalBilling";

const AppRoutes = (props) => {


  return (
    <Switch>

          <Route exact path={urls.HOW_IT_WORKS_URL} component={HowItWorks} />
          <Route exact path={urls.PRIVACY_POLICY_URL}  component={PrivacyPolicy} />
          <Route exact path={urls.TAC_URL} component={TermsAndConditions} />

          <Route exact path={urls.PROFILE_URL} component={Profile} />

          <Route exact path={urls.INDEX_URL} component={Companies} />
          <Route exact path={urls.ADMIN_COMPANIES_URL} component={Companies} />
          <Route exact path={urls.ADMIN_MIGRATION_URL} component={Migrations} />
          <Route exact path={urls.ADMIN_CRM_URL} component={Crm} />
          <Route exact path={urls.ADMIN_TOTAL_BILLING_URL} component={TotalBilling} />

          <Route exact path={urls.ADMIN_COMPANY_URL()} component={CompanyDashboard} />
          <Route exact path={urls.ADMIN_COMPANY_BILLING_URL()} component={CompanyBilling} />
          <Route exact path={urls.ADMIN_COMPANY_USERS_URL()} component={CompanyUsers} />
          <Route exact path={urls.ADMIN_COMPANY_PARKS_URL()} component={CompanyParks} />
          <Route exact path={urls.ADMIN_COMPANY_INVITATIONS_URL()} component={CompanyInvitations} />
          <Route exact path={urls.ADMIN_COMPANY_SETTINGS_URL()} component={CompanySettings} />
          <Route exact path={urls.ADMIN_COMPANY_BLOCK_URL()} component={CompanyBlock} />
          <Route exact path={urls.ADMIN_COMPANY_BOOKING_CONSOLE_URL()} component={BookingConsole} />
          <Route exact path={urls.ADMIN_COMPANY_BOOKING_DASHBOARD_URL()} component={BookingDashboard} />
          <Route exact path={urls.ADMIN_COMPANY_NOTIFICATION_CONSOLE_URL()} component={NotificationConsole} />
          
          <Route exact path={urls.RESET_PASSWORD_URL} component={ResetPassword} />

          
          <Route component={NotFound} />
      
    </Switch>
  );
};

export default AppRoutes;
