import objectPath from "object-path";

export default class UIFormatterUtil {

    static getUserAppDetails(profile, includeOS = false) {
        return [
          objectPath.get(profile,'appVersion',null),
          objectPath.get(profile,'appBuild',null),
          objectPath.get(profile,'appEnv',null),
          includeOS ? objectPath.get(profile,'messagingPlatform',null) : null
        ].filter(x => x !== null).join(' ');
    }

  
}