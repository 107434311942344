import React from 'react';

import { connect } from "react-redux";
import UserUtil from '../../shared/utils/UserUtil.js';
import EnvSetting from '../../shared/config/env';
import { Hidden } from "@material-ui/core";
import ParkStatesDebug from './ParkStatesDebug.js';
import NotificationsDebug from './NotificationsDebug.js';



class DebugConsole extends React.Component {



  render() {
    return UserUtil.getCompanyId(this.props.userInfo)
      && !EnvSetting.IS_PROD() 
      ? <Hidden mdDown>
    <ParkStatesDebug /> 
    <hr /> 
    <NotificationsDebug />
    </Hidden>
          : null;
  }
}

  function mapStateToProps(state) {
    return {
      userInfo: state.userInfo,
    };
  }
  
  
  
  export default connect(
    mapStateToProps
  )(DebugConsole);






