import constants from './constants';

export default {
    INDEX_URL: '/',
    LOGIN_URL: '/login/',
    RESET_PASSWORD_URL: '/reset-password/',
    CREATE_ACCOUNT_URL: '/create-account/',
    DASHBOARD_URL: (date) => '/dashboard/' + (date ? date : ':date?'),
    PROFILE_URL: "/profile/me/",
    HOW_IT_WORKS_URL: '/how-it-works/',
    HOW_IT_WORKS_EXTERNAL_URL: 'https://www.flexparking.co.nz/how-it-works',
    PRIVACY_POLICY_EXTERNAL_URL: 'https://www.flexparking.co.nz/privacy-policy',
    TERMS_OF_USE_EXTERNAL_URL: 'https://www.flexparking.co.nz/terms-of-use',

    PRIVACY_POLICY_URL: '/privacy-policy/',
    TAC_URL: '/terms-and-conditions/',

    PARK_URL: (action, id) => '/park/' + (action ? action : ':action') + '/' + (id ? id : ':id?'),

    COMPANY_PROFILE_URL: "/profile/company/",
    COMPANY_BILLING_URL: "/billing/",
    COMPANY_USERS_URL: "/users/",
    COMPANY_INSIGHTS_URL: "/insights/",
    COMPANY_SETTING_URL: "/settings/",
    COMPANY_FAIRUSE_URL: "/fair-use/",
    COMPANY_REPEATING_ROSTER_URL: "/repeating-roster/",



    ADMIN_DASHBOARD_URL: '/admin/',
    ADMIN_COMPANIES_URL: '/admin/companies/',
    ADMIN_MIGRATION_URL: '/admin/migrations/:id?',
    ADMIN_CRM_URL: '/admin/crm/',
    ADMIN_TOTAL_BILLING_URL: '/admin/total-billing/',
    ADMIN_COMPANY_URL: (id) => '/admin/company/' + (id ? id : ':id'),
    ADMIN_COMPANY_BILLING_URL: (id) => '/admin/company/' + (id ? id : ':id') + '/billings/',
    ADMIN_COMPANY_USERS_URL: (id) => '/admin/company/' + (id ? id : ':id') + '/users/',
    ADMIN_COMPANY_INVITATIONS_URL: (id) => '/admin/company/' + (id ? id : ':id') + '/invitations/',
    ADMIN_COMPANY_PARKS_URL: (id) => '/admin/company/' + (id ? id : ':id') + '/parks/',
    ADMIN_COMPANY_SETTINGS_URL: (id) => '/admin/company/' + (id ? id : ':id') + '/settings/',
    ADMIN_COMPANY_BLOCK_URL: (id) => '/admin/company/' + (id ? id : ':id') + '/block/',
    ADMIN_COMPANY_BOOKING_DASHBOARD_URL: (id) => '/admin/company/' + (id ? id : ':id') + '/booking-dashboard/',
    ADMIN_COMPANY_BOOKING_CONSOLE_URL: (id) => '/admin/company/' + (id ? id : ':id') + '/booking-console/',
    ADMIN_COMPANY_NOTIFICATION_CONSOLE_URL: (id) => '/admin/company/' + (id ? id : ':id') + '/notification-console/',

    ABSOLUTE_ROOT_URL: () => constants.APP_DOMAIN.slice(0, -1),
    
}