import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as repeatingRosterActions from "../actions/repeatingRoster";

import repeatingRosterConstants from "../shared/constants/repeatingRoster";

import DateUtil from "../shared/utils/general/DateUtil";
import UserUtil from "../shared/utils/UserUtil";
import {
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import CompanyUtil from "../shared/utils/CompanyUtil";
import ConfirmDialog from "../components/ConfirmDialog";
import FirestoreQueryUtil from "../shared/utils/FirestoreQueryUtil";
import firestoreApi from "../firebase";
import AlertDialog from "../components/AlertDialog";
import ArrayUtil from "../shared/utils/general/ArrayUtil";
import RepeatingRosterUtil from "../shared/utils/RepeatingRosterUtil";

class RepeatingRoster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      daysToProceedBooking: [],
      daysBookingProceeded: 0,
      manualBookingSelectedOption:
        repeatingRosterConstants.MANUAL_BOOKING_OPTION_ALL,
    };
  }

  proceedManualBooking = () => {
    const { manualBookingSelectedOption } = this.state;
    const { userInfo, actions, onCloseHandler } = this.props;
    var firestoreQueryUtil = new FirestoreQueryUtil(firestoreApi);
    var company = UserUtil.getCompany(userInfo);
    var availableDays = DateUtil.getDaysBetweenDates(
      this.getManualBookingFirstDay(manualBookingSelectedOption),
      this.getManualBookingLastDay(manualBookingSelectedOption),
      CompanyUtil.includeWeekend(company)
    );
    this.setState({
      daysBookingProceeded: 0,
      daysToProceedBooking: availableDays,
    });

    console.log(
      "proceedManualBooking for days",
      availableDays.map((x) => DateUtil.formatDayForCalendar(x.date)).join(",")
    );
    Promise.all(
      availableDays.map((selectedDay, index) => {
        var selectedDate = selectedDay.date;
        return firestoreQueryUtil
          .getRosterBookingData(company, selectedDate)
          .then((bookingData) => {
            var rosterSettingForDay = bookingData[0];
            var parkStatesForDay = bookingData[1];
            console.log("rosterSettingForDay "+DateUtil.formatDayForCalendar(selectedDate), {
              rosterSettingForDay,
              parkStatesForDay,
            });
            //   console.log('parkStatesForDay',{parkStatesForDay});
            var _actions = [];
            var invalidRosterBookingParkStatesToRemove =
              parkStatesForDay.filter(
                (ps) =>
                  ps.lockType &&
                  //roster booking
                  ps.proceededBy === null &&
                  //there is no roster setting for this park and user
                  rosterSettingForDay.findIndex(
                    (rs) => ps.parkId === rs.parkId && ps.userId === rs.userId
                  ) === -1
              );
            //filter out invalid park states that will be removed
            var validRosterBookingParkStates = parkStatesForDay.filter(
              (ps) =>
                invalidRosterBookingParkStatesToRemove.findIndex(
                  (irbps) => irbps.id === ps.id
                ) === -1
            );
            console.log(
              "invalidRosterBookingParkStatesToRemove "+DateUtil.formatDayForCalendar(selectedDate),
              invalidRosterBookingParkStatesToRemove
            );
            console.log(
              "validRosterBookingParkStates "+DateUtil.formatDayForCalendar(selectedDate),
              validRosterBookingParkStates
            );

            _actions =
              //delete invalid parks
              invalidRosterBookingParkStatesToRemove
                .map((irbps) =>
                  firestoreApi.collection("parkStates").doc(irbps.id).delete()
                )
                .concat(
                  //create new bookings
                  firestoreQueryUtil.getActionsToProceedRosterBookingForDay(
                    company,
                    selectedDate,
                    rosterSettingForDay,
                    validRosterBookingParkStates
                  )
                );

            console.log(
              "---- actions to proceed " +
                DateUtil.formatDayForCalendar(selectedDate),
              _actions.length
            );
            return Promise.all(_actions).finally(() => this.updateProgress());
          });
      })
    ).finally(() =>
      actions
        .putRepeatingRoster(UserUtil.getCompanyUid(userInfo), {
          lastRunUnix: DateUtil.getCurrentUnix(),
          unconfirmedUpdate: false,
          lastConfirmationRunUnix: DateUtil.getCurrentUnix(),
          lastDateProcessed: DateUtil.dbFormat(availableDays[availableDays.length-1].date),
        })
        .then(() => onCloseHandler())
    );
  };

  updateProgress = () => {
    const { daysBookingProceeded } = this.state;
    this.setState({
        "daysBookingProceeded": daysBookingProceeded+1,
    });
  }

  getManualBookingFirstDay = (manualBookingSelectedOption) => {
    const { userInfo } = this.props;

    var startDay = DateUtil.getCurrentMoment().add(1, "day"); // tomorrow
    if (
      manualBookingSelectedOption ===
      repeatingRosterConstants.MANUAL_BOOKING_OPTION_HIDDEN
    ) {
      var company = UserUtil.getCompany(userInfo);
      startDay = DateUtil.getCurrentMoment().add(
        CompanyUtil.getVisibleDaysAhead(company),
        "days"
      );
    }
    return DateUtil.ifDisabledDayGetNextAvailableOne(startDay, UserUtil.includeWeekend(userInfo));
  };

  getManualBookingLastDay = () => {
    const { userInfo, repeatingRoster } = this.props;
    var company = UserUtil.getCompany(userInfo);
    var endDay = DateUtil.getCurrentMoment().add(
      CompanyUtil.getVisibleDaysAhead(company) + 3,
      "days"
    );
    
    var lastDateProcessedMoment = RepeatingRosterUtil.getLastServerSideProcessedMomentDate(repeatingRoster);
    endDay = lastDateProcessedMoment > endDay ? lastDateProcessedMoment : endDay;

    return DateUtil.ifDisabledDayGetNextAvailableOne(endDay, UserUtil.includeWeekend(userInfo));
  };

  getDaysRangeTextByBookingOption = (option) => {
    return (
      "(" +
      DateUtil.formatDayWithMonthForCalendar(
        this.getManualBookingFirstDay(option)
      ) +
      // " - " +
      // DateUtil.formatDayWithMonthForCalendar(
      //   this.getManualBookingLastDay(option)
      // ) +
      ")"
    );
  };

  render() {
    const { onCloseHandler, userInfo } = this.props;
    const {
      manualBookingSelectedOption,
      daysToProceedBooking,
      daysBookingProceeded,
    } = this.state;


    return (
      <React.Fragment>
        {ArrayUtil.isNonEmptyArray(daysToProceedBooking) &&
        daysBookingProceeded <= daysToProceedBooking.length ? (
          <AlertDialog
            title={
              <LinearProgress
                size={50}
                thickness={50}
                variant="determinate"
                value={((daysBookingProceeded / daysToProceedBooking.length) * 100)}
              />
            }
            message={"Booking in progress. Please wait ..."}
          />
        ) : (
          <ConfirmDialog
            onConfirm={() => this.proceedManualBooking()}
            onCancel={onCloseHandler}
            title={"When would you like to start this roster?"}
            message={
              <React.Fragment>
                
                <RadioGroup
                  value={manualBookingSelectedOption || ""}
                  onChange={(event) => {
                    this.setState({
                      manualBookingSelectedOption: Number(event.target.value),
                    });
                  }}
                >
                  {repeatingRosterConstants.MANUAL_BOOKING_OPTIONS.map((x) => (
                    <FormControlLabel
                      key={x.label}
                      value={x.value}
                      control={<Radio />}
                      label={
                        x.label +
                        " " +
                        this.getDaysRangeTextByBookingOption(x.value)
                      }
                    />
                  ))}
                </RadioGroup>
                <Typography variant="caption">
                  {
                    "Users can see "+CompanyUtil.getVisibleDaysAhead(UserUtil.getCompany(userInfo))+" days ahead"
                  }
                </Typography>
              </React.Fragment>
            }
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    companyParks: state.companyParks,
    companyInvitations: state.companyInvitations,
    companyProfiles: state.companyProfiles,
    rosterSelection: state.rosterSelection,
    repeatingRosterSetting: state.repeatingRosterSetting,
    repeatingRoster: state.repeatingRoster,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...repeatingRosterActions,
      },
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepeatingRoster)
);
