import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import * as profileActions from "../../../../actions/profile";
import * as parkActions from "../../../../actions/park";
import ComponentLoading from '../../../../components/ComponentLoading';
import AppSortableTable from '../../../../components/table/AppSortableTable';

import PageHeading from '../../../../components/PageHeading';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import objectPath from 'object-path';
import AdminCompanyMenu from '../../../../components/admin/AdminCompanyMenu';

import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../../../shared/utils/general/ArrayUtil';
import firestoreApi from '../../../../firebase';
import FirestoreUtil from '../../../../shared/utils/general/FirestoreUtil';
import DateUtil from '../../../../shared/utils/general/DateUtil';
import CompanyUtil from '../../../../shared/utils/CompanyUtil';
import WarningIcon from '@material-ui/icons/Warning';
import { Box, Typography } from '@material-ui/core';
class CompanyBilling extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
      billingRecords: []
    }   
  }

  componentDidMount() {
    const { actions, match } = this.props;
    let _this = this;
        this.mountCompany().then((response) => {
          var company = objectPath.get(response,'data');
        if(objectPath.get(match,'params.id') === objectPath.get(company,'id')){
          Promise.all([
            actions.getProfilesByCompanyId(company.companyId),
            actions.getParksByCompanyId(company.companyId),
          ]).then(() => {
            return this.loadBillingRecords().then(()=>{
              
              _this.setState({
                listDataLoading: false,
                
              });
            });
  
            //catch errors
          }).catch(function(err) {
            console.log('loading error',err);
            _this.setState({listDataLoading: false});
          });
        } else {
          _this.setState({listDataLoading: false});
        }
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  mountCompany = () =>{
    const { actions, match, company } = this.props;
    if(objectPath.get(match,'params.id') && objectPath.get(match,'params.id') !== objectPath.get(company,'id')){
      return actions.getCompany(objectPath.get(match,'params.id'));
    } else {
      return Promise.resolve().then(() => {return {data: company}});
    }
  }

  loadBillingRecords = () => {
    const { company } = this.props;
    return firestoreApi.collection('billingRecords')
            .where("companyId", "==", objectPath.get(company,'companyId'))
            .get().then(data => this.setState({billingRecords : FirestoreUtil.parseCollectionData(data)}));
  }

  getTableHeaders = () => {
    return [
      { id: 'month',numeric : true, text: TranslatorUtil.t("Month") },
      { id: 'year',numeric : true, text: TranslatorUtil.t("Year") },
      { id: 'calculatedPrice',numeric : true, text: TranslatorUtil.t("Calculated Price") },
      { id: 'billingDay',numeric : true, text: TranslatorUtil.t("Billing day") },
      { id: 'createdUnix',numeric : true, text: TranslatorUtil.t("Created") },
      { id: 'pricePerPark',numeric : true, text: TranslatorUtil.t("Price per park") },
      { id: 'parksCount',numeric : true, text: TranslatorUtil.t("Parks") },
      { id: 'activeUsersCount',numeric : true, text: TranslatorUtil.t("Active users") },
      { id: 'plan',numeric : true, text: TranslatorUtil.t("Plan") },
      { id: 'createdRetrospectively', text: TranslatorUtil.t("Retrospectively") },
    ];
  }


  getTableRows = () => {
    const { billingRecords } = this.state;
    return ArrayUtil.isNonEmptyArray(billingRecords) ? billingRecords.map( item => { return {
      item: item,
      items: [
        {
          text: item.month
        },
        {
          text: item.year
        },
        {
          text: item.calculatedPrice
        },
        {
          text: item.billingDay
        },
        {
          text: item.createdUnix ? DateUtil.formatDateTimeShort(item.createdUnix) : '-',
        },
        {
          text: item.pricePerPark
        },
        {
          text: item.parksCount
        },
        {
          text: item.activeUsersCount
        },
        {
          text: CompanyUtil.getPlanName(item), 
        },
        {
          text: objectPath.get(item,'createdRetrospectively',false) === true ? <WarningIcon /> : '', 
        }
      ]
    }}) : [];
  }

  

  // generateRetrospectively = () => {
  //   const { company, companyProfiles, companyParks } = this.props;
  //   const { billingRecords } = this.state;
  //   var dateStart = moment(company.createdUnix).add(2,'month');
  //   var dateEnd = moment().subtract(1,'month');
  //   var timeValues = [];
  //   var billingPeriod = null;

  //   while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
  //     billingPeriod = {
  //       month: dateStart.format('MM'),
  //       year: dateStart.format('YYYY')
  //     };
  //     //exculde existing
  //     if(!CompanyUtil.getBillingRecordExistCheck(billingRecords,billingPeriod.month,billingPeriod.year)){
  //       timeValues.push(billingPeriod);
  //     }
  //     dateStart.add(1,'month');
  //   }

    
  //   return Promise.all(
  //     timeValues.map((x, index) => {
  //       var billingRecord = CompanyUtil.getBillingObject(company,x.month,x.year,companyProfiles.length, companyParks.length, true);
  //       billingRecord.createdUnix = billingRecord.createdUnix + index*1000;
  //       firestoreApi.collection('billingRecords').add(billingRecord);
  //     })
  //     ).then(() => this.loadBillingRecords());
  // }

  getListingInfo = () => {
    const rows = this.getTableRows();
    
    return ArrayUtil.isNonEmptyArray(rows) ? TranslatorUtil.t("Listing")+ " " + rows.length + " " + TranslatorUtil.t("rows") : '';
  }
  
  render() {

    const { company } = this.props;
    const { listDataLoading, billingRecords } = this.state;

    return <div className="app-container-wrap app-admin wider-table-wrap">
        <PageHeading icon={<AttachMoneyIcon />} title={TranslatorUtil.t("Billing")} />

        <AdminCompanyMenu company={company} />
        

        { listDataLoading ? <ComponentLoading /> : <React.Fragment>
          <Box>
              <Typography variant="h4">{TranslatorUtil.t("Estimated total") +" : "+ CompanyUtil.getBillingsTotal(billingRecords)}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">{TranslatorUtil.t("Company was created") +" "+ (company.createdUnix ? DateUtil.formatDateTimeShort(company.createdUnix) : '-')} {TranslatorUtil.t("and currently on")} {CompanyUtil.getPlanName(company) }</Typography>
              <br/><br/>
            </Box>
          <Box className='text-right'>
            {
              //!CompanyUtil.isFreePlan(company) ? <Button variant="contained" onClick={this.generateRetrospectively}>Generate retrospectively from created plus 30 and then next month</Button> : null
            }
            
          </Box>
        
        <AppSortableTable hasActions={false} headers={this.getTableHeaders()} rows={this.getTableRows()} defaultSortBy="createdUnix" listingInfo={this.getListingInfo()} />
          </React.Fragment> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    company: state.company,
    companyProfiles: state.companyProfiles,
    companyParks: state.companyParks,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...profileActions,
        ...parkActions,
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyBilling));
