const WEEKLY = 7;
const FORNIGHTLY = 14;
const MANUAL_BOOKING_OPTION_ALL = 1;
const MANUAL_BOOKING_OPTION_HIDDEN = 2;

export default {
    WEEKLY : WEEKLY,
    FORNIGHTLY : FORNIGHTLY,
    MANUAL_BOOKING_OPTION_ALL: MANUAL_BOOKING_OPTION_ALL,
    MANUAL_BOOKING_OPTION_HIDDEN: MANUAL_BOOKING_OPTION_HIDDEN,
    
    FREQUENCY_OPTIONS: [
        {
            value: WEEKLY,
            label: "weekly"
        },
        {
            value: FORNIGHTLY,
            label: "fortnightly"
        },
    ],

    MANUAL_BOOKING_OPTIONS: [
        {
            value: MANUAL_BOOKING_OPTION_ALL,
            label: "Tomorrow"
        },
        {
            value: MANUAL_BOOKING_OPTION_HIDDEN,
            label: "Next visible day for user"
        },
    ],
};

