

import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { Button, Typography, ButtonGroup } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import * as rosterSelectionActions from '../../actions/rosterSelection';
import ParkSelectionUtil from '../../utils/ParkSelectionUtil';

import repeatingRosterConstants from '../../shared/constants/repeatingRoster';
import CompanyUtil from '../../shared/utils/CompanyUtil';
import ParkUtil from '../../shared/utils/ParkUtil';
import RepeatingRosterUtil from '../../shared/utils/RepeatingRosterUtil';
import DateUtil from '../../shared/utils/general/DateUtil';

class ParkRosterCell extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    





    getBtnText = (parkOwner, scheduledBy) => {
        return parkOwner ? "Owned" : scheduledBy ? "Scheduled" : "Available";
    }


    getRosterSelectionDates () {
        const { frequency, selectedDate } = this.props;
        const selectedDates = [selectedDate];
        if(frequency === repeatingRosterConstants.WEEKLY){ // add next week as well
            var nextWeekDate = selectedDate.date.clone();
            nextWeekDate.add(1,'week');
            selectedDates.push({
                id: DateUtil.getNextWeekDayIdById(selectedDate.id),
                day: selectedDate.day,
                date: nextWeekDate
            });
        }
        return selectedDates;
    }

    addToRosterSelection (){
        const { actions, park } = this.props;
        const selectedDates = this.getRosterSelectionDates();
        Promise.all(selectedDates.map(sd => actions.addRosterSelection(park, sd)));
    }

    removeFromRosterSelection (){
        const { actions, park } = this.props;
        const selectedDates = this.getRosterSelectionDates();
        Promise.all(selectedDates.map(sd => actions.removeRosterSelection(park, sd)));
    }

    getSelectBtn = (parkOwner, scheduledBy) => {
        const { rosterSelection, park, selectedDate, disableActions } = this.props;

        var selectedItemIndex = ParkSelectionUtil.getSelectedItemIndex(rosterSelection, park, selectedDate);
        const isSelected = selectedItemIndex !== -1;

        var isDisabled = Boolean(!ParkSelectionUtil.isInSelectedRow(rosterSelection, park) || parkOwner || disableActions);
        var buttonClasses = [];
        if(parkOwner){
            buttonClasses.push("roster-owned");
        }
        if(scheduledBy){
            buttonClasses.push("roster-scheduled");
        }

        return <ButtonGroup size="small" variant="outlined" className={buttonClasses.join(" ")} onClick={() => isDisabled ? null : (!isSelected ? this.addToRosterSelection() : this.removeFromRosterSelection())}>
            <Button disabled={isDisabled}>{this.getBtnText(parkOwner, scheduledBy)}</Button>
            <Button disabled={isDisabled} color="primary" className="info-btn">
            
            {isSelected ? <CheckBoxIcon size="small" /> : <CheckBoxOutlineBlankIcon size="small" />}
        </Button></ButtonGroup>;
    }




    render() {

        const { park, selectedDate, repeatingRosterSetting, companyProfiles, companyInvitations, loading } = this.props;


        //
        let users = CompanyUtil.getUsersAndInvitations(companyProfiles,companyInvitations);
        const parkOwner = ParkUtil.getCurrentParkOwner(park, users);
        const scheduledBy = RepeatingRosterUtil.getScheduledBy(park, selectedDate, repeatingRosterSetting, users, null); // null as not certain date and so we dont want to compare last processed date
        



        return loading ? <LinearProgress className="parkStateLoading" /> : <React.Fragment>
            {
                scheduledBy ? <Typography component="span" variant="caption" className={"scheduled-by"}>{scheduledBy.displayName}</Typography> : null
            }
            {
                parkOwner ? <Typography component="span" variant="caption" className={"owned-by"}>{parkOwner.displayName}</Typography> : null
            }
            

                {this.getSelectBtn(parkOwner, scheduledBy)}
                
            
        </React.Fragment>

    }
}


function mapStateToProps(state) {
    return {
        companyParks: state.companyParks,
        companyProfiles: state.companyProfiles,
        companyInvitations: state.companyInvitations,
        parkOwnerships: state.parkOwnerships,
        repeatingRosterSetting: state.repeatingRosterSetting,
        rosterSelection: state.rosterSelection

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...rosterSelectionActions
            },
            dispatch
        )
    };
}



export default withRouter(withSnackbar(connect(
    mapStateToProps,
    mapDispatchToProps
  )(ParkRosterCell)));


