

import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';

export default class ProfilePicture extends React.Component {

    

render() {

    
    const { profile, large } = this.props;
    let profileUrl = profile && profile.photoURL ? (profile.photoThumbURL && large !== true ? profile.photoThumbURL : profile.photoURL ) : null;

    return (
        <Avatar className={"profilePicture"+(large === true ? " large" : "")} style={{backgroundImage: "url("+profileUrl+")"}}>
        { profileUrl === null ? <PersonIcon /> : null }
        </Avatar>
    );
}
}                    