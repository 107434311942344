import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LinearProgress, Box, Hidden } from '@material-ui/core';


import FormatterUtil from '../../shared/utils/general/FormatterUtil';
import UserUtil from '../../shared/utils/UserUtil';
import ParkUtil from '../../shared/utils/ParkUtil';
import ServerTimeUtil from '../../utils/ServerTimeUtil';

class ParkUsageStatsBar extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      statusesLoading: false
    }   
  }

  getUsage = () => {
    const { days, park, parkStates, parkOwnerships, companyProfiles, userInfo } = this.props;
    var totalBlocks = 0;
    var totalBooked = 0;
    days.forEach(d => {
        if(!UserUtil.isDisallowedFuture(d.date, userInfo, ServerTimeUtil.getUserCompanyTimezone(), ServerTimeUtil.getServerTimeOffset())){
                totalBlocks++;
                if(ParkUtil.isBooked(park, d, parkStates, parkOwnerships, companyProfiles)){
                    totalBooked++;
                }
        }
    });
    return FormatterUtil.getPercentage(totalBlocks,totalBooked)
  }




  
  render() {

    
    const usage = this.getUsage();

  return <Box className="usage-bar-wrap">
    <Hidden smUp><br/></Hidden>
      <Box className="usage-text">used / booked {usage}%</Box>
      {
        !Number.isNaN(usage) ? <LinearProgress className="progress" variant="determinate" value={Number(usage)} /> : null
      }
      
  </Box>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    companyProfiles: state.companyProfiles,
    parkStates: state.parkStates,
    parkOwnerships: state.parkOwnerships,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkUsageStatsBar));
