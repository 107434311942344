import React from 'react';
import { Typography, Divider, Box } from '@material-ui/core';
import objectPath from 'object-path';

import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';
import UserUtil from '../../shared/utils/UserUtil';
import DateUtil from '../../shared/utils/general/DateUtil';
import { Alert } from '@material-ui/lab';



export default class BookUserInfo extends React.Component {


    

  render() {
    const { scheduledBy, selectedDate, ownedBy, bookedBy, proceededBy, children } = this.props;

    return <React.Fragment>
    <Typography variant="body2" component="div" className="parkStatusWrap"><em>{DateUtil.viewFormat(selectedDate.date)}</em>
    <br /><Divider/>
    { 
                        scheduledBy ? <Alert severity="info">{
                          (ownedBy || bookedBy) ? TranslatorUtil.t("This park is currently not available to be booked for this day by repeating roster for "+scheduledBy.email) : 
                          TranslatorUtil.t("This park is currently scheduled to be booked for this day by repeating roster for "+scheduledBy.email)
                          }<br/></Alert> : null
                      }
    <br />
                    {bookedBy ? <React.Fragment>
                        
                        <Typography variant="body2" color="secondary">booked by {ownedBy && UserUtil.compareUsers(ownedBy,bookedBy) ? "park owner" : null} :</Typography> 
                        {UserUtil.isTempUserInvitationOnly(bookedBy) ? <small>{ TranslatorUtil.t("invitation only") }<br/></small> : null }
                    <strong>{ objectPath.get(bookedBy,'displayName',null) }</strong>
                    <br /><strong>{ objectPath.get(bookedBy,'email',null) }</strong>
                    { bookedBy && bookedBy.phone ? <strong><br />{bookedBy.phone}</strong> : null }
                    
                    </React.Fragment> : <Typography variant="body2" color="secondary">{TranslatorUtil.t("Park is available")}</Typography>
                    }
                    
                    {ownedBy || bookedBy ? <Box>
                        <br />
                    <Typography variant="body2" color="secondary">{bookedBy ? 'booking done' : 'unlocked'} by : </Typography>
                        <strong>{proceededBy ? objectPath.get(proceededBy,'email',null) : 'Flex Parking system' }</strong>
                    </Box> : null }
                    
                    
                    { children }


                    { ownedBy && !UserUtil.compareUsers(ownedBy,bookedBy) ? <span><br /><Divider/><br />
                    <Typography variant="body2" color="secondary">{TranslatorUtil.t("owned by")}:</Typography>
                    {UserUtil.isTempUserInvitationOnly(ownedBy) ? <small>{ TranslatorUtil.t("invitation only") }<br/></small> : null }
                    <strong>{ objectPath.get(ownedBy,'displayName',null) }</strong>
                    <br /><strong>{ objectPath.get(ownedBy,'email',null) }</strong>
                    { ownedBy && ownedBy.phone ? <strong><br />{ownedBy.phone}</strong> : null }</span> : null }
                </Typography>
    </React.Fragment>
  }
}