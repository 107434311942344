// import React from 'react';
// import HelpIcon from '@material-ui/icons/Help';
// import HowItWorksContent from '../../../../components/about/HowItWorksContent'
// import StaticPage from './StaticPage'
// import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';

import urls from '../../../../shared/constants/urls';

function HowItWorks(props) {

  return window.location.replace(urls.HOW_IT_WORKS_EXTERNAL_URL);
  // const icon = <HelpIcon className="icon" />;
  // const content = <HowItWorksContent />;
  // const title = TranslatorUtil.t("How it works?");
  // const navigateText = TranslatorUtil.t("Go Back");
  // const navigateLink = urls.INDEX_URL;

  
  //return <StaticPage content={content} icon={icon} title={title} navigateText={navigateText} navigateLink={navigateLink} />
}

export default HowItWorks;
