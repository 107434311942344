import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import HowItWorksContent from './HowItWorksContent'
import Logo from '../Logo'
import HelpIcon from '@material-ui/icons/Help';
import Avatar from '@material-ui/core/Avatar';


import Slide from '@material-ui/core/Slide';




const styles = {
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class HowItWorksDialog extends Component {



  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  }



  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }



  render() {

    const { classes, onClose } = this.props;
    return (
      <Dialog
        fullScreen
        open={true}
        onClose={onClose}
        transition={Transition}
      >

        <AppBar className={classes.appBar}>
          <Toolbar>
              <Logo className="logo" header={true} />
            <IconButton color="inherit" style={{ marginLeft: 'auto' }} onClick={onClose} aria-label="Close">
              <CloseIcon />
            </IconButton>

          </Toolbar>
        </AppBar>
        <div className="public-container-wrap">
          <Grid container spacing={4} alignContent="center">
            <Grid item xs={12}>
              <div className="page-heading">
                <Avatar className="avatar"><HelpIcon className="icon" /></Avatar>
                <Typography variant="h1">How it works?</Typography>
              </div>

            </Grid>
            <Grid item xs={12} sm={6}>
              <HowItWorksContent />
            </Grid>
            <Grid item xs={12} sm={6}>

            </Grid>
          </Grid>
        </div>
      </Dialog>

    );
  }
}

export default withStyles(styles)(HowItWorksDialog);