import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import ParkStatus from './ParkStatus';
import { Tooltip, Typography } from '@material-ui/core';

import DateUtil from '../../shared/utils/general/DateUtil';
import UserUtil from '../../shared/utils/UserUtil';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';
import ServerTimeUtil from '../../utils/ServerTimeUtil';
import constants from '../../shared/constants/constants';
class ParkMonthTable extends React.Component {
  

  constructor (props) {
    super(props);
    this.state = {
      lastRenderTime: 0,
    }   
  }

  componentDidMount() {
    this.rerenderInterval = setInterval(() => {
      this.setState({ lastRenderTime: Date.now().valueOf() });
    }, constants.RERENDER_CALENDARS_INTERVAL);  //force rerender on every minute
  }

  componentWillUnmount(){
    clearInterval(this.rerenderInterval);
  }
  


  renderParkButton = (p,d) => {
    const { disableActions, dataLoading, userInfo  } = this.props;
    return <ParkStatus 
    appAdminView={false}
    userInfo={userInfo}
    disableActions={disableActions}
    park={p} 
    selectedDate={d} 
    loading={dataLoading}
  /> 
  }

  

  getWeekFillers = (day) => {
    var dayIndex = parseInt(day.date.format('d'));
    var days = [];
    if(dayIndex !== 1){
      for(var i=1; i<dayIndex;i++){
        days.push(i);
      }
    }
    return days.map((d,i) => <TableCell className="empty-day" key={i}></TableCell>)
  }

  
  render() {
    const { lastRenderTime } = this.state;
    const { year, month, park, userInfo } = this.props;


    const days = DateUtil.getDaysArray(year, month, UserUtil.includeWeekend(userInfo));
    const weeks = DateUtil.getWeeksArray(year, month);

    return <TableContainer className={"month-park-view-table-wrap lastRender-"+(lastRenderTime)}>
    <Table>
      <TableBody>
        {weeks.map((week,weekIndex) => <TableRow key={weekIndex}>
          {days.filter(d => d.date.week() === week).map((day,dayIndex) => {
            var isNormalUserDisallowedFuture = UserUtil.isNormalUserDisallowedFuture(day.date, userInfo, ServerTimeUtil.getUserCompanyTimezone(), ServerTimeUtil.getServerTimeOffset());
            var dayText = <Typography color="secondary" className="date" variant="caption">{DateUtil.formatDayForCalendar(day.date)}</Typography>;
            return <React.Fragment key={dayIndex}>
            {
              dayIndex === 0 ? this.getWeekFillers(day) : null
            }
            <TableCell className={"table-btn-group-cell "+(isNormalUserDisallowedFuture ? "normal-user-disallowed-future" : "")}>
            
            {isNormalUserDisallowedFuture ? <Tooltip title={TranslatorUtil.t("Currently visible only by manager or admin user role.")}>{dayText}</Tooltip> : dayText}
          {this.renderParkButton(park,day)}
            </TableCell>
            </React.Fragment>
          }
          )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    companyParks: state.companyParks,
    companyProfiles: state.companyProfiles,
    parkStates: state.parkStates,
    parkOwnerships: state.parkOwnerships,
  };
}




export default withRouter(connect(
  mapStateToProps
)(ParkMonthTable));
