import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import LinkRef from '../../../../components/LinkRef';
import PageHeading from '../../../../components/PageHeading';



function StaticPage(props) {
  return (<div className="public-container-wrap"><Grid container spacing={4} alignContent="center">
    <Grid item xs={12}>
    <PageHeading icon={props.icon} title={props.title} />
    
      </Grid>
      <Grid item xs={12}>
        {props.content}
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" component={LinkRef} to={props.navigateLink}>{props.navigateText}</Button>
      </Grid>
      
      
    </Grid>
    </div>)
}

export default StaticPage;
