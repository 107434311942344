
import ValidationUtil from '../../../utils/ValidationUtil';

const validate = values => {
  let errors = {};
  const requiredFields = [
    "displayName"
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);

  errors = ValidationUtil.checkStringMaxLength(['displayName','phone'],values,errors,150);
  errors = ValidationUtil.checkStringMaxLength(['info'],values,errors,300);

  return errors;
};

export default validate;
