import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as parkStateActions from '../../../../actions/parkStates';
import * as invitationsActions from '../../../../actions/invitation';

import PersonIcon from '@material-ui/icons/Person';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PageHeading from '../../../../components/PageHeading';
import CalendarFilter from '../../../../components/park/CalendarFilter';

import { Box, Tab, Tabs, Button, FormControlLabel, Switch } from '@material-ui/core';
import objectPath from 'object-path';

import ComponentLoading from '../../../../components/ComponentLoading';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import ParkMonthTable from '../../../../components/park/ParkMonthTable';
import ParksUsageStatsBar from '../../../../components/park/ParksUsageStatsBar';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import Calendar from 'react-calendar';
import moment from 'moment';
import UserUsageStatsBar from '../../../../components/user/UserUsageStatsBar';
import { CSVLink } from "react-csv";
import ListAltIcon from '@material-ui/icons/ListAlt';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ParkUsageStatsBar from '../../../../components/park/ParkUsageStatsBar';


import UserUtil from '../../../../shared/utils/UserUtil';
import ParkUtil from '../../../../shared/utils/ParkUtil';
import CompanyUtil from '../../../../shared/utils/CompanyUtil';
import DateUtil from '../../../../shared/utils/general/DateUtil';
import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import { MoveToInbox } from '@material-ui/icons';
import extraFeatures from '../../../../shared/constants/extraFeatures';
import FeatureNotEnabled from '../../../../components/FeatureNotEnabled';

class CompanyStats extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
        dataLoading: true,
        year: DateUtil.getCurrentYear(),
        month: DateUtil.getCurrentMonth(),
        days: [],
        showCsvToDownload: false,
        showSummaryCsvToDownload: false,
        startDate: moment().subtract(14, 'days'),
        endDate: moment(),
        dateRangeValid: true,
        tabId: 0,
        calendarOpen: true,
        excludeUsersWithoutBooking: true,
        usersWithBookingData: [],
        listingUsers: true,
    }   
  }

  componentDidMount() {
    const { actions, userInfo } = this.props;
    let _this = this;
      Promise.all(this.isFeatureEnabled() ? [
        this.getParkStatuses(),
        actions.getInvitationsByCompanyId(UserUtil.getCompanyId(userInfo)),
      ] : []).then(() => {
        Promise.all([
          
        ]).then(()=>{
          console.log('loading done');
          _this.setState({dataLoading: false});

          //catch errors
        }).catch(function(err) {
          console.log('loading error',err);
        });
      }).catch(function(err) {
        console.log('loading error',err);
      });

    
  }

  componentWillUnmount(){
  }

  isFeatureEnabled = () => {
    const { userInfo } = this.props;
    return CompanyUtil.hasFeatureAllowed(userInfo.company, extraFeatures.INSIGHTS);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var month = objectPath.get(this,'props.month',null);
    var prevMonth = objectPath.get(prevProps,'month',null);

    if (month && prevMonth && month !== prevMonth && this.isFeatureEnabled()) {
      this.getParkStatuses();
    }
  }


  getParkStatuses = () => {
    const { actions, userInfo } = this.props;
    const { year, month, tabId,startDate, endDate } = this.state;
    if(!this.state.dataLoading){
      this.setState({dataLoading:true});
    }
    if(tabId === 0){
      return actions.getParksStatesByCompanyIdAndBetweenDates(
        UserUtil.getCompanyId(userInfo),
        startDate.valueOf(), endDate.valueOf()
      ).then(() => {
        this.setUsersWithBookingData();
        this.setState({dataLoading:false});
      });
    } else {
      return actions.getParksStatesByCompanyIdAndMonthYear(
        UserUtil.getCompanyId(userInfo),
        month,
        year
      ).then(() => this.setState({dataLoading:false}));
    }
    
  }


  handleMonthChanged = event => {
    this.setState({ month: event.target.value }, () => {
        this.getParkStatuses();
      });
  };

  handleYearChanged = event => {
    this.setState({ year: event.target.value }, () => {
      this.getParkStatuses();
    });
  };

  handleMonthYearChanged = (month,year) => {
    this.setState({ year: year, month: month }, () => {
      this.getParkStatuses();
    });
  };


  handleChangeTab = (event, value) => {
    this.setState({ tabId: value }, () => {
      this.getParkStatuses();
    });
  };

  getMonthStats = () => {
    const { year, month } = this.state;
    const { companyParks, userInfo } = this.props;
    return <React.Fragment><Box textAlign="center" mb={2}>
          <CalendarFilter futureAllowed={false} handleYearChanged={this.handleYearChanged} handleMonthYearChanged={this.handleMonthYearChanged} handleMonthChanged={this.handleMonthChanged} year={year} month={month} />
        </Box>
        <Box mb={2}>
        <ParksUsageStatsBar days={DateUtil.getDaysArray(year,month, UserUtil.includeWeekend(userInfo))} />
        </Box>
        {
          companyParks.map((p,i) => <Box mb={4} key={i}>
          <PageHeading icon={<LocalParkingIcon />} title={p.parkId} />
          <ParkUsageStatsBar park={p} days={DateUtil.getDaysArray(year,month, UserUtil.includeWeekend(userInfo))} />
          <ParkMonthTable disableActions={true} year={year} month={month} park={p} />
          </Box>
          )
        }

        </React.Fragment>
  }


  changeDate = (range) => {
    var start = moment(range[0]);
    var end = moment(range[1]);
    console.log(start.diff(end, 'days'));
    if(start && end && Math.abs(start.diff(end, 'days')) <= this.getMaxDaysRange()){
    this.setState({
        dataLoading: true,
        startDate: moment(range[0]),
        endDate: moment(range[1]),
        dateRangeValid: true,
        showCsvToDownload: false,
        showSummaryCsvToDownload: false
      }, () => {
        this.getParkStatuses();
      });
    } else {
      this.setState({ 
        dateRangeValid: false
      });
    }
    
    
    
  }

  setUsersWithBookingData = () => {
    const { companyProfiles, companyInvitations, parkOwnerships, companyParks, parkStates, userInfo } = this.props;
    const { startDate, endDate } = this.state;
    var days = DateUtil.getDaysBetweenDates(startDate,endDate, UserUtil.includeWeekend(userInfo) );
    var bookingData = ParkUtil.getBookingData(days, companyParks, parkStates, parkOwnerships, companyProfiles);
    this.setState({
      usersWithBookingData : CompanyUtil.getUsersAndInvitations(companyProfiles,companyInvitations)
      .map(x => {return {
        ...x,
        bookedDays : bookingData.filter(bd => UserUtil.compareUsers(bd.user,x)),
      }})
    });
  }

  getFilteredUsers = () => {
    const { usersWithBookingData, excludeUsersWithoutBooking } = this.state;
    return usersWithBookingData.filter(x => excludeUsersWithoutBooking ? x.bookedDays.length > 0 : true);
  }


  getCsvData = (filteredUsersWithBookingData) => {
    var data = [];
    filteredUsersWithBookingData.forEach(profile => {
      if(profile.bookedDays.length){
        profile.bookedDays.forEach(userBooked => {
          data.push({
            email: profile.email,
            date: DateUtil.viewFormatShort(userBooked.day.date),
            parkID: userBooked.park.parkId
          });
        });
      }
    });
    return data;
  }

  getCsvHeaders = () => {
    return [
      { label: "User", key: "email" },
      { label: "Date", key: "date" },
      { label: "Park ID", key: "parkID" }
    ];
  }

  getCsvSummaryData = (filteredUsersWithBookingData) => {
    var data = [];
    filteredUsersWithBookingData.forEach(profile => {
      
      if(profile.bookedDays.length){
        
          data.push({
            email: profile.email,
            count: profile.bookedDays.length,
            details: profile.bookedDays.map(x => '['+DateUtil.viewFormatShort(x.day.date)+' - '+x.park.parkId+']').join(' / ')
          });
      }
    });
    return data;
  }

  getCsvSummaryHeaders = () => {
    return [
      { label: "User", key: "email" },
      { label: "Count", key: "count" },
      { label: "Details", key: "details" }
    ];
  }

  getEndDate = () =>{
    const { selectedDate } = this.state;
    var endDate = selectedDate.clone().add(13,'days').endOf('day');
    if(endDate >= moment().endOf('day')){
      return moment().endOf('day');
    }
    return endDate;
  }

  getMaxDaysRange = () => {
    return 62;
  }

  getUsersStats = () => {
    const { userInfo } = this.props;
    const { listingUsers, calendarOpen,startDate,endDate,dateRangeValid,showCsvToDownload,showSummaryCsvToDownload,excludeUsersWithoutBooking } = this.state;
    var days = DateUtil.getDaysBetweenDates(startDate,endDate, UserUtil.includeWeekend(userInfo));
    var filteredUsersWithBookingData = this.getFilteredUsers();
    return <React.Fragment><Box textAlign="center" mb={2} className={calendarOpen ? '' : 'hidden'}>
      <div className="text-danger">{dateRangeValid ? '' : 'Invalid date range. Max is '+this.getMaxDaysRange()+' days!'}</div>
      <Calendar
      maxDate={moment().endOf('day').toDate()}
        tileDisabled={({activeStartDate, date, view }) => !DateUtil.isAvailableDay(moment(date), UserUtil.includeWeekend(userInfo))}
        tileClassName={({ activeStartDate, date, view }) => {
          return moment(date).isBetween(startDate.clone().toDate(), endDate.clone().toDate(), 'days', '[]') ? 'inRange' : 'notInRange'
        }}
        selectRange={true}
        returnValue="range"
        onChange={this.changeDate}
        value={[startDate.clone().toDate(), endDate.clone().toDate()]}
      />
        </Box>
        <Box textAlign="center" mb={2}>
          <Button startIcon={<DateRangeIcon />} size="large" variant="contained" color="primary">{ DateUtil.getRangeFormat(startDate,endDate)}</Button>
        </Box>

        <Box textAlign="right" m={2}>
          { !showCsvToDownload ? <Button variant="contained" onClick={() => this.setState({showCsvToDownload:true})}>
          <ListAltIcon fontSize="small"/> Generate CSV export
          </Button> : 
          <CSVLink
          data={this.getCsvData(this.getFilteredUsers())}
          headers={this.getCsvHeaders()}
          filename={"flexparking-export-"+(DateUtil.getRangeFormat(startDate,endDate).replace(/\s+/g, '-'))+".csv"}
          className="MuiButtonBase-root MuiButton-root MuiButton-contained"
          target="_blank"
        ><MoveToInbox fontSize="small"/> Export to CSV</CSVLink>
        }
        &nbsp; &nbsp; &nbsp;
        { !showSummaryCsvToDownload ? <Button variant="contained" onClick={() => this.setState({showSummaryCsvToDownload:true})}>
          <ListAltIcon fontSize="small"/> Generate CSV summary export
          </Button> : 
          <CSVLink
          data={this.getCsvSummaryData(filteredUsersWithBookingData)}
          headers={this.getCsvSummaryHeaders()}
          filename={"flexparking-export-summary-"+(DateUtil.getRangeFormat(startDate,endDate).replace(/\s+/g, '-'))+".csv"}
          className="MuiButtonBase-root MuiButton-root MuiButton-contained"
          target="_blank"
        ><MoveToInbox fontSize="small"/> Export summary to CSV</CSVLink>
        }
        
        </Box>
        <Box textAlign="right" m={2}>
        {filteredUsersWithBookingData.length} users &nbsp; 
          <FormControlLabel
                control={
                  <Switch
                    checked={excludeUsersWithoutBooking}
                    onChange={() => this.setState({excludeUsersWithoutBooking: !excludeUsersWithoutBooking})}
                    color="primary"
                  />
                }
                label="Exclude users without booking"
              />

        </Box>


        { listingUsers ? <List dense disablePadding>
              {filteredUsersWithBookingData.map((user,index) => (
                <ListItem key={index} divider>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={user.displayName+ " - "+user.email+(UserUtil.isTempUserInvitationOnly(user) ? " ("+TranslatorUtil.t("invitation only")+")" : "")} 
                  secondaryTypographyProps={{
                    component: "div"
                  }}
                  secondary={
                    <UserUsageStatsBar days={days} user={user} bookedData={user.bookedDays} />
                  } />
                </ListItem>
              ))}
            </List> : false }

        </React.Fragment>
  }
  
  render() {

    const { dataLoading, tabId } = this.state;

    return <div className="app-container-wrap">
        <PageHeading icon={<EqualizerIcon />} title={TranslatorUtil.t("Insights")} />
        {dataLoading ? <ComponentLoading /> : 
        
        !this.isFeatureEnabled() ? <FeatureNotEnabled /> : <Box>
          <Box pt={1}>
          <Tabs value={tabId} onChange={this.handleChangeTab} indicatorColor="primary">
          <Tab label={TranslatorUtil.t("Users")} />
            <Tab label={TranslatorUtil.t("Parks")} />
            
          </Tabs>
        </Box>
        <Box pt={4}>
            
            {tabId === 0 ? this.getUsersStats() : null}
            {tabId === 1 ? this.getMonthStats() : null}
            
        </Box>
        </Box>}
      </div>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    parkOwnerships: state.parkOwnerships,
    companyParks: state.companyParks,
    companyProfiles: state.companyProfiles,
    companyInvitations: state.companyInvitations,
    parkStates: state.parkStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...parkStateActions,
        ...invitationsActions
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyStats));
