import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';


import TranslatorUtil from '../shared/utils/general/TranslatorUtil';

class AlertDialog extends React.Component {
   


  render() {

    const { title, message, onClose } = this.props

    return (
        <Dialog
          className="alert-dialog"
          open={true}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {title}
          </DialogTitle>
          <DialogContent id="alert-dialog-description">
                {message}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={onClose} color="primary" autoFocus>
    {TranslatorUtil.t("OK")}
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default AlertDialog;