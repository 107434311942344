import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as parkActions from "../../actions/park";
import * as parkOwnershipActions from "../../actions/parkOwnerships";
import * as parkStatesActions from "../../actions/parkStates";
import CalendarFilter from './CalendarFilter';
import { Box } from '@material-ui/core';
import ParkMonthTable from './ParkMonthTable';
import objectPath from 'object-path';

import DateUtil from '../../shared/utils/general/DateUtil';

class Calendar extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
        year: DateUtil.getCurrentYear(),
        month: DateUtil.getCurrentMonth(),
        days: [],
        statusesLoading: false,
      parkStatesListener: null
    }   
  }


  componentDidMount() {
    this.listenParkStates();
  }

  componentWillUnmount(){
    this.unsubscribeListeners();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var month = objectPath.get(this,'props.month',null);
    var prevMonth = objectPath.get(prevProps,'month',null);
    if (month && prevMonth && month !== prevMonth) {
      //this.unsubscribeListeners();
      this.listenParkStates();
    }
  }

  unsubscribeListeners = () => {
     
  }

  listenParkStates = () => {
    const { actions, park } = this.props;
    const { year, month } = this.state;
    this.setState({statusesLoading:true});
    console.log('listen park states for ', year, month);
    actions.listenParksStatesByParkIdMonthYear(
      park.id,
      month,
      year,
      () => {this.setState({statusesLoading:false})}
    );
  }



    handleMonthChanged = event => {
        this.setState({ month: event.target.value }, () => {
          this.listenParkStates();
        });
      };
    
      handleYearChanged = event => {
        this.setState({ year: event.target.value }, () => {
          this.listenParkStates();
        });
      };

      handleMonthYearChanged = (month,year) => {
        this.setState({ year: year, month: month }, () => {
          this.listenParkStates();
        });
      };
  
  render() {

    const { year, month, statusesLoading } = this.state;
    const { park } = this.props;


    return <React.Fragment>
            <Box textAlign="center">
                <CalendarFilter futureAllowed={true} handleYearChanged={this.handleYearChanged} handleMonthYearChanged={this.handleMonthYearChanged} handleMonthChanged={this.handleMonthChanged} year={year} month={month} />
            </Box>
            <Box>
                <ParkMonthTable year={year} month={month} park={park} dataLoading={statusesLoading} />
            </Box>
      </React.Fragment>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    park: state.park,
    companyProfiles: state.companyProfiles,
    companyParks: state.companyParks
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...parkActions,
        ...parkOwnershipActions,
        ...parkStatesActions
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar));
