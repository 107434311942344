import * as types from '../constants/actionTypes';


export function getBillings(state = [], action) {
    if (action.type === types.GET_COMPANY_BILLINGS) {
        console.log(types.GET_COMPANY_BILLINGS+' set in store');
        return action.data;
    }
    return state;
}


export function getBilling(state = null, action) {
    if (action.type === types.GET_BILLING) {
        console.log(types.GET_BILLING+' set in store');
        return action.data;
    }
    return state;
}


