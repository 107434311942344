import { createTheme } from "@material-ui/core/styles";

export default createTheme({
    palette: {
      primary: {
        light: '#862478',
        main: '#4d2581',
        dark: '#4d2581',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#f8a5c4',
        main: '#e81a6a',
        dark: '#b22071',
        contrastText: '#000',
      },
      background: {
        paper: "rgba(255,255,255, 0.975)"
      }
    },
    typography: {
      fontFamily: "'Varela Round', sans-serif",
      fontWeight: 100,
      button: {
        fontFamily: "'Varela Round', sans-serif",
        fontWeight: 100,
        textTransform: 'none'
      },
      title: {
        fontFamily: "'Varela Round', sans-serif",
      },
      h1: {
        fontWeight: 300,
        fontSize: "3.5rem",
        letterSpacing: '0.0325rem',
        fontFamily: "'Nunito', sans-serif",
      },
      h2: {
        fontWeight: 400,
        fontFamily: "'Nunito', sans-serif",
      },
      h3: {
        fontWeight: 400,
        fontFamily: "'Nunito', sans-serif",
      },
      h4: {
        fontWeight: 100,
        fontFamily: "'Nunito', sans-serif",
      },
      h5: {
        fontWeight: 100,
        fontFamily: "'Nunito', sans-serif",
      }

    }
  });