import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as profileActions from "../../../actions/profile";
import ComponentLoading from '../../../components/ComponentLoading';

import PageHeading from '../../../components/PageHeading';
import objectPath from 'object-path';
import SnackBarUtil from '../../../utils/SnackBarUtil';
import { withSnackbar } from "notistack";
import ProfileForm from '../../../forms/user/profile/form';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import urls from '../../../shared/constants/urls';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
class Profile extends React.Component {



  handleResponse = response => {
    const { enqueueSnackbar, history } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = TranslatorUtil.t("Updated");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      history.push(urls.INDEX_URL);
    }
  }

  handleSubmit = (values) => {
    const { actions, userInfo } = this.props;

    return actions.putProfile(values).then(response => {
      actions.getCurrentUserProfile(userInfo.firebase.uid);
      this.handleResponse(response);
    });
  };

  
  render() {

    const { userInfo } = this.props;

    return <div className="app-container-wrap">
        <PageHeading icon={<AccountCircleOutlinedIcon />} title={TranslatorUtil.t("My profile")} />
        
        { objectPath(userInfo,'profile',null) === null ? <ComponentLoading /> : <ProfileForm proceedSubmit={this.handleSubmit} initialValues={userInfo.profile} userInfo={userInfo} /> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...profileActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)));
