import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, Button, ButtonGroup } from '@material-ui/core';
import * as bookingSelectionActions from '../../actions/bookingSelection';
import * as parkStateActions from '../../actions/parkStates';
import UserSelectDialog from '../form/UserSelectDialog';
import ParkSelectionUtil from '../../utils/ParkSelectionUtil';

import UserUtil from '../../shared/utils/UserUtil';
import ParkUtil from '../../shared/utils/ParkUtil';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';

class ParksBulkActions extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      showUserSelect: false
    }   
  }

  proceedBulkBookAction = (user) => {
    const {actions,bookingSelection,parkOwnerships,companyProfiles,parkStates,startLoading, finishLoading} = this.props;

    //start loading
    startLoading();

    let bulkActions = [];
    bookingSelection.forEach((selection) => {
      if(ParkUtil.isBooked(selection.park, selection.date, parkStates, parkOwnerships, companyProfiles)){
        //unlock first if needed
        bulkActions.push(() => {
          return this.proceedUnlockPark(selection.date,selection.park,user);
        });
        
      }
      bulkActions.push(() => {
        return this.proceedBookPark(selection.date,selection.park,user);
      });
    });
    

    this.setState({showUserSelect: false});
    //run bulk actions
    Promise.all(bulkActions.map( ba => ba())).then((values) => {
      //empty selection
      finishLoading();
      actions.removeBookingSelections();
      
    });
  }

  proceedBulkUnlockAction = () => {
    const {actions,bookingSelection,parkOwnerships,companyProfiles,parkStates,startLoading, finishLoading} = this.props;

    //start loading
    startLoading();

    let bulkActions = [];
    bookingSelection.forEach((selection) => {
      if(ParkUtil.isBooked(selection.park, selection.date, parkStates, parkOwnerships, companyProfiles)){
        //unlock first if needed
        bulkActions.push(() => {
          return this.proceedUnlockPark(selection.date,selection.park,null);
        });
      }
    });
    

    this.setState({showUserSelect: false});
    //run bulk actions
    Promise.all(bulkActions.map( ba => ba())).then((values) => {
      //empty selection
      finishLoading();
      actions.removeBookingSelections();
      
    });
  }



  proceedBookPark = (selectedDate, park, selectedUser) => {
    const { userInfo, actions, parkStates, parkOwnerships, companyProfiles } = this.props;

    return ParkUtil.bookPark(park, selectedDate, selectedUser, userInfo, parkOwnerships, companyProfiles, parkStates, actions);
  }

  proceedUnlockPark = (selectedDate, park) => {
    const { userInfo, actions, parkStates, parkOwnerships, companyProfiles } = this.props;

    return ParkUtil.unlockPark(park, selectedDate, userInfo, parkOwnerships, companyProfiles, parkStates, actions);
  }




  
  render() {

    const {bookingSelectionEnabled,actions,userInfo,bookingSelection,companyProfiles, parkStates, parkOwnerships} = this.props;
    const {showUserSelect} = this.state;

  return UserUtil.isAtLeastCompanyManager(userInfo) ? <Box className="bulkActions">
    
      {bookingSelectionEnabled ? <Box mt={2}>
    <ButtonGroup variant="contained" color="default" aria-label="button group">
      
      {bookingSelection.length ? <Button color="primary" onClick={() => this.setState({showUserSelect : true})}>
      {TranslatorUtil.t("Book")}
      </Button> : null } 
      {bookingSelection.length && ParkSelectionUtil.hasSelectionBookedPark(bookingSelection, parkStates, parkOwnerships, companyProfiles) ? <Button color="primary"  className="error-btn" onClick={this.proceedBulkUnlockAction}>
      {TranslatorUtil.t("Unlock")}
      </Button> : null } 
      {bookingSelection.length ? <Button color="default" onClick={() => actions.removeBookingSelections()}>
      {TranslatorUtil.t("Remove selection")}
      </Button> : null } 
      </ButtonGroup>
      {showUserSelect ? <UserSelectDialog onClose={() => this.setState({showUserSelect : false})} users={companyProfiles} userSelected={this.proceedBulkBookAction} /> : null }
      </Box> : null }
  </Box> : null
  }
}



function mapStateToProps(state) {
  return {
    companyProfiles: state.companyProfiles,
    userInfo: state.userInfo,
    parkStates: state.parkStates,
    parkOwnerships: state.parkOwnerships,
    bookingSelectionEnabled: state.bookingSelectionEnabled,
    bookingSelection: state.bookingSelection
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...parkStateActions,
        ...bookingSelectionActions
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ParksBulkActions));
