import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreUtil from '../shared/utils/general/FirestoreUtil';


const collectionName = 'invitations';



export function getInvitationsByCompanyId(companyId) {
  return function(dispatch) {
    console.log('getInvitationsByCompanyId');
    return firestoreApi.collection(collectionName).where("companyId", "==", companyId).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_COMPANY_INVITATIONS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_INVITATIONS))
      );
  };
}

export function getInvitationByEmail(email) {
  return function(dispatch) {
    console.log('getInvitationByEmail');
    return firestoreApi.collection(collectionName)
    .where("email", "==", email.toLowerCase())
    .limit(1)
    .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseFirstItemFromCollectionData(response), types.GET_INVITATION)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_INVITATION))
      );
  };
}


  export function getInvitation(id) {
    return function(dispatch) {
      console.log('getInvitation');
      return firestoreApi.collection(collectionName).doc(id).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionItemData(response), types.GET_INVITATION)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_INVITATION))
        );
    };
  }

  export function postInvitation(data) {
    return function(dispatch) {
      console.log('postInvitation');
      return firestoreApi.collection(collectionName).add(data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionItemData(response), types.POST_INVITATION)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_INVITATION))
        );
    };
  }

  export function deleteInvitation(id) {
    return function(dispatch) {
      console.log('deleteInvitation');
      return firestoreApi.collection(collectionName).doc(id).delete()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_INVITATION)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.DELETE_INVITATION))
        );
    };
  }

