import React from 'react';

export default class Logo extends React.Component {


  render() {
    return <h1 className="logo">Flex Parking</h1>;
  }
}

