import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from "react-redux";
import { Typography, Box } from '@material-ui/core';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';

import constants from '../../shared/constants/constants';
import CompanyUtil from '../../shared/utils/CompanyUtil';
import UserUtil from '../../shared/utils/UserUtil';

class PlanWarning extends React.Component {


  render() {

    const company = UserUtil.getCompany(this.props.userInfo);


    return CompanyUtil.isDisabled(company) ?
      <Dialog
        open={true}
      >
        <DialogContent className="text-center">
          <Typography variant="h4" color="secondary">{TranslatorUtil.t('Your company has been disabled')}!</Typography> <br /><br />
          <Typography>{TranslatorUtil.t('Please contact us on')} <br /><a href={"mailto:" + constants.CONTACT_EMAIL}><Typography color="secondary" component="span">{constants.CONTACT_EMAIL}</Typography></a>.<br /><br /></Typography>

        </DialogContent>
      </Dialog>
      :
      (
        CompanyUtil.isFreePlan(company) ?
          (CompanyUtil.hasFreePlanExceeded(company) ?
            <Dialog
              open={true}
            >
              <DialogContent className="text-center">
                <Typography variant="h4" color="secondary">{TranslatorUtil.t('Your FREE plan has expired')}!</Typography> <br /><br />
                <Typography>{TranslatorUtil.t('Please contact us on')} <br /><a href={"mailto:" + constants.CONTACT_EMAIL}><Typography color="secondary" component="span">{constants.CONTACT_EMAIL}</Typography></a><br /> {TranslatorUtil.t('to upgrade')}.<br /><br /></Typography>

              </DialogContent>
            </Dialog>
            :
            <Box className="text-center free-plan-bar">
              {CompanyUtil.freePlanDaysLeft(company) !== null ? <Typography color="secondary" variant="caption">
                {TranslatorUtil.t('You are on FREE plan that will expire in')} {CompanyUtil.freePlanDaysLeft(company)} {CompanyUtil.freePlanDaysLeft(company) === 1 ? TranslatorUtil.t('day') : TranslatorUtil.t('days')}!
              </Typography> : <Typography color="secondary" variant="caption">
                {TranslatorUtil.t('You are on FREE plan that will expire in')} {CompanyUtil.freePlanDays} {TranslatorUtil.t('days')}
              </Typography>}
            </Box>
          )
          :
          null
      )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo
  };
}




export default connect(
  mapStateToProps
)(PlanWarning);