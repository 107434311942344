import React from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import * as formNames from "../../../constants/forms";
import {
  TextInput,
  SelectInput,
  CheckboxInput
} from "../../../components/form/inputs";


import validate from "./validate";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Box, MenuItem, Typography, Paper } from "@material-ui/core";


import timezones from '../../../shared/constants/timezones';
import latestTimeToUnlock from '../../../shared/constants/latestTimeToUnlock';
import constants from '../../../shared/constants/constants';
import plans from '../../../shared/constants/plans';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
import billingDayOptions from "../../../shared/constants/billingDayOptions";
import extraFeatures from "../../../shared/constants/extraFeatures";
import disableCurrentDayAt from "../../../shared/constants/disableCurrentDayAtTime";
import currencies from "../../../shared/constants/currencies";
import langs from "../../../shared/constants/langs";
const formName = formNames.COMPANY_SETTING_FORM;

export class CompanySettingForm extends React.Component {


  componentDidUpdate(prevProps) {
    const { initialValues, change, selectedPlan, optionsData } = this.props;
    if (selectedPlan !== prevProps.selectedPlan) {
      if (selectedPlan !== plans.PAY_AS_YOU_GO || (selectedPlan === plans.PAY_AS_YOU_GO && initialValues.pricePerPark !== null)) {
        change(formName, 'pricePerPark', optionsData.plans.find(x => x.value === selectedPlan).pricePerPark);
      }
      change(formName, 'parksLimit', optionsData.plans.find(x => x.value === selectedPlan).parksLimit);
    }
  }


  // changeFieldProgrammatically = () => {
  //   this.props.change(formName, "firstName", "Test");
  // };

  render = () => {
    const {
      handleSubmit,
      pristine,
      submitting,
      proceedSubmit,
      optionsData,
      selectedPlan
    } = this.props;

    const disableSubmit = pristine || submitting;
    const submitButton = <Button variant="contained" color="primary" type="submit" size="large" disabled={disableSubmit}>{TranslatorUtil.t("Save")}</Button>;
    const isPayAsYouGo = selectedPlan === plans.PAY_AS_YOU_GO_PLAN ? true : false;

    return (
      <Box pb={2} pt={1}>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))}>

          <div className="maxWidthPart">
            <Grid container spacing={2}>






              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="companyId" component={TextInput} label={TranslatorUtil.t("Company ID")} readOnly disabled />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="displayName" component={TextInput} label={TranslatorUtil.t("Display name")} />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="userId" component={SelectInput} label={TranslatorUtil.t("Admin")}>
                        {
                          optionsData.profiles.map(x => <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="plan" component={SelectInput} label={TranslatorUtil.t("Plan")}
                      >
                        {
                          optionsData.plans.map(x => <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="pricePerPark" type="number" component={TextInput} label={TranslatorUtil.t("Price per park")} readOnly={!isPayAsYouGo} disabled={!isPayAsYouGo} />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="parksLimit" type="number" component={TextInput} label={TranslatorUtil.t("Parks limit")} readOnly disabled />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="billingDay" component={SelectInput} label={TranslatorUtil.t("Generate monthly billing on")} helperText={TranslatorUtil.t("This is shown in app admin only. By default company gets billing record created on 14th.")}>
                        {
                          billingDayOptions.map(x => <MenuItem key={x.label} value={x.value}>{x.label}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="timezone" component={SelectInput} label={TranslatorUtil.t("Timezone")} helperText={TranslatorUtil.t("Default is "+constants.DEFAULT_TIMEZONE+". This is used to run day free parks collector to know what time we should send notifications.")}>
                        {
                          timezones.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="billingCurrency" component={SelectInput} label={TranslatorUtil.t("Billing currency")} helperText={TranslatorUtil.t("Default is "+constants.DEFAULT_BILLING_CURRENCY+".")}>
                        {
                          currencies.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="preferredLanguage" component={SelectInput} label={TranslatorUtil.t("Preferred language")} helperText={TranslatorUtil.t("Default is "+constants.DEFAULT_LANG+".")}>
                        {
                          langs.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper variant="outlined">
                      <Box px={3} py={2}>
                        <FormGroup>
                          <Field name="hideBookedByForNormalUser" component={CheckboxInput} label={TranslatorUtil.t('Hide "booked by" of others for normal users')} helperText={TranslatorUtil.t("By default we show booked by information to all users.")} />
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper variant="outlined">
                      <Box px={3} py={2}>
                        <FormGroup>
                          <Field name="includeWeekend" component={CheckboxInput} label={TranslatorUtil.t('Allow users to book on weekends')} helperText={TranslatorUtil.t("Include Saturday’s and Sunday’s for users to book. When this setting is off, users will only be able to book Monday to Friday.")} />
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper variant="outlined">
                      <Box px={3} py={2}>
                        <FormGroup>
                          <Field name="disableCurrentDayAt" component={SelectInput} label={TranslatorUtil.t("Disable current day at")} helperText={TranslatorUtil.t("By default current day will be disabled at midnight.")}>
                            {
                              disableCurrentDayAt.map(x => <MenuItem key={x.label} value={x.value}>{x.label}</MenuItem>)
                            }
                          </Field>
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper variant="outlined">
                      <Box px={3} py={2}>
                        <FormGroup>
                          <Field name="latestTimeToUnlock" component={SelectInput} label={TranslatorUtil.t("Users cannot unlock park after")} helperText={TranslatorUtil.t("By default users can unlock anytime.")}>
                            {
                              latestTimeToUnlock.map(x => <MenuItem key={x.label} value={x.value}>{x.label}</MenuItem>)
                            }
                          </Field>
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} className="hidden">
                    <Box pt={4}>
                      <Typography variant="h5">{TranslatorUtil.t("Special features")}</Typography>
                      <Typography variant="body1">{TranslatorUtil.t("All features are enabled by default")}</Typography>
                    </Box>
                    
                    <Box pt={4}>
                      <FormGroup>
                        <Field name={extraFeatures.BASIC_FAIR_USE} component={SelectInput} label={TranslatorUtil.t("Basic fair use features")} helperText={TranslatorUtil.t("Visible days ahead / maximum parks per week.")}>
                          {
                            constants.BOOLEAN_OPTIONS.map(x => <MenuItem key={x.label} value={x.value}>{x.label}</MenuItem>)
                          }
                        </Field>
                      </FormGroup>
                    </Box>
                    <Box pt={4}>
                      <FormGroup>
                        <Field name={extraFeatures.PRIORITY_QUEUE} component={SelectInput} label={TranslatorUtil.t("Priority queue feature")} >
                          {
                            constants.BOOLEAN_OPTIONS.map(x => <MenuItem key={x.label} value={x.value}>{x.label}</MenuItem>)
                          }
                        </Field>
                      </FormGroup>
                    </Box>
                    <Box pt={4}>
                      <FormGroup>
                        <Field name={extraFeatures.REPEATING_ROSTER} component={SelectInput} label={TranslatorUtil.t("Repeating roster feature")} >
                          {
                            constants.BOOLEAN_OPTIONS.map(x => <MenuItem key={x.label} value={x.value}>{x.label}</MenuItem>)
                          }
                        </Field>
                      </FormGroup>
                    </Box>
                    <Box pt={4}>
                      <FormGroup>
                        <Field name={extraFeatures.INSIGHTS} component={SelectInput} label={TranslatorUtil.t("Insights feature")} >
                          {
                            constants.BOOLEAN_OPTIONS.map(x => <MenuItem key={x.label} value={x.value}>{x.label}</MenuItem>)
                          }
                        </Field>
                      </FormGroup>
                    </Box>
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box pt={2} display="flex" flexDirection="row-reverse">
                  {submitButton}
                </Box>
              </Grid>




            </Grid>
          </div>
        </form>
      </Box>
    );

  }
}



const selector = formValueSelector(formName); // select current form
// Decorate form with selected redux form state values
const mapStateToProps = state => {
  const selectedPlan = selector(state, "plan");
  const selectedPricePerPark = selector(state, "pricePerPark");
  return {
    selectedPlan,
    selectedPricePerPark
  };
};

// Decorate form with dispatchable actions
const mapDispatchToProps = {
  change
};

// create a redux form, then include the above decorators for the created form to utilize
export default reduxForm({
  form: formName,
  validate,
  enableReinitialize: true
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanySettingForm)
);