import React from 'react';
import ProfilePicture from '../user/ProfilePicture'
import { Card, CardHeader} from '@material-ui/core';

import UserUtil from '../../shared/utils/UserUtil';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';

export default class UserCard extends React.Component {


  render() {
    const { user, users, userId, subheader } = this.props;
    var displayUser = user ? user : (Array.isArray(users) ? users.find(x => x.id === userId) : null); 
    var appendText =  (user && UserUtil.isTempUserInvitationOnly(user) ? " "+TranslatorUtil.t("invitation only") : ""); 
        return user ? <Card className="noShadowCard">
        <CardHeader
          avatar={
            <ProfilePicture profile={displayUser} large={false} />
          }
          title={subheader+appendText}
          subheader={displayUser.displayName+" ("+displayUser.email+")"}
        />
        
      </Card> : 
      <Card className="noShadowCard">
      <CardHeader
        avatar={
          <ProfilePicture profile={null} large={false} />
        }
        title="Owner is currently not defined"
        subheader=""
      />
      
    </Card>
      }
    }
    