import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import * as profileActions from "../../../../actions/profile";
import ComponentLoading from '../../../../components/ComponentLoading';
import PeopleIcon from '@material-ui/icons/People';

import PageHeading from '../../../../components/PageHeading';
import objectPath from 'object-path';
import AdminCompanyMenu from '../../../../components/admin/AdminCompanyMenu';

import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../../../shared/utils/general/ArrayUtil';

import UIFormatterUtil from '../../../../utils/UIFormatterUtil';
import PlatformIcon from '../../../../components/icons/PlatformIcon';
import AppSortableTable from '../../../../components/table/AppSortableTable';
import DateUtil from '../../../../shared/utils/general/DateUtil';
import { Check, Clear } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { firebaseFunctions } from '../../../../firebase';
import AlertDialog from '../../../../components/AlertDialog';
import Loading from '../../../../components/Loading';


class CompanyUsers extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
      passwordLink: null,
      passwordLinkEmail : null,
    }   
  }

  componentDidMount() {
    const { actions, match } = this.props;
    let _this = this;
        this.mountCompany().then((response) => {
          var company = objectPath.get(response,'data');
        if(objectPath.get(match,'params.id') === objectPath.get(company,'id')){
          Promise.all([
            actions.getProfilesByCompanyId(company.companyId),
          ]).then(()=>{
            console.log('loading done');
            _this.setState({listDataLoading: false});
  
            //catch errors
          }).catch(function(err) {
            console.log('loading error',err);
            _this.setState({listDataLoading: false});
          });
        } else {
          _this.setState({listDataLoading: false});
        }
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  mountCompany = () =>{
    const { actions, match, company } = this.props;
    if(objectPath.get(match,'params.id') && objectPath.get(match,'params.id') !== objectPath.get(company,'id')){
      return actions.getCompany(objectPath.get(match,'params.id'));
    } else {
      return Promise.resolve().then(() => {return {data: company}});
    }
  }

  generatePasswordResetLinkForProfile = (profile) => {
    this.setState({passwordLinkEmail: profile.email});
    const passwordResetLinkListener = firebaseFunctions.httpsCallable('passwordResetLinkListener');
    passwordResetLinkListener({ email: profile.email })
    .then((result) => {
      console.log(result);
     this.setState({
      passwordLink : objectPath.get(result,'data.link','something went wrong!'), 
    });
    });
  }


  getTableRows = () => {
    const { companyProfiles } = this.props;
    return ArrayUtil.isNonEmptyArray(companyProfiles) ? companyProfiles.map(profile => {
      return {
        item: profile,
        items: [
          {
            text: profile.email
          },
          {
            text: <PlatformIcon profile={profile} />,
            
          },
          {
            text: UIFormatterUtil.getUserAppDetails(profile),
            
          },
          {
            text: profile.updatedUnix ? DateUtil.formatDateTimeShort(profile.updatedUnix) : '-',
            
          },
          {
            text: profile.disabled ? <Check /> : <Clear />
          },
          {
            text: profile.id
          }
        ],
        actions: <Button variant="contained" onClick={() => this.generatePasswordResetLinkForProfile(profile)}>{TranslatorUtil.t("Get password reset link")}</Button>
        
      }
    }) : [];
  }

  getTableHeaders = () => {
    return [
      { id: 'email', numeric: false,  text: TranslatorUtil.t("Email") },
      { id: 'messagingPlatform', numeric: false,  text: TranslatorUtil.t("OS"),
       },
      { id: 'appVersion', numeric: false,  text: TranslatorUtil.t("Version"),
       },
      { id: 'updatedUnix', numeric: true,  text: TranslatorUtil.t("Updated"),
       },
      { id: 'disabled', numeric: true,  text: TranslatorUtil.t("Disabled?") },
      { id: 'id', numeric: true,  text: TranslatorUtil.t("id") },
    ];
  }

  


  
  render() {

    const { company } = this.props;
    const { listDataLoading, passwordLink, passwordLinkEmail } = this.state;

    return <div className="app-container-wrap app-admin wide-table-wrap">
        <PageHeading icon={<PeopleIcon />} title={TranslatorUtil.t("Company users")} />

        <AdminCompanyMenu company={company} />

        { listDataLoading ? <ComponentLoading /> : <AppSortableTable hasActions={true} headers={this.getTableHeaders()} rows={this.getTableRows()} defaultSortBy="email" /> }
        
        {passwordLinkEmail ? 
        <AlertDialog 
        title={TranslatorUtil.t("Reset password link for")+ " - "+passwordLinkEmail} 
        message={!passwordLink ? <Loading loading={true} /> : passwordLink} 
        onClose={() => this.setState({passwordLink : null,passwordLinkEmail : null})} />
        : null}
      </div>
  }
}



function mapStateToProps(state) {
  return {
    company: state.company,
    companyProfiles: state.companyProfiles,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...profileActions,
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUsers));
