import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import * as formNames from "../../../constants/forms";
import {
  TextInput
} from "../../../components/form/inputs";

import validate from "./validate";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Box, Typography } from "@material-ui/core";


import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';

const formName = formNames.INVITATION_FORM;

export class InvitationForm extends React.Component {




  render = () => {
    const {
      handleSubmit,
      pristine,
      submitting,
      proceedSubmit,
      processing
    } = this.props;

    const disableSubmit = pristine || submitting || processing;
    const submitButton = <Button variant="contained" color="primary" type="submit" disabled={disableSubmit}>{TranslatorUtil.t("Invite")}</Button>;

    return (
      <Box pb={2} pt={1}>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className="fullwidth">

          <div className="">
            <Grid container spacing={2}>



            <Grid item xs={12}>

<FormGroup>
  <Field name="invitations" component={TextInput} label={TranslatorUtil.t("Email(s)")} required />
  <Typography variant="caption">{TranslatorUtil.t("You can invite up to 10 users in one go. (Example: 'john@example.com,jane@example.com,george@example.co.nz')")}</Typography>
</FormGroup>
</Grid>
              <Grid item xs={12}>
                  {submitButton}
              </Grid>







            </Grid>
          </div>
        </form>
      </Box>
    );

  }
}



//const selector = formValueSelector(formName); // select current form
// Decorate form with selected redux form state values
const mapStateToProps = state => {
  return {
  };
};

// Decorate form with dispatchable actions
const mapDispatchToProps = {
  change
};


// create a redux form, then include the above decorators for the created form to utilize
export default reduxForm({
  form: formName,
  validate,
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.reset()
  }
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InvitationForm)
);