import objectPath from "object-path";
import translations from "../../translations";
import constants from '../../constants/constants';



class TranslatorUtil{

  static t(name, l = null) {
      if(l === null){
        l = constants.DEFAULT_TRANSLATION_LANG;
      }
      var langTranslations = translations[l.toLowerCase()];
      if(typeof translations[l.toLowerCase()] === 'undefined'){
        langTranslations = translations[l.toLowerCase()];
      }
        return objectPath.get(langTranslations, name, name);
  }

  static getLangsOptions(){
    return Object.keys(translations).map((x) => {
      return {"label": x,"value": x}
    });
  }
}

export default TranslatorUtil;



