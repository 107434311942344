import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../actions/company";
import * as parkActions from "../../../actions/park";
import ComponentLoading from '../../../components/ComponentLoading';
import BusinessIcon from '@material-ui/icons/Business';
import PageHeading from '../../../components/PageHeading';
import * as types from "../../../constants/actionTypes";
import StoreUtil from '../../../utils/StoreUtil';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';


import urls from '../../../shared/constants/urls';
import ArrayUtil from '../../../shared/utils/general/ArrayUtil';
import DateUtil from '../../../shared/utils/general/DateUtil';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
import CompanyUtil from '../../../shared/utils/CompanyUtil';
import AppSortableTable from '../../../components/table/AppSortableTable';
import objectPath from 'object-path';
import PlanConstants from '../../../shared/constants/plans';
import { Box, FormControlLabel, Switch } from '@material-ui/core';

class Companies extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
      excludeDisabled: true
    }   
  }

  componentDidMount() {
    const { actions, allParks } = this.props;
    let _this = this;
    this.selectCompany(null);
      Promise.all([
        actions.getCompanies(),
        !ArrayUtil.isNonEmptyArray(allParks) ? actions.getAllParks() : Promise.resolve()
      ]).then(() => {
        Promise.all([
          
        ]).then(()=>{
          console.log('loading done');
          _this.setState({listDataLoading: false});

          //catch errors
        }).catch(function(err) {
          console.log('loading error',err);
          _this.setState({listDataLoading: false});
        });
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  selectCompany = (companyId) => {
    const { history, actions } = this.props;
    if(companyId){
      actions.getCompany(companyId).then(() => history.push(urls.ADMIN_COMPANY_URL(companyId)))
    } else {
      StoreUtil.dispatchAction({data: null,  type: types.GET_COMPANY });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_PROFILES });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_PARKS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_BILLINGS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_INVITATIONS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_PARK_STATUSES });
      StoreUtil.dispatchAction({data: [],  type: types.GET_PARK_OWNERSHIPS });
    }
    
  }


  getTableRows = () => {
    const { companies, allParks } = this.props;
    const { excludeDisabled } = this.state;
    return ArrayUtil.isNonEmptyArray(companies) ? companies.filter(x => ((excludeDisabled && objectPath.get(x,'disabled',false) !== true) || !excludeDisabled)).map( company => { 
      company.parksCount = allParks.filter(x => x.companyId === company.companyId).length;
      company.estimatedPricePerMonth = company.parksCount * company.pricePerPark;
      return {
      item: company,
      items: [
        {
          text: <React.Fragment>
          <div className="clickable" onClick={() => this.selectCompany(company.id)}>
            {company.companyId}
          </div>
        </React.Fragment>
        },
        {
          text: company.userEmail,
          
        },
        {
          text: objectPath.get(company,'timezone','-'),
          
        },
        {
          text: company.createdUnix ? DateUtil.formatDateTimeShort(company.createdUnix) : '-',
          
        },
        {
          text: CompanyUtil.getPlanName(company)+(CompanyUtil.isFreePlan(company) ? ' ('+CompanyUtil.freePlanDaysLeft(company)+' days left)' : ''), 
        },
        {
          text: company.parksCount,
          additionalClasses: "hidden-sm-down"
        },
        {
          text: company.pricePerPark,
          additionalClasses: "hidden-sm-down"
        },
        {
          text: company.estimatedPricePerMonth,
          additionalClasses: "hidden-sm-down"
        },
        {
          text: company.disabled === true ? <BlockIcon /> : <CheckIcon />, 
          additionalClasses: "hidden-sm-down"
        },
      ],
    }}) : [];
  }

  getTableHeaders = () => {
    return [
      { id: 'companyId', numeric : false, text: TranslatorUtil.t("Company ID") },
      { id: 'userEmail', numeric : false, text: TranslatorUtil.t("Admin Email"),  },
      { id: 'timezone', numeric : false, text: TranslatorUtil.t("TimeZone"),  },
      { id: 'createdUnix', numeric : true, text: TranslatorUtil.t("Created"),  },
      { id: 'plan', numeric : true, text: TranslatorUtil.t("Plan") },
      { id: 'parksCount', numeric : true, text: TranslatorUtil.t("Parks"), additionalClasses: "hidden-sm-down"  },
      { id: 'pricePerPark', numeric : true, text: TranslatorUtil.t("Price/park"), additionalClasses: "hidden-sm-down"  },
      { id: 'estimatedPricePerMonth', numeric : true, text: TranslatorUtil.t("Price/month"), additionalClasses: "hidden-sm-down"  },
      { id: 'disabled', numeric : true, text: TranslatorUtil.t("Enabled"), additionalClasses: "hidden-sm-down" },
    ];
  }

  getListingInfoText = (rows) => {
    const payAsYouGoPlans = rows.filter(x => x.item.plan === PlanConstants.PAY_AS_YOU_GO_PLAN);
    const payAsYouGoValidPlans = payAsYouGoPlans.filter(x => x.item.disabled !== true);
    const validFreePlans = rows.filter(x => CompanyUtil.isFreePlan(x.item) && CompanyUtil.freePlanDaysLeft(x.item));
    let totalIncome = 0;
    let totalParks = 0;
    rows.forEach(row => {
      totalIncome = totalIncome + parseFloat(row.item.estimatedPricePerMonth);
      totalParks = totalParks + row.item.parksCount;
    });

    return TranslatorUtil.t("Listing")+ " " + rows.length + " " + TranslatorUtil.t("rows") + " / " 
    + TranslatorUtil.t("Valid PAY AS YOU GO") + ": " + payAsYouGoValidPlans.length +" ("+ TranslatorUtil.t("Disabled") + ": " + (payAsYouGoPlans.length - payAsYouGoValidPlans.length) + ") / " 
    + TranslatorUtil.t("Valid FREE")  + ": " + validFreePlans.length + " / " + TranslatorUtil.t("Managed parks") + " : " + totalParks + " / " + TranslatorUtil.t("Monthly income") + " : " + totalIncome;
  }


  render() {

    const { listDataLoading, excludeDisabled } = this.state;
    let rows = this.getTableRows();

    return <div className="app-container-wrap app-admin wide-table-wrap">
        <PageHeading icon={<BusinessIcon />} title={TranslatorUtil.t("Companies")} />

        { listDataLoading ? <ComponentLoading /> : <Box>
        <Box textAlign="right" m={2}>
          <FormControlLabel
                control={
                  <Switch
                    checked={excludeDisabled}
                    onChange={() => this.setState({excludeDisabled: !excludeDisabled})}
                    color="primary"
                  />
                }
                label="Exclude disabled companies"
              />

        </Box>
        <AppSortableTable hasActions={false} rows={rows} headers={this.getTableHeaders()} defaultSortBy={"createdUnix"} listingInfo={this.getListingInfoText(rows)} />
          </Box> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    companies: state.companies,
    userInfo: state.userInfo,
    allParks: state.allParks,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...parkActions
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Companies));
