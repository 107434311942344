import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import appConfig from '../../../shared/config/appConfig';
import * as firebaseUserActions from "../../../actions/firebaseUser";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import ResetPasswordForm from '../../../forms/user/resetpassword/form';
import { Paper, Typography, Box, Button } from "@material-ui/core";
import LinkRef from '../../../components/LinkRef';
import { auth } from '../../../firebase.js';
import { Alert, AlertTitle } from '@material-ui/lab';

import urls from '../../../shared/constants/urls';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';

class ResetPassword extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      serverMessage: null,
    }
  }



  handleSubmit = (values) => {

    const _this = this;
    var email = values.email;
    var actionCodeSettings = {
      url: appConfig.APP_HOME_URL(),
      handleCodeInApp: true
    };
    return auth.sendPasswordResetEmail(
      email, actionCodeSettings)
      .then(function () {
        _this.setState({
          serverMessage: {
            severity: "success",
            title: TranslatorUtil.t("Password has been reset."),
            message: TranslatorUtil.t("You should receive email with link to reset your password shortly.")
          }
        })
      })
      .catch(function (error) {
        _this.setState({
          serverMessage: {
            severity: "error",
            title: TranslatorUtil.t("Password reset failed!"),
            message: TranslatorUtil.t(error.message)
          }


        })
        // Error occurred. Inspect error.code.
      });

  };

  render() {

    const { serverMessage } = this.state;


    return (<div className="maxWidthPart">
      <Box className="intro text-center" py={2} pb={3}>
        <Typography variant="h5">{TranslatorUtil.t("Forgotten password")}</Typography>
      </Box>

      <Paper className="paper">
        {
          serverMessage ?
            <Alert severity={serverMessage.severity}>
              <AlertTitle>{serverMessage.title}</AlertTitle>
              {serverMessage.message}
            </Alert> : <ResetPasswordForm proceedSubmit={this.handleSubmit} />
        }

      </Paper>
      <Box className="text-center secondaryActions" py={2}>
      <Button variant="text" color="default" component={LinkRef} to={urls.LOGIN_URL}>{TranslatorUtil.t("Back to login")}</Button>
      </Box>

    </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = dispatch =>
  ({
    actions: bindActionCreators(
      {
        ...firebaseUserActions,
      },
      dispatch
    )
  });


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPassword)
));