import { combineReducers } from 'redux'
import userReducer from './user';
import uiStateReducer from './uiState';
import * as companyReducer from './companies';
import * as invitationReducer from './invitations';
import * as parkReducer from './parks';
import * as billingReducer from './billings';
import * as profileReducer from './profiles';
import * as parkStatesReducer from './parkStates';
import * as parkOwnershipsReducer from './parkOwnerships';
import * as serverReducer from './server';
import * as bookingSelection from './bookingSelection';
import * as rosterSelection from './rosterSelection';
import * as repeatingRosterSetting from './repeatingRosterSetting';

import captchaReducer from './captcha';
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  form: formReducer,
  userInfo: userReducer,
  captchaVerified: captchaReducer,
  uiState: uiStateReducer,
  companies: companyReducer.getCompanies,
  companyParks: parkReducer.getParks,
  companyProfiles: profileReducer.getProfiles,
  companyInvitations: invitationReducer.getInvitations,
  companyBillings: billingReducer.getBillings,
  company: companyReducer.getCompany,
  park: parkReducer.getPark,
  allParks: parkReducer.getAllParks,
  parkStates: parkStatesReducer.getParkStates,
  parkOwnerships: parkOwnershipsReducer.getParkOwnerships,
  serverOffsetTime: serverReducer.getOffsetTime,
  bookingSelectionEnabled: bookingSelection.setBookingSelection,
  bookingSelection: bookingSelection.getBookingSelection,
  rosterSelection: rosterSelection.getRosterSelection,
  repeatingRosterSetting: repeatingRosterSetting.getRepeatingRosterSetting,
  repeatingRoster: repeatingRosterSetting.getRepeatingRoster,
  parkRepeatingRosterSetting: repeatingRosterSetting.getParkRepeatingRosterSetting
});
export default createRootReducer