import React from 'react';
import { Button, Divider, Typography, ButtonGroup } from '@material-ui/core';


import { connect } from "react-redux";


import DateUtil from '../../shared/utils/general/DateUtil';
import ParkUtil from '../../shared/utils/ParkUtil';


class DisallowedParkStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
      }

      toggleParkStatusDetail = () => {
        this.setState({parkStatusDetailShow: !this.state.parkStatusDetailShow});
    }

    getDetailTitle = () => {
        const { park } = this.props;
        return "Park "+park.parkId;
    }

    getDetailMessage = () => {
        const { selectedDate, companyProfiles, park } = this.props;
        var ownedBy = ParkUtil.getCurrentParkOwner(park,companyProfiles);
         return <Typography variant="body2" className="parkStatusWrap"><em>
         {
             DateUtil.viewFormat(selectedDate.date)
         }
         </em>
                    

                    { ownedBy ? <span><br /><Divider/><br />
                      <Typography variant="body2" color="secondary">Currently owned by:</Typography>
                    <strong>{ ownedBy ? ownedBy.displayName : null }</strong>
                    <br /><strong>{ ownedBy ? ownedBy.email : null }</strong>
                    { ownedBy && ownedBy.phone ? <strong><br />{ownedBy.phone}</strong> : null }</span> : 
                    <span><br /><Divider/><br /><Typography variant="body2" color="secondary">Currently without owner</Typography></span>
                }
                </Typography>;
    }



  render() {
    // const {parkStatusDetailShow} = this.state;
    
    return <React.Fragment><ButtonGroup size="small" variant="outlined"><Button className="disallowed-future-btn" 
    // onClick={() => this.toggleParkStatusDetail()}
    >n/a</Button>
            {/* { parkStatusDetailShow ? <AlertDialog title={this.getDetailTitle()} message={this.getDetailMessage()} onClose={this.toggleParkStatusDetail} /> : null } */}
            </ButtonGroup>
        </React.Fragment>
    

    
  }
}

function mapStateToProps(state) {
  return {
    companyProfiles: state.companyProfiles,
    parkStates: state.parkStates,
    userInfo: state.userInfo,
    parkOwnerships: state.parkOwnerships
  };
}



export default connect(
  mapStateToProps
)(DisallowedParkStatus);




