import React from "react";
import { withRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import * as firebaseUserActions from "../actions/firebaseUser"
import * as captchaActions from "../actions/captcha"
import * as serverActions from "../actions/server"
import * as parkActions from "../actions/park"
import * as parkOwnershipActions from "../actions/parkOwnerships"
import * as repeatingRosterActions from "../actions/repeatingRoster"
import * as invitationsActions from '../actions/invitation';
import * as profileActions from "../actions/profile";



//actions



//styles
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import styles from "../theme/styles";
import CustomTheme from "../theme/custom";
import classnames from "classnames";

//components
import PublicAppRoutes from "../routes/PublicAppRoutes";
import AppRoutes from "../routes/AppRoutes";
import AdminRoutes from "../routes/AdminRoutes";

import withFirebaseAuth from "../hoc/withFirebaseAuth";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppLoading from "../components/AppLoading";
//import StoreUtil from "../utils/StoreUtil";
import GetErrorsListener from "../components/GetErrorsListener";
import SnackBarDismissAction from "../components/SnackBarDismissAction";
import objectPath from "object-path";
import MainAppBar from "../components/MainAppBar";
import EmailVerify from "./pages/user/EmailVerify";
import CompanySetup from "./pages/user/CompanySetup";
import ProfileSetup from "./pages/user/ProfileSetup";
import PlanWarning from "../components/user/PlanWarning";

import SuperAdminUtil from "../utils/SuperAdminUtil";

import DebugConsole from "../components/dev/DebugConsole";


import UserUtil from '../shared/utils/UserUtil';
import EnvBar from "../components/dev/EnvBar";
import ProdTracker from "../components/ProdTracker";

class App extends React.Component {
  

  constructor (props) {
    super(props);
    this.state = {
      appInitLoading: true
    }   
  }

  logoutUser = () => {
    const { actions } = this.props;
      actions.logoutUser().then(() => window.location.href = "/");
  }


  loginAnonym = () => {
    const { actions } = this.props;
    actions.loginAnonym();
  }

  runCaptcha = () => {
    const { actions } = this.props;
    actions.getCaptcha(actions.setCaptcha);
  }

  
  componentDidMount() {
    const { actions } = this.props;
    let _this = this;
      Promise.all([
        actions.getServerTimeOffset()
        //this.runCaptcha()
      ]).then(() => {
        Promise.all([
          
        ]).then(()=>{
          console.log('app init loading done');
          _this.setState({appInitLoading: false});

          //catch errors
        }).catch(function(err) {
          _this.appLoadingFailed(err);
        });
      }).catch(function(err) {
        _this.appLoadingFailed(err);
      });

    
  }

  


  componentWillReceiveProps(nextProps) {
    var userId = objectPath.get(nextProps,'user.uid');
		if (userId && userId !== objectPath.get(this,'props.user.uid')) {
      this.setupUserInfo(nextProps);
		}
  }

  setupUserInfo = (nextProps) => {
    const { actions } = this.props;
    var userInfo = UserUtil.getUserInfoObject(
      objectPath.get(nextProps,'user.profile', null),
      objectPath.get(nextProps,'user.company', null),
      objectPath.get(nextProps,'user', null),
      objectPath.get(nextProps,'user.serverTime', null),
      );
    actions.setUserInfo(userInfo);
    actions.setCaptcha();
    if(UserUtil.hasCompany(userInfo)){
      this.mountUserRelatedData(userInfo);
    }
  }

  mountUserRelatedData = (userInfo) => {
    const { actions } = this.props;
    let _this = this;
    _this.setState({appInitLoading: true});
    Promise.all([
      actions.listenProfilesByCompanyId(UserUtil.getCompanyId(userInfo)),
      actions.getInvitationsByCompanyId(UserUtil.getCompanyId(userInfo)),
      actions.listenParksByCompanyId(UserUtil.getCompanyId(userInfo)),
      actions.listenParksOwnershipsByCompanyId(UserUtil.getCompanyId(userInfo)),
      UserUtil.isAtLeastCompanyManager(userInfo) ? actions.listenRepeatingRosterSettingByCompanyId(UserUtil.getCompanyUid(userInfo), () => {}) : Promise.resolve(),
      UserUtil.isAtLeastCompanyManager(userInfo) ? actions.listenRepeatingRoster(UserUtil.getCompanyUid(userInfo), () => {}) : Promise.resolve(),
    ]).then(()=>{
      console.log('app user related data loading done');
      _this.setState({appInitLoading: false});

      //catch errors
    }).catch(function(err) {
      _this.appLoadingFailed(err);
    });
  }



  



  appLoadingFailed = (err) => {
    //const { history } = this.props;
    console.log(err);
    this.setState({appInitLoading: false}, () => {
      //history.push('/500');
    });
  }

  render() {
    const { 
      classes,
      userInfo,
      //captchaVerified,
      user, 
      userLoading } = this.props;
    const { appInitLoading
    } = this.state;
    

    return (
      <MuiThemeProvider theme={CustomTheme}>
        <ProdTracker />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          classes={{
            root: "snackBarWrap",
            variantSuccess: classnames(classes.snackbarWrap, classes.success),
            variantError: classnames(classes.snackbarWrap,classes.error),
            variantWarning: classnames(classes.snackbarWrap,classes.warning),
            variantInfo: classnames(classes.snackbarWrap,classes.info)
          }}
          action={key => <SnackBarDismissAction id={key} />}
        >
        <div>

          
        

        { appInitLoading || userLoading ? <AppLoading />  : <main>

          { UserUtil.isLoggedIn(userInfo) ? 
          ( 
            <React.Fragment>
             
            {
              !UserUtil.hasProfile(userInfo) ? <ProfileSetup user={user} /> // setup profile
              :
              UserUtil.isCreatedAsCompanyAdmin(userInfo) && !UserUtil.isEmailVerified(userInfo) ? <EmailVerify user={user} /> //verify email
              : 
              !UserUtil.hasCompany(userInfo) ? <CompanySetup />
              :
              <React.Fragment>
                <MainAppBar userInfo={userInfo} />
                {SuperAdminUtil.isSuperAdmin(userInfo) ? <React.Fragment>
                  <AdminRoutes userInfo={userInfo} />
                  <EnvBar />
                </React.Fragment> : <React.Fragment>
                <AppRoutes userInfo={userInfo} />
                <DebugConsole />
                <EnvBar />
                <PlanWarning />
                </React.Fragment>
                }
              </React.Fragment>
            }
            </React.Fragment>
          ) : <PublicAppRoutes /> }


            </main> }
          <GetErrorsListener />
          </div>
        </SnackbarProvider>
        
        </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    captchaVerified: state.captchaVerified,
    userInfo: state.userInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...firebaseUserActions,
        ...captchaActions,
        ...parkActions,
        ...parkOwnershipActions,
        ...profileActions,
        ...invitationsActions,
        ...serverActions,
        ...repeatingRosterActions
      },
      dispatch
    )
  };
}

const StyledApp = withStyles(styles, { withTheme: true })(App);

const ReduxApp = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StyledApp)
);

const AuthedApp = withFirebaseAuth(ReduxApp);

export default AuthedApp;
