
import ValidationUtil from '../../../utils/ValidationUtil';

const validate = values => {
  let errors = {};
  const requiredFields = [
    "invitations"
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);

  

  return errors;
};

export default validate;
