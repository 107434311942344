import * as types from "../constants/actionTypes";


export function addRosterSelection(park, date){
    console.log('addRosterSelection');
    return function(dispatch) {
        return dispatch({type: types.ADD_ROSTER_SELECTION,data: {park, date} });
    };
}

export function removeRosterSelection(park, date){
    console.log('removeRosterSelection');
    return function(dispatch) {
        return dispatch({type: types.REMOVE_ROSTER_SELECTION, data: {park, date} });
    };
}

export function removeRosterSelections(){
    return function(dispatch) {
        return dispatch({type: types.REMOVE_ROSTER_SELECTIONS });
    };
}