import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ComponentLoading from '../../../components/ComponentLoading';
import PageHeading from '../../../components/PageHeading';
import { Box, LinearProgress, Tab, Tabs, Typography } from '@material-ui/core';
import firestoreApi from '../../../firebase.js';
import * as types from "../../../constants/actionTypes";
import StoreUtil from '../../../utils/StoreUtil';


import ArrayUtil from '../../../shared/utils/general/ArrayUtil';
import DateUtil from '../../../shared/utils/general/DateUtil';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
import CompanyUtil from '../../../shared/utils/CompanyUtil';
import AppSortableTable from '../../../components/table/AppSortableTable';
import { AttachMoney, Warning } from '@material-ui/icons';
import FirestoreUtil from '../../../shared/utils/general/FirestoreUtil';
import objectPath from 'object-path';
import PlanConstants from '../../../shared/constants/plans';
import FormatterUtil from '../../../shared/utils/general/FormatterUtil';

class TotalBilling extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      tabId: 0,
      billingRecords: [],
    }   
  }

  componentDidMount() {
    let _this = this;
    this.setState({
      loading: true
    })
    this.flushCompany();
      Promise.all([
        firestoreApi.collection('billingRecords')
        .where('plan','==',PlanConstants.PAY_AS_YOU_GO_PLAN)//count only pay as you go plans
        .get(),
      ]).then((results) => {
        this.setState({
          billingRecords: FirestoreUtil.parseCollectionData(results[0]),
          loading: false
        });
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  flushCompany = () => {

      StoreUtil.dispatchAction({data: null,  type: types.GET_COMPANY });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_PROFILES });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_PARKS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_BILLINGS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_INVITATIONS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_PARK_STATUSES });
      StoreUtil.dispatchAction({data: [],  type: types.GET_PARK_OWNERSHIPS });
    
  }

  isTheSameMonth = (a,b) =>{
    return a.month === b.month && a.year === b.year;
  }

  
  getCompaniesTableHeaders = () => {
    return [
      { id: 'month',numeric : true, text: TranslatorUtil.t("Month") },
      { id: 'year',numeric : true, text: TranslatorUtil.t("Year") },
      { id: 'companyId',numeric : true, text: TranslatorUtil.t("Company") },
      { id: 'calculatedPrice',numeric : true, text: TranslatorUtil.t("Calculated Price") },
      { id: 'billingDay',numeric : true, text: TranslatorUtil.t("Billing day") },
      { id: 'createdUnix',numeric : true, text: TranslatorUtil.t("Created") },
      { id: 'pricePerPark',numeric : true, text: TranslatorUtil.t("Price per park") },
      { id: 'parksCount',numeric : true, text: TranslatorUtil.t("Parks") },
      { id: 'activeUsersCount',numeric : true, text: TranslatorUtil.t("Active users") },
      { id: 'createdRetrospectively', text: TranslatorUtil.t("Retrospectively") },
    ];
  }

  

  getMonthsTableRows = () => {
    const { billingRecords } = this.state;
    var highestMonthTotal = 0;
    if(ArrayUtil.isNonEmptyArray(billingRecords)){
      var months = [];
      billingRecords.forEach(b => {
        if(months.findIndex(m => this.isTheSameMonth(m,b)) === -1){
          var monthBilling = billingRecords.filter(x => this.isTheSameMonth(x,b));
          var monthTotal = 0;
          monthBilling.forEach(x => {
            monthTotal = monthTotal + x.calculatedPrice;
          });
          if(monthTotal > highestMonthTotal){
            highestMonthTotal = monthTotal;
          }
          months.push({
            month: b.month,
            year: b.year,
            monthYear: parseInt(String(b.year)+String(b.month)),
            count: monthBilling.length,
            average: monthTotal/monthBilling.length,
            total: monthTotal
          });
        }
      });

      

      return months.map( item => { return {
        item: item,
        items: [
          {
            text: item.month + " / " + item.year
          },
          {
            text: item.count
          },
          {
            text: (Math.round(item.average * 10) / 10)
          },
          {
            text: <Box>
              <Box>{item.total}</Box>
              {
                !Number.isNaN(item.total) ? <LinearProgress className="progress" variant="determinate" value={Number(FormatterUtil.getPercentage(highestMonthTotal, item.total))} /> : null
              }
          </Box>,
          additionalClasses:'progress-col'
          }
        ]
      }})
    }
    return [];
  }

  getMonthsTableHeaders = () => {
    return [
      { id: 'monthYear',numeric : true, text: TranslatorUtil.t("Month") },
      { id: 'count',numeric : true, text: TranslatorUtil.t("Count") },
      { id: 'average',numeric : true, text: TranslatorUtil.t("Average") },
      { id: 'total',numeric : true, text: TranslatorUtil.t("Total"),
      additionalClasses:'progress-col' },
    ];
  }

  getCompaniesTableRows = () => {
    const { billingRecords } = this.state;
    return ArrayUtil.isNonEmptyArray(billingRecords) ? billingRecords.map( item => { return {
      item: item,
      items: [
        {
          text: item.month
        },
        {
          text: item.year
        },
        {
          text: item.companyId
        },
        {
          text: item.calculatedPrice
        },
        {
          text: item.billingDay
        },
        {
          text: item.createdUnix ? DateUtil.formatDateTimeShort(item.createdUnix) : '-',
        },
        {
          text: item.pricePerPark
        },
        {
          text: item.parksCount
        },
        {
          text: item.activeUsersCount
        },
        {
          text: objectPath.get(item,'createdRetrospectively',false) === true ? <Warning /> : '', 
        }
      ]
    }}) : [];
  }

  getCompaniesListingInfo = () => {
    const rows = this.getCompaniesTableRows();
    
    return ArrayUtil.isNonEmptyArray(rows) ? TranslatorUtil.t("Listing")+ " " + rows.length + " " + TranslatorUtil.t("rows") : '';
  }


  handleChangeTab = (event, value) => {
    this.setState({ tabId: value });
  }
  
  render() {

    const { listDataLoading, billingRecords, tabId } = this.state;

    return <div className="app-container-wrap app-admin wider-table-wrap">
        <PageHeading icon={<AttachMoney />} title={TranslatorUtil.t("Total billing")} />

        { listDataLoading ? <ComponentLoading /> : <React.Fragment>
          <Box>
              <Typography variant="h4">{TranslatorUtil.t("Estimated total") +" : "+ CompanyUtil.getBillingsTotal(billingRecords)}</Typography>
            </Box>
        
            <Box pt={1}>
          <Tabs value={tabId} onChange={this.handleChangeTab} indicatorColor="primary">
            <Tab label={TranslatorUtil.t("Monthly view")} />
            <Tab label={TranslatorUtil.t("Companies view")} />
          </Tabs>
        </Box>

            <Box pt={4}>
            {tabId === 0 ? <AppSortableTable hasActions={false} headers={this.getMonthsTableHeaders()} rows={this.getMonthsTableRows()} defaultSortBy="monthYear" listingInfo={' '} /> : null}
            {tabId === 1 ? <AppSortableTable hasActions={false} headers={this.getCompaniesTableHeaders()} rows={this.getCompaniesTableRows()} defaultSortBy="createdUnix" listingInfo={this.getCompaniesListingInfo()} /> : null}
        </Box>

        
          </React.Fragment> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalBilling));
