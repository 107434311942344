

import React from 'react';
import { Button, FormControlLabel, Switch, TextField } from '@material-ui/core';
import firestoreApi from '../../../../firebase.js';
import DateUtil from '../../../../shared/utils/general/DateUtil';
import FirestoreUtil from '../../../../shared/utils/general/FirestoreUtil';
import CompanyUtil from '../../../../shared/utils/CompanyUtil.js';
import moment from 'moment';

export default class MigrationTimezoneDateUnix extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
          loading: false,

          validInputs: false,
          simulateResults: [],
          migrationTextResult: 'No result',
          simulate: true,


          migrationName: 'MigrationTimezoneDateUnix',
          year: '2022',
          month: '',
          
        }   
      }

      revalidate = () => { 

        this.setState({
            validInputs : false
        })
        if(this.isValid()){
            this.setState({
                validInputs : true
            })
            return true;
        };
        return false;
      }


      isValid = () => { 
        const { year, month } = this.state;
        return String(year).length && String(month).length;
      }



  runMigration = () =>{
    const { simulate, year, month } = this.state;

    var batch = firestoreApi.batch();
    var readCounts = 0;
    var updateCounts = 0;
    var fixedCounts = 0;
    var updateLimit = 250;
    var simulateResults = [];
    
    
    if(this.isValid()){
        //modify migration if needed
        firestoreApi.collection('companies')
        .get().then(companiesData => {
            var companies = FirestoreUtil.parseCollectionData(companiesData);
            firestoreApi.collection('parkStates')
                .where("year", "==", String(year))
                .where("month", "==", String(month))
                .get().then(result => {
                    if (result && result.docs) {
                        result.docs.forEach(item => {
                            readCounts++;
                            var itemData = item.data();
                            //THIS IS HOW to check for old migration to start of the day without timezone
                            var isStartOfTheDay = parseInt(itemData.dateUnix) === parseInt(DateUtil.unixStartOfDay(itemData.day,itemData.month,itemData.year));/// wrong assumption without using timezone
                            if(isStartOfTheDay && updateCounts < updateLimit){
                                var itemTimeZone = CompanyUtil.getTimezone(companies.find(x => x.companyId === itemData.companyId));
                                var timezoneOffset = moment.tz.zone(itemTimeZone).offset(moment());
                                var newDateUnix = DateUtil.unixOfDay(itemData.day,itemData.month,itemData.year,12,0,1) + (timezoneOffset * 60000);

                                var companyTimezoneItemDay = moment(DateUtil.unixStartOfDay(itemData.day,itemData.month,itemData.year)).tz(itemTimeZone).format("DD");
                                itemData.dateUnixManualFix = true;
                                
                                if(companyTimezoneItemDay !== itemData.day){//fix only the ones with incorrect day
                                    if(simulate){
                                            simulateResults.push([
                                                "same day: "+ (companyTimezoneItemDay === itemData.day ? " YES " : " ❌ NO"),
                                                "new same day: "+ (moment(newDateUnix).tz(itemTimeZone).format("DD") === itemData.day ? " YES " : " ❌ NO"),
                                                timezoneOffset / 60,
                                                itemData.companyId,
                                                itemTimeZone,
                                                itemData.day,
                                                itemData.month,
                                                "old: "+itemData.dateUnix+" ("+DateUtil.formatDateTimeDate(itemData.dateUnix)+")",
                                                "new: "+newDateUnix+" ("+DateUtil.formatDateTimeDate(newDateUnix,itemTimeZone)+")",
                                                
                                            ]
                                            .join(' | '));
                                            this.setState({
                                                simulateResults: simulateResults
                                            });
                                        
                                    } else {
                                            var overwrites = {"dateUnix": newDateUnix,"dateUnixTimeZoneManualFix":true};
                                            console.log('udpate',overwrites,itemData)
                                            var updateRef = firestoreApi.collection("parkStates").doc(item.id);
                                            batch.update(updateRef, overwrites);
                                        
                                    }
                                    updateCounts++;
                                }
                                
                            } 
                            if(!isStartOfTheDay && itemData.dateUnixTimeZoneManualFix) {
                                fixedCounts++;
                                // if(simulate){
                                //     simulateResults.push(['✔️ '+itemData.companyId,item.id,itemData.day,itemData.month,itemData.year,'dateUnix:'+itemData.dateUnix+" (checked:"+DateUtil.formatDateTimeSeconds24hrs(itemData.dateUnix)+")"].join(' | '));
                                //     this.setState({
                                //         simulateResults: simulateResults
                                //     });
                                // }
                            }
                        });
                    }
                    var migrationTextResult = ((simulate ? '(Simulation ON) ' :'') +' Results count : ' + readCounts + ' / already fixed count : ' + fixedCounts + ' / updates count : ' + updateCounts + ' (limit : '+updateLimit+')');

                    this.setState({
                        migrationTextResult: migrationTextResult,
                        simulateResults: simulateResults
                    });

                    if(simulate){
                        return Promise.resolve();
                    } else {
                        return batch.commit().then((batchResult) => {
                            console.log(migrationTextResult);
                            return migrationTextResult;
                        });
                    }
                    
                });
        });
        
    }
    
  }


  render() {
    const { loading, validInputs, simulate, migrationTextResult, migrationName, simulateResults } = this.state;
    return <div className="innerWrap">
        <h2>{ migrationName }</h2>
        <p>Get all park states by date filter and add dateUnix information to the ones which are missing this property.</p>
        <div>
            <div>
                <TextField value={this.state.year} label="Year" variant="outlined" disabled={loading} onChange={evt => {this.setState({ year: evt.target.value });this.revalidate();} } />
                <TextField value={this.state.month} label="Month" variant="outlined" disabled={loading} onChange={evt => {this.setState({ month: evt.target.value });this.revalidate();} } />
            </div>
            <div>
            <Button variant="contained" onClick={this.runMigration} color="primary" disabled={loading || !validInputs}>{loading ? "Processing" : "Run" }</Button> &nbsp;&nbsp;&nbsp;
                <FormControlLabel
                    control={<Switch checked={simulate} onChange={() => this.setState({ simulate: !simulate })} />}
                    label={simulate ? "Simulate" : "Running updates"}
                />
                
            </div>
            
        </div>

        <div>
            <div>{migrationTextResult}</div>
            <div>{simulateResults.map(x => <div>{x}<hr/></div>)}</div>
        </div>

    </div>
  }
}
