import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StaticPage from './StaticPage'
import { Typography } from '@material-ui/core';

import urls from '../../../../shared/constants/urls';
import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';

function TermsAndConditions(props) {

  const icon = <AssignmentIcon className="icon" />;
  const content = <Typography>Terms and Conditions</Typography>;
  const title = TranslatorUtil.t("Terms and Conditions");
  const navigateText = TranslatorUtil.t("Go Back");
  const navigateLink = urls.INDEX_URL;

  return <StaticPage content={content} icon={icon} title={title} navigateText={navigateText} navigateLink={navigateLink} />
}

export default TermsAndConditions;
