import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import * as profileActions from "../../../../actions/profile";
import * as parkActions from "../../../../actions/park";
import ComponentLoading from '../../../../components/ComponentLoading';

import PageHeading from '../../../../components/PageHeading';
import objectPath from 'object-path';
import AdminCompanyMenu from '../../../../components/admin/AdminCompanyMenu';

import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';

import { NotificationsActive } from '@material-ui/icons';
import DateUtil from '../../../../shared/utils/general/DateUtil';
import { Box, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Calendar from 'react-calendar';
import moment from 'moment';
import firestoreApi from '../../../../firebase';
import FirestoreUtil from '../../../../shared/utils/general/FirestoreUtil';
import CompanyUtil from '../../../../shared/utils/CompanyUtil';
import constants from '../../../../shared/constants/constants';
import UserUtil from '../../../../shared/utils/UserUtil';

class NotificationConsole extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
      dataLoading: true,
      company: null,
      parkIdFilter: "",
      days: [],
      selectedDate: DateUtil.getTwoWeeksAgo(),
      endDate: moment(),

      collapsedI: null,
      orderBy: "updatedUnix",
      notifications: [], //parkId,date,updatedUnix
    }   
  }

  componentDidMount() {
    const { actions, match } = this.props;
    let _this = this;
        this.mountCompany().then((response) => {
          var company = objectPath.get(response,'data');
          _this.setState({company: company});
        if(objectPath.get(match,'params.id') === objectPath.get(company,'id')){
          Promise.all([
            actions.getParksByCompanyId(company.companyId),
            actions.getProfilesByCompanyId(company.companyId),
            this.getNotifications()
          ]).then(()=>{
            console.log('loading done');
            _this.setState({listDataLoading: false});
  
            //catch errors
          }).catch(function(err) {
            console.log('loading error',err);
            _this.setState({listDataLoading: false});
          });
        } else {
          _this.setState({listDataLoading: false});
        }
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  getDates = () => {
    const { userInfo } = this.props;
    var date = moment().subtract(2, 'day');
    return DateUtil.getTwoWeeks(DateUtil.getURLdate(date, UserUtil.includeWeekend(userInfo)), true, UserUtil.includeWeekend(userInfo));
  }

  orderedNotifications = () => {
    const {orderBy,parkIdFilter,notifications} = this.state;
    var filtered = parkIdFilter ? notifications.filter(x => x.parkId === parkIdFilter) : notifications;
    return filtered.sort((a, b) => (objectPath.get(b,orderBy,'')+"").localeCompare(objectPath.get(a,orderBy,'')+""))
  }

  mountCompany = () =>{
    const { actions, match, company } = this.props;
    if(objectPath.get(match,'params.id') && objectPath.get(match,'params.id') !== objectPath.get(company,'id')){
      return actions.getCompany(objectPath.get(match,'params.id'));
    } else {
      return Promise.resolve().then(() => {return {data: company}});
    }
  }



  getNotifications = () => {
    const { company } = this.state;
    var dates = this.getDates();
    console.log('getting notifications for '+company.id,dates);
    return firestoreApi.collection('notifications')
    .where('companyId', '==', company.companyId)
    .where('dateUnix', '>=', DateUtil.getStartUnixFromDates(dates))
    .where('dateUnix', '<=', DateUtil.getEndUnixFromDates(dates))
    .onSnapshot(
        response => this.setState({
            notifications: FirestoreUtil.parseCollectionData(response)
            // .sort((a, b) => b.updatedUnix - a.updatedUnix)
          }),
          error => this.setState({
              notifications: []
            })
      );
    
  }

  getFilteredParkStates = () => {
    const { parkIdFilter } = this.state;
    const { parkStates } = this.props;
    return parkIdFilter ? parkStates.filter(x => x.parkId === parkIdFilter) : parkStates;
  }


  getDates = () => {
    const { userInfo } = this.props;
    const { selectedDate } = this.state;
    return DateUtil.getTwoWeeks(selectedDate, false, UserUtil.includeWeekend(userInfo));
  }
  

  changeDate = (date) => {
    
    this.setState({ 
      selectedDate: moment(date),
      endDate: moment(date).clone().add(13,'days').endOf('day')
    }, () => {
      this.getNotifications();
    });
  }

  getNotificationResultText(n){
    var result = ' - ';
    if(n){
      var resultKey = objectPath.get(n,'result',0);
      result = objectPath.get(constants,'NOTIFICATION_RESULTS.'+resultKey,' - ');
    }
    return result;
  }


  
  render() {

    const { company, companyParks, companyProfiles } = this.props;
    const { listDataLoading, selectedDate, endDate, collapsedI } = this.state;
    const calendarDateValue = selectedDate.clone().toDate();

    return <div className="app-container-wrap app-admin wider-table-wrap">
        <PageHeading icon={<NotificationsActive />} title={TranslatorUtil.t("Notifications")} />

        <AdminCompanyMenu company={company} />

        { listDataLoading ? <ComponentLoading /> : <React.Fragment>
        <Box mb={2}>
        
        <Calendar
        tileClassName={({ activeStartDate, date, view }) => {
          return moment(date).isBetween(selectedDate, endDate, 'days', '[]') ? 'inRange' : 'notInRange'
        }}
        onChange={this.changeDate}
        value={calendarDateValue}
      />
      </Box>

        <Box>
      <Box py={2}>
      <small><strong>{ CompanyUtil.getTimezone(company) } (time  {CompanyUtil.getCurrentTimeByTimezone(company,'HH:mm')}) and system will { CompanyUtil.isNotificationAllowedByTimezone(company) ? '' : 'NOT' } PROCESS notifications for this company atm.</strong></small><br />
      <small>Listing notifications for dates: { this.getDates().map(x => DateUtil.dbFormat(x.date)).join(', ') }. <br/>Possible results are : <i>{constants.NOTIFICATION_RESULTS.join(', ')}</i> - all calculated at <i>Processed Time</i></small>
      </Box>
      <Box mb={1}>
        <Paper>
          <Box p={2}>
        order by : <Select
          native
          value={this.state.orderBy}
          onChange={(event) => this.setState({
            orderBy: event.target.value
          })}
        >
          {['parkId','updatedUnix','date'].map(x => <option key={x} value={x}>{x}</option>)}
        </Select> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; park ID filter : <Select
          native
          value={this.state.parkIdFilter}
          onChange={(event) => this.setState({
            parkIdFilter: event.target.value
          })}
        >
          <option value="">All</option>
          {companyParks.map(x => <option key={x.id} value={x.id}>{x.parkId}</option>)}
        </Select>
        </Box>
        </Paper>
      
      </Box>

      <TableContainer component={Paper}>
      <Table size="small">
      <TableHead>
        
          <TableRow>
              <TableCell className="w-150px">Date</TableCell>
              <TableCell className="w-100px">Park ID</TableCell>
              <TableCell className="w-150px">Done by</TableCell>
              <TableCell className="w-150px">Result</TableCell>
              <TableCell >Sending to</TableCell>
              <TableCell className="w-100px">Priority</TableCell>
              <TableCell className="w-150px">Created</TableCell>
              <TableCell className="w-150px">Processed Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.orderedNotifications().map((n,i) => {
                    var park = companyParks.find(p => p.id === n.parkId);
                    var proceededBy = companyProfiles.find(p => p.id === n.proceededBy);
                    return <React.Fragment key={i}>
                      <TableRow onClick={() => this.setState({collapsedI: i})}>
                      <TableCell className="w-150px">{n.date}</TableCell>
                      <TableCell className="w-100px">{objectPath.get(park,'parkId',' - ')}</TableCell>
                      <TableCell className="w-150px">{(objectPath.get(proceededBy,'email',' 𐄂 '))}</TableCell>
                      <TableCell className="w-150px">{this.getNotificationResultText(n)}</TableCell>
                      <TableCell>{objectPath.get(n,'sendingTo',[]).join(',')}</TableCell>
                      <TableCell className="w-100px">{objectPath.get(n,'priority',false)  ? '✓' : '𐄂'}</TableCell>
                      <TableCell className="w-150px">{DateUtil.formatDateTimeSeconds(n.createdUnix)}</TableCell>
                      <TableCell className="w-150px">{objectPath.get(n,'processed',false) ? DateUtil.formatDateTimeSeconds(n.updatedUnix) : '𐄂'}</TableCell>
                      
                  </TableRow>
                      {
                        collapsedI === i ? <TableRow key={"collapsed"+i} onClick={() => this.setState({collapsedI: i})}>
                        <TableCell colSpan={8}>{JSON.stringify(n).replaceAll('","','", "')}</TableCell>
                    </TableRow> : null
                      }
                      </React.Fragment>
          })}
        </TableBody>
      </Table>
    </TableContainer></Box>
          </React.Fragment> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    company: state.company,
    companyProfiles: state.companyProfiles,
    companyParks: state.companyParks,
    parkStates: state.parkStates,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...profileActions,
        ...parkActions,
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationConsole));
