// AJAX
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";

// AUTH
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const USER_LOGIN = "USER_LOGIN";
export const USER_ANONYM_LOGIN = "USER_ANONYM_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_SIGNIN = "USER_SIGNIN";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";
export const SET_USER_INFO_SUCCESS = "SET_USER_INFO_SUCCESS";
export const SET_USER_INFO_ERROR = "SET_USER_INFO_ERROR";

// USER RELATED
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_COMPANY = "GET_USER_COMPANY";

// PROFILES
export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILE = "GET_PROFILE";
export const POST_PROFILE = "POST_PROFILE";
export const PUT_PROFILE = "PUT_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const GET_PROFILE_COMPANY = "GET_PROFILE_COMPANY";
export const PUT_PROFILE_COMPANY = "PUT_PROFILE_COMPANY";

//COMPANIES
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";
export const POST_COMPANY = "POST_COMPANY";
export const GET_COMPANY_CHECK = "GET_COMPANY_CHECK";
export const PUT_COMPANY = "PUT_COMPANY";
export const GET_COMPANY_PROFILES = "GET_COMPANY_PROFILES";
export const GET_COMPANY_PARKS = "GET_COMPANY_PARKS";
export const GET_COMPANY_INVITATIONS = "GET_COMPANY_INVITATIONS";
export const GET_COMPANY_BILLINGS = "GET_COMPANY_BILLINGS";
export const GET_ADMIN_COMPANY_PARK_STATES = "GET_ADMIN_COMPANY_PARK_STATES";

//parks
export const GET_ALL_PARKS = "GET_ALL_PARKS";
export const GET_PARK = "GET_PARK";
export const PUT_PARK = "PUT_PARK";
export const SET_PARK_OWNER = "SET_PARK_OWNER";
export const CREATE_PARK = "CREATE_PARK";
export const DELETE_PARK = "DELETE_PARK";
export const DELETE_PARK_OWNERSHIPS = "DELETE_PARK_OWNERSHIPS";
export const DELETE_PARK_STATUSES = "DELETE_PARK_STATUSES";
export const GET_INVITATION = "GET_INVITATION";
export const POST_INVITATION = "POST_INVITATION";
export const DELETE_INVITATION = "DELETE_INVITATION";
export const GET_BILLING = "GET_BILLING";

export const SET_CAPTCHA = "SET_CAPTCHA";
export const GET_CAPTCHA = "GET_CAPTCHA";

export const SET_TRANSLATIONS = "SET_TRANSLATIONS";

export const SET_FORM_SUBMITTING_STARTED = "SET_FORM_SUBMITTING_STARTED";
export const SET_FORM_SUBMITTING_FINISHED = "SET_FORM_SUBMITTING_FINISHED";

//roster
export const GET_REPEATING_ROSTER = "GET_REPEATING_ROSTER";
export const PUT_REPEATING_ROSTER = "PUT_REPEATING_ROSTER";
export const SET_REPEATING_ROSTER = "SET_REPEATING_ROSTER";
export const GET_PARK_REPEATING_ROSTER_SETTING = "GET_PARK_REPEATING_ROSTER_SETTING";
export const GET_REPEATING_ROSTER_SETTING = "GET_REPEATING_ROSTER_SETTING";
export const SET_REPEATING_ROSTER_SETTING = "SET_REPEATING_ROSTER_SETTING";
export const ADD_REPEATING_ROSTER_SETTING = "ADD_REPEATING_ROSTER_SETTING";
export const REMOVE_REPEATING_ROSTER_SETTING = "REMOVE_REPEATING_ROSTER_SETTING";
export const ADD_ROSTER_SELECTION = "ADD_ROSTER_SELECTION";
export const REMOVE_ROSTER_SELECTION = "REMOVE_ROSTER_SELECTION";
export const REMOVE_ROSTER_SELECTIONS = "REMOVE_ROSTER_SELECTIONS";
export const SET_ROSTER_SELECTION = "SET_ROSTER_SELECTION";


//statuses

export const GET_PARK_STATUSES = "GET_PARK_STATUSES";
export const POST_PARK_STATE = "POST_PARK_STATE";
export const DELETE_PARK_STATE = "DELETE_PARK_STATE";
export const GET_PARK_OWNERSHIPS = "GET_PARK_OWNERSHIPS";
export const POST_PARK_OWNERSHIP = "POST_PARK_OWNERSHIP";
export const END_PARK_OWNERSHIP = "END_PARK_OWNERSHIP";
export const GET_PARK_STATUSES_FOR_SELECTION = "GET_PARK_STATUSES_FOR_SELECTION";

//booking selection 

export const ADD_BOOKING_SELECTION = "ADD_BOOKING_SELECTION";
export const REMOVE_BOOKING_SELECTION = "REMOVE_BOOKING_SELECTION";
export const REMOVE_BOOKING_SELECTIONS = "REMOVE_BOOKING_SELECTIONS";
export const SET_BOOKING_SELECTION = "SET_BOOKING_SELECTION";

//server

export const GET_SERVER_TIME_OFFSET = "GET_SERVER_TIME_OFFSET";



