import * as types from '../constants/actionTypes';


export function getParkStates(state = [], action) {
    if (action.type === types.GET_PARK_STATUSES) {
        console.log(types.GET_PARK_STATUSES+' set in store');
        return action.data;
    }
    return state;
}



