import moment from 'moment';
import { store } from '../store';
import objectPath from 'object-path';


export default class ServerTimeUtil {

    static getServerTimeOffset() {
      const storeState = store.getState();
      return objectPath.get(storeState,'serverOffsetTime', 0);
    }

    static getUserCompanyTimezone() {
        const storeState = store.getState();
        return objectPath.get(storeState,'userInfo.company.timezone', moment.tz.guess(true));
      }
    
  }
  