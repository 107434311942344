
class UserConstants{
    static NORMAL_USER = 1;
    static COMPANY_MANAGER = 2;
    static COMPANY_ADMIN = 3;

    static options = [
        { id: UserConstants.NORMAL_USER, name: "NORMAL USER" },
        { id: UserConstants.COMPANY_MANAGER, name: "COMPANY MANAGER" },
        { id: UserConstants.COMPANY_ADMIN, name: "COMPANY ADMIN" },  
    ]
    
}

export default UserConstants;