//this is super important because of circular dependency to go after export
import objectPath from "object-path";
import DateUtil from "./general/DateUtil";
import ArrayUtil from "./general/ArrayUtil";
import UserUtil from "./UserUtil";

/** 
 * Class helper to have all repeating roster logic in one place 
 * this shared folder is also watched and copied to 
 * functions folder to be reused there
 * 
 * invitedOwnerEmail
 * dayIso
 * parkId
 * weekType
 * userId
*/
class RepeatingRosterUtil {

  static isScheduledForBooking(park, selectedDate, rosterSettings, users, repeatingRoster) {
    return RepeatingRosterUtil.getScheduledBy(park, selectedDate, rosterSettings, users, repeatingRoster) !== null;
  }

  static getScheduledBy(park, selectedDate, rosterSettings, users, repeatingRoster) {
    if(
      repeatingRoster !== null //for RR setup screen it is always scheduled so passing null
      && 
      RepeatingRosterUtil.getLastServerSideProcessedMomentDate(repeatingRoster).diff(selectedDate.date, 'days') >= 0){
      // if manually changed / removed and it is past for RR booking queue in next day view
      return null;
    }
    var scheduledSetting = RepeatingRosterUtil.getScheduledSettingByParkAndDate(park, selectedDate, rosterSettings);
    var output = null;
    if(ArrayUtil.isNonEmptyArray(users) && scheduledSetting !== null){
      output = users.find(u => UserUtil.isProfileByIdOrEmail(u, objectPath.get(scheduledSetting,'invitedOwnerEmail'), objectPath.get(scheduledSetting,'userId')));
    }
    return output === undefined ? null : output;
  }

  static getScheduledSettingByParkAndDate(park, selectedDate, rosterSettings) {
    var output = null;
    if(ArrayUtil.isNonEmptyArray(rosterSettings)){
      output = rosterSettings.find(x => {
        // console.log('getScheduledSettingByParkAndDate',park, selectedDate, x);
        return park.id === x.parkId 
        &&
        DateUtil.isCurrentDayByIsoDay(selectedDate, objectPath.get(x,'dayIso')) // check day
        && 
        DateUtil.isForCurrentWeekByWeekType(selectedDate, objectPath.get(x,'weekType')) // check week
      });
    }
    return output === undefined ? null : output;
  }


  static createSettingObject(park, selectedDate, user){
    return {
      invitedOwnerEmail: objectPath.get(user,'id',null) !== null ? "" : objectPath.get(user,'email'),
      userId: objectPath.get(user,'id',""),
      parkId: park.id,
      dayIso: DateUtil.getIsoDayAsNumber(selectedDate),
      weekType: DateUtil.getDateWeekType(selectedDate), 
      updatedUnix: DateUtil.getCurrentUnix()
    }

  }

  static getLastServerSideProcessedMomentDate = (repeatingRoster) => {
    return this.hasLastDateProcessed(repeatingRoster) ? DateUtil.dbDateToMoment(repeatingRoster.lastDateProcessed) : DateUtil.getCurrentMoment();
  }

  static hasLastDateProcessed = ( repeatingRoster ) => {
    return objectPath.get(repeatingRoster, "lastDateProcessed", null) !== null &&
    repeatingRoster.lastDateProcessed.length > 0
  }

  static hasLastRunUnix = ( repeatingRoster ) => {
    return objectPath.has(repeatingRoster, "lastRunUnix")
  }

  

}


export default RepeatingRosterUtil;





