import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import * as profileActions from "../../../../actions/profile";
import ComponentLoading from '../../../../components/ComponentLoading';

import PageHeading from '../../../../components/PageHeading';
import SettingsIcon from '@material-ui/icons/Settings';
import objectPath from 'object-path';
import AdminCompanyMenu from '../../../../components/admin/AdminCompanyMenu';
import SnackBarUtil from '../../../../utils/SnackBarUtil';
import CompanySettingForm from '../../../../forms/company/admin/form';
import { withSnackbar } from "notistack";

import plans from '../../../../shared/constants/plans';
import urls from '../../../../shared/constants/urls';
import UserUtil from '../../../../shared/utils/UserUtil';
import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../../../shared/utils/general/ArrayUtil';

class CompanySettings extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
    }   
  }

  componentDidMount() {
    const { actions, match } = this.props;
    let _this = this;
        this.mountCompany().then((response) => {
          var company = objectPath.get(response,'data');
        if(objectPath.get(match,'params.id') === objectPath.get(company,'id')){
          Promise.all([
            actions.getProfilesByCompanyId(company.companyId),
          ]).then(()=>{
            console.log('loading done');
            _this.setState({listDataLoading: false});
  
            //catch errors
          }).catch(function(err) {
            console.log('loading error',err);
            _this.setState({listDataLoading: false});
          });
        } else {
          _this.setState({listDataLoading: false});
        }
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  mountCompany = () =>{
    const { actions, match, company } = this.props;
    if(objectPath.get(match,'params.id') && objectPath.get(match,'params.id') !== objectPath.get(company,'id')){
      return actions.getCompany(objectPath.get(match,'params.id'));
    } else {
      return Promise.resolve().then(() => {return {data: company}});
    }
  }

  handleResponse = response => {
    const { enqueueSnackbar, history, match } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = TranslatorUtil.t("Updated");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      var companyUrl = urls.ADMIN_COMPANY_URL(objectPath.get(match,'params.id'));
      history.push(companyUrl);
    }
  }



  handleSubmit = (values) => {
    const { actions, company, companyProfiles } = this.props;
    if(company.userId !== values.userId){
      var newAdmin = companyProfiles.find(x => x.id === values.userId);
      if(newAdmin){
        values.userId = newAdmin.id;
        values.userEmail = newAdmin.email;
      }
    }

    return actions.putAdminCompany(values).then(response => this.handleResponse(response));
  };



  
  render() {

    const { company, companyProfiles } = this.props;
    const { listDataLoading } = this.state;

    return <div className="app-container-wrap app-admin">
        <PageHeading icon={<SettingsIcon />} title={TranslatorUtil.t("Company settings")} />

        <AdminCompanyMenu company={company} />

        { listDataLoading ? <ComponentLoading /> : 
          <CompanySettingForm proceedSubmit={this.handleSubmit} initialValues={company} optionsData={{
            plans : plans.options,
            profiles : ArrayUtil.sortObjectsAlphabetically(companyProfiles.filter(x => objectPath.get(x,'disabled',false) !== true), 'email').map(x => { return {value: x.id, label: UserUtil.getDisplayNameWithEmailFromProfile(x)}})
        }} />
        }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    company: state.company,
    companyProfiles: state.companyProfiles,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...profileActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
    )(CompanySettings)
));
