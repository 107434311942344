import React from 'react';
import { Select, MenuItem, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import DateUtil from '../../shared/utils/general/DateUtil';



export default class CalendarFilter extends React.Component {


  goToMonthYear = (next) => {
    const { year, month, handleMonthYearChanged } = this.props;
    const availableYears = DateUtil.getAvailableYears(true);
    const monthsList = DateUtil.getMonthsList(true);
    var newMonth = month;
    var newYear = year;
    if(next){
      if(month === monthsList[monthsList.length-1].id){
        newMonth = monthsList[0].id;
        newYear = availableYears[availableYears.findIndex(x => x.id === year)+1].id;
      } else {
        newMonth = monthsList[monthsList.findIndex(x => x.id === month)+1].id;
      }
    }else {
      if(month === monthsList[0].id){
        newMonth = monthsList[monthsList.length-1].id;
        newYear = availableYears[availableYears.findIndex(x => x.id === year)-1].id;
      } else {
        newMonth = monthsList[monthsList.findIndex(x => x.id === month)-1].id;
      }
    }
    handleMonthYearChanged(newMonth,newYear);
  }
    

  render() {
    const { year, month, handleYearChanged, handleMonthChanged, futureAllowed } = this.props;

    const availableYears = DateUtil.getAvailableYears(futureAllowed);
    const monthsList = DateUtil.getMonthsList(!(!futureAllowed && year === DateUtil.getCurrentYear()));

    const disableNext = year === availableYears[availableYears.length-1].id && month === monthsList[monthsList.length-1].id;
    const disablePrev = year === availableYears[0].id && month === monthsList[0].id;

    return <React.Fragment>
    <IconButton disabled={disablePrev} onClick={() => this.goToMonthYear(false)}><ChevronLeftIcon size="small" /></IconButton>
    <Select
      value={year}
      onChange={handleYearChanged}
      inputProps={{
        name: 'title',
        id: 'id',
      }}
    >
      { 
        availableYears.map((item,i) => 
        <MenuItem key={i} value={item.id}>
          {year === item.id ? <em>{item.title}</em> : item.title}
        </MenuItem>) 
      }
    </Select>

    <Select
      value={month}
      onChange={handleMonthChanged}
      inputProps={{
        name: 'title',
        id: 'id',
      }}
    >
      { 
        monthsList.map((item,i) => 
        <MenuItem key={i} value={item.id}>
          {month === item.id ? <em>{item.title}</em> : item.title}
        </MenuItem>) 
      }
    </Select>
    <IconButton disabled={disableNext} onClick={() => this.goToMonthYear(true)}><ChevronRightIcon size="small" /></IconButton>
    </React.Fragment>
  }
}