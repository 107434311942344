import React from 'react';
import { Button, Typography, ButtonGroup, Box } from '@material-ui/core';
import AlertDialog from '../AlertDialog';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BookUserInfo from './BookUserInfo';
import Alert from '@material-ui/lab/Alert';
import UserSelectDialog from '../form/UserSelectDialog';
import * as parkStateActions from '../../actions/parkStates';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ParkUtil from '../../shared/utils/ParkUtil';
import UserUtil from '../../shared/utils/UserUtil';


class PastParkStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            parkStatusDetailShow : false,
            showBookOnBehalfDialog : false
        };
      }


      toggleParkStatusDetail = () => {
        this.setState({parkStatusDetailShow: !this.state.parkStatusDetailShow});
    }

    getDetailTitle = () => {
        const { park } = this.props;
        return "Park "+park.parkId;
    }

    bookOnBehalf = (selectedUser) => {
        this.setState({ showBookOnBehalfDialog: false });
        return this.proceedBookPark(selectedUser);
    }


    proceedBookPark = (selectedUser) => {
        const { selectedDate, park, userInfo, actions, parkOwnerships, companyProfiles, parkStates, appAdminView } = this.props;
        if(appAdminView){
            return this.actionNotAllowed();
        }
        return ParkUtil.bookPark(park, selectedDate, selectedUser, userInfo, parkOwnerships, companyProfiles, parkStates, actions);
    }

    proceedUnlockPark = () => {
        const { selectedDate, park, userInfo, actions, parkStates, parkOwnerships, companyProfiles, appAdminView } = this.props;
        if(appAdminView){
            return this.actionNotAllowed();
        }
        return ParkUtil.unlockPark(park, selectedDate, userInfo, parkOwnerships, companyProfiles, parkStates, actions);
    }

    getDetailMessage = (bookedBy) => {
        const {park, companyProfiles, selectedDate, parkOwnerships, userInfo, parkStates} = this.props;
        const {showBookOnBehalfDialog} = this.state;
        //var bookedBy = ParkUtil.getBookedBy(park,selectedDate,parkStates,ownershipHistory,)//this.getBookedBy();
        var ownedBy = ParkUtil.getParkOwnerByDate(park,selectedDate,parkOwnerships,companyProfiles);

        var proceededBy = ParkUtil.getProceededBy(park, selectedDate, parkStates, parkOwnerships, companyProfiles);

         return <BookUserInfo bookedBy={bookedBy} ownedBy={ownedBy} proceededBy={proceededBy} selectedDate={selectedDate} >
         {
         UserUtil.isAtLeastCompanyManager(userInfo) ?
         <Box pt={2}>
             <Alert severity="warning">Managers and admin can modify past states.</Alert><br />
         { bookedBy ? <Button className="error-btn" onClick={() => this.proceedUnlockPark()}>
                    Unlock
            </Button> : <Button variant="contained" onClick={() => this.setState({ showBookOnBehalfDialog: true })}>
                        Book
                        </Button>}
                        {showBookOnBehalfDialog ? <UserSelectDialog users={companyProfiles} userSelected={this.bookOnBehalf} onClose={() => this.setState({ showBookOnBehalfDialog: false })} /> : null}
                        </Box>
         : null}
         </BookUserInfo>;
    }





  render() {
    const {parkStatusDetailShow} = this.state;
    const {userInfo, park, selectedDate, parkStates, disableActions, companyProfiles, parkOwnerships, highlightMe} = this.props;



    const bookedBy = ParkUtil.getBookedBy(park, selectedDate, parkStates, parkOwnerships, companyProfiles);
    const highlight = highlightMe && bookedBy && UserUtil.compareProfilesByEmail(userInfo.profile,bookedBy);

    return <React.Fragment>
        {
            bookedBy && !ParkUtil.hideBookedBy(bookedBy, userInfo) ? <Typography component="span" variant="caption" className={"booked-by"+(highlight ? ' highlight-me' : '')}>{bookedBy.displayName}</Typography> : null
        }<ButtonGroup size="small" className={highlight ? ' highlight-me' : ''}>
                <Button variant="contained" disabled className={"past-btn "+(bookedBy? 'booked' : 'unused')+(highlight ? ' highlight-me' : '')}>
                    {
                        bookedBy ? "Booked" : "Unused"
                    }
                </Button>
                    { ParkUtil.showBookMoreInfoButton(userInfo) ? 
                    <Button className="info-btn" disabled={disableActions} color="default" onClick={() => this.toggleParkStatusDetail()}>
                        <MoreVertIcon />
                    </Button> : null }
                { 
                    parkStatusDetailShow ? <AlertDialog title={this.getDetailTitle()} message={this.getDetailMessage(bookedBy)} onClose={this.toggleParkStatusDetail} /> : null 
                }
            </ButtonGroup>
            </React.Fragment>

    
  }
}


function mapStateToProps(state) {
    return {
      companyProfiles: state.companyProfiles,
      parkStates: state.parkStates,
      userInfo: state.userInfo,
      parkOwnerships: state.parkOwnerships
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...parkStateActions,
            },
            dispatch
        )
    };
}
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(PastParkStatus);


