import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as firebaseUserActions from "../../../actions/firebaseUser";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import Logo from '../../../components/Logo';
import { Box } from '@material-ui/core';

import UserUtil from '../../../shared/utils/UserUtil';
class EmailVerify extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
        emailResent: false,
    }
  }
  



  resendVerificationEmail = () => {
    const { user } = this.props;
    const _this = this;
    return user.sendEmailVerification().then(function() {
        // Email sent.
        _this.setState({
            emailResent: true
        })
      }).catch(function(error) {
        // An error happened.
        console.log('error', error);
      });
  }



  render() {
    const { userInfo } = this.props;
    const { emailResent } = this.state;
    

    return <div className="index-wrap">
    <div className="welcome-wrap graphics-bg-wrap profile-setup"><div className="maxWidthPart">
      <Logo className="logo" />
    <Box className="intro text-center max-width-center-wrap" py={2} pb={3}>
    <Typography variant="h3"><img src="/assets/images/Verify.svg" alt="verify" /> Verify your Email</Typography>
    <Grid container spacing={4} alignContent="center">
        <Grid item xs={12}>
        <Box className="intro text-center max-width-center-wrap" py={3}>
      <Typography variant="h6"><strong>We've just sent a verification email to {UserUtil.getEmail(userInfo)}</strong></Typography>
      </Box>
      <Typography variant="body1">Open this email and select the link to verify your address, then come back here and select verified button.</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Button variant="outlined" className="secondary" fullWidth disabled={emailResent ? true : false } onClick={() => this.resendVerificationEmail()}  size="large">
            {emailResent ? "Email sent" : "Resend Email" }
        </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Button variant="contained" className="primary"  fullWidth onClick={() => window.location.reload()} size="large">
            I'm Verified
        </Button>
        </Grid>
        </Grid>
    </Box>
    
    

    </div></div></div>
  }
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo,
  });
  
  const mapDispatchToProps = dispatch =>
  ({
    actions: bindActionCreators(
      {
        ...firebaseUserActions,
      },
      dispatch
    )
  });
  
  
  export default withRouter(withSnackbar(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EmailVerify)
  ));
