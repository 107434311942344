import * as types from '../constants/actionTypes';
import ArrayUtil from '../shared/utils/general/ArrayUtil';


export function getParks(state = [], action) {
    if (action.type === types.GET_COMPANY_PARKS) {
        console.log(types.GET_COMPANY_PARKS+' set in store');
        return Array.isArray(action.data) ? ArrayUtil.sortObjectsAlphabetically(action.data, 'parkId') : [];
    }
    return state;
}


export function getPark(state = null, action) {
    if (action.type === types.GET_PARK) {
        console.log(types.GET_PARK+' set in store');
        return action.data;
    }
    return state;
}

export function getAllParks(state = [], action) {
    if (action.type === types.GET_ALL_PARKS) {
        console.log(types.GET_ALL_PARKS+' set in store');
        return Array.isArray(action.data) ? ArrayUtil.sortObjectsAlphabetically(action.data, 'parkId') : [];
    }
    return state;
}


