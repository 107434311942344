import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

class ConfirmDialog extends React.Component {
   


  render() {

    const { title, message, onCancel,onConfirm, confirmOkBtnText, confirmCancelBtnText } = this.props

    return (
        <Dialog
        className="confirm-dialog"
          open={true}
          onClose={onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {title}
          </DialogTitle>
          <DialogContent id="alert-dialog-description">
                {message}
          </DialogContent>
          <DialogActions>
          <Button onClick={onCancel} color="default">
            {confirmCancelBtnText ? confirmCancelBtnText : "Cancel"}
            </Button>
            <Button variant="contained" onClick={onConfirm} color="primary" autoFocus>
            {confirmOkBtnText ? confirmOkBtnText : "Confirm"}
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default ConfirmDialog;