import ls from 'local-storage';

import FormatterUtil from '../shared/utils/general/FormatterUtil';
export default class LocalStorageUtil {


      //https://www.npmjs.com/package/local-storage

    static set(key,value) {
        return ls(key, value);
    }


    static get(key) {
        return ls(key);
    }

    static getTyped(key){
        return FormatterUtil.parseJsonSafe(LocalStorageUtil.get(key));
    }

    static flushAll() {
        ls.clear();
        return ls;
    }

    static storageAvailable() {
        try {
            var storage = window['localStorage'],
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return false;
        }
    }
    


    
  }
  