import React from 'react';
import { Button, Typography, ButtonGroup, CircularProgress } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AlertDialog from '../AlertDialog';
import UserSelectDialog from '../form/UserSelectDialog';
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import * as parkStateActions from '../../actions/parkStates';
import * as bookingSelectionActions from '../../actions/bookingSelection';
import ParkSelectionUtil from '../../utils/ParkSelectionUtil';
import BookUserInfo from './BookUserInfo';
import MoreVertIcon from '@material-ui/icons/MoreVert';


import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';
import FormatterUtil from '../../shared/utils/general/FormatterUtil';
import CompanyUtil from '../../shared/utils/CompanyUtil';
import UserUtil from '../../shared/utils/UserUtil';
import ParkUtil from '../../shared/utils/ParkUtil';
import RepeatingRosterUtil from '../../shared/utils/RepeatingRosterUtil';
import ServerTimeUtil from '../../utils/ServerTimeUtil';
import FirestoreQueryUtil from '../../shared/utils/FirestoreQueryUtil';
import firestoreApi from '../../firebase';

class AllowedParkStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            parkStatusDetailShow: false,
            showBookOnBehalfDialog: false
        };
    }

    toggleParkStatusDetail = () => {
        this.setState({ parkStatusDetailShow: !this.state.parkStatusDetailShow });
    }

    toggleParkBookingManageDetail = () => {
        this.setState({ parkBookingManageDetailShow: !this.state.parkBookingManageDetailShow });
    }

    getDetailTitle = () => {
        const { park } = this.props;
        return "Park " + park.parkId;
    }

    getManagerTitle = () => {
        const { park } = this.props;
        return "Manage park " + park.parkId;
    }

    bookOnBehalf = (selectedUser) => {
        this.setState({ showBookOnBehalfDialog: false });
        return this.proceedBookPark(selectedUser);
    }


    proceedBookPark = (selectedUser) => {
        const { selectedDate, park, userInfo, actions, parkOwnerships, companyProfiles, parkStates, appAdminView, enqueueSnackbar } = this.props;
        if(appAdminView){
            return this.actionNotAllowed();
        }
        this.setState({
            processing : true
        });
        return ParkUtil.bookPark(park, selectedDate, selectedUser, userInfo, parkOwnerships, companyProfiles, parkStates, actions)
        .then(() => {
            setTimeout( () => {
                if(!UserUtil.isAtLeastCompanyManager(userInfo)){
                    var firestoreQueryUtil = new FirestoreQueryUtil(firestoreApi);
                    var parkStateId = ParkUtil.getParkStateFirestoreDocId({
                        parkId: park.id,
                        date: selectedDate.date,
                        lockType: true,
                    });
                    firestoreQueryUtil.getParkStateById(parkStateId).then((parkState) => {
                        if(parkState && parkState.lockType === true && parkState.userId !== UserUtil.getId(userInfo)){
                            enqueueSnackbar(TranslatorUtil.t("Sorry, someone else got to the park first."), {
                                variant: "error"
                              });
                        }
                    });
                }
                this.setState({
                    processing : false
                });
            }, 1000);
          });
    }

    proceedUnlockPark = () => {
        const { selectedDate, park, userInfo, actions, parkStates, parkOwnerships, companyProfiles, appAdminView } = this.props;
        if(appAdminView){
            return this.actionNotAllowed();
        }
        return CompanyUtil.isNormalUserTooLateToUnlock(selectedDate, UserUtil.getCompany(userInfo), UserUtil.isNormalUser(userInfo), ServerTimeUtil.getServerTimeOffset()) ? this.tooLateToUnlockPark() : ParkUtil.unlockPark(park, selectedDate, userInfo, parkOwnerships, companyProfiles, parkStates, actions);
    }

    getDetailMessage = (ownedBy, bookedBy) => {
        const { park, selectedDate, companyProfiles, userInfo, parkStates, parkOwnerships, repeatingRosterSetting, repeatingRoster } = this.props;
        const { showBookOnBehalfDialog } = this.state;

        var proceededBy = ParkUtil.getProceededBy(park, selectedDate, parkStates, parkOwnerships, companyProfiles);

        const scheduledBy = UserUtil.isAtLeastCompanyManager(userInfo) && UserUtil.isNormalUserDisallowedFuture(selectedDate.date, userInfo, ServerTimeUtil.getUserCompanyTimezone(), ServerTimeUtil.getServerTimeOffset()) ? 
        RepeatingRosterUtil.getScheduledBy(park, selectedDate, repeatingRosterSetting, companyProfiles, repeatingRoster) : null;

        return <BookUserInfo bookedBy={bookedBy} ownedBy={ownedBy} proceededBy={proceededBy} selectedDate={selectedDate} scheduledBy={scheduledBy}>
            {UserUtil.isAtLeastCompanyManager(userInfo) ?
                <div><br />
                { bookedBy ? <Button className="error-btn" onClick={() => this.proceedUnlockPark()}>
                    Unlock
            </Button> : <Button variant="contained" onClick={() => this.setState({ showBookOnBehalfDialog: true })}>
                        Book
                        </Button>}</div> : null
            }
            {showBookOnBehalfDialog ? <UserSelectDialog users={companyProfiles} userSelected={this.bookOnBehalf} onClose={() => this.setState({ showBookOnBehalfDialog: false })} /> : null}

        </BookUserInfo>
    }

    getNotOwnerBookUnlockBtn = (parkStatusWithoutOwner) => {
        const { userInfo } = this.props;

        if (ParkUtil.isUserHolderOfParkState(parkStatusWithoutOwner, UserUtil.getProfile(userInfo))) {
            return <Button className={"booked"} onClick={() => this.proceedUnlockPark()}>
                Unlock
            </Button>
        } else {
            return <Button disabled className={"booked"}>
                Booked
            </Button>
        }
    }

    actionNotAllowed = () => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(TranslatorUtil.t("Action is not allowed."), {
            variant: "error"
          });
    }

    tooLateToUnlockPark = () => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(TranslatorUtil.t("It is too late to unlock this park."), {
            variant: "error"
          });
    }
    

    userBookingLimitExceeded = () => {
        const { enqueueSnackbar, userInfo } = this.props;
        enqueueSnackbar(TranslatorUtil.t("Maximum parks per user per week is")+" "+CompanyUtil.getMaxParksPerUserPerPeriod(UserUtil.getCompany(userInfo)), {
            variant: "error"
          });
    }

    normalUserOnlyOneBookingPerDay = () => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(TranslatorUtil.t("You can book only one park per day."), {
            variant: "error"
          });
    }

    normalUserOnlyTooLateToBook = () => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(TranslatorUtil.t("It is no longer possible to book for the current day."), {
            variant: "error"
          });
    }

    proceedValidatedBooking = () => {
        const { companyParks, selectedDate, userInfo, parkStates, parkOwnerships, companyProfiles } = this.props;
        if(!CompanyUtil.canNormalUsersBookMoreParksPerDay(UserUtil.getCompany(userInfo)) && UserUtil.isNormalUser(userInfo) && ParkUtil.hasUserParkForDay(selectedDate,companyParks,parkStates,parkOwnerships,companyProfiles,UserUtil.getProfile(userInfo))){
            return this.normalUserOnlyOneBookingPerDay();
        }
        else if(CompanyUtil.isTooLateToBook(selectedDate.date, UserUtil.getCompany(userInfo), ServerTimeUtil.getServerTimeOffset())){
            return this.normalUserOnlyTooLateToBook();
        }
        else if(ParkUtil.isBookingDisabledForCurrentUser(companyParks, selectedDate, userInfo, parkStates, parkOwnerships, companyProfiles)){
            return this.userBookingLimitExceeded();
        } else {
            return this.proceedBookPark(null);
        }
    }

    getBookBtn = (scheduledBy) => {
        return <Button className={"avail"+(scheduledBy ? " scheduledBooking" : "")} onClick={() => this.proceedValidatedBooking() }>
            {scheduledBy ? "Scheduled" : "Book"}
            </Button>
    }


    getMainBtn = (parkOwner, isCurrentUserOwner, parkStatusWithOwner, parkStatusWithoutOwner, scheduledBy) => {

        if (parkOwner) {

            //owned park
            if (!FormatterUtil.isEmpty(parkStatusWithOwner)) {
                return !FormatterUtil.isEmpty(parkStatusWithoutOwner) ?
                    this.getNotOwnerBookUnlockBtn(parkStatusWithoutOwner)
                    :
                    this.getBookBtn(scheduledBy);
            } else {
                //booked by owner by default
                //if owner can unlock
                return <Button disabled={!isCurrentUserOwner} className={"booked"} onClick={() => this.proceedUnlockPark()}>
                    {isCurrentUserOwner ? "Unlock" : "Booked"}
                </Button>
            }
        } else {
            return !FormatterUtil.isEmpty(parkStatusWithoutOwner) ?
                this.getNotOwnerBookUnlockBtn(parkStatusWithoutOwner)
                :
                this.getBookBtn(scheduledBy);
        }
    }

    getInfoBtn = () => {
        const { bookingSelectionEnabled, disableActions, userInfo } = this.props;
        return bookingSelectionEnabled ? this.getSelectBtn() : (ParkUtil.showBookMoreInfoButton(userInfo) ? <Button className="info-btn" disabled={disableActions} onClick={() => this.toggleParkStatusDetail()}>
            <MoreVertIcon size="small" />
        </Button> : null);
    }

    getSelectBtn = () => {
        const { actions, bookingSelection, park, selectedDate } = this.props;

        var selectedItemIndex = ParkSelectionUtil.getSelectedItemIndex(bookingSelection, park, selectedDate);
        var isDisabled = !ParkSelectionUtil.isInSelectedRow(bookingSelection, park);

        return selectedItemIndex === -1 ? <Button disabled={isDisabled} color="primary" className="info-btn" onClick={() => actions.addBookingSelection(park, selectedDate)}>
            <CheckBoxOutlineBlankIcon size="small" />
        </Button> : <Button disabled={isDisabled} color="primary" className="info-btn" onClick={() => actions.removeBookingSelection(selectedItemIndex)}>
                <CheckBoxIcon size="small" />
            </Button>;
    }
    
    isBookedByMe = () => {
        const { park, userInfo, selectedDate, parkStates, companyProfiles, parkOwnerships } = this.props;
        const bookedBy = ParkUtil.getBookedBy(park, selectedDate, parkStates, parkOwnerships, companyProfiles);
        return (bookedBy && UserUtil.compareProfilesByEmail(userInfo.profile,bookedBy));
    }




    render() {

        const { park, userInfo, selectedDate, parkStates, companyProfiles, parkOwnerships, bookingSelectionEnabled, disableActions, highlightMe, repeatingRosterSetting, repeatingRoster } = this.props;
        const { parkStatusDetailShow, processing } = this.state;


        //owner
        const parkOwner = ParkUtil.getCurrentParkOwner(park, companyProfiles);
        const bookedBy = ParkUtil.getBookedBy(park, selectedDate, parkStates, parkOwnerships, companyProfiles);
        const isCurrentUserOwner = (objectPath.get(parkOwner, 'id', null) === UserUtil.getId(userInfo)) ? true : false;


        const parkStatusWithOwner = ParkUtil.getParkStatusWithOwnerByDate(park, selectedDate, parkStates, parkOwnerships, companyProfiles);
        const parkStatusWithoutOwner = ParkUtil.getParkStatusWithoutOwnerByDate(park, selectedDate, parkStates, parkOwnerships, companyProfiles);
        const highlight = highlightMe && (bookedBy === null || this.isBookedByMe());
        const scheduledBy = UserUtil.isAtLeastCompanyManager(userInfo) && UserUtil.isNormalUserDisallowedFuture(selectedDate.date, userInfo, ServerTimeUtil.getUserCompanyTimezone(), ServerTimeUtil.getServerTimeOffset()) ? 
        RepeatingRosterUtil.getScheduledBy(park, selectedDate, repeatingRosterSetting, companyProfiles, repeatingRoster) : null;

        if(processing && !UserUtil.isAtLeastCompanyManager(userInfo)){
            return <ButtonGroup size="small" variant="outlined">
                <Button disabled><CircularProgress className="progress" size="1rem" /></Button>
            </ButtonGroup>
        }

        return <React.Fragment>
            {
                bookedBy && !ParkUtil.hideBookedBy(bookedBy, userInfo) ? <Typography component="span" variant="caption" className={"booked-by"+(highlight ? ' highlight-me' : '')}>{bookedBy.displayName}</Typography> : (scheduledBy ? <Typography component="span" variant="caption" className={"scheduled-by"}>{scheduledBy.displayName}</Typography> : null)
            }
            <ButtonGroup size="small" variant="outlined" className={[highlight ? ' highlight-me' : '',scheduledBy && !bookedBy ? ' scheduled-not-booked ' : '','btnGroupWrap'].join(" ")}>

                {bookingSelectionEnabled || disableActions ?
                    <Button disabled className={bookedBy ? "booked" : "avail" }>{bookedBy ? "Booked" : scheduledBy ? "Scheduled" : "Available"}</Button>
                    : this.getMainBtn(parkOwner ? true : false, isCurrentUserOwner, parkStatusWithOwner, parkStatusWithoutOwner, scheduledBy)}
                {this.getInfoBtn()}
                {parkStatusDetailShow ? <AlertDialog title={this.getDetailTitle()} message={this.getDetailMessage(parkOwner, bookedBy)} onClose={this.toggleParkStatusDetail} /> : null}
            </ButtonGroup>
        </React.Fragment>

    }
}


function mapStateToProps(state) {
    return {
        companyParks: state.companyParks,
        companyProfiles: state.companyProfiles,
        parkStates: state.parkStates,
        userInfo: state.userInfo,
        parkOwnerships: state.parkOwnerships,
        bookingSelectionEnabled: state.bookingSelectionEnabled,
        bookingSelection: state.bookingSelection,
        repeatingRosterSetting: state.repeatingRosterSetting,
        repeatingRoster: state.repeatingRoster
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...parkStateActions,
                ...bookingSelectionActions
            },
            dispatch
        )
    };
}



export default withRouter(withSnackbar(connect(
    mapStateToProps,
    mapDispatchToProps
  )(AllowedParkStatus)));


