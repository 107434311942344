import objectPath from 'object-path';
import FormatterUtil from '../shared/utils/general/FormatterUtil';
import TranslatorUtil from '../shared/utils/general/TranslatorUtil';

export default class ValidationUtil {

    static shouldValidateError(params){
      
      //default behaviour

      /* let { initialRender, structure, values, nextProps, lastFieldValidatorKeys, fieldValidatorKeys} = params;
      if (initialRender) {
        return true;
      }
      return !structure.deepEqual(values, nextProps && nextProps.values) || !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys); */

      //return true to revalidate Date input not being revalidated... not sure for what reason
      return true;
  }
 
    static checkRequiredFields(fields,values,errors){
        fields.forEach(field => {
            let fieldValue = objectPath.get(values,field);
            let isNullOrUndefined = (fieldValue === null || fieldValue === '' || typeof fieldValue === "undefined");
            let isArrayAndEmpty = (Array.isArray(fieldValue) && fieldValue.length < 1);
            if (
              (
                isNullOrUndefined
                ||
                isArrayAndEmpty
              )
            ) {
              objectPath.set(errors,field,TranslatorUtil.t("Required"));
            }
        });
        return errors;
    }


    static checkImageField(fields,values,errors, maxSize){
      fields.forEach(field => {
          let fieldBase64String = objectPath.get(values,field+'.base64String');
          let fieldSize = objectPath.get(values,field+'.size');
          if (fieldBase64String && fieldBase64String.length && fieldSize > maxSize) {
            objectPath.set(errors,field,TranslatorUtil.t("Maximum file size is "+FormatterUtil.bytesToSize(maxSize)+" MB"));
          }
      });
      return errors;
    }

    static checkIntegerFields(fields,values,errors){
        fields.forEach(field => {
            var hasError = objectPath.get(errors, field);
            let fieldValue = objectPath.get(values,field);
            if(!hasError){
              var isnum = /^\d+$/.test(fieldValue);
              if(!isnum){
                objectPath.set(errors,field,TranslatorUtil.t("Please format number correctly"));
              }
            }
          });
          return errors;
    }

    static checkPercentageFields(fields,values,errors){
        fields.forEach(field => {
            var hasError = objectPath.get(errors, field);
            let fieldValue = objectPath.get(values,field,null);
            if(!hasError && fieldValue){ // empty is correct because we need to allow empty value (if do not add to required field)
              var n = Math.floor(Number(fieldValue));
              let isPercentageFormat = n >= 0 && n < 101;
              if(!isPercentageFormat){
                objectPath.set(errors,field,TranslatorUtil.t("Please format percentage correctly (0-100)"));
              }
            }
          });
          return errors;
    }

    static checkIntegerMinMax(fields,values,errors, min, max){
      fields.forEach(field => {
          var hasError = objectPath.get(errors, field);
          let fieldValue = objectPath.get(values,field);
          if(!hasError){
            var n = Math.floor(Number(fieldValue));
            let isCorrectFormat = n >= min && n <= max;
            if(!isCorrectFormat){
              objectPath.set(errors,field,TranslatorUtil.t("Please format number correctly ("+min+"-"+max+")"));
            }
          }
        });
        return errors;
  }


    static checkStringMaxLength(fields,values,errors, maxLength){
        fields.forEach(field => {
            var hasError = objectPath.get(errors, field);
            let fieldValue = objectPath.get(values,field,'').toString();
            if(!hasError){
              if(fieldValue.length > maxLength){
                objectPath.set(errors,field, "Max length is "+maxLength+" characters.");
              }
            }
          });
          return errors;
    }

    static checkArrayMaxLength(fields,values,errors, maxLength){
      fields.forEach(field => {
          var hasError = objectPath.get(errors, field);
          let fieldValue = objectPath.get(values,field,[]);
          if(!hasError){
            if(Array.isArray(fieldValue) && fieldValue.length > maxLength){
              objectPath.set(errors,field, "Max is "+maxLength+" items.");
            }
          }
        });
        return errors;
    }


    static checkIfUnique(fields,values,errors, options){
      fields.forEach(field => {
          var hasError = objectPath.get(errors, field);
          let fieldValue = objectPath.get(values,field,'').toString().toLowerCase();
          if(!hasError){
            if(Array.isArray(options) && options.map(x => x.toLowerCase()).includes(fieldValue)){
              objectPath.set(errors,field, "Must be unique!");
            }
          }
        });
        return errors;
    }

    static checkStringMinLength(fields,values,errors, minLength){
      fields.forEach(field => {
          var hasError = objectPath.get(errors, field);
          let fieldValue = objectPath.get(values,field,'').toString();
          if(!hasError){
            if(fieldValue.length < minLength){
              objectPath.set(errors,field, "Min length is "+minLength+" characters.");
            }
          }
        });
        return errors;
  }

  static validateYoutubeLink(fields,values,errors){
    fields.forEach(field => {
      var hasError = objectPath.get(errors, field);
      let fieldValue = objectPath.get(values,field,'').toString();
      if(!hasError){
        
        if(fieldValue.length && !ValidationUtil.getYoutubeLinkIdFromLink(fieldValue)){
          objectPath.set(errors,field, TranslatorUtil.t("Youtube link badly formated"));
        }
      }
    });
    return errors;
  }

  static getYoutubeLinkIdFromLink(url){
      var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      return (url.match(p)) ? RegExp.$1 : false;
  }

  static isValidEmail(email){
    // eslint-disable-next-line no-useless-escape
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
    return email && re.test(email);
  }

    static isEmailFormat(fields,values,errors){
      fields.forEach(field => {
          var hasError = objectPath.get(errors, field);
          let fieldValue = objectPath.get(values,field,'').toString();
          if(!hasError){
            
            if(!ValidationUtil.isValidEmail(fieldValue)){
              objectPath.set(errors,field, TranslatorUtil.t("Invalid format"));
            }
          }
        });
        return errors;
  }

  static isFieldRequired = (rules) => {
    return rules.indexOf("required") !== -1 ? "required" : ""
}



}
