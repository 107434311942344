import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

export default class Loading extends React.Component {


  render() {
    return this.props.loading ? 
    <div style={{
        width: '100%',
        minHeight: '50px',
        position: 'relative',
    }}>
    <div style={{
            width: 200,
            position: 'absolute',
            top: `50%`,
            left: `50%`,
            outline: '0',
            transform: `translate(-50%, -50%)`
        }}>
        <LinearProgress thickness={7} />
        </div> </div> : null
  }
}

