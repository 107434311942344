import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, Button } from '@material-ui/core';
import * as bookingSelectionActions from '../../actions/bookingSelection';

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ReplayIcon from '@material-ui/icons/Replay';
import CloseIcon from '@material-ui/icons/Close';

import urls from '../../shared/constants/urls';
import UserUtil from '../../shared/utils/UserUtil';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';
import EventNoteIcon from '@material-ui/icons/EventNote';
import objectPath from 'object-path';
import CompanyUtil from '../../shared/utils/CompanyUtil';
import extraFeatures from '../../shared/constants/extraFeatures';

class ParkingSecondaryActions extends React.Component {
  
  
  render() {

    const {bookingSelectionEnabled,actions,userInfo,history, location} = this.props;

    const pathname = objectPath.get(location,'pathname',"");
    
    const isFairUse = pathname.includes(urls.COMPANY_FAIRUSE_URL);
    const isRepeatingRoster = pathname.includes(urls.COMPANY_REPEATING_ROSTER_URL);
    
    const isDashboard = !(isFairUse || isRepeatingRoster);

  return UserUtil.isAtLeastCompanyManager(userInfo) ? <Box className="bulkActions text-right">
    <Box mt={2}>
    
      { isDashboard ? <Button startIcon={ bookingSelectionEnabled ? <CloseIcon color="secondary" /> : <FormatListBulletedIcon color="primary" />} onClick={() => actions.setBookingSelection(!bookingSelectionEnabled)}>
      { bookingSelectionEnabled ? 'Turn off bulk actions' : 'Bulk actions' }
      </Button> : 
      <Button startIcon={<EventNoteIcon color="primary" />} onClick={() => history.push(urls.INDEX_URL)}>
      { TranslatorUtil.t('Calendar') }
      </Button> }
      
      { isFairUse ? null : <Button startIcon={<ThumbUpAltOutlinedIcon color="primary" />} onClick={() => history.push(urls.COMPANY_FAIRUSE_URL)}>
      { TranslatorUtil.t('Fair use') }
      </Button>}
      
      { !isRepeatingRoster && CompanyUtil.hasFeatureAllowed(UserUtil.getCompany(userInfo), extraFeatures.REPEATING_ROSTER, false) ? <Button startIcon={<ReplayIcon color="primary" />} onClick={() => history.push(urls.COMPANY_REPEATING_ROSTER_URL)}>
      { TranslatorUtil.t('Repeating roster') }
      </Button> : null}
      </Box>
  
  </Box> : null
  }
}






function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    bookingSelectionEnabled: state.bookingSelectionEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...bookingSelectionActions
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkingSecondaryActions));
