import * as types from "../constants/actionTypes";


export function setBookingSelection(enabled){
    console.log('setBookingSelection',enabled);
    return function(dispatch) {
        return dispatch({type: types.SET_BOOKING_SELECTION ,data: {enabled : enabled}});
    };
}

export function addBookingSelection(park, date){
    console.log('addBookingSelection');
    return function(dispatch) {
        return dispatch({type: types.ADD_BOOKING_SELECTION,data: {park, date} });
    };
}

export function removeBookingSelection(index){
    console.log('removeBookingSelection');
    return function(dispatch) {
        return dispatch({type: types.REMOVE_BOOKING_SELECTION, data: {index: index} });
    };
}

export function removeBookingSelections(){
    return function(dispatch) {
        return dispatch({type: types.REMOVE_BOOKING_SELECTIONS });
    };
}