import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import * as profileActions from "../../../../actions/profile";
import ComponentLoading from '../../../../components/ComponentLoading';

import PageHeading from '../../../../components/PageHeading';
import SettingsIcon from '@material-ui/icons/Settings';
import objectPath from 'object-path';
import SnackBarUtil from '../../../../utils/SnackBarUtil';
import CompanyProfileForm from '../../../../forms/company/profile/form';
import { withSnackbar } from "notistack";

import plans from '../../../../shared/constants/plans';
import urls from '../../../../shared/constants/urls';
import UserUtil from '../../../../shared/utils/UserUtil';
import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../../../shared/utils/general/ArrayUtil';
class CompanySettings extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
    }   
  }

  componentDidMount() {
    const { actions, userInfo } = this.props;
    const company = UserUtil.getCompany(userInfo);
    let _this = this;
    if(company){
      Promise.all([
        actions.getProfilesByCompanyId(company.companyId),
      ]).then(()=>{
        console.log('loading done');
        _this.setState({listDataLoading: false});

        //catch errors
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
    }
          
        
  }

  handleResponse = (response, redirect) => {
    const { enqueueSnackbar } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = TranslatorUtil.t("Updated");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      if(redirect){
        document.location.href = urls.INDEX_URL;
      }
    }
    
  }



  handleSubmit = (values) => {
    const { actions, companyProfiles, userInfo } = this.props;
    const company = UserUtil.getCompany(userInfo);

    if(company.userId !== values.userId){
      var newAdmin = companyProfiles.find(x => x.id === values.userId);
      if(newAdmin){
        values.userId = newAdmin.id;
        values.userEmail = newAdmin.email;
      } else {
        values.userId = company.userId;
        values.userEmail = company.userEmail;
      }
    }

    return actions.putCompany(values).then(response => {
      actions.getCurrentUserCompanyByCompanyId(UserUtil.getCompanyId(userInfo));
      this.handleResponse(response, true);
    });
  };

  updateCompanyMap = (mapPath) => {
    const { actions, userInfo, enqueueSnackbar } = this.props;
    var company = UserUtil.getCompany(userInfo);

    if(company.id){
      return actions.updateCompanyMap(company.id,mapPath).then(response => {
        let removal = mapPath === null;
        if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
          var successMessage = TranslatorUtil.t(removal ? "Company Map removed." : "Company Map updated." );
          enqueueSnackbar(successMessage, {
            variant: removal ? "warning" : "success"
          });
        }
      });
    }    
  };



  
  render() {

    const { companyProfiles, userInfo } = this.props;
    const { listDataLoading } = this.state;
    var company = UserUtil.getCompany(userInfo);
    company.managerIds = objectPath.get(company, 'managerIds',[]);

    return <div className="app-container-wrap">
        <PageHeading icon={<SettingsIcon />} title={TranslatorUtil.t("Settings")} />


        { company === null || listDataLoading ? <ComponentLoading /> : 
          <CompanyProfileForm proceedSubmit={this.handleSubmit} 
          updateCompanyMap={this.updateCompanyMap}
          initialValues={company} optionsData={{
            plans : plans.options,
            profiles : ArrayUtil.sortObjectsAlphabetically(companyProfiles.filter(x => objectPath.get(x,'disabled',false) !== true), 'email').map(x => { return {value: x.id, label: UserUtil.getDisplayNameWithEmailFromProfile(x)}})
        }} />
        }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    companyProfiles: state.companyProfiles,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...profileActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
    )(CompanySettings)
));
