import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreUtil from '../shared/utils/general/FirestoreUtil';


const collectionName = 'parks';



export function getParksByCompanyId(companyId) {
  return function(dispatch) {
    console.log('getParksByCompanyId');
    return firestoreApi.collection(collectionName).where("companyId", "==", companyId).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_COMPANY_PARKS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_PARKS))
      );
  };
}

export function getAllParks() {
  return function(dispatch) {
    console.log('getAllParks');
    return firestoreApi.collection(collectionName).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_ALL_PARKS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_ALL_PARKS))
      );
  };
}

export function listenParksByCompanyId(companyId,callback = () => {}) {
  return function(dispatch) {
    console.log('listenParksByCompanyId');
    return firestoreApi.collection(collectionName).where("companyId", "==", companyId).onSnapshot(
        response => {
          callback();
          return dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response),  types.GET_COMPANY_PARKS))
        },
          error => {
            callback();
            return dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_PARKS))
          }
      );
  };
}


  export function getPark(id) {
    return function(dispatch) {
      console.log('getPark');
      return firestoreApi.collection(collectionName).doc(id).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionItemData(response), types.GET_PARK)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PARK))
        );
    };
  }

  export function putPark(data) {
    return function(dispatch) {
      console.log('putPark');
      return firestoreApi.collection(collectionName).doc(data.id).set(FirestoreUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_PARK)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_PARK))
        );
    };
  }

  export function createPark(data) {
    return function(dispatch) {
      console.log('createPark');
      return firestoreApi.collection(collectionName).add(FirestoreUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.CREATE_PARK)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.CREATE_PARK))
        );
    };
  }

  export function setParkOwner(setOwnerForPark, user) {
    return function(dispatch) {
      return firestoreApi.collection(collectionName).doc(setOwnerForPark.id)
      .update({
        "ownerId": user.id,
        "invitedOwnerEmail": null
      })
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.SET_PARK_OWNER)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.SET_PARK_OWNER))
        );
    };
  }

  export function removePark(id) {
      
      return function(dispatch) {
        console.log('removePark');
        return firestoreApi.collection(collectionName).doc(id).delete().then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_PARK)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.DELETE_PARK))
        );
      }
  }

 

