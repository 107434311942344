import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Logo from '../../../components/Logo.js';
import { Box } from '@material-ui/core';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as companiesActions from "../../../actions/company";
import * as profilesActions from "../../../actions/profile";
import ComponentLoading from '../../../components/ComponentLoading.js';

import plans from '../../../shared/constants/plans';
import UserUtil from '../../../shared/utils/UserUtil';
import DateUtil from '../../../shared/utils/general/DateUtil';
import constants from '../../../shared/constants/constants.js';
class CompanySelect extends React.Component {

  constructor(props) {
    super(props);

    
    this.state = {
      loading: false,
      companyId: null,
      searchTextValue: '',
      company: null,
      lastSearchedTerm: false,
    }
  }
  

  componentDidMount(){
    
  }


  searchForCompany = (searchForCompanyId) => {
    let { lastSearchedTerm } = this.state;
    let { actions } = this.props;
    if(searchForCompanyId !== lastSearchedTerm){
      this.setState({
        loading: true,
        companyId: searchForCompanyId
      });
      actions.getCompanyCheckByCompanyId(searchForCompanyId).then((result) => {
        var company = result.data;
        this.setState({
          lastSearchedTerm: searchForCompanyId,
          company,
          loading: false
        });
      }
    );
    }
  }


  searchTermChanged = (term) => {
    let { lastSearchedTerm } = this.state;
    this.setState({searchTextValue: term}, () => {
      if(term && term.length >= 3 && lastSearchedTerm !== term){
        this.searchForCompany(term);
      }
    });
  }

  setCompanyId = () => {
    const { userInfo, actions } = this.props;
    const { lastSearchedTerm } = this.state;
    var userId = UserUtil.getId(userInfo);
    var serverTime = DateUtil.getServerTime();
    var plan = plans.options.find(x => x.value === plans.DEFAULT_PLAN);
    var newCompany = {
      userId: userId,
      userEmail: UserUtil.getEmail(userInfo),
      companyId: lastSearchedTerm,
      displayName: lastSearchedTerm,
      plan: plan.value,
      pricePerPark: plan.pricePerPark,
      parksLimit: plan.parksLimit,
      createdUnix: serverTime,
      updatedUnix: serverTime,
      billingDay: constants.DEFAULT_BILLING_DAY,
      timezone: DateUtil.guessTimezone(),
    };
    this.setState({
      loading: true
    });
    Promise.all([
      actions.postCompany(newCompany),
      actions.patchProfile(userId, {
        companyId: lastSearchedTerm
    }),
    ]).then(() => {
      window.location.reload();
    });
  }

  isCompanyValid = () => {
    let { lastSearchedTerm, company, searchTextValue } = this.state;
    return searchTextValue === lastSearchedTerm && lastSearchedTerm && lastSearchedTerm.length >= 3 && company === null;
  }

  render() {
    const { loading, searchTextValue, company} = this.state;



    return (
       
      <div className="index-wrap">
    <div className="welcome-wrap graphics-bg-wrap company-setup profile-setup"><div className="maxWidthPart">
      <Logo className="logo" />
    <Box className="intro text-center max-width-center-wrap" py={2} pb={3}>
    <Typography variant="h3"><img src="/assets/images/Name.svg" alt="company name" /> Company Name</Typography>
    <Grid container spacing={4} alignContent="center">
        <Grid item xs={12}>
        <Box className="intro text-center max-width-center-wrap" py={3}>
      <Typography variant="h6"><strong>Tell us the name of your company</strong></Typography>
      </Box>
      <Typography variant="body1">This will be your display name inside of the app, so your team knows who's who.</Typography>
        </Grid>
        <Grid item xs={12}>
        <TextField
        className="whiteInput"
        id="companyId"
        placeholder="Your company"
        value={searchTextValue}
        onChange={(event) => this.searchTermChanged(event.target.value)}
        margin="normal"
        inputProps={{
          maxLength: 30,
        }}
      />
        </Grid>
        <Grid item xs={12}>
        {
          loading ? <ComponentLoading /> :
      this.isCompanyValid() ?
      <Grid item xs={12}>
        <Button variant="contained" className="primary" onClick={() => this.setCompanyId()}>That's Us</Button>
        </Grid> : <Grid item xs={12}>
        <Typography variant="body1">
          {company ? "Company \""+searchTextValue+"\" already exists! Please use a different ID." : searchTextValue.length <= 3 ? "Company ID has to be at least 3 characters long." : ''}
          </Typography>
        </Grid> 
    }
        </Grid>
        </Grid>
    </Box>
    
    

    </div></div></div>
      
      




      
          
    );
  }
}


const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = dispatch =>
({
  actions: bindActionCreators(
    {
      ...companiesActions,
      ...profilesActions,
    },
    dispatch
  )
});


export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanySelect);


