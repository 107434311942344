import React from 'react';



import CarIcon from '@material-ui/icons/DirectionsCar';
import PeopleIcon from '@material-ui/icons/People';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import BusinessIcon from '@material-ui/icons/Business';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsIcon from '@material-ui/icons/Settings';
import BlockIcon from '@material-ui/icons/Block';
import BugReportIcon from '@material-ui/icons/BugReport';
import DateRangeIcon from '@material-ui/icons/DateRange';

import urls from '../shared/constants/urls';
import TranslatorUtil from '../shared/utils/general/TranslatorUtil';
import { NotificationsActive } from '@material-ui/icons';
export default class MenuUtil {

    static getSuperAdminCompanyMenu(company){
        return company ? [
            {
              title: TranslatorUtil.t("Overview"),
              icon: <BusinessIcon />,
              url: urls.ADMIN_COMPANY_URL(company.id),
            },
            {
              title: TranslatorUtil.t("Settings"),
              icon: <SettingsIcon />,
              url: urls.ADMIN_COMPANY_SETTINGS_URL(company.id),
            },
            // {
            //   title: TranslatorUtil.t("Billing"),
            //   icon: <AttachMoneyIcon />,
            //   url: urls.ADMIN_COMPANY_BILLING_URL(company.id),
            // },
            {
              title: TranslatorUtil.t("Bookings"),
              icon: <DateRangeIcon />,
              url: urls.ADMIN_COMPANY_BOOKING_DASHBOARD_URL(company.id),
            },
            {
              title: TranslatorUtil.t("Conflicts"),
              icon: <BugReportIcon />,
              url: urls.ADMIN_COMPANY_BOOKING_CONSOLE_URL(company.id),
            },
            {
              title: TranslatorUtil.t("Notifications"),
              icon: <NotificationsActive />,
              url: urls.ADMIN_COMPANY_NOTIFICATION_CONSOLE_URL(company.id),
            },
            {
              title: TranslatorUtil.t("Parks"),
              icon: <CarIcon />,
              url: urls.ADMIN_COMPANY_PARKS_URL(company.id),
            },
            {
              title: TranslatorUtil.t("Users"),
              icon: <PeopleIcon />,
              url: urls.ADMIN_COMPANY_USERS_URL(company.id),
            },
            {
              title: TranslatorUtil.t("Invitations"),
              icon: <InsertInvitationIcon />,
              url: urls.ADMIN_COMPANY_INVITATIONS_URL(company.id),
            },
            {
              title: TranslatorUtil.t("Block"),
              icon: <BlockIcon />,
              url: urls.ADMIN_COMPANY_BLOCK_URL(company.id),
            },
          ] : [];
    } 
}
  