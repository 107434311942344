import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ComponentLoading from '../../../components/ComponentLoading';
import PageHeading from '../../../components/PageHeading';
import { Box, Paper, Select, Typography } from '@material-ui/core';
import firestoreApi from '../../../firebase.js';
import * as types from "../../../constants/actionTypes";
import StoreUtil from '../../../utils/StoreUtil';


import ArrayUtil from '../../../shared/utils/general/ArrayUtil';
import UserUtil from '../../../shared/utils/UserUtil';
import DateUtil from '../../../shared/utils/general/DateUtil';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
import CompanyUtil from '../../../shared/utils/CompanyUtil';
import AppSortableTable from '../../../components/table/AppSortableTable';
import { Group, ListAlt } from '@material-ui/icons';
import FirestoreUtil from '../../../shared/utils/general/FirestoreUtil';
import UIFormatterUtil from '../../../utils/UIFormatterUtil';
import PlatformIcon from '../../../components/icons/PlatformIcon';
import objectPath from 'object-path';
import { CSVLink } from 'react-csv';

class Crm extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      companies: [],
      profiles: [],

      minRoleFilterValue: '',
      versionFilterValue: '',
      companyFilterValue: ''
    }   
  }

  componentDidMount() {
    let _this = this;
    this.setState({
      loading: true
    })
    this.flushCompany();
      Promise.all([
        firestoreApi.collection('companies').get(),
        firestoreApi.collection('profiles').where('companyAdmin','==',true).get()
      ]).then((results) => {
        this.setState({
          companies: FirestoreUtil.parseCollectionData(results[0]).filter(x => !objectPath.get(x,'disabled', false)),
          profiles: FirestoreUtil.parseCollectionData(results[1]).filter(x => !objectPath.get(x,'disabled', false)),
          loading: false
        });
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  flushCompany = () => {

      StoreUtil.dispatchAction({data: null,  type: types.GET_COMPANY });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_PROFILES });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_PARKS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_BILLINGS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_COMPANY_INVITATIONS });
      StoreUtil.dispatchAction({data: [],  type: types.GET_PARK_STATUSES });
      StoreUtil.dispatchAction({data: [],  type: types.GET_PARK_OWNERSHIPS });
    
  }


  getTableRows = () => {
    const profiles = this.getFilteredProfiles();
    
    return ArrayUtil.isNonEmptyArray(profiles) ? profiles.map(profile => {
      return {
        item: profile,
        items: [
          {
            text: profile.email
          },
          {
            text: <PlatformIcon profile={profile} />,
            
          },
          {
            text: UIFormatterUtil.getUserAppDetails(profile),
            
          },
          {
            text: profile.createdUnix ? DateUtil.formatDateTimeShort(profile.createdUnix) : '-',
            
          },
          {
            text: profile.updatedUnix ? DateUtil.formatDateTimeShort(profile.updatedUnix) : '-',
            
          },
          // {
          //   text: UserConstants.options.findIndex(x => x.id === profile.userRole) !== -1 ? UserConstants.options.find(x => x.id === profile.userRole).name : ''
          // },
          {
            text: profile.companyId
          },
          {
            text: CompanyUtil.getPlanName(profile.company)+(CompanyUtil.isFreePlan(profile.company) ? ' ('+CompanyUtil.freePlanDaysLeft(profile.company)+' days left)' : ''), 
            additionalClasses: "hidden-sm-down"
          },
        ]
      }
    }) : [];
  }

  getTableHeaders = () => {
    return [
      { id: 'email', numeric: false,  text: TranslatorUtil.t("Email") },
      { id: 'messagingPlatform', numeric: false,  text: TranslatorUtil.t("OS"),
       },
      { id: 'appVersion', numeric: false,  text: TranslatorUtil.t("Version"),
       },
      { id: 'updatedUnix', numeric: true,  text: TranslatorUtil.t("Updated"),
       },
       { id: 'createdUnix', numeric: true,  text: TranslatorUtil.t("Created"),
      },
      // { id: 'userRole', numeric: false,  text: TranslatorUtil.t("Role"),},
      { id: 'companyId', numeric: false,  text: TranslatorUtil.t("Company ID"),
       },
      { id: 'companyPlan', numeric: true,  text: TranslatorUtil.t("Company plan"),
       },
    ];
  }

  getCompanyByProfile = (profile) => {
    const { companies } = this.state;
    return companies.find(x => profile.companyId === x.companyId);
  }

  getFilteredProfiles = () => {
    const { profiles, versionFilterValue, companyFilterValue, minRoleFilterValue } = this.state;

    var versionToCompare = versionFilterValue !== '' ? parseInt(versionFilterValue.replaceAll('.','')) : 9999999;

    console.log('versionToCompare',versionToCompare);

    return profiles.map(x => {
      let company = this.getCompanyByProfile(x);
      return {
        ...x,
        company: company,
        companyPlan: objectPath.get(company, 'plan'),
        userRole: company ? UserUtil.getUserTypeFromProfile(company,x) : null
      }
    }).filter(x => {
      var passed = true;
      if(minRoleFilterValue !== '' && x.userRole < minRoleFilterValue){
        passed = false;
      }
      if(companyFilterValue !== ''){
        if(objectPath.get(x, 'companyId') !== companyFilterValue){
          passed = false;
        }
      }
      if(versionToCompare){
        if(versionToCompare < parseInt(objectPath.get(x, 'appVersion', '0').replaceAll('.',''))){
          passed = false;
        }
      }
      return passed;
    });
  }

  getVersionOptions = () => {
    const { profiles } = this.state;
    return [...new Set(profiles.filter(x => objectPath.has(x,'appVersion')).map(x => objectPath.get(x,'appVersion')))]
  }


  render() {

    const { listDataLoading, companies } = this.state;

    return <div className="app-container-wrap app-admin wide-table-wrap">
        <PageHeading icon={<Group />} title={TranslatorUtil.t("CRM")} />
        <Typography>{TranslatorUtil.t("Listing only accounts that did not match with any invitation at time of creation.")}</Typography>
        <Typography variant='caption'>
          {TranslatorUtil.t("Possible reasons:")}
          <ul>
          <li>company registraion
            <ul>
              <li><span role="img" aria-label="check">✔️</span>creating of a company by admin (In that case it should have valid company ID)</li>
              <li>creating of a company and email not verified / company ID not set (In that case it should have no company ID)</li>
            </ul>
          </li>
          <li>email mistmach
            <ul>
              <li>misspelt invitation (admin / manager fault)</li>
              <li>email that did not match invitation was used by mistake (normal user fault)</li>
            </ul>
          </li>
        </ul>
        </Typography>
        
        <Box mb={1}>
        <Paper>
          <Box p={2}>
         {/* Min role : <Select
          native
          value={this.state.minRoleFilterValue}
          onChange={(event) => this.setState({
            minRoleFilterValue: event.target.value
          })}
        >
          <option value="">All</option>
          {UserConstants.options.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
        </Select> &nbsp;&nbsp;&nbsp; 
        Version [min.required: {constants.MIN_APP_VERSION}] : <Select
          native
          value={this.state.versionFilterValue}
          onChange={(event) => this.setState({
            versionFilterValue: event.target.value
          })}
        >
          <option value="">All</option>
          {this.getVersionOptions().map(x => <option key={x} value={x}>{'<= '+x}</option>)}
        </Select> &nbsp;&nbsp;&nbsp; 
         */}
        Company : <Select
          native
          value={this.state.companyFilterValue}
          onChange={(event) => this.setState({
            companyFilterValue: event.target.value
          })}
        >
          <option value="">All</option>
          {companies.map(x => <option key={x.companyId} value={x.companyId}>{x.companyId}</option>)}
        </Select>
        &nbsp;&nbsp;&nbsp;
        <CSVLink
          data={this.getFilteredProfiles().map(x => {return {email : x.email}})}
          filename={"flexparking-crm-export.csv"}
          className="MuiButtonBase-root MuiButton-root MuiButton-contained"
          target="_blank"
        >
          <ListAlt fontSize="small"/> Export emails to CSV
        </CSVLink>
        </Box>
        </Paper>
      
      </Box>

        { listDataLoading ? <ComponentLoading /> : <AppSortableTable hasActions={false} rows={this.getTableRows()} headers={this.getTableHeaders()} defaultSortBy={"createdUnix"} /> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Crm));
