import objectPath from "object-path";
class FirestoreUtil {


  static parseCollectionData(items) {
    if (items && items.docs) {

      return items.docs.map(item => Object.assign(item.data(), FirestoreUtil.addMetaData(item)));
    }
    return [];

  }

  static parseFirstItemFromCollectionData(items) {
    return items && items.docs && items.docs.length > 0 ? FirestoreUtil.parseCollectionItemData(items.docs[0]) : null;

  }

  static addMetaData(item) {
    return {
      id: item.id,
      createdTime: objectPath.get(item, '_document.proto.createTime', null),
      updatedTime: objectPath.get(item, '_document.proto.updateTime', null),
    }
  }

  static removeMetaData(item) {
    delete item.createdTime;
    delete item.updatedTime;
    delete item.id;
    return item;
  }

  static parseCollectionItemData(item) {
    if (item && item.exists) {
      return Object.assign(item.data(), FirestoreUtil.addMetaData(item))
    }
    return null;

  }
}


export default FirestoreUtil;

