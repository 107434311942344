import * as types from '../constants/actionTypes';




export function getOffsetTime(state = null, action) {
    if (action.type === types.GET_SERVER_TIME_OFFSET) {
        console.log(types.GET_SERVER_TIME_OFFSET+' set in store');
        return action.data;
    }
    return state;
}

