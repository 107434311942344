import React from 'react';
import Logo from './Logo';
import Typography from '@material-ui/core/Typography/Typography';
import { CircularProgress } from '@material-ui/core';

export default class AppLoading extends React.Component {



  


  render() {
    return <div className="appLoader graphics-bg-wrap">
    <div className="innerWrap">
        <div className="logoWrap">
        <Logo className="logo" />
        </div>
        <div className="progressWrap">
        <CircularProgress thickness={5} color="secondary" />
        </div>
        <Typography align="center" className="text">Please wait</Typography>
        
        
    </div>
    </div>
  }
}

