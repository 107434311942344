import React from 'react';
import PolicyIcon from '@material-ui/icons/Policy';
import StaticPage from './StaticPage'
import { Typography } from '@material-ui/core';


import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import urls from '../../../../shared/constants/urls';

function PrivacyPolicy(props) {

  const icon = <PolicyIcon className="icon" />;
  const content = <Typography>Privacy Policy text</Typography>;
  const title = TranslatorUtil.t("Privacy Policy");
  const navigateText = TranslatorUtil.t("Go Back");
  const navigateLink = urls.INDEX_URL;

  return <StaticPage content={content} icon={icon} title={title} navigateText={navigateText} navigateLink={navigateLink} />
}

export default PrivacyPolicy;
