import React from 'react';
import EnvSetting from '../shared/config/env';

class ProdTracker extends React.Component {

  render() {
    return (EnvSetting.IS_PROD()) ? <div id="tracker">
        <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2148010&vkey=80f3ce1a6c1a197c617f71beafdea00c" alt="capterra tracking" />
    </div> : null;
  }
}

export default ProdTracker;