import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LinearProgress, Box, IconButton, Grid, Table, TableRow, TableCell, TableBody } from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';


import FormatterUtil from '../../shared/utils/general/FormatterUtil';
import DateUtil from '../../shared/utils/general/DateUtil';

class UserUsageStatsBar extends React.Component {



  constructor(props) {
    super(props);
    this.state = {
      statusesLoading: false,
      showDetails: false
    }
  }

  getDays = () => {
    const { days } = this.props;
    return days.filter(x => !DateUtil.isFuture(x.date));
  }

  getBookedDays = () => {
    const { days, bookedData } = this.props;

    return days.filter(day => bookedData.filter(tpb => DateUtil.isSameDay(tpb.day.date,day.date)).length > 0);
  }

  getBookedParks = () => {
    const { bookedData } = this.props;

    return bookedData;
  }

  getUsageText(usageInDays, usageInParks) {
    let output = 'No parks booked';
    if (usageInDays.length > 0 && usageInDays.length !== usageInParks.length) {
      output = usageInParks.length + (usageInParks.length === 1 ? ' park' : ' parks') + ' booked for ' + usageInDays.length + (usageInDays.length === 1 ? ' day' : ' days');
    } else if (usageInDays.length > 0) {
      output = 'Park booked for ' + usageInDays.length + (usageInDays.length === 1 ? ' day' : ' days');
    }
    return output;
  }



  render() {
    const { showDetails } = this.state;
    const usageInDays = this.getBookedDays();
    const usageInParks = this.getBookedParks();
    const daysPercentageBooked = Number(FormatterUtil.getPercentage(this.getDays().length, usageInDays.length));


    return <Box className="usage-bar-wrap">
      <Grid container spacing={4} >
        <Grid item xs={12} sm={6}>
          <Box className="usage-text">
            {this.getUsageText(usageInDays, usageInParks)}
            {usageInDays.length > 0 ? <IconButton size="small" onClick={() => { this.setState({ showDetails: !this.state.showDetails }) }}><InfoIcon /></IconButton> : null}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="usage-text text-right">
            {daysPercentageBooked}%
          </Box>
        </Grid>
      </Grid>


      <LinearProgress className="progress" variant="determinate" value={daysPercentageBooked} />
      {
        showDetails ? <Box className="max-w-xs"><Table>
        <TableBody>
          {usageInParks.map((u, index) => (
            <TableRow key={index}>
              <TableCell padding="none">{DateUtil.formatDayWithMonthForCalendar(u.day.date)}</TableCell>
              <TableCell padding="none" align="right">{u.park.parkId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table></Box> : null
      }
    </Box>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    companyParks: state.companyParks,
    companyProfiles: state.companyProfiles,
    parkStates: state.parkStates,
    parkOwnerships: state.parkOwnerships,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserUsageStatsBar));
