import React, { Component } from "react";
import { auth, firebaseFunctions } from '../firebase';
import * as profileActions from "../actions/profile";
import * as companyActions from "../actions/company";
import objectPath from "object-path";
import CompanyUtil from "../shared/utils/CompanyUtil";
import DateUtil from "../shared/utils/general/DateUtil";

const withFirebaseAuth = WrappedComponent => {


  class AuthedComponent extends Component {
    state = {
      user: false,
      userLoading: true
    };

    componentDidMount() {
      this.authenticate();
    }

    authenticate() {
      
      auth.onAuthStateChanged((user) => {
        let _this = this;
        if(user){
          Promise.all([
            _this.mountUserProfile(user)
          ]).then(() => {
            Promise.all([
              _this.mountUserCompany(user)
            ]).then((company)=>{
              _this.mountCompanyServerTime(user, company).then(() => _this.finishLoading(user));
              // _this.finishLoading(user);
              //catch errors
            }).catch(function(err) {
              _this.userLoadingFailed(err);
            });
          }).catch(function(err) {
            _this.userLoadingFailed(err);
          });
        } else {
          _this.finishLoading(user);
        }
      });
    }

    mountCompanyServerTime = (user, company) => {
      if(user && company){
        const getServerTime = firebaseFunctions.httpsCallable('getServerTime');
        return getServerTime({ timezone: CompanyUtil.getTimezone(company), unix: DateUtil.getCurrentUnix() }).then(response => {
          user.serverTime = objectPath.get(response,'data',null);
        });
      } else {
        return Promise.resolve();
      }
    }

    mountUserProfile = (user) => {
      return profileActions.getFirestoreProfileById(user.uid).then((profile) => {
        if(profile === null){
          console.log('no profile');
        }
        user.profile = profile;
      });
    }

    mountUserCompany = (user) => {
      if(objectPath.get(user,'profile.companyId',null) != null){
        return companyActions.getFirestoreCompanyByCompanyId(user.profile.companyId).then((company) => {
          if(company === null){
            console.log('no company');
          }
          user.company = company;
          // this.finishLoading(user);
          return company;
        });
      } else {
        return Promise.resolve();
      }
      
    }

    userLoadingFailed = (err) => {
      //const { history } = this.props;
      console.log(err);
      this.setState({userLoading: false}, () => {
        //history.push('/500');
      });
    }

    finishLoading = (user) => {
      this.setState({
        user,
        userLoading: false
      });
    }


    render() {
      let { user, userLoading } = this.state;

        
        return <WrappedComponent user={user} userLoading={userLoading} />;

      
    }
  }


  return AuthedComponent;
};

export default withFirebaseAuth;
