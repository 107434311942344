import React from 'react';
import { Box, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { connect } from "react-redux";
import objectPath from 'object-path';

import firestoreApi from '../../firebase.js';

import FirestoreUtil from '../../shared/utils/general/FirestoreUtil'; 
import DateUtil from '../../shared/utils/general/DateUtil';
import UserUtil from '../../shared/utils/UserUtil.js';
import constants from '../../shared/constants/constants.js';
import moment from "moment";
import CompanyUtil from '../../shared/utils/CompanyUtil.js';



class NotificationsDebug extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      collapsedI: null,
      parkIdFilter: "",
      orderBy: "updatedUnix",
      notifications: [], //parkId,date,updatedUnix
    }
  }


  listenNotifications() {
    const {userInfo} = this.props;
      var dates = this.getDates();
      return firestoreApi.collection('notifications')
      .where('companyId', '==', UserUtil.getCompanyId(userInfo))
      .where('dateUnix', '>=', DateUtil.getStartUnixFromDates(dates))
      .where('dateUnix', '<=', DateUtil.getEndUnixFromDates(dates))
      .onSnapshot(
          response => this.setState({
              notifications: FirestoreUtil.parseCollectionData(response)
              // .sort((a, b) => b.updatedUnix - a.updatedUnix)
            }),
            error => this.setState({
                notifications: []
              })
        );
  }

  componentDidMount(){
    this.listenNotifications(); 
  }

  getDates = () => {
    const {userInfo} = this.props;
    var date = moment().subtract(2, 'day');
    return DateUtil.getTwoWeeks(DateUtil.getURLdate(date, UserUtil.includeWeekend(userInfo)), true, UserUtil.includeWeekend(userInfo));
  }

  orderedNotifications = () => {
    const {orderBy,parkIdFilter,notifications} = this.state;
    var filtered = parkIdFilter ? notifications.filter(x => x.parkId === parkIdFilter) : notifications;
    return filtered.sort((a, b) => (objectPath.get(b,orderBy,'')+"").localeCompare(objectPath.get(a,orderBy,'')+""))
  }

  getNotificationResultText(n){
    var result = ' - ';
    if(n){
      var resultKey = objectPath.get(n,'result',0);
      result = objectPath.get(constants,'NOTIFICATION_RESULTS.'+resultKey,' - ');
    }
    return result;
  }

  render() {
    const {companyParks, companyProfiles, userInfo} = this.props;
    const {collapsedI} = this.state;
    const company = UserUtil.getCompany(userInfo);

    return <Box m={5} className="park-states-debug">
      <Box p={2} className="bg-warning">NOTIFICATION DEBUG CONSOLE<br/>
      <small><strong>{ CompanyUtil.getTimezone(company) } (time  {CompanyUtil.getCurrentTimeByTimezone(company,'HH:mm')}) and system will { CompanyUtil.isNotificationAllowedByTimezone(company) ? '' : 'NOT' } PROCESS notifications for this company atm.</strong></small><br />
      <small>Listing notifications for dates: { this.getDates().map(d => DateUtil.dbFormat(d.date)).join(', ') }. <br/>Possible results are : <i>{constants.NOTIFICATION_RESULTS.join(', ')}</i> - all calculated at <i>Processed Time</i></small>
      <hr/>
      order by : <Select
          native
          value={this.state.orderBy}
          onChange={(event) => this.setState({
            orderBy: event.target.value
          })}
        >
          {['parkId','updatedUnix','date'].map(x => <option key={x} value={x}>{x}</option>)}
        </Select> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; park ID filter : <Select
          native
          value={this.state.parkIdFilter}
          onChange={(event) => this.setState({
            parkIdFilter: event.target.value
          })}
        >
          <option value="">All</option>
          {companyParks.map(x => <option key={x.id} value={x.id}>{x.parkId}</option>)}
        </Select></Box>

      <TableContainer component={Paper}>
      <Table size="small">
      <TableHead>
        
          <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Park ID</TableCell>
              <TableCell>Done by</TableCell>
              <TableCell>Result</TableCell>
              <TableCell>Sending to</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Processed Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.orderedNotifications().map((n,i) => {
                    var park = companyParks.find(p => p.id === n.parkId);
                    var proceededBy = companyProfiles.find(p => p.id === n.proceededBy);
                    return <React.Fragment key={i}>
                      <TableRow onClick={() => this.setState({collapsedI: i})}>
                      <TableCell>{n.date}</TableCell>
                      <TableCell>{objectPath.get(park,'parkId',' - ')}</TableCell>
                      <TableCell>{(objectPath.get(proceededBy,'email',' 𐄂 '))}</TableCell>
                      <TableCell>{this.getNotificationResultText(n)}</TableCell>
                      <TableCell>{objectPath.get(n,'sendingTo',[]).join(',')}</TableCell>
                      <TableCell>{objectPath.get(n,'priority',false)  ? '✓' : '𐄂'}</TableCell>
                      <TableCell>{DateUtil.formatDateTimeSeconds(n.createdUnix)}</TableCell>
                      <TableCell>{objectPath.get(n,'processed',false) ? DateUtil.formatDateTimeSeconds(n.updatedUnix) : '𐄂'}</TableCell>
                      
                  </TableRow>
                      {
                        collapsedI === i ? <TableRow key={"collapsed"+i} onClick={() => this.setState({collapsedI: i})}>
                        <TableCell colSpan={8}>{JSON.stringify(n).replaceAll('","','", "')}</TableCell>
                    </TableRow> : null
                      }
                      </React.Fragment>
          })}
        </TableBody>
      </Table>
    </TableContainer></Box>;

    
  }
}


function mapStateToProps(state) {
    return {
      companyProfiles: state.companyProfiles,
      companyParks: state.companyParks,
      userInfo: state.userInfo,
    };
  }
  
  
  
  export default connect(
    mapStateToProps
  )(NotificationsDebug);


