import React, { Fragment } from 'react';
import { useSnackbar } from "notistack";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function SnackBarDismissAction(props){
    const { closeSnackbar } = useSnackbar()
    const { id } = props;
    return (
        <Fragment>
            <IconButton onClick={() => closeSnackbar(id)} size="small" className="snackBarCloseBtn">
            <CloseIcon />
            </IconButton>
        </Fragment>
    )
}

export default SnackBarDismissAction;


            
