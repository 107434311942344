import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import * as parkActions from "../../../actions/park";
import * as parkOwnershipActions from "../../../actions/parkOwnerships";
import * as parkStateActions from "../../../actions/parkStates";
import * as repeatingRosterActions from "../../../actions/repeatingRoster";
import * as invitationActions from "../../../actions/invitation";

import objectPath from 'object-path';
import ParkCalendar from '../../../components/park/Calendar';
import PageHeading from '../../../components/PageHeading';

import ComponentLoading from '../../../components/ComponentLoading';
import ParkForm from '../../../forms/park/form';
import SnackBarUtil from '../../../utils/SnackBarUtil';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import ParkMenu from '../../../components/park/ParkMenu';
import HistoryOwnershipCard from '../../../components/park/HistoryOwnershipCard';
import UserCard from '../../../components/user/UserCard';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Box, Typography, Grid, Card, CardContent, CardHeader } from '@material-ui/core';
import ConfirmDialog from '../../../components/ConfirmDialog';
import UserSelectDialog from '../../../components/form/UserSelectDialog';
import ValidationUtil from '../../../utils/ValidationUtil';

import urls from '../../../shared/constants/urls';
import plans from '../../../shared/constants/plans';
import constants from '../../../shared/constants/constants';
import UserUtil from '../../../shared/utils/UserUtil';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
import CompanyUtil from '../../../shared/utils/CompanyUtil';
import ParkUtil from '../../../shared/utils/ParkUtil';
import DateUtil from '../../../shared/utils/general/DateUtil';
import FormatterUtil from '../../../shared/utils/general/FormatterUtil';
import ArrayUtil from '../../../shared/utils/general/ArrayUtil';
import PersonIcon from '@material-ui/icons/Person';

class Park extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      dataLoading: true,
      deletionProccessing : false,
      showConfirmation: false,
      confirmTitle:"",
      confirmMessage:"",
      setOwnerForPark: null
    }   
  }


  componentDidMount(){
    this.mountData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    var id = objectPath.get(this,'props.match.params.id');
    var prevId = objectPath.get(prevProps,'match.params.id');
    if (id !== prevId) {
      this.mountData();
    }
  }

  mountData = () => {
    const { userInfo } = this.props;
    this.setState({dataLoading : true});
    Promise.all([
      this.mountPark(),
      UserUtil.isAtLeastCompanyManager(userInfo) ? this.mountParkRosterSetting() : Promise.resolve(),
    ]).then(() => {
      this.setState({dataLoading : false});
    })
  }

  mountPark = () => {
    const { actions } = this.props;
    var id = objectPath.get(this,'props.match.params.id');
    if (id) {
      return actions.getPark(id);
    }
  }

  mountParkRosterSetting = () => {
    const { userInfo, actions } = this.props;
    var id = objectPath.get(this,'props.match.params.id');
    if (id) {
      return actions.getParkRepeatingRosterSetting(UserUtil.getCompanyUid(userInfo),id);
    }
  }

  


  handleResponse = (response, message ) => {
    const { enqueueSnackbar, history, userInfo, actions } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = message ? message : TranslatorUtil.t("Updated");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      actions.getParksByCompanyId(UserUtil.getCompanyId(userInfo)).then( () => history.push(urls.INDEX_URL));
      
    }
    
  }

  addInvitation = (email, oldEmail) => {
    const { userInfo, actions } = this.props;
    if(email && email.length && ValidationUtil.isValidEmail(email) && email !== oldEmail){
      return actions.getInvitationsByCompanyId(UserUtil.getCompanyId(userInfo)).then(response => {
        var invitationEmails = objectPath.get(response,'data',[]).map(x => x.email);
        if(!invitationEmails.includes(email)){
          actions.postInvitation({
              companyId: UserUtil.getCompanyId(userInfo),
              email:email,
              createdUnix: DateUtil.getCurrentUnix()
            }).then(response => this.handleResponse(response, email+" "+TranslatorUtil.t("successfully invited.")))
        }
      });
    }
  }



  handleSubmit = (values) => {
    const { actions,park } = this.props;
    console.log('handleSubmit',values);
    //make sure its lowercase
    if(values.invitedOwnerEmail){
      values.invitedOwnerEmail = values.invitedOwnerEmail.toLowerCase();
    }
    return actions.putPark(values).then(response => {
      if(ParkUtil.hasNewOwner(values,park)){
        this.updateParkOwnerHistory({ id:park.id , ...values });
      } else if (ParkUtil.hadOwnerAndDoesNotHaveNewOne(values,park)){
        this.endLastParkOwnerHistory();
      }
      this.addInvitation(objectPath.get(values,'invitedOwnerEmail',''),objectPath.get(park,'invitedOwnerEmail',''));
      this.handleResponse(response);
    });
  };

  handleCreateSubmit = (values) => {
    const { actions, userInfo } = this.props;
    console.log('handleCreateSubmit',values);
    //make sure its lowercase
    if(values.invitedOwnerEmail){
      values.invitedOwnerEmail = values.invitedOwnerEmail.toLowerCase();
    }
    values.companyId = UserUtil.getCompanyId(userInfo);
    return actions.createPark(values).then(response => {
      if(objectPath.get(values,'ownerId',null) || objectPath.get(values,'invitedOwnerEmail',null)){
        this.updateParkOwnerHistory({ id:response.data.id , ...values });
      }
      this.addInvitation(objectPath.get(values,'invitedOwnerEmail',''),null);
      this.handleResponse(response);
    });
  };


  isCurrentAction = (action) => {
    return objectPath.get(this,'props.match.params.action','view') === action
  }


  confirmDeleteDialog = () => {
    const { park } = this.props;
    this.setState({
      showConfirmation: true,
      confirmTitle: TranslatorUtil.t("Are you sure?"), 
      confirmMessage: TranslatorUtil.t("Please confirm removal of park ")+park.parkId+TranslatorUtil.t(" and be aware that the park cannot be restored anymore.")
    });
  }

  confirmOnDeleteCancel = () => {
    this.setState({
      showConfirmation: false,
      confirmTitle: "", 
      confirmMessage: ""
    });
  }

  confirmOnDeleteConfirm = () => {
    const { park, actions } = this.props; 
    this.setState({deletionProccessing : true});
    Promise.all([
      actions.removeParkStatesByParkId(park.id),
      actions.removeParkOwnershipsByParkId(park.id),
      actions.removePark(park.id),
    ]).then(
      () => {
        document.location.href = urls.INDEX_URL;
      }
      );
  }


  userSelected = (user) => {
    const { actions } = this.props;
    const { setOwnerForPark } = this.state;
    
    return actions.setParkOwner(setOwnerForPark, user).then(() => {
      this.updateParkOwnerHistory({...setOwnerForPark, ownerId: user.id});
      this.setState({ 
        setOwnerForPark: null
      });
      this.mountData();
    });
  }

  endLastParkOwnerHistory = () => {
    const { parkOwnerships, park, actions } = this.props;
    var updateTime = DateUtil.getServerTime();
    var lastParkOwnership = ParkUtil.getLastParkOwnership(parkOwnerships, park.id);
    if(lastParkOwnership){
      return actions.endParkOwnership(lastParkOwnership.id,FormatterUtil.parseInteger(updateTime));
    }
    return Promise.resolve();
  }

  updateParkOwnerHistory = (park) =>{
    const { actions,parksOwnershipHistory } = this.props;
    var updateTime = DateUtil.getServerTime();
    var lastParkOwnership = ParkUtil.getLastParkOwnership(parksOwnershipHistory, park.id);
    var newParkOwnership = null;
    var newInvitedOwnerEmail = objectPath.get(park,'invitedOwnerEmail',null);
    var newOwnerId = objectPath.get(park,'ownerId',null);

    // console.log('updateParkOwnerHistory time',updateTime);
    
    //is diff from last
    if(
      ParkUtil.hasNewOwner(park,lastParkOwnership)
    ){
        newParkOwnership = {
          parkId: park.id,
          companyId: park.companyId,
          ownerId: newOwnerId,
          invitedOwnerEmail: newInvitedOwnerEmail,
          startedUnix: FormatterUtil.parseInteger(updateTime)
        };
    }

    console.log('newParkOwnership to update',park);
    if(newParkOwnership){
      return actions.postParkOwnership(newParkOwnership);
    }
    return Promise.resolve();
  }

  getPlanOffer = () => {
    const { userInfo } = this.props;
    return <Grid container spacing={2}>
      <Grid item xs={12}>
      <Typography variant="body1">
  You cannot create a new park as your limit is {CompanyUtil.getParksLimit(UserUtil.getCompany(userInfo))}. Please contact <a href={"mailto:"+constants.CONTACT_EMAIL}><Typography color="secondary" component="span">{constants.CONTACT_EMAIL}</Typography></a> to upgrade your plan.
            </Typography>
      </Grid>
      {plans.options.map(p => <Grid item xs={12} sm={6}>
        {p.value !== 1 ? (p.value !== 4 ? <Card>
            <CardContent>
              <Typography variant="h4" component="h4">{p.value}) {p.label}</Typography>
              <br/>
              <Typography color="textSecondary">
                Parks limit : {p.parksLimit}
              </Typography>
              <Typography color="textSecondary">
                Price per park / month : {p.pricePerPark} NZD
              </Typography>
            </CardContent>
          </Card>
          :
          <Card>
            <CardContent>
              <Typography variant="h4" component="h4">{p.value}) {p.label}</Typography>
              <br/>
              <Typography color="textSecondary">
                No parks limit
              </Typography>
              <Typography color="textSecondary">
                Price per park / month : contact us
              </Typography>
            </CardContent>
          </Card>
          )
          :
          <Card>
            <CardContent>
              <Typography variant="h4" component="h4">{p.value}) {p.label}</Typography>
              <br/>
              <Typography color="textSecondary">
                No parks limit
              </Typography>
              <Typography color="secondary">
                Free that expires in 30 days
              </Typography>
            </CardContent>
          </Card>
        }
        </Grid>
        )}
      
      </Grid>
  }
  

  
  render() {

    const { dataLoading, deletionProccessing, showConfirmation, confirmTitle, confirmMessage, setOwnerForPark } = this.state;
    const { park, companyProfiles, parkOwnerships, companyParks, userInfo, parkRepeatingRosterSetting } = this.props;

    const editAllowed = UserUtil.isAtLeastCompanyManager(userInfo);

    const ownedBy = !this.isCurrentAction("create") ? ParkUtil.getCurrentParkOwner(park,companyProfiles) : null;

    return deletionProccessing ? <ComponentLoading /> : <div className="app-container-wrap">
        <PageHeading icon={<LocalParkingIcon />} title={dataLoading ? TranslatorUtil.t('Loading park data') : objectPath.get(park,'parkId',this.isCurrentAction("create") ? TranslatorUtil.t('Add a new park') : '')}>
          
        </PageHeading>
        { dataLoading ? <ComponentLoading /> :  <React.Fragment>

        { !this.isCurrentAction("create") ? <React.Fragment>
          <ParkMenu setOwnerForPark={() => this.setState({setOwnerForPark : park})} setOwnershipDisabled={ArrayUtil.isNonEmptyArray(parkRepeatingRosterSetting)} />
          <div className="park-ownership-history-wrap">
            {
              !ParkUtil.hideBookedBy(ownedBy, userInfo) ? <React.Fragment><UserCard user={ownedBy} subheader={TranslatorUtil.t("Currently owned by")} />
              { ParkUtil.showBookMoreInfoButton(userInfo) ? <HistoryOwnershipCard 
              users={companyProfiles} 
              park={park} 
              parksOwnershipHistory={parkOwnerships} /> : null }</React.Fragment> : <Card className="noShadowCard">
              <CardHeader
                avatar={
                  <PersonIcon />
                }
                title={TranslatorUtil.t(ownedBy ? "Currently owned" : "Owner is currently not defined")}
              />
            </Card>
            }
            
            </div>
        </React.Fragment> : null }
        

        
        
        { (this.isCurrentAction("edit") && editAllowed) || this.isCurrentAction("view") ? <ParkForm proceedSubmit={this.isCurrentAction("edit") ? this.handleSubmit : null} initialValues={park} optionsData={{
            profiles : companyProfiles.map(x => { return {value: x.id, label: UserUtil.getDisplayNameWithEmailFromProfile(x)}}),
            companyParks: companyParks
        }}
        companyProfiles={companyProfiles} 
        parkRepeatingRosterSetting={parkRepeatingRosterSetting} 
        /> : null }

        { (this.isCurrentAction("create") && editAllowed) ? 
          CompanyUtil.isParkLimitExceeded(UserUtil.getCompany(userInfo),companyParks) ? this.getPlanOffer() :
          <ParkForm proceedSubmit={this.handleCreateSubmit} userInfo={userInfo} optionsData={{
            profiles : companyProfiles.map(x => { return {value: x.id, label: UserUtil.getDisplayNameWithEmailFromProfile(x)}}),
            companyParks: companyParks,
            action: "create"
        }}
        companyProfiles={companyProfiles} 
        parkRepeatingRosterSetting={parkRepeatingRosterSetting} 
        /> : null }

        { (this.isCurrentAction("edit") && editAllowed) ? <Box pt={4}><Button onClick={this.confirmDeleteDialog} className="error-btn" startIcon={<DeleteIcon  />}>{TranslatorUtil.t("Delete Park")}</Button></Box> : null }
        
        {
          this.isCurrentAction("calendar") ? <ParkCalendar park={park} /> : null 
        }
        
        </React.Fragment> }

        {
          showConfirmation ? <ConfirmDialog title={confirmTitle} message={confirmMessage} onCancel={this.confirmOnDeleteCancel} onConfirm={this.confirmOnDeleteConfirm} /> : null
        }

      {
        setOwnerForPark ? <UserSelectDialog userSelected={this.userSelected} onClose={() => this.setState({setOwnerForPark : null})} users={companyProfiles} /> : null
      }
      
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    park: state.park,
    companyProfiles: state.companyProfiles,
    companyInvitations: state.companyInvitations,
    companyParks: state.companyParks,
    parkOwnerships: state.parkOwnerships,
    parkRepeatingRosterSetting: state.parkRepeatingRosterSetting
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...parkActions,
        ...parkOwnershipActions,
        ...parkStateActions,
        ...invitationActions,
        ...repeatingRosterActions
      },
      dispatch
    )
  };
}



export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
    )(Park)
));
