import  React, { useState , useEffect } from 'react'
import DateUtil from '../../shared/utils/general/DateUtil';
import ServerTimeUtil from '../../utils/ServerTimeUtil';
import moment from 'moment';

export const TimeDebug = () => {

    var [date,setDate] = useState(DateUtil.getServerTime(ServerTimeUtil.getServerTimeOffset()));
    
    useEffect(() => {
        var timer = setInterval(()=>setDate(DateUtil.getServerTime(ServerTimeUtil.getServerTimeOffset())), 5000 )
        return function cleanup() {
            clearInterval(timer)
        }
    
    });

    var companyTime = moment(date).clone().tz(ServerTimeUtil.getUserCompanyTimezone()).toString();
    var clientTime = moment().toString();

    return(
        <small>
            <div> Server offset : {ServerTimeUtil.getServerTimeOffset()}</div>
            { clientTime === companyTime ? null : <div>
                <div> company | your unix : {moment(date).valueOf()} | {moment().valueOf()}</div>
                <div> company | your time: {companyTime} | {clientTime}</div>    
                </div>}
            
        </small>
    )
}

export default TimeDebug;