

import React from 'react';
import { Button, TextField } from '@material-ui/core';
import firestoreApi from '../../../../firebase.js';
import DateUtil from '../../../../shared/utils/general/DateUtil';
import AppSortableTable from '../../../../components/table/AppSortableTable.js';
import ArrayUtil from '../../../../shared/utils/general/ArrayUtil.js';
import { CheckCircle, ReportProblem } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';
import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil.js';
import FirestoreUtil from '../../../../shared/utils/general/FirestoreUtil.js';
import ParkStateUtil from '../../../../shared/utils/ParkStateUtil.js';
import constants from '../../../../shared/constants/constants.js';

export default class MigrationDoubleBookingConflictResolver extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
          loading: false,

          validInputs: false,
          resultMessage: 'No result',


          migrationName: 'MigrationDoubleBookingConflictResolver',
          year: '2022',
          month: '',

          parkStates: [],
          duplicatesToRemove: [],
          duplicatesToKeep: [],
          removedIds:[],
          
        }   
      }

      revalidate = () => { 

        this.setState({
            validInputs : false
        })
        if(this.isValid()){
            this.setState({
                validInputs : true
            })
            return true;
        };
        return false;
      }


      isValid = () => { 
        const { year, month } = this.state;
        return String(year).length && String(month).length;
      }

      isDuplicate = (ps) => {
        const { duplicatesToRemove } = this.state;
        return duplicatesToRemove.findIndex(x => x.id === ps.id) !== -1;
      }

      isConflict = (ps) => {
        const { duplicatesToRemove, duplicatesToKeep } = this.state;
        return duplicatesToRemove.findIndex(x => x.id === ps.id) !== -1 || duplicatesToKeep.findIndex(x => x.id === ps.id) !== -1;
      }

      getTableRows = () => {
        const { parkStates, removedIds } = this.state;
        return ArrayUtil.isNonEmptyArray(parkStates) ? parkStates.filter(x => this.isConflict(x)).map(parkState => {
          return {
            item: {
              ...parkState,
              isDuplicate: this.isDuplicate(parkState)
            },
            items: [
              {
                text: (removedIds.includes(parkState.id) ? " REMOVED!!! " : "")+ parkState.parkId
              },
              {
                text: this.isDuplicate(parkState) ? <ReportProblem style={{ color: red[500] }} /> : <CheckCircle style={{ color: green[500] }} />
              },
              {
                text: parkState.date
              },
              {
                text: parkState.dateUnix
              },
              {
                text: parkState.userId
              },
              {
                text: parkState.updatedUnix ? DateUtil.formatDateTimeSeconds(parkState.updatedUnix) : '-',
                
              },
              {
                text: parkState.id
              },
              {
                text: parkState.companyId
              },
            ]
          }
        }) : [];
      }
    
      getTableHeaders = () => {
        return [
          { id: 'parkId', numeric: false,  text: TranslatorUtil.t("Park") },
          { id: 'isDuplicate', numeric: false,  text: TranslatorUtil.t("Duplicate") },
          { id: 'date', numeric: false,  text: TranslatorUtil.t("Date") },
          { id: 'dateUnix', numeric: true,  text: TranslatorUtil.t("DateUnix") },
          { id: 'userId', numeric: false,  text: TranslatorUtil.t("User") },
          { id: 'updatedUnix', numeric: true,  text: TranslatorUtil.t("Created") },
          { id: 'id', numeric: false,  text: TranslatorUtil.t("Park state ID") },
          { id: 'companyId', numeric: false,  text: TranslatorUtil.t("Company ID") },
          
        ];
      }



      loadData = () =>{
    const { year, month } = this.state;
    
    if(this.isValid()){
        //modify migration if needed
        firestoreApi.collection('parkStates')
        .where("year", "==", String(year))
        .where("month", "==", String(month))
        .where("lockType", '==', true)
        
        .get().then(result => {
            var parkStates = FirestoreUtil.parseCollectionData(result);
            var keepVsRemove = ParkStateUtil.getBookingDuplicates(parkStates);
            var message = 'Loaded ' + parkStates.length + ' park states with ' + keepVsRemove.duplicatesToKeep.length + ' conflicts ' + 
            (keepVsRemove.duplicatesToKeep.length > 0 ? ' to keep and ' + keepVsRemove.duplicatesToRemove.length + ' to be removed' : '')
            this.setState({
                parkStates : parkStates,
                duplicatesToKeep: keepVsRemove.duplicatesToKeep,
                duplicatesToRemove: keepVsRemove.duplicatesToRemove,
                resultMessage: message,
                removedIds:[],
            });
            
        });
    }
    
  }

  resolveConflicts = () => {
    const { duplicatesToRemove } = this.state;

    this.setState({
      loading: true
    });
    Promise.all(duplicatesToRemove.map(dtr => firestoreApi.collection('parkStates').doc(dtr.id).update(constants.UNSET_PARKSTATE))).then(() => this.setState({
      removedIds : duplicatesToRemove.map(x=>x.id)
    }));
  }


  render() {
    const { loading, validInputs, resultMessage, migrationName, duplicatesToRemove } = this.state;
    return <div className="innerWrap">
        <h2>{ migrationName }</h2>
        <p>Get all park states by date filter that has lockType "true" and booking is considered to be in conflict when there are more than 1 record with the same date, parkId, companyId and lockType.</p>
        <div>
            <div>
                <TextField value={this.state.year} label="Year" variant="outlined" disabled={loading} onChange={evt => {this.setState({ year: evt.target.value });this.revalidate();} } />
                <TextField value={this.state.month} label="Month" variant="outlined" disabled={loading} onChange={evt => {this.setState({ month: evt.target.value });this.revalidate();} } />
            </div>
            <div>
            <Button variant="contained" onClick={this.loadData}  disabled={loading || !validInputs}>{loading ? "Processing" : "Load data" }</Button> &nbsp; 
            {duplicatesToRemove.length ? <Button variant="contained" onClick={this.resolveConflicts}  disabled={loading || !validInputs}><ReportProblem style={{ color: red[500] }} /> {loading ? "Processing" : "Resolve conflicts" }</Button> : null}
                
            </div>
            
        </div>

        <div>
            <div>{resultMessage}</div>
            <AppSortableTable hasActions={false} headers={this.getTableHeaders()} rows={this.getTableRows()} defaultSortBy="date" />
            
        </div>

    </div>
  }
}
