import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import //firestoreApi, 
{ firedatabaseApi } from '../firebase.js';



export function getServerTimeOffset() {
  return function(dispatch) {
    console.log('serverTimeOffset');
    return firedatabaseApi.ref("/.info/serverTimeOffset").once('value').then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response.val(), types.GET_SERVER_TIME_OFFSET)),
      );
  };
}

 

