import React from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import * as formNames from "../../../constants/forms";
import {
  SelectInput
} from "../../../components/form/inputs";
import InfoIcon from '@material-ui/icons/Info';

import validate from "./validate";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Box, MenuItem, Typography, Collapse, Card, CardContent, IconButton, Badge, Tooltip } from "@material-ui/core";

import DeleteIcon from '@material-ui/icons/Delete';

import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import objectPath from "object-path";
import FlagIcon from '@material-ui/icons/Flag';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Alert } from "@material-ui/lab";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import UserSelectDialog from "../../../components/form/UserSelectDialog";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../../shared/utils/general/ArrayUtil';
import CompanyUtil from '../../../shared/utils/CompanyUtil';
import UserUtil from '../../../shared/utils/UserUtil';
import constants from "../../../shared/constants/constants";
import extraFeatures from "../../../shared/constants/extraFeatures";
import FeatureNotEnabled from "../../../components/FeatureNotEnabled";

const formName = formNames.COMPANY_FAIR_USE_FORM;

export class CompanySettingForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visibleDaysCollapsed: false,
      visibleDaytimeCollapsed: false,
      maximumParksPerUserCollapsed: false,
      priorityParkingQueueCollapsed: false,
      showAddUserDialog: false,
      priorityQueueUsers: this.props.priorityQueueUsers,
      nextPriorityUserEmail: this.props.nextPriorityUserEmail,
      priorityChanges: false,
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const {
      priorityQueueUsers
    } = this.state;

    console.log('onDragEnd', result);
    var startPos = objectPath.get(result, 'source.index', 0);
    var endPos = objectPath.get(result, 'destination.index');
    this.setState({
      priorityChanges : true,
      priorityQueueUsers: ArrayUtil.moveItemToIndex(priorityQueueUsers, startPos, endPos)
    });

  };


  removeFromQueue = (user) => {
    if(this.state.nextPriorityUserEmail === user.email){
      this.setAsNextPriority(null);
    }
    this.setState({
      priorityChanges : true,
      priorityQueueUsers: this.state.priorityQueueUsers.filter(x => x.email !== user.email)
    });
    
  }

  addUserToPriorityQueue = (user) => {

    this.setState({
      priorityChanges : true,
      priorityQueueUsers: [...this.state.priorityQueueUsers, user]
    });
  }

  setAsNextPriority = (user) => {
    this.setState({
      priorityChanges : true,
      nextPriorityUserEmail: user ? user.email : null
    });
  }

  getFilteredListOfAddUsers = () => {
    const {
      companyUsers
    } = this.props;
    const {
      priorityQueueUsers
    } = this.state;
    var priorityQueueUserEmails = priorityQueueUsers.map(x => x.email);

    return companyUsers.filter(x => !priorityQueueUserEmails.includes(x.email));
  }

  processSubmission = (values) => {
    const {
      proceedSubmit,
    } = this.props;
    const {
      priorityQueueUsers,
      nextPriorityUserEmail
    } = this.state;
    
    proceedSubmit({
      ...values,
      priorityQueue: priorityQueueUsers.map(x => x.email),
      nextPriorityUserEmail: nextPriorityUserEmail
    });
  }


  render = () => {
    const {
      handleSubmit,
      pristine,
      submitting,
      visibleDays,
      visibleDaytime,
      maximumParksPerUser,
      includeWeekend,
      initialValues
    } = this.props;

    const {
      visibleDaysCollapsed,
      visibleDaytimeCollapsed,
      maximumParksPerUserCollapsed,
      priorityParkingQueueCollapsed,
      priorityQueueUsers,
      nextPriorityUserEmail,
      showAddUserDialog,
      priorityChanges
    } = this.state;

    const hasChange = priorityChanges || !pristine;
    const disableSubmit = submitting || !hasChange;
    const submitButton = <Button variant="contained" color="primary" type="submit" size="large" disabled={disableSubmit}>{TranslatorUtil.t("Save")}</Button>;
    const priorityQueueFeature = CompanyUtil.hasFeatureAllowed(initialValues, extraFeatures.PRIORITY_QUEUE);
    const basicFairUseFeature = CompanyUtil.hasFeatureAllowed(initialValues, extraFeatures.BASIC_FAIR_USE);

    return (
      <Box pb={2} pt={1}>
        <form onSubmit={handleSubmit(values => this.processSubmission(values))}>

          <div className="maxWidthPart">
            <Grid container spacing={2}>


            {basicFairUseFeature ? null : <Grid item xs={12}><FeatureNotEnabled /></Grid>}
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>

                  <Grid item xs={12}>
                  
                    <Typography variant="body1">
                      Visible days users can see ahead <IconButton size="small" color={!visibleDaysCollapsed ? "primary" : "default"} onClick={() => this.setState({ visibleDaysCollapsed: !this.state.visibleDaysCollapsed })}><InfoIcon /></IconButton>
                    </Typography>
                    <Collapse in={visibleDaysCollapsed} timeout="auto" unmountOnExit>
                      <Card>
                        <CardContent>
                          <Typography color="secondary" variant="caption" component="div">
                            Visible days
                      </Typography>
                          <Typography color="default" variant="caption">
                          Set a limit on how many days ahead users can view or book parks.
                      </Typography>
                        </CardContent>
                      </Card>
                    </Collapse>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="visibleDays" disabled={!basicFairUseFeature} component={SelectInput} helperText={!visibleDays ? "Default is "+constants.DEFAULT_VISIBLE_DAYS_AHEAD : null}
                      >
                        {
                          ArrayUtil.numberRange(2, 31).map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                  
                    <Typography variant="body1">
                    Show all new days for booking at <IconButton size="small" color={!visibleDaytimeCollapsed ? "primary" : "default"} onClick={() => this.setState({ visibleDaytimeCollapsed: !this.state.visibleDaytimeCollapsed })}><InfoIcon /></IconButton>
                    </Typography>
                    <Collapse in={visibleDaytimeCollapsed} timeout="auto" unmountOnExit>
                      <Card>
                        <CardContent>
                          <Typography color="secondary" variant="caption" component="div">
                          Show all new days
                      </Typography>
                          <Typography color="default" variant="caption">
                          Control what time new days are open to users. When a date in the future moves in to the users visible range, it will be shown and made available for booking at the selected time.
                      </Typography>
                        </CardContent>
                      </Card>
                    </Collapse>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="visibleDaytime" disabled={!basicFairUseFeature} component={SelectInput} helperText={!visibleDaytime ? "Default is "+constants.DEFAULT_VISIBLE_DAYTIME_AHEAD : null}
                      >
                        {
                          ArrayUtil.numberRange(0, 23).map(x => <MenuItem key={x} value={x}>{x < 10 ? '0'+x : x}:00</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}><br /></Grid>


                  <Grid item xs={12}>

                    <Typography variant="body1">
                      Maximum parks per user per week<IconButton size="small" color={!maximumParksPerUserCollapsed ? "primary" : "default"} onClick={() => this.setState({ maximumParksPerUserCollapsed: !this.state.maximumParksPerUserCollapsed })}><InfoIcon /></IconButton>
                    </Typography>
                    <Collapse in={maximumParksPerUserCollapsed} timeout="auto" unmountOnExit>
                      <Card>
                        <CardContent>
                          <Typography color="secondary" variant="caption" component="div">
                            Maximum parks per user
                      </Typography>
                          <Typography variant="caption">
                          Set a limit on how many parks a single user can book, to allow parks to be more evenly shared. This is only applied for users that do not own a park. This is not applied to managing parks when done by a manager / admin / repeating roster of your company.
                      </Typography>
                        </CardContent>
                      </Card>
                    </Collapse>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="maximumParksPerUser" disabled={!basicFairUseFeature} component={SelectInput} helperText={!maximumParksPerUser ? "Default is "+includeWeekend ? constants.DEFAULT_PARKS_PER_USER_PER_PERIOD_INCLUDE_WEEKEND : constants.DEFAULT_PARKS_PER_USER_PER_PERIOD : null}
                      >
                        {
                          ArrayUtil.numberRange(1, 8).map(x => <MenuItem key={x} value={x} disabled={x > 5 && !includeWeekend ? true : false}>{x}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>





                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box pt={2} display="flex" flexDirection="row-reverse">
                  {submitButton}
                </Box>
              </Grid>








            </Grid>

          </div>



        </form>
        <Box mt={5} pt={4}>
          <Grid container>
            <Grid item xs={12}>

              <Typography variant="h5">
                Priority parking queue <IconButton size="small" color={!priorityParkingQueueCollapsed ? "primary" : "default"} onClick={() => this.setState({ priorityParkingQueueCollapsed: !this.state.priorityParkingQueueCollapsed })}><InfoIcon /></IconButton>
              </Typography>
              {priorityQueueFeature ? null : <FeatureNotEnabled />}
              <Collapse in={priorityParkingQueueCollapsed} timeout="auto" unmountOnExit>
                <Box mb={2}>
                  <Card>
                    <CardContent>
                      <Typography color="secondary" variant="caption" component="div">
                        Priority parking queue
  </Typography>
                      <Typography color="default" variant="caption">
                      Add, remove or reorder users in the list below. The user marked as "next" will be notified when a park is made available. That user will have 10 minutes to book the park, if they don't book the park, a notification will be sent to all remaining users without a park on that day. The next park to then be made available will be offered to the user that comes after "next" on the list and so forth.
                        <br /><br />Conditions that are checked at the time of notification<ul>
                          <li>User does not have a park on the day for which the park has been unlocked</li>
                          <li>User has activated their account by logging into the app at least once</li>
                          <li>User has app notifications allowed</li>
                          {/* <li>User has not exceeded maximum allowed parks per week</li> */}
                          <li class="text-danger">If any of these conditions are not met, the next user in the list meeting these conditions will be notified first.</li>
                        </ul>
  </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              {!CompanyUtil.hasValidNextUserSet(priorityQueueUsers, nextPriorityUserEmail) ? <Alert severity="warning">Please make sure to set next priority user in order to use priority queue feature.</Alert> : null}
              <Box py={2}>
                <Button disabled={!priorityQueueFeature} color="primary" onClick={() => !priorityQueueFeature ? null : this.setState({ showAddUserDialog: true })} startIcon={<AddIcon />}>{TranslatorUtil.t("Add a user to the list")}</Button>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box mt={3}>
                <DragDropContext
                  onDragEnd={this.onDragEnd}
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <Card
                        style={{ minHeight: 60 }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {priorityQueueFeature ? priorityQueueUsers.map((item, index) => (
                          <Draggable draggableId={item.email} index={index} key={item.email}>
                            {(provided, snapshot) => (
                              <Card
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Box p={1}>
                                  <Grid container>
                                    <Grid item xs={9} md={10}>
                                      <Box p={1}>{
                                        CompanyUtil.isNextPriorityUser(nextPriorityUserEmail, item) ?
                                          <Badge badgeContent={<Box py={1}><FlagIcon className="badgeIcon" size="small" /> Next {UserUtil.isTempUserInvitationOnly(item) ? <small>(Invitation)</small> : null}</Box>} color="primary">
                                            {item.email}
                                          </Badge> : UserUtil.isTempUserInvitationOnly(item) ? <Badge className="badge-warning" badgeContent={<Box py={1}><ContactMailIcon className="badgeIcon" size="small" /> Invitation</Box>}>
                                            {item.email}
                                          </Badge> : item.email
                                      }</Box>
                                    </Grid>
                                    <Grid item xs={3} md={2} className="text-right">
                                      {!CompanyUtil.isNextPriorityUser(nextPriorityUserEmail, item) ?
                                        <Tooltip title="Set as next"><Button color="primary" onClick={() => this.setAsNextPriority(item)} startIcon={<ArrowForwardIcon />} endIcon={<FlagIcon />}><span className="sr-only">Set as next</span></Button></Tooltip>
                                        : <Tooltip title="Set as not next"><Button className="text-danger" onClick={() => this.setAsNextPriority(null)} startIcon={<CloseIcon />} endIcon={<FlagIcon />}><span className="sr-only">Remove as next</span></Button></Tooltip>
                                        } &nbsp; <IconButton onClick={() => this.removeFromQueue(item)}><DeleteIcon className="text-danger" size="small" /></IconButton>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Card>
                            )}
                          </Draggable>
                        )) : priorityQueueUsers.map((item, index) => (
                          <Card>
                                <Box p={1}>
                                  <Grid container>
                                    <Grid item xs={9} md={10}>
                                      <Box p={1}>{
                                        CompanyUtil.isNextPriorityUser(nextPriorityUserEmail, item) ?
                                          <Badge badgeContent={<Box py={1}><FlagIcon className="badgeIcon" size="small" /> Next {UserUtil.isTempUserInvitationOnly(item) ? <small>(Invitation)</small> : null}</Box>} color="primary">
                                            {item.email}
                                          </Badge> : UserUtil.isTempUserInvitationOnly(item) ? <Badge className="badge-warning" badgeContent={<Box py={1}><ContactMailIcon className="badgeIcon" size="small" /> Invitation</Box>}>
                                            {item.email}
                                          </Badge> : item.email
                                      }</Box>
                                    </Grid>
                                    
                                  </Grid>
                                </Box>
                              </Card>
                        ))}
                      </Card>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {showAddUserDialog ? <UserSelectDialog users={this.getFilteredListOfAddUsers()} userSelected={this.addUserToPriorityQueue} onClose={() => this.setState({ showAddUserDialog: false })} /> : null}
      </Box>
    );

  }
}



const selector = formValueSelector(formName); // select current form
// Decorate form with selected redux form state values
const mapStateToProps = state => {
  const maximumParksPerUser = selector(state, "maximumParksPerUser");
  const visibleDays = selector(state, "visibleDays");
  const visibleDaytime = selector(state, "visibleDaytime");
  const includeWeekend = selector(state, "includeWeekend");
  return {
    maximumParksPerUser,
    visibleDays,
    visibleDaytime,
    includeWeekend
  };
};

// Decorate form with dispatchable actions
const mapDispatchToProps = {
  change
};


// create a redux form, then include the above decorators for the created form to utilize
export default reduxForm({
  form: formName,
  validate,
  enableReinitialize: true
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanySettingForm)
);