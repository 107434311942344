class Util {


    static renderOptions(options, ids) {
        return options.reduce((res, option) => {
            if (ids.length < 1 || (ids.indexOf(option.id) !== -1)) {
                res.push(option.name);
            }
            return res;
        }, []);   
    }

    static isEmpty(variable){
        return ((typeof variable === "undefined") || (!variable) || (variable && variable.length < 1)) ? true : false;
    }

    static b64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,(match, p1) => {
            return String.fromCharCode(parseInt(p1, 16))
        }))
    }

    static parseInteger(input){
        return parseInt(input, 10);
    }


    static isSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));


    static isObjectsPropertiesEqual = (a,b) => JSON.stringify(a) === JSON.stringify(b);
    
    static doesArrayIncludesObject = (array, object) => {
        return Array.isArray(array) ? array.findIndex(x => Util.isObjectsPropertiesEqual(x,object)) : -1;
    }

    static sortNumbers = (a,b) => {
        return a - b;
    }

    static extractHostname = (url) => {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname
    
        if (url.indexOf("://") > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }
    
        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];
    
        return hostname;
    }

    static compareCaseInsensitive(str1,str2){
        return str1 && str2 && str1.toUpperCase() === str2.toUpperCase();
    }

}

export default Util;