
import constants from '../../constants/constants';
import objectPath from "object-path";
import moment from "moment";
import momentTz from "moment-timezone";
import timezones from '../../constants/timezones';
require("moment/locale/en-nz");
moment.updateLocale('en-NZ');



class DateUtil{
    
    static getMoment () {
        return moment;
    }

    static formatDateTimeSeconds (unixTime) {
        return moment(unixTime).format("DD/MM/YY, h:mm:ss a")
    }


    static createDateTimeInUtc(momentDateTime, format = "YYYY-MM-DD hh:mm:ss"){
        return moment.utc(momentDateTime.format(format));
    }

    static createStartOfDateInUtc(momentDate){
        return moment.utc(momentDate.format('YYYY')+'-'+momentDate.format('MM')+'-'+momentDate.format('DD')+' 00:00:00');
    }

    static createStartOfDateInUtcFromDMY(d,m,y){
        return moment.utc(y+'-'+m+'-'+d+' 00:00:00');
    }

    static createEndOfDateInUtc(momentDate){
        return moment.utc(momentDate.format('YYYY')+'-'+momentDate.format('MM')+'-'+momentDate.format('DD')+' 23:59:59');
    }

    static createEndOfDateInUtcFromDMY(d,m,y){
        return moment.utc(y+'-'+m+'-'+d+' 23:59:59');
    }

    static formatDateTimeDate(unixTime, itemTimeZone = null, format = "DD/MM/YY, hh:mm:ss"){
        if(itemTimeZone){
            return moment(unixTime).tz(itemTimeZone).format(format); 
        }
        return moment(unixTime).format(format);
    }

    static formatDateTime (unixTime) {

        return moment(unixTime).format("DD/MM/YY, h:mm a")
    }

    static formatDateTimeShort (unixTime) {

        return moment(unixTime).format("DD/MM/YY")
    }

    static isStartOfWeek (day){
        return day.date.format('d') === "1";//is monday
    }

    static isEvenWeek (day){
        return day.date.isoWeek() % 2 === 0;
    }

    static getDateWeekType(day){
        return DateUtil.isEvenWeek(day) ? constants.WEEK_TYPE_EVEN : constants.WEEK_TYPE_ODD;
    }

    static isForCurrentWeekByWeekType(selectedDate, weekType){
        return DateUtil.getDateWeekType(selectedDate) === weekType;
    }

    static getIsoDayAsNumber(selectedDate){
        return parseInt(selectedDate.date.isoWeekday());
    }

    static isCurrentDayByIsoDay(selectedDate, dayIso){
        return  DateUtil.getIsoDayAsNumber(selectedDate) === dayIso;
    }

    static addDaysToTodayStart (numberOfDays, timezone = null,serverTimeOffset = 0) {
        if(timezone){
            return (moment(DateUtil.getServerTime(serverTimeOffset)).tz(timezone).startOf('day').add(numberOfDays, "days"));
        }
        return (moment(DateUtil.getServerTime(serverTimeOffset)).startOf('day').add(numberOfDays, "days"));
    }

    static getDateObjectFromParkState (parkState) {
        var day = moment(parkState.date, DateUtil.dbFormatString());
        return {
            day: day.format('ddd'),
            date: day
        };
    }

    static getDateObjectFromDate (date) {
        var day = moment(date, DateUtil.dbFormatString());
        return {
            day: DateUtil.dbFormatDay(day),
            month: DateUtil.dbFormatMonth(day),
            year: DateUtil.dbFormatYear(day),
            date: day
        };
    }

    static isFuture (selectedDate) {
        const disallowedFuture = (moment().endOf('day'));
        return (disallowedFuture <= selectedDate) ? true : false;
    }

    static timeConverter (UNIX_timestamp, offset) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        min = min < 10 ? "0" + min : min;
        //var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
        //LogUtil.devOnly(offset);
        return time;
    }

    static isAvailableDay (d, includeWeekend = false) {
        //LogUtil.devOnly('isAvailableDay', !constants.DISABLED_DAYS.includes(d.format('dddd')));
        return includeWeekend || (includeWeekend === false && !constants.DISABLED_DAYS.includes(d.format('dddd'))) ? true : false;
    }

    static isTomorrowCheckerAvailableDay (d) {
        return !constants.TOMORROW_CHECKER_DISABLED_DAYS.includes(d.format('dddd')) ? true : false;
    }

    static isEveningOrNight (d) {
        return [17,18,19,20,21,22].includes(parseInt(d.format('H'))) ? true : false;
    }

    static getStartUnixFromDates(dates){
        if(Array.isArray(dates) && dates.length && dates[0] && moment.isMoment(dates[0].date)){
            return dates[0].date.startOf('day').valueOf();
        }
        return null;
    }

    static getEndUnixFromDates(dates){
        if(Array.isArray(dates) && dates.length > 0 && moment.isMoment(dates[dates.length-1].date)){
            return dates[dates.length-1].date.endOf('day').valueOf();
        }
        return null;
    }

    static getTwoWeeks (date, fromStartOfTheWeek = true, includeWeekend = false) {
        var selectedDate = date ? date : moment();
        var startDay = fromStartOfTheWeek ? selectedDate.clone().startOf('week') : selectedDate.clone();
        if (!DateUtil.isAvailableDay(startDay,includeWeekend)) {
            startDay = startDay.clone().startOf('week').add(1, 'week');
        }
        
        var days = [];
        for (var i = 0; i <= 13; i++) {
            var day = moment(startDay).add(i, 'days');
            if (DateUtil.isAvailableDay(day,includeWeekend)) {
                days.push({
                    id: DateUtil.getDayIdByIndex(i),
                    day: day.format('ddd'),
                    date: day
                });
            }
        }
        // console.log('days',days, startDay);
        return days;
    }

    static getDayIdByIndex(index){
        return (index < 10) ? "0" + index : String(index);
    }
    
    static getNextWeekDayIdById(id){
        return DateUtil.getDayIdByIndex((parseInt(id) || 0) + 7);
    }

    static ifDisabledDayGetNextAvailableOne (date, includeWeekend = false){
        return DateUtil.isAvailableDay(date) ? date : date.clone().startOf('week').add(1, 'week');
    }

    static getDaysBetweenDates (startDate, endDate, includeWeekend = false) {
        var startDay = startDate ? startDate.clone() : moment();
        startDay = DateUtil.ifDisabledDayGetNextAvailableOne(startDate, includeWeekend);
        var diffDays = endDate ? Math.abs(startDate.diff(endDate,'days')) : startDate.clone();
        var days = [];
        for (var i = 0; i <= diffDays; i++) {
            var day = moment(startDay).add(i, 'days');
            if (DateUtil.isAvailableDay(day, includeWeekend)) {
                var dayId = (i < 10) ? "0" + i : String(i);
                days.push({
                    id: dayId,
                    day: day.format('ddd'),
                    date: day
                });
            }
        }
        return days;
    }


    static getDaysOfWeeks (date, weeksNumber = 1, fromStartOfTheWeek = true, includeWeekend = false) {
        var selectedDate = date ? date : moment();
        var startDay = fromStartOfTheWeek ? selectedDate.clone().startOf('week') : selectedDate.clone();
        if (!DateUtil.isAvailableDay(startDay, includeWeekend)) {
            startDay = startDay.clone().startOf('week').add(1, 'week');
        }
        var daysCount = weeksNumber * 7;
        var days = [];
        for (var i = 0; i < daysCount; i++) {
            var day = moment(startDay).add(i, 'days');
            if (DateUtil.isAvailableDay(day, includeWeekend)) {
                var dayId = (i < (daysCount - (daysCount % 5))) ? "0" + i : String(i);
                days.push({
                    id: dayId,
                    day: day.format('ddd'),
                    date: day
                });
            }
        }
        return days;
    }

    static getTwoWeeksAgo () {
        return moment().subtract(14, 'days');
    }

    static formatDayForCalendar (d) {
        return d.format('ddd DD');
    }

    static formatDayOfWeekOnly (d) {
        return d.format('ddd');
    }

    static formatDayWithMonthForCalendar (d) {
        return d.format('ddd DD MMM');
    }

    static getRangeFormat (d1, d2) {
        var result = d2.format('DD MMM YYYY');
        var yearDiff = DateUtil.dbFormatYear(d1) !== DateUtil.dbFormatYear(d2);
        var monthDiff = DateUtil.dbFormatMonth(d1) !== DateUtil.dbFormatMonth(d2);
        var dayDiff = DateUtil.dbFormatDay(d1) !== DateUtil.dbFormatDay(d2);

        if (yearDiff || monthDiff || dayDiff) {
            var format = 'DD' + (monthDiff || yearDiff ? ' MMM' : '') + (yearDiff ? ' YYYY' : '');
            result = d1.format(format) + " - " + result;
        }
        return result;
    }

    static getAvailableYears (allowFuture) {
        var years = [
            {
                id: moment().subtract(1, 'years').format('YYYY'),
                title: moment().subtract(1, 'years').format('YYYY')
            },
            {
                id: moment().format('YYYY'),
                title: moment().format('YYYY')
            }
        ];
        if (allowFuture) {
            years.push({
                id: moment().add(1, 'years').format('YYYY'),
                title: moment().add(1, 'years').format('YYYY')
            })
        }
        return years;
    }

    static getCurrentYear () {
        return moment().format('YYYY');
    }

    static getCurrentMonth () {
        return moment().format('MM');
    }

    static getMonthsList (allowFuture) {
        return moment.months().filter((x, index) => {
            return allowFuture || (!allowFuture && (index + 1) <= DateUtil.getCurrentMonth());
        }).map((x, index) => {
            return { id: ((index + 1) < 10) ? "0" + (index + 1) :  String(index + 1), title: x }
        });
    }

    static getAvailableWeeks () {
        var weeks = [];
        var startDate = moment().subtract(2, 'months').isoWeekday(0);
        var endDate = moment().add(2, 'months').isoWeekday(7);

        //LogUtil.devOnly('startDate',startDate.format('DD-MM-YYYY'));
        //LogUtil.devOnly('endDate',endDate.format('DD-MM-YYYY'));

        while (startDate.isBefore(endDate)) {
            let startDateWeek = startDate.isoWeekday('Monday').format('DD-MM-YYYY');
            let endDateWeek = startDate.isoWeekday('Sunday').format('DD-MM-YYYY');
            startDate.add(7, 'days');
            weeks.push({
                id: DateUtil.getWeekYearCode(startDate.isoWeekday('Monday')),
                title: startDateWeek + " - " + endDateWeek
            });
        }
        return weeks;
    }

    static getCurrentUnix () {
        return moment().valueOf();
    }

    static getYesterdayUnix () {
        return moment().subtract(24, 'hours').valueOf();
    }

    static getCurrentWeek () {
        return moment().format('WW');
    }


    static getCurrentWeekYearCode () {
        return DateUtil.getCurrentYear() + '_' + DateUtil.getCurrentWeek();
    }

    static getWeekYearCode (date) {
        return date.format('YYYY') + '_' + date.format('WW');
    }

    static dbFormat (date) {
        return date ? date.format('DD-MM-YYYY') : '';
    }

    static dbFormatDay (date) {
        return date.format('DD');
    }

    static dbFormatMonth (date) {
        return date.format('MM');
    }

    static dbFormatYear (date) {
        return date.format('YYYY');
    }

    static viewFormat (date) {
        return date.format('DD.MM. YYYY');
    }

    static viewFormatShort (date) {
        return date.format('DD/MM/YY');
    }

    static viewFormatString () {
        return 'dd DD.MM. YYYY';
    }

    static viewMainFormatString () {
        return 'ddd DD MMM YYYY';
    }

    static dbFormatString () {
        return 'DD-MM-YYYY';
    }

    static getDateFragmentFromFormattedDate(date,inputFormat,outputFormat){
        return moment(date,inputFormat).format(outputFormat);
    }

    static getURLdate (date = null, includeWeekend = false) {
        date = moment(date ? date : moment(), DateUtil.dbFormatString());
        //LogUtil.devOnly('getURLdate',DateUtil.dbFormat(datePointer));
        return DateUtil.isAvailableDay(date,includeWeekend) ? date : date.add(1, 'week').startOf('week');
    }

    static getFirstFutureMonday () {
        var date = moment(moment(), DateUtil.dbFormatString());
        return date.add(1, 'week').startOf('week');
    }

    static getFirstFutureMondayByVisibleDays (visibleDays) {
        var date = moment(moment(), DateUtil.dbFormatString());
        return date.add(visibleDays+1, 'days').startOf('week');
    }

    static getFirstFutureInvisibleDay (visibleDays) {
        var date = moment(moment(), DateUtil.dbFormatString());
        return date.add(visibleDays, 'days');
    }

    static getCurrentStartOfWeek(){
        return moment().startOf('week');
    }

    static getManagableFornightWeeksStarters () {
        let pointer = moment().subtract(2,'week').startOf('week');
        let availableMondays = [];
        let max = Math.floor(constants.MANAGER_VISIBLE_DAYS_AHEAD/7) + 2;
        for(var x=0;x<max;x++){
            availableMondays.push(pointer.clone().add(x+1,'week'));
        }
        return availableMondays;
    }

    

    static getDaysArray (year, month, includeWeekend = false) {
        var days = [];
        var monthDaysCount = moment(year + '-' + month, "YYYY-MM").daysInMonth();
        for (var i = 1; i <= monthDaysCount; i++) {
            var dayId = (i < 10) ? "0" + i : String(i);
            var dayDate = moment(dayId + '-' + month + '-' + year, DateUtil.dbFormatString());
            if (DateUtil.isAvailableDay(dayDate, includeWeekend)) {
                days.push(
                    {
                        id: dayId,
                        day: dayDate.format('ddd'),
                        date: dayDate
                    }
                )
            }
        }
        return days;
    }


    static getWeeksArray (year, month, includeWeekend = false) {
        var weeks = [];
        var days = DateUtil.getDaysArray(year, month, includeWeekend);
        days.forEach(d => {
            if (!weeks.includes(d.date.week())) {
                weeks.push(d.date.week());
            }
        })
        return weeks;
    }

    static getYearsFromDates (dates) {
        var years = [];
        dates.forEach((d) => {
            var year = DateUtil.dbFormatYear(d.date);
            if (!years.includes(year)) {
                years.push(year);
            }
        });
        return years;
    }

    static getMonthsFromDates (dates) {
        var months = [];
        dates.forEach((d) => {
            var month = DateUtil.dbFormatMonth(d.date);
            if (!months.includes(month)) {
                months.push(month);
            }
        });
        return months;
    }

    static getServerTime (offset = 0) {
        //var offset = StoreUtil.getValueByKey('serverOffsetTime');
        var currentTime = new Date().getTime();
        return offset ? currentTime + offset : currentTime;
    }

    static getServerTimeMoment (offset = 0) {
        //var offset = StoreUtil.getValueByKey('serverOffsetTime');
        var currentTime = new Date().getTime();
        return moment(offset ? currentTime + offset : currentTime);
    }


    static isSameDay(d1,d2){
        return moment(d1).startOf('day').valueOf() === moment(d2).startOf('day').valueOf();
    }

    static isSameDate(d1,d2){
        return d1.year() === d2.year() && d1.month() === d2.month() && d1.date() === d2.date();
    }

    static getMonthName(num){
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthNames[num];
    }

    static unixStartOfDay(day,month,year){
        var momentDate = moment(null);
        if(day && month && year){
            momentDate = moment([day,month,year].join('-'),DateUtil.dbFormatString());
        }
        return momentDate.startOf('day').valueOf();
    }

    static unixOfDay(day,month,year,hrsToSet = 12,minsToSet = 0, secToSet = 0, timezone = null){
        var momentDate = moment(null);
        if(day && month && year){
            momentDate = moment([day,month,year].join('-'),DateUtil.dbFormatString());
        }
        if(timezone){
            momentDate = momentDate.tz(timezone);
        }
        return momentDate.startOf('day').hours(hrsToSet)
        .minutes(minsToSet).seconds(secToSet).valueOf();
    }

    static momentStartOfDay(day,month,year){
        var momentDate = moment(null);
        if(day && month && year){
            momentDate = moment([day,month,year].join('-'),DateUtil.dbFormatString());
        }
        return momentDate.startOf('day');
    }

    static momentEndOfDay(day,month,year){
        var momentDate = moment(null);
        if(day && month && year){
            momentDate = moment([day,month,year].join('-'),DateUtil.dbFormatString());
        }
        return momentDate.endOf('day');
    }

    static getTimeByCompanyTimezone(company, date = null){
        let ctz = objectPath.get(company,'timezone',constants.DEFAULT_TIMEZONE);
        return date ? momentTz(date).tz(ctz) : momentTz().tz(ctz);
    }
    
    static isDayCheckerValidByTimeZone(company){
        let currentDateTimeByCompany = DateUtil.getTimeByCompanyTimezone(company);
        return DateUtil.isTomorrowCheckerAvailableDay(currentDateTimeByCompany) && DateUtil.isEveningOrNight(currentDateTimeByCompany);
    }

    static guessTimezone(){
        var guessed = moment.tz.guess();
        return timezones.includes(guessed) ? guessed : constants.DEFAULT_TIMEZONE;
    }

    static getCurrentMoment(){
        return moment();
    }

    static dbDateToMoment (date) {
        return moment(date ? date : moment().format(DateUtil.dbFormatString()), DateUtil.dbFormatString());
    }
}

export default DateUtil;

