



import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import MenuUtil from '../../utils/MenuUtil';
import { withRouter } from "react-router-dom";
import { Box } from '@material-ui/core';
import objectPath from 'object-path';
import { Alert } from '@material-ui/lab';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';

function AdminCompanyMenu(props) {



  return props.company ? <Hidden xsDown><Box className="company-menu-wrap"  pb={3} pt={1}>
    {props.company.disabled ? <Box pb={2}>
    <Alert severity={"error"}>
  {TranslatorUtil.t("Company is blocked at the moment!")}
</Alert>
</Box> : null}
    <ButtonGroup size="small" variant="text" color="primary">
  {
      MenuUtil.getSuperAdminCompanyMenu(props.company).map((item,index) => <Button key={index} 
      onClick={() => props.history.push(item.url)} 
      startIcon={item.icon} 
      className={[(item.url === objectPath.get(props,'location.pathname') ? "active" : ""),(item.additionalClasses ? item.additionalClasses : "")].join(" ")}>
      <Hidden mdDown><Typography color="inherit" variant='caption'>
        {item.title}
      </Typography></Hidden>
    </Button>)
  }
</ButtonGroup>

</Box></Hidden> : null;
}

export default withRouter(AdminCompanyMenu);
