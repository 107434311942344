import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreUtil from '../shared/utils/general/FirestoreUtil';
import FormatterUtil from "../shared/utils/general/FormatterUtil";
import constants from "../shared/constants/constants";


const collectionName = 'companies';



export function getCompanies() {
  return function(dispatch) {
    console.log('getCompanies');
    return firestoreApi.collection(collectionName).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_COMPANIES)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANIES))
      );
  };
}

export function getCompanyByCompanyId(companyId) {
  return function(dispatch) {
    console.log('getCompanyByCompanyId');
    return firestoreApi.collection(collectionName).where("companyId", "==", companyId).limit(1).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseFirstItemFromCollectionData(response), types.GET_COMPANY)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY))
      );
  };
}

export function getCompanyCheckByCompanyId(companyId) {
  return function(dispatch) {
    console.log('getCompanyByCompanyId');
    return firestoreApi.collection(collectionName).where("companyId", "==", companyId).limit(1).get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseFirstItemFromCollectionData(response), types.GET_COMPANY_CHECK)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_CHECK))
      );
  };
}

  export function getCompany(id) {
    return function(dispatch) {
      console.log('getCompany');
      return firestoreApi.collection(collectionName).doc(id).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionItemData(response), types.GET_COMPANY)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY))
        );
    };
  }

  export function getCurrentUserCompanyByCompanyId(companyId) {
    return function(dispatch) {
      console.log('getCurrentUserCompanyByCompanyId');
      return firestoreApi.collection(collectionName).where("companyId", "==", companyId).limit(1).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseFirstItemFromCollectionData(response), types.GET_USER_COMPANY)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_USER_COMPANY))
        );
    };
  }

  export function getFirestoreCompanyByCompanyId(companyId) {
      console.log('getFirestoreCompanyByCompanyId');
      return firestoreApi.collection(collectionName).where("companyId", "==", companyId).limit(1).get()
        .then(
          response => FirestoreUtil.parseFirstItemFromCollectionData(response),
          error => error
        );
  }


  export function putCompany(data) {
    return function(dispatch) {
      console.log('putCompany');
      return firestoreApi.collection(collectionName).doc(data.id).set(FirestoreUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_PROFILE_COMPANY)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_PROFILE_COMPANY))
        );
    };
  }

  export function updateCompanyFairUse(data) {
    return function(dispatch) {
      console.log('updateCompanyFairUse');
      return firestoreApi.collection(collectionName).doc(data.id)
      .update({
        nextPriorityUserEmail: data.nextPriorityUserEmail,
        priorityQueue: data.priorityQueue,
        visibleDays: !FormatterUtil.isEmpty(data.visibleDays) ? data.visibleDays : constants.DEFAULT_VISIBLE_DAYS_AHEAD,
        visibleDaytime: !FormatterUtil.isEmpty(data.visibleDaytime) ? data.visibleDaytime : constants.DEFAULT_VISIBLE_DAYTIME_AHEAD,
        maximumParksPerUser: !FormatterUtil.isEmpty(data.maximumParksPerUser) ? data.maximumParksPerUser :  constants.DEFAULT_PARKS_PER_USER_PER_PERIOD
      })
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_PROFILE_COMPANY)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_PROFILE_COMPANY))
        );
    };
  }

  export function updateCompanyMap(companyId,mapPath) {
    return function(dispatch) {
      console.log('updateCompanyMap',companyId,mapPath);
      return firestoreApi.collection(collectionName).doc(companyId)
      .update({
        mapPath: mapPath
      })
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_PROFILE_COMPANY)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_PROFILE_COMPANY))
        );
    };
  }

  export function postCompany(data) {
    return function(dispatch) {
      console.log('putCompany');
      return firestoreApi.collection(collectionName).add(FirestoreUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_COMPANY)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_COMPANY))
        );
    };
  }

  export function putAdminCompany(data) {
    return function(dispatch) {
      console.log('putAdminCompany');
      return firestoreApi.collection(collectionName).doc(data.id).set(FirestoreUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_COMPANY)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_COMPANY))
        );
    };
  }

 

