import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as parkStateActions from '../../actions/parkStates';
import * as bookingSelectionActions from '../../actions/bookingSelection';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ParkStatus from './ParkStatus';
import { Button, Tooltip, Typography } from '@material-ui/core';
import LinkRef from '../LinkRef';
import objectPath from 'object-path';
import ParkSelectionUtil from '../../utils/ParkSelectionUtil';
import DateUtil from '../../shared/utils/general/DateUtil';
import UserUtil from '../../shared/utils/UserUtil';
import urls from '../../shared/constants/urls';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';
import ServerTimeUtil from '../../utils/ServerTimeUtil';
import CompanyUtil from '../../shared/utils/CompanyUtil';
import constants from '../../shared/constants/constants';

class ParkingWeeksTable extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      statusesLoading: false,
      parkStatesListener: null,
      lastRenderTime: 0,
    }   
  }

  componentDidMount() {
    this.listenParkStates();
    this.rerenderInterval = setInterval(() => {
      this.setState({ lastRenderTime: Date.now().valueOf() });
    }, constants.RERENDER_CALENDARS_INTERVAL); //force rerender on every minute
  }

  componentWillUnmount(){
    this.unsubscribeListeners();
    clearInterval(this.rerenderInterval);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var day = objectPath.get(this,'props.day',null);
    var prevDay = objectPath.get(prevProps,'day',null);
    if (day && prevDay && day !== prevDay) {
      //this.unsubscribeListeners();
      this.listenParkStates();
    }
  }

  unsubscribeListeners = () => {
     
  }

  listenParkStates = () => {
    const { userInfo, days, actions, companyId } = this.props;
    this.setState({dataLoading:true});
    actions.listenParksStatesByCompanyIdAndDates(
      UserUtil.isSuperAdminUser(userInfo) && companyId ? companyId : 
      UserUtil.getCompanyId(userInfo),
      days,
      () => {this.setState({dataLoading:false})}
    );
  }


  renderParkButton = (p,d) => {
    const { dataLoading } = this.state;
    const { userInfo, highlightMe, appAdminView } = this.props;
    return <ParkStatus 
    disableActions={false}
    appAdminView={appAdminView}
    highlightMe={highlightMe}
    park={p} 
    userInfo={userInfo}
    selectedDate={d} 
    loading={dataLoading}
  /> 
  }

  selectAllDays = (companyPark) => {
      const { days, actions, bookingSelection, userInfo } = this.props;
      var serverTimeOffset = ServerTimeUtil.getServerTimeOffset();
      var companyTimezone = ServerTimeUtil.getUserCompanyTimezone();
      days.forEach(d => {
        if(!UserUtil.isDisallowedFuture(d.date, userInfo, companyTimezone, serverTimeOffset) 
        &&
       !CompanyUtil.isCompanyInvisiblePastDate(d.date, UserUtil.getCompany(userInfo),serverTimeOffset)){
          if(!ParkSelectionUtil.isSelected(bookingSelection, companyPark, d)){
            actions.addBookingSelection(companyPark, d);
          }
        }
      });
      

  }

  getParkButton = (companyPark) => {
    const { bookingSelectionEnabled,bookingSelection, appAdminView } = this.props;

    if(appAdminView){
      return <Button variant="contained" color="primary" className="park-btn">
          {companyPark.parkId}
      </Button>
    }
    else 
    if(bookingSelectionEnabled){
      var isDisabled = !ParkSelectionUtil.isInSelectedRow(bookingSelection, companyPark);
      return <Button disabled={isDisabled} onClick={() => this.selectAllDays(companyPark)} variant="contained" color="primary" className="park-btn">
          {companyPark.parkId}
      </Button>
    }
    return <Button component={LinkRef} to={urls.PARK_URL('calendar',companyPark.id)} variant="contained" color="primary" className="park-btn">
          {companyPark.parkId}
      </Button> 
  }

  renderDayText = (day, isNormalUserDisallowedFuture) => {
    var dayText = <Typography variant='body2'>{DateUtil.formatDayForCalendar(day.date)}</Typography>;
    return <React.Fragment>
        <Typography variant='caption' className={DateUtil.isStartOfWeek(day) ? 'text-dark' : 'hidden-text'}>{
         DateUtil.isEvenWeek(day) ? "Even week" : "Odd week"
        }
        
        </Typography>
        {isNormalUserDisallowedFuture ? <Tooltip title={TranslatorUtil.t("Currently visible only by manager or admin user role.")}>{dayText}</Tooltip> : dayText}
        {/* <Typography variant="caption" className='text-muted'>{DateUtil.dbFormatDay(day.date)}</Typography> */}
        </React.Fragment>;
  }

  
  render() {
    const { lastRenderTime } = this.state;
    const { days, companyParks, highlightMe, userInfo } = this.props;


    return <TableContainer className={"week-view-table-wrap "+(highlightMe ? " highlight-me-enabled" : "")+" lastRender-"+(lastRenderTime)}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
            <TableCell></TableCell>
            {
                days.map((day,index) => {
                  let isNormalUserDisallowedFuture = UserUtil.isNormalUserDisallowedFuture(day.date, userInfo, ServerTimeUtil.getUserCompanyTimezone(), ServerTimeUtil.getServerTimeOffset());
                  return <React.Fragment key={index}>
                      {index === UserUtil.getCompanyDelimiterDayIndex(userInfo) ? <TableCell className="week-divider-cell"></TableCell> : null}
                      <TableCell
                      className={isNormalUserDisallowedFuture ? "normal-user-disallowed-future" : ""}
                      >
                        {this.renderDayText(day, isNormalUserDisallowedFuture)}</TableCell>
                  </React.Fragment>
                })
            }
        </TableRow>
      </TableHead>
      <TableBody>
        {companyParks.map((companyPark,companyParkIndex) => (
          <TableRow key={companyParkIndex}>
            <TableCell component="th" align="left">
              {this.getParkButton(companyPark)}
                
            </TableCell>
            {
                days.map((day,index) =>  index !== UserUtil.getCompanyDelimiterDayIndex(userInfo) ? <TableCell className="table-btn-group-cell" key={index}>{this.renderParkButton(companyPark,day)}</TableCell> : <React.Fragment  key={index}>
                <TableCell className="week-divider-cell"></TableCell>
                <TableCell className="table-btn-group-cell">{this.renderParkButton(companyPark,day)}</TableCell>
            </React.Fragment>)
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    companyParks: state.companyParks,
    companyProfiles: state.companyProfiles,
    parkStates: state.parkStates,
    parkOwnerships: state.parkOwnerships,
    bookingSelectionEnabled: state.bookingSelectionEnabled,
    bookingSelection: state.bookingSelection
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...parkStateActions,
        ...bookingSelectionActions
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkingWeeksTable));
