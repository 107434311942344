import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AppBar, Toolbar, IconButton, Button, Box, Hidden, Typography, Divider } from '@material-ui/core';
import Logo from "./Logo";

import * as firebaseUserActions from '../actions/firebaseUser';

import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CarIcon from '@material-ui/icons/DirectionsCar';
import PeopleIcon from '@material-ui/icons/People';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import ListIcon from '@material-ui/icons/List';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MenuUtil from '../utils/MenuUtil';
import SuperAdminUtil from '../utils/SuperAdminUtil';
import LocalStorageUtil from '../utils/LocalStorageUtil';

import urls from '../shared/constants/urls';
import CompanyUtil from '../shared/utils/CompanyUtil';
import UserUtil from '../shared/utils/UserUtil';
import TranslatorUtil from '../shared/utils/general/TranslatorUtil';
import { Group } from '@material-ui/icons';
// import { AttachMoney } from '@material-ui/icons';




class MainAppBar extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      userMenuAnchorEl: null,
      menuAnchorEl: null
    }
  }


  logoutUser = () => {
    const { actions } = this.props;
    actions.logoutUser().then(() => window.location.href = "/");
  }

  handleRouterRedirect = (route) => {
    this.handleUserMenu(null);
    this.handleMenu(null);
    this.props.history.push(route);
  }

  handleMenu = event => {
    this.setState({
      menuAnchorEl: event ? event.currentTarget : null
    })
  };

  handleUserMenu = event => {
    this.setState({
      userMenuAnchorEl: event ? event.currentTarget : null
    })
  };

  superAdminMenu = () => {
    const { company } = this.props;

    var menu = [];

    if(company){
      menu.push({
        title: CompanyUtil.getDisplayName(company),
        icon: <BusinessIcon />,
        url: urls.ADMIN_COMPANY_URL(company.id),
      });
      
      menu = menu.concat(MenuUtil.getSuperAdminCompanyMenu(company).map(item => {
        item.additionalClasses = "hidden-md-up";
        return item;
      }));
    }
    menu.push({
      title: TranslatorUtil.t("Companies"),
        icon: <ListIcon />,
        url: urls.ADMIN_COMPANIES_URL,
        additionalClasses: company ? "back-to-list" : ""
    });

    menu.push({
      title: TranslatorUtil.t("CRM"),
        icon: <Group />,
        url: urls.ADMIN_CRM_URL,
        additionalClasses: company ? "back-to-list" : ""
    });

    // menu.push({
    //   title: TranslatorUtil.t("Billing"),
    //     icon: <AttachMoney />,
    //     url: urls.ADMIN_TOTAL_BILLING_URL,
    //     additionalClasses: company ? "back-to-list" : ""
    // });

    

    return menu;
  }

  appMenu = () => {
    const { userInfo } = this.props;
    var menu = [
      {
        title: TranslatorUtil.t("Parking"),
        icon: <CarIcon />,
        url: urls.INDEX_URL,
      }
    ];
    if(UserUtil.isAtLeastCompanyManager(userInfo)){
      menu.push({
        title: TranslatorUtil.t("Insights"),
        icon: <EqualizerIcon />,
        url: urls.COMPANY_INSIGHTS_URL,
      });
    }
    if(UserUtil.isAtLeastCompanyManager(userInfo)){
      menu.push({
        title: TranslatorUtil.t("Users"),
        icon: <PeopleIcon />,
        url: urls.COMPANY_USERS_URL,
      });
    }
    
    // if(UserUtil.isCompanyAdmin(userInfo)){
    //   menu.push({
    //     title: TranslatorUtil.t("Billing"),
    //     icon: <AttachMoneyIcon />,
    //     url: urls.COMPANY_BILLING_URL,
    //   });
    // }
    if(UserUtil.isCompanyAdmin(userInfo)){
      menu.push({
        title: TranslatorUtil.t("Settings"),
        icon: <SettingsIcon />,
        url: urls.COMPANY_SETTING_URL,
      });
    }

    return menu;
  }

  userMenu = () => {
    return [
      {
        icon: <HelpIcon  />,
        title: TranslatorUtil.t("How it works"),
        url: urls.HOW_IT_WORKS_URL,
      }
    ]
  }

  renderUserMenu = () => {
    
    return this.userMenu().map((item,index) => <MenuItem key={index} onClick={() => item.url ? this.handleRouterRedirect(item.url) : null} disabled={!item.url}>
        {item.icon ? item.icon : null}
        {item.title}
    </MenuItem>);
  }

  renderMainMenu = () => {
    const { userInfo } = this.props;
    const menuItems = SuperAdminUtil.isSuperAdmin(userInfo) ? this.superAdminMenu() : this.appMenu();
    return menuItems.map((item,index) => <Button key={index} onClick={() => this.handleRouterRedirect(item.url)} color="default" variant="text" startIcon={item.icon} className={item.additionalClasses ? item.additionalClasses : ""}>
      <Hidden smDown><Typography color="inherit">
        {item.title}
      </Typography></Hidden>
    </Button>);
  }

  renderMobileMainMenu = () => {
    const { userInfo } = this.props;
    const menuItems = SuperAdminUtil.isSuperAdmin(userInfo) ? this.superAdminMenu() : this.appMenu();
    return menuItems.map((item,index) => <MenuItem key={index} onClick={() => this.handleRouterRedirect(item.url)}>
        {item.icon ? item.icon : null}
        {item.title}
    </MenuItem>);
  }

  switchInterface = () => {
    if(LocalStorageUtil.getTyped('admin') === 1){
      LocalStorageUtil.set('admin',0);
    }else{
      LocalStorageUtil.set('admin',1);
    }
    document.location.href = urls.INDEX_URL;
  }




  render() {
    const { userMenuAnchorEl, menuAnchorEl } = this.state;
    const { userInfo } = this.props;


    return <AppBar position="static" color="default" className="app-bar">

      <Toolbar>
        <Box className="left-part">
          
            <Logo className="logo" />
            <Hidden xsDown>
          <Typography variant="h5" className="main-title" >
            {SuperAdminUtil.isSuperAdmin(userInfo) ? TranslatorUtil.t("Admin")
            : 
            UserUtil.getCompanyDisplayName(userInfo)}
          </Typography>
          
          

          
          </Hidden>
          
        </Box>
        <Box className="right-part">
          <Hidden smDown>
            {this.renderMainMenu()}
          </Hidden>

          

          <Button onClick={this.handleUserMenu}
            className="user-menu-btn"
            aria-controls="usermenu-appbar"
            aria-haspopup="true"
            color="default" variant="text" startIcon={<AccountCircleOutlinedIcon />}>
              <Hidden xsDown>
            <Typography color="inherit">
              {UserUtil.getDisplayName(userInfo)}
            </Typography>
            </Hidden>
          </Button>

          <Menu
            id="usermenu-appbar"
            anchorEl={userMenuAnchorEl}
            keepMounted
            open={userMenuAnchorEl != null}
            onClose={() => this.handleUserMenu(null)}
            MenuListProps={{disablePadding:true}}
          >
            
            <MenuItem onClick={() => this.handleRouterRedirect(urls.PROFILE_URL)}><AccountCircleOutlinedIcon  />{UserUtil.getDisplayName(userInfo)}</MenuItem>
            {
              LocalStorageUtil.getTyped('admin') !== 1 ? <div>
                <MenuItem onClick={() => this.handleRouterRedirect(urls.COMPANY_SETTING_URL)} disabled={UserUtil.isCompanyAdmin(userInfo) ? false : true}><BusinessIcon  />{UserUtil.getCompanyDisplayName(userInfo)}</MenuItem>
                <Divider />
                {this.renderUserMenu()}
              </div>
               : null
            }
            <Divider />
            {
              UserUtil.isSuperAdminUser(userInfo) ? 
              LocalStorageUtil.getTyped('admin') === 1 ? 
              <MenuItem onClick={this.switchInterface}><HomeWorkIcon  />{TranslatorUtil.t('Company admin')}</MenuItem>
              :
              <MenuItem onClick={this.switchInterface}><SupervisedUserCircleIcon  />{TranslatorUtil.t('APP admin')}</MenuItem>
               : null
            }
            <Divider />
            <MenuItem onClick={this.logoutUser}>
            <ExitToAppRoundedIcon  />
              {TranslatorUtil.t("Logout")}
              </MenuItem>

          </Menu>

          <Hidden mdUp>
            <IconButton onClick={this.handleMenu}
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="default" variant="text">
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Menu
            id="menu-appbar"
            anchorEl={menuAnchorEl}
            keepMounted
            open={menuAnchorEl != null}
            onClose={() => this.handleMenu(null)}
            MenuListProps={{disablePadding:true}}
          >
            {this.renderMobileMainMenu()}
          </Menu>
          
        </Box>
      </Toolbar>
    </AppBar>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    company: state.company,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...firebaseUserActions,
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MainAppBar));
