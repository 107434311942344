

import React from 'react';
import { Button, FormControlLabel, Switch, TextField } from '@material-ui/core';
import firestoreApi from '../../../../firebase.js';
import objectPath from 'object-path';
import DateUtil from '../../../../shared/utils/general/DateUtil';

export default class MigrationDateUnix extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
          loading: false,

          validInputs: false,
          simulateResults: [],
          migrationTextResult: 'No result',
          simulate: true,


          migrationName: 'MigrationDateUnix',
          year: '2022',
          month: '',
          
        }   
      }

      revalidate = () => { 

        this.setState({
            validInputs : false
        })
        if(this.isValid()){
            this.setState({
                validInputs : true
            })
            return true;
        };
        return false;
      }


      isValid = () => { 
        const { year, month } = this.state;
        return String(year).length && String(month).length;
      }


  runMigration = () =>{
    const { simulate, year, month } = this.state;

    var batch = firestoreApi.batch();
    var readCounts = 0;
    var updateCounts = 0;
    var fixedCounts = 0;
    var updateLimit = 250;
    var simulateResults = [];
    
    
    if(this.isValid()){
        //modify migration if needed
        firestoreApi.collection('parkStates')
        .where("year", "==", String(year))
        .where("month", "==", String(month))
        .get().then(result => {
            if (result && result.docs) {
                result.docs.forEach(item => {
                    readCounts++;
                    var itemData = item.data();
                    if(!objectPath.has(itemData,'dateUnix') && updateCounts < updateLimit){
                        var newDateUnix = DateUtil.unixOfDay(itemData.day,itemData.month,itemData.year,12,10,1);

                        if(simulate){
                            simulateResults.push([item.id,itemData.day,itemData.month,itemData.year,'dateUnix:'+newDateUnix+" (check:"+DateUtil.formatDateTimeSeconds(newDateUnix)+")"].join(' | '));
                            this.setState({
                                simulateResults: simulateResults
                            });
                        } else {
                            var updateRef = firestoreApi.collection("parkStates").doc(item.id);
                            batch.update(updateRef, {"dateUnix": newDateUnix});
                        }
                        updateCounts++;
                    } 
                    if(objectPath.has(itemData,'dateUnix')) {
                        fixedCounts++;
                    }
                });
            }
            var migrationTextResult = ((simulate ? '(Simulation ON) ' :'') +' Results count : ' + readCounts + ' / already fixed count : ' + fixedCounts + ' / updates count : ' + updateCounts + ' (limit : '+updateLimit+')');

            this.setState({
                migrationTextResult: migrationTextResult,
                simulateResults: simulateResults
            });

            if(simulate){
                return Promise.resolve();
            } else {
                return batch.commit().then((batchResult) => {
                    console.log(migrationTextResult);
                    return migrationTextResult;
                });
            }
            
        });
    }
    
  }


  render() {
    const { loading, validInputs, simulate, migrationTextResult, migrationName, simulateResults } = this.state;
    return <div className="innerWrap">
        <h2>{ migrationName }</h2>
        <p>Get all park states by date filter and add dateUnix information to the ones which are missing this property.</p>
        <div>
            <div>
                <TextField value={this.state.year} label="Year" variant="outlined" disabled={loading} onChange={evt => {this.setState({ year: evt.target.value });this.revalidate();} } />
                <TextField value={this.state.month} label="Month" variant="outlined" disabled={loading} onChange={evt => {this.setState({ month: evt.target.value });this.revalidate();} } />
            </div>
            <div>
            <Button variant="contained" onClick={this.runMigration} color="primary" disabled={loading || !validInputs}>{loading ? "Processing" : "Run" }</Button> &nbsp;&nbsp;&nbsp;
                <FormControlLabel
                    control={<Switch checked={simulate} onChange={() => this.setState({ simulate: !simulate })} />}
                    label={simulate ? "Simulate" : "Running updates"}
                />
                
            </div>
            
        </div>

        <div>
            <div>{migrationTextResult}</div>
            <div>{simulateResults.map(x => <div>{x}<hr/></div>)}</div>
        </div>

    </div>
  }
}
