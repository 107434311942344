
import ValidationUtil from '../../../utils/ValidationUtil';

const validate = values => {
  let errors = {};
  const requiredFields = [
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);


  errors = ValidationUtil.checkArrayMaxLength(['fairUseUsers'],values,errors,3);

  return errors;
};

export default validate;
