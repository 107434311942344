import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


import objectPath from 'object-path';
import { ButtonGroup, Button, Box, Hidden, Grid, Menu, MenuItem } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LinkRef from '../LinkRef';
import CarIcon from '@material-ui/icons/DirectionsCar';

import EventNoteIcon from '@material-ui/icons/EventNote';
import EditIcon from '@material-ui/icons/Edit';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import urls from '../../shared/constants/urls';
import UserUtil from '../../shared/utils/UserUtil';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';


class ParkMenu extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
          parksMenuAnchorEl: null
        }   
      }

      componentDidUpdate(prevProps, prevState, snapshot) {
    
      }

      handleParksMenu = event => {
        this.setState({
          parksMenuAnchorEl: event ? event.currentTarget : null
        })
      };

      getNavigationPark = (next) => {
        const { companyParks, park } = this.props;
        var parkToLinkTo = null;
        if(park && this.enoughParks()){
          companyParks.forEach((element,index) => {
            if(parkToLinkTo === null && element.id === park.id){
              if(next){
                parkToLinkTo = typeof companyParks[index+1] !== 'undefined' ? companyParks[index+1] : companyParks[0];
              }
              if(next === false){
                parkToLinkTo = typeof companyParks[index-1] !== 'undefined' ? companyParks[index-1] : companyParks[companyParks.length - 1];
              }
            }
          });
        }
        return parkToLinkTo;
      }
    

      getCurrentAction = () => {
        return objectPath.get(this,'props.match.params.action','view')
      }
    
      isCurrentAction = (action) => {
        return objectPath.get(this,'props.match.params.action','view') === action
      }
    
      getCurrentClass = (action) => {
        return this.isCurrentAction(action) ? "current" : "";
      }

      enoughParks = () => {
        const { companyParks } = this.props;
        return companyParks.length > 2;
      }

  render() {

    const navigationNext = this.getNavigationPark(true);
    const navigationPrev = this.getNavigationPark(false);

    const { parksMenuAnchorEl } = this.state;
    const { park, companyParks, setOwnerForPark, userInfo, setOwnershipDisabled } = this.props;

    return park ? <Grid container className="park-filter-wrap">
    <Grid item xs={12} sm={6}>
      <Box>
          <ButtonGroup variant="contained" color="primary">
          <Button className={this.getCurrentClass("calendar")} disabled={this.isCurrentAction("calendar")} component={LinkRef} to={urls.PARK_URL('calendar', objectPath.get(park,'id'))} startIcon={<EventNoteIcon />}><Hidden mdDown>{TranslatorUtil.t("Calendar")}</Hidden></Button>
          {UserUtil.isAtLeastCompanyManager(userInfo) ? <Button startIcon={<PersonPinOutlinedIcon />} disabled={setOwnershipDisabled} onClick={setOwnershipDisabled ? null : setOwnerForPark}><Hidden mdDown>{TranslatorUtil.t("Ownership")}</Hidden></Button> : null} 
          {UserUtil.isAtLeastCompanyManager(userInfo) ? <Button className={this.getCurrentClass("edit")} disabled={this.isCurrentAction("edit")} component={LinkRef} to={urls.PARK_URL('edit', objectPath.get(park,'id'))} startIcon={<EditIcon />}><Hidden mdDown>{TranslatorUtil.t("Edit")}</Hidden></Button> : null} 
          
        </ButtonGroup>
      </Box>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Hidden smUp><br/></Hidden>
    <Box textAlign="end">
        <ButtonGroup variant="contained" color="primary">
          <Button component={LinkRef} to={urls.INDEX_URL} startIcon={<CarIcon />}><Hidden mdDown>{TranslatorUtil.t("Parking")}</Hidden></Button>
          { this.enoughParks() ? <Button className="prev" color="default" disabled={navigationPrev !== null ? false : true} component={LinkRef} to={urls.PARK_URL(this.getCurrentAction(), objectPath.get(navigationPrev,'id'))}><ArrowBackIcon /><Hidden mdDown> {objectPath.get(navigationPrev,'parkId')}</Hidden></Button> : null}
          <Button className="parks-menu"aria-controls="parks-menu" onClick={this.handleParksMenu} color="default"><MoreHorizIcon /></Button>
          { this.enoughParks() ? <Button className="next" color="default" disabled={navigationNext !== null ? false : true} component={LinkRef} to={urls.PARK_URL(this.getCurrentAction(), objectPath.get(navigationNext,'id'))}><Hidden mdDown>{objectPath.get(navigationNext,'parkId')} </Hidden><ArrowForwardIcon /></Button> : null }
        </ButtonGroup>


        <Menu
          id="parks-menu"
          anchorEl={parksMenuAnchorEl}
          open={parksMenuAnchorEl !== null}
          onClose={() => this.handleParksMenu(null)}
          MenuListProps={{disablePadding:true}}
        >
          {
              companyParks.map((item,index) => <MenuItem disabled={item.id === park.id} key={index} component={LinkRef} to={urls.PARK_URL(this.getCurrentAction(), objectPath.get(item,'id'))}>{objectPath.get(item,'parkId')}</MenuItem>)
          }
        </Menu>
    </Box>
    </Grid>

  </Grid> : null
  }
}



function mapStateToProps(state) {
    return {
      userInfo: state.userInfo,
      park: state.park,
      companyParks: state.companyParks,
      parkRepeatingRosterSetting: state.parkRepeatingRosterSetting
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(
        {
        },
        dispatch
      )
    };
  }
  
  
  export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(ParkMenu));

