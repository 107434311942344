export default {
    "langCode": "en",
    "Dashboard" : "Dashboard",
    "Password": "Password",
    "Name": "Name",
    "Surname": "Surname",
    "Admin": "Admin",
    "Email": "Email",
    "Login": "Login",
    "Sign in": "Sign in",
    "Create account": "Create account",
    "Logout": "Logout",
    "Items" : "Items",
    "Email with password recovery link sent.": "Email with password recovery link sent.",
    "You are logged in.": "You are logged in.",
    "Reset Password": "Reset Password",
    "Please wait":"Please wait",
    "Add new item": "Add new item",
    "Show more" : "Show more",
    "No results": "No results",
    "SimpleItem": "Item",
    "Simple Item": "Item",
    "Simple Items": "Items",
    "Please confirm you wish to remove " : "Please confirm you wish to remove ",
    "Are you sure?": "Are you sure?",
    "Cancel": "Cancel",
    "New": "New",
    "Update": "Update",
    "Save": "Save",
    "Delete":"Delete",
    "Edit":"Edit",
};