import * as types from '../constants/actionTypes';


export function getInvitations(state = [], action) {
    if (action.type === types.GET_COMPANY_INVITATIONS) {
        console.log(types.GET_COMPANY_INVITATIONS+' set in store');
        return action.data;
    }
    return state;
}


export function getInvitation(state = null, action) {
    if (action.type === types.GET_INVITATION) {
        console.log(types.GET_INVITATION+' set in store');
        return action.data;
    }
    return state;
}


