
class PlanConstants{
    static FREE_PLAN = 1;
    static SMALL_BUSINESS_PLAN = 2;
    static ENTERPRISE_PLAN = 3;
    static PAY_AS_YOU_GO_PLAN = 4;

    static DEFAULT_PLAN = 1;

    static options = [
        { value: PlanConstants.FREE_PLAN, label: "FREE", parksLimit: 0, pricePerPark: 0 },
        { value: PlanConstants.SMALL_BUSINESS_PLAN, label: "SMALL BUSINESS", parksLimit: 15, pricePerPark: 3 },
        { value: PlanConstants.ENTERPRISE_PLAN, label: "ENTERPRISE", parksLimit: 30, pricePerPark: 2.5 },
        { value: PlanConstants.PAY_AS_YOU_GO_PLAN, label: "PAY AS YOU GO", parksLimit: 0, pricePerPark: 2 },
    ]
    
}


export default PlanConstants;