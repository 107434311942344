//this is super important because of circular dependency to go after export
import objectPath from "object-path";
import semver from "semver";
import constants from "../constants/constants";
import users from "../constants/users";
import ArrayUtil from "./general/ArrayUtil";
import DateUtil from "./general/DateUtil";
import Util from "./general/Util";
import ParkUtil from "./ParkUtil";
import CompanyUtil from "./CompanyUtil";

/** 
 * Class helper to have all user and profile related logic in one place 
 * this shared folder is also watched and copied to 
 * functions folder to be reused there
*/
class UserUtil {

  /**
   * Returns user info object that contains profile and company object 
   * and for logged in users may contain firebase auth object
   *
   * @param {profile} profile - object from firestore
   * @param {company} company - object from firestore
   * @param {firebaseAuth} firebaseAuth - firebase auth object that may be null if used in firebase functions
   * @return {string} 
   */
  static getUserInfoObject(profile,company,firebaseAuth = null, serverTime = null){
    return {
      "profile": profile,
      "company": company,
      "firebase": firebaseAuth ? UserUtil.getUserInfoFromFBObject(firebaseAuth) : null,
      "serverTime": serverTime ? serverTime : null
    }
  }


  /**
   * Returns display name
   *
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string} 
   */
  static getDisplayName(userInfo) {
    var nameFromEmail = UserUtil.getDisplayNameFromEmail(objectPath.get(userInfo, 'firebase.email', 'unknown'));

    return objectPath.get(userInfo, 'profile.displayName', nameFromEmail)
  }

  /**
   * Returns user company display name
   *
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string} 
   */
   static getCompanyDisplayName(userInfo) {
    return objectPath.get(userInfo, 'company.displayName', objectPath.get(userInfo, 'profile.companyId', 'unknown'))
  }


  /**
   * Returns disallowed booking days ahead according to user role and company setting
   * if normal user number taken from fair use if set otherwise constants.DEFAULT_VISIBLE_DAYS_AHEAD is used
   *
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {number} 
   */
   static getDisallowedBookingAheadDays(userInfo) {
    return UserUtil.isAtLeastCompanyManager(userInfo) ? constants.MANAGER_VISIBLE_DAYS_AHEAD : UserUtil.getNormalUsersDisallowedBookingAheadDays(userInfo);
  }


  static includeWeekend(userInfo) {
    return CompanyUtil.includeWeekend(UserUtil.getCompany(userInfo));
  }

  /**
   * Check if it is disallowed future for user
   * 
   * @param {selectedDate} selectedDate - moment date
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean} 
   */
  static isDisallowedFuture(selectedDate, userInfo, companyTimezone = null, serverOffsetTime = 0) {
    if(UserUtil.isAtLeastCompanyManager(userInfo)){
      const disallowedFuture = DateUtil.addDaysToTodayStart(UserUtil.getDisallowedBookingAheadDays(userInfo),companyTimezone,serverOffsetTime);
      return (disallowedFuture <= selectedDate) ? true : false;
    } else {
      return UserUtil.isNormalUserDisallowedFuture(selectedDate, userInfo, companyTimezone, serverOffsetTime);
    }
    
  }

  /**
   * Returns disallowed booking days ahead according to company setting
   * if normal user number taken from fair use if set otherwise constants.DEFAULT_VISIBLE_DAYS_AHEAD is used
   *
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {number} 
   */
  static getNormalUsersDisallowedBookingAheadDays(userInfo) {
    return CompanyUtil.getNormalUsersDisallowedBookingAheadDays(UserUtil.getCompany(userInfo));
  }


  /**
   * Check if it is disallowed future for normal users using the same logic as mobile app via utc date
   * 
   * @param {selectedDate} selectedDate - moment date
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean} 
   */
  static isNormalUserDisallowedFuture(selectedDate, userInfo, companyTimezone = null, serverOffsetTime = 0) {

    return CompanyUtil.isCompanyNormalUserInvisibleFutureDate(selectedDate, UserUtil.getCompany(userInfo),serverOffsetTime);
  }

  /**
   * Returns profile from user info object
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {profile} profile from (firestore)
   */
  static getProfile(userInfo) {

    return objectPath.get(userInfo, 'profile', null)
  }

  /**
   * Generate display name from email
   * 
   * @param {string} email
   * @return {string}
   */
  static getDisplayNameFromEmail(email) {
    var nameFromEmail = email;
    var indexOfAt = nameFromEmail.indexOf('@');
    if (indexOfAt !== -1) {
      nameFromEmail = nameFromEmail.substring(0, indexOfAt);
    }
    return nameFromEmail;
  }

  /**
   * Returns id profile id matches firebase auth uid
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string} id
   */
  static getId(userInfo) {
    return objectPath.get(userInfo, 'profile.id', objectPath.get(userInfo, 'firebase.uid', null))
  }

  /**
   * Returns display name
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string} 
   */
  static getDisplayNameFromProfile(profile) {
    return objectPath.get(profile, 'displayName', 'unknown');
  }

  /**
   * Returns display name with email
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string} 
   */
  static getDisplayNameWithEmailFromProfile(profile) {
    return objectPath.get(profile, 'displayName') + " (" + objectPath.get(profile, 'email') + ")";
  }

  /**
   * Returns owner from profiles by park
   * 
   * @param {profile[]} profiles - array of profiles from firestore
   * @param {park} park - object from firestore
   * @return {profile} owner 
   */
  static getOwnerFromProfilesByPark(profiles, park) {
    if (ArrayUtil.isNonEmptyArray(profiles)) {
      return profiles.find(x => ParkUtil.isUserCurrentOwner(park, objectPath.get(x, 'id', null), objectPath.get(x, 'email', null)));
    }
    return null;
  }

  /**
   * Returns owned park from parks by profile
   * 
   * @param {parks[]} parks - array of parks from firestore
   * @param {profile} profile - object from firestore
   * @return {profile} owner 
   */
   static getOwnedParksFromParksByProfile(parks, profile) {
    if (ArrayUtil.isNonEmptyArray(parks)) {
      return parks.filter(park => ParkUtil.isUserCurrentOwner(park, objectPath.get(profile, 'id', null), objectPath.get(profile, 'email', null)));
    }
    return [];
  }

  /**
   * Check if user has profile
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean} 
   */
  static hasProfile(userInfo) {
    return objectPath.get(userInfo, 'profile', null)
  }

  /**
   * Check if user has company
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean} 
   */
  static hasCompany(userInfo) {
    return objectPath.get(userInfo, 'profile.companyId', null)
  }


  /**
   * Returns email
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string} 
   */
  static getEmail(userInfo) {
    return objectPath.get(userInfo, 'profile.email', objectPath.get(userInfo, 'firebase.email', null))
  }

  /**
   * Returns photo url
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string} 
   */
  static getPhotoURL(userInfo) {
    return objectPath.get(userInfo, 'profile.photoURL', objectPath.get(userInfo, 'firebase.photoURL', null))
  }

  /**
   * Returns phone
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string} 
   */
  static getPhoneNumber(userInfo) {
    return objectPath.get(userInfo, 'profile.phoneNumber', objectPath.get(userInfo, 'firebase.phoneNumber', null))
  }

  /**
   * Check if user email is verified
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean} 
   */
  static isEmailVerified(userInfo) {
    return objectPath.get(userInfo, 'firebase.emailVerified', false);
  }

  /**
   * Get Current Owner Email From Profiles By Park 
   * 
   * @param {profile[]} profiles - array of profiles from firestore
   * @param {park} park - object from firestore
   * @return {string} email of owner or null 
   */
  static getCurrentOwnerEmailFromProfilesByPark(profiles, park) {
    var owner = UserUtil.getOwnerFromProfilesByPark(profiles, park);
    return objectPath.get(owner, 'email',null);
  }

  /**
   * Returns company object
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {company} company from firestore 
   */
  static getCompany(userInfo) {
    return objectPath.get(userInfo, 'company', null);
  }

  /**
   * Returns company id
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string} company id
   */
  static getCompanyId(userInfo) {
    return objectPath.get(userInfo, 'profile.companyId');
  }

  /**
   * Checking if user is logged in
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean}
   */
  static isLoggedIn(userInfo) {
    return userInfo && userInfo.firebase && userInfo.firebase.email;
  }

  /**
   * Checking if user Is Super Admin User
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean}
   */
  static isSuperAdminUser(userInfo) {
    return UserUtil.isLoggedIn(userInfo) && constants.SUPER_ADMINS.includes(userInfo.firebase.email);
  }

  /**
   * Checking if user Is normal user
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean}
   */
  static isNormalUser(userInfo) {
    return UserUtil.getUserType(userInfo) === users.NORMAL_USER;
  }

  /**
   * Checking if user Is company manager
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean}
   */
  static isCompanyManager(userInfo) {
    return UserUtil.isLoggedIn(userInfo) && objectPath.get(userInfo, 'company.managerIds', []).includes(UserUtil.getId(userInfo));
  }

  /**
   * Checking if user Is company admin
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean}
   */
  static isCompanyAdmin(userInfo) {
    return UserUtil.isLoggedIn(userInfo) && (
      objectPath.get(userInfo, 'company.userId') === UserUtil.getId(userInfo)
      ||
      //TODO remove this as like this admin cannot be removed
      //need to manually or by script update all companies
      objectPath.get(userInfo, 'profile.type', null) === users.COMPANY_ADMIN //old data
    );
  }

  /**
   * Did user created a company on registration
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean}
   */
   static isCreatedAsCompanyAdmin(userInfo) {
    return UserUtil.isLoggedIn(userInfo) && objectPath.get(userInfo, 'profile.companyAdmin', false);
  }

  /**
   * get company uid
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string}
   */
   static getCompanyUid(userInfo) {
    return objectPath.get(userInfo, 'company.id', "");
  }

  

  /**
   * Checking if user Is company admin
   * 
   * @param {profile} profile firestore
   * @param {company} company firestore
   * @return {boolean}
   */
  static isCompanyAdminByProfile(profile, company) {
    return objectPath.get(profile, 'id') === objectPath.get(company, 'userId');
  }

  /**
   * Checking if user Is At Least Company Manager
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean}
   */
  static isAtLeastCompanyManager(userInfo) {
    return UserUtil.isCompanyAdmin(userInfo) || UserUtil.isCompanyManager(userInfo);
  }

  /**
   * Returns user type
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {number} number of user type
   */
  static getUserType(userInfo) {
    if (UserUtil.isCompanyAdmin(userInfo)) {
      return users.COMPANY_ADMIN;
    } else if (UserUtil.isCompanyManager(userInfo)) {
      return users.COMPANY_MANAGER;
    }
    return users.NORMAL_USER;
  }

  /**
   * Returns user type from profile and company
   * 
   * @param {company} company - see company
   * @param {profile} profile - see profile
   * @return {number} number of user type
   */
   static getUserTypeFromProfile(company,profile) {
    if (objectPath.get(company, 'userId') === profile.id) {
      return users.COMPANY_ADMIN;
    } else if (objectPath.get(company, 'managerIds', []).includes(profile.id)) {
      return users.COMPANY_MANAGER;
    }
    return users.NORMAL_USER;
  }

  /**
   * Returns user type name from profile and company
   * 
   * @param {company} company - see company
   * @param {profile} profile - see profile
   * @return {number} number of user type
   */
   static getUserTypeNameFromProfile(company,profile) {
     var userType = users.NORMAL_USER;
    if (objectPath.get(company, 'userId') === profile.id) {
      userType = users.COMPANY_ADMIN;
    } else if (objectPath.get(company, 'managerIds', []).includes(profile.id)) {
      userType = users.COMPANY_MANAGER;
    }
    return users.options.find(x => x.id === userType).name;
  }

  /**
   * Returns user type name
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {string} string of user type
   */
  static getUserTypeName(userInfo) {
    return users.options.find(x => x.id === UserUtil.getUserType(userInfo)).name;
  }

  /**
   * Get Allowed Firebase User Props
   * 
   * @return {array} array of props
   */
  static getAllowedFBUserProps() {
    return [
      'uid',
      'displayName',
      'photoURL',
      'email',
      'emailVerified',
      'isAnonymous',
      'lastLoginAt',
      'createdAt',
    ];
  }

  /**
   * Returns firestore user object with only Allowed Firebase User Props
   *
   * @param {firebaseAuth} firebaseAuth - firebase auth object
   * @return {firebase} firebase user - with only app allowed firebase user props
   */
  static getUserInfoFromFBObject(fbUserObject) {
    return Object.keys(fbUserObject).reduce((obj, k) => {
      if (UserUtil.getAllowedFBUserProps().includes(k)) obj[k] = fbUserObject[k];
      return obj;
    }, {});
  }


  /**
   * Get Temp User profile of Invitation By email and companyId
   * 
   * @param {string} email
   * @param {string} companyId
   * @return {object} fake profile from invitation
   */
  static getTempUserInvitationByEmail(email, companyId) {
    return {
      id: null,
      email: email,
      displayName: UserUtil.getDisplayNameFromEmail(email),
      isInvitationOnly: true,
      companyId: companyId
    }
  }

  /**
   * Is Temp User Invitation Only
   * 
   * @param {profile} profile
   * @return {boolean} 
   */
  static isTempUserInvitationOnly(profile) {
    return objectPath.get(profile, 'isInvitationOnly', false);
  }

  /**
   * Compare Profiles By Email
   * 
   * @param {profile1} profile firestore
   * @param {profile2} profile firestore
   * @return {boolean} 
   */
  static compareProfilesByEmail(profile1, profile2) {
    let email1 = objectPath.get(profile1, 'email', null);
    let email2 = objectPath.get(profile2, 'email', null)
    return email1 && email2 && Util.compareCaseInsensitive(email1,email2);
  }

  /**
   * Compare Profiles By ID
   * 
   * @param {profile1} profile firestore
   * @param {profile2} profile firestore
   * @return {boolean} 
   */
  static compareProfilesById(profile1, profile2) {
    return objectPath.get(profile1, 'id', null) && objectPath.get(profile2, 'id', null) && objectPath.get(profile1, 'id', null) === objectPath.get(profile2, 'id', null)
  }

  /**
   * Compare Profiles By ID OR email
   * 
   * @param {profile1} profile firestore
   * @param {profile2} profile firestore
   * @return {boolean} 
   */
  static compareUsers(profile1, profile2) {
    return UserUtil.compareProfilesById(profile1, profile2) || UserUtil.compareProfilesByEmail(profile1, profile2);
  }

  /**
   * Check if profile matches id or email
   * 
   * @param {profile} profile firestore
   * @param {string} email
   * @param {string} id
   * @return {boolean} 
   */
  static isProfileByIdOrEmail(profile, email, id) {
    return profile && ((id && id === objectPath.get(profile, 'id', null)) || (email && email === objectPath.get(profile, 'email', null)));
  }

  /**
   * Is valid for mobile notification
   * 
   * @param {profile} profile firestore
   * @param {boolean} checkIfMessagingTokenExist
   * @return {boolean} 
   */
   static isValidForMobileNotification(profile, notUserId, checkIfMessagingTokenExist = false) {
    if(checkIfMessagingTokenExist){
      return profile.messagingToken && profile.id !== notUserId && objectPath.get(profile,'disabled',false) === false;
    }
    return profile.id !== notUserId && objectPath.get(profile,'disabled',false) === false;
  }

  
  /**
   * Check if profile is on minimum required version of app
   * 
   * @param {profile} profile firestore
   * @return {boolean} 
   */
   static isOnMinimumRequiredAppVersion(profile) {
    var firstVersion = '0.0.1';
    var isUserWithApp = objectPath.get(profile,'messagingToken',null) !== null ? true : false;
    return profile && (!isUserWithApp || (isUserWithApp && semver.satisfies(objectPath.get(profile,'appVersion',firstVersion), '>='+constants.MIN_APP_VERSION)));
  }

  static getCompanyDelimiterDayIndex(userInfo){
    return UserUtil.includeWeekend(userInfo) ? 7 : 5;
  }


  

}


export default UserUtil;


