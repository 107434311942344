import * as types from '../constants/actionTypes';


export function getCompanies(state = [], action) {
    if (action.type === types.GET_COMPANIES) {
        console.log(types.GET_COMPANIES+' set in store');
        return action.data;
    }
    return state;
}


export function getCompany(state = null, action) {
    if (action.type === types.GET_COMPANY) {
        console.log(types.GET_COMPANY+' set in store');
        return action.data;
    }
    return state;
}


