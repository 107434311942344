import React from 'react';
import { Box, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { connect } from "react-redux";
import objectPath from 'object-path';

import CompanyUtil from '../../shared/utils/CompanyUtil';
import ParkUtil from '../../shared/utils/ParkUtil';
import DateUtil from '../../shared/utils/general/DateUtil';
import ParkStateUtil from '../../shared/utils/ParkStateUtil';



class ParkStatesDebug extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      collapsedI: null,
      parkIdFilter: "",
      orderBy: "parkId" //parkId,date,updatedUnix
    }
  }

  orderedParkStates = () => {
    const {parkStates} = this.props;
    const {orderBy,parkIdFilter} = this.state;
    var filtered = parkIdFilter ? parkStates.filter(x => x.parkId === parkIdFilter) : parkStates;
    return filtered.sort((a, b) => (objectPath.get(b,orderBy,'')+"").localeCompare(objectPath.get(a,orderBy,'')+""))
  }

  render() {
    const {parkStates,companyProfiles,companyParks,companyInvitations, parkOwnerships} = this.props;
    const {collapsedI} = this.state;

    const profilesAndInvitations = CompanyUtil.getUsersAndInvitations(companyProfiles,companyInvitations);

    return <Box m={5} className="park-states-debug">
      <Box p={2} className="bg-warning">PARK STATES DEBUG CONSOLE - loaded items count - {this.orderedParkStates().length}<br/>
      <small>Listing currently loaded park states from park/s and date/s to be displayed and checked against the states and users for result. 
      Lock type is boolean saying <i>"unlocked by owner"</i> if <b>false</b> and <i>"booked"</i> if <b>true</b>.</small>
      <hr/>
      order by : <Select
          native
          value={this.state.orderBy}
          onChange={(event) => this.setState({
            orderBy: event.target.value
          })}
        >
          {['parkId','updatedUnix','date'].map(x => <option key={x} value={x}>{x}</option>)}
        </Select> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; park ID filter : <Select
          native
          value={this.state.parkIdFilter}
          onChange={(event) => this.setState({
            parkIdFilter: event.target.value
          })}
        >
          <option value="">All</option>
          {companyParks.map(x => <option key={x.id} value={x.id}>{x.parkId}</option>)}
        </Select></Box>

      <TableContainer component={Paper}>
      <Table size="small">
      <TableHead>
        
          <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Park ID</TableCell>
              <TableCell>Booked By</TableCell>
              <TableCell>Owned By</TableCell>
              <TableCell>Done By</TableCell>
              <TableCell>Lock type</TableCell>
              <TableCell>Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.orderedParkStates().map((ps,i) => {
                    var park = companyParks.find(p => p.id === ps.parkId);
                    var dateObj = DateUtil.getDateObjectFromParkState(ps);
                    var bookedBy = park ? ParkUtil.getBookedBy(park, dateObj, parkStates, parkOwnerships, profilesAndInvitations) : null;
                    var ownedBy = park ? ParkUtil.getParkOwnerByDate(park, dateObj, parkOwnerships, profilesAndInvitations) : null;
                    return <React.Fragment key={i}>
                      <TableRow onClick={() => this.setState({collapsedI: i})}>
                      <TableCell>{ps.date}</TableCell>
                      <TableCell>{objectPath.get(park,'parkId',' - ')}</TableCell>
                      <TableCell>{objectPath.get(bookedBy,'email',objectPath.get(bookedBy,'id',' - '))}</TableCell>
                      <TableCell>{objectPath.get(ownedBy,'email',objectPath.get(ownedBy,'id',' - '))}</TableCell>
                      <TableCell>{objectPath.get(ParkStateUtil.getProceededByParkState(ps,profilesAndInvitations),'email',' - ')}</TableCell>
                      <TableCell>{ps.lockType ? 'yes' : 'no'}</TableCell>
                      <TableCell>{DateUtil.formatDateTimeSeconds(ps.updatedUnix)}</TableCell>
                      
                  </TableRow>
                      {
                        collapsedI === i ? <TableRow key={"collapsed"+i} onClick={() => this.setState({collapsedI: i})}>
                        <TableCell colSpan={7}>{JSON.stringify(ps).replaceAll('","','", "')}</TableCell>
                    </TableRow> : null
                      }
                      </React.Fragment>
          })}
        </TableBody>
      </Table>
    </TableContainer></Box>;

    
  }
}


function mapStateToProps(state) {
    return {
      companyProfiles: state.companyProfiles,
      companyInvitations: state.companyInvitations,
      parkStates: state.parkStates,
      userInfo: state.userInfo,
      companyParks: state.companyParks,
      parkOwnerships: state.parkOwnerships
    };
  }
  
  
  
  export default connect(
    mapStateToProps
  )(ParkStatesDebug);


