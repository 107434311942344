
import ValidationUtil from '../../utils/ValidationUtil';
import objectPath from 'object-path';

const validate = (values, props) => {
  let errors = {};
  const requiredFields = [
    "parkId"
  ];



  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);
  
  errors = ValidationUtil.checkStringMaxLength(['description'],values,errors,255);
  errors = ValidationUtil.checkStringMaxLength(['parkId'],values,errors,4);

  errors = ValidationUtil.isEmailFormat(['onHoldEmail'],values,errors);
  
  var otherIDs = objectPath.get(props,'optionsData.companyParks',[]).filter(x=> x.id !== objectPath.get(props, 'initialValues.id',null)).map(x => x.parkId);
  errors = ValidationUtil.checkIfUnique(['parkId'],values,errors,otherIDs);

  return errors;
};

export default validate;
