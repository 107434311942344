export const USER_LOGIN_FORM = "USER_LOGIN_FORM";
export const USER_SIGNIN_FORM = "USER_SIGNIN_FORM";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";

export const PROFILE_FORM = "PROFILE_FORM";
export const COMPANY_PROFILE_FORM = "COMPANY_PROFILE_FORM";
export const COMPANY_FAIR_USE_FORM = "COMPANY_FAIR_USE_FORM";
export const INVITATION_FORM = "INVITATION_FORM";

export const COMPANY_SETTING_FORM = "COMPANY_SETTING_FORM";

export const PARK_FORM = "PARK_FORM";