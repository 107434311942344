import AndroidIcon from '@material-ui/icons/Android';
import objectPath from 'object-path';
import React from 'react';
import AppleIcon from './AppleIcon';

const PlatformIcon = (props) => {
    let platform = objectPath.get(props,'profile.messagingPlatform',null);
    if(platform === 'ios'){
        return <AppleIcon />;
    }
    else if(platform === 'android'){
        return <AndroidIcon />;
    } else {
        return <React.Fragment> - </React.Fragment>;
    }
}

export default PlatformIcon;