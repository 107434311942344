import CURRENT_ENVIRONMENT from './currentEnv';
const PROD = 'PROD';//setting to production
const DEV = 'DEV';
const LOCAL = 'LOCAL';


class EnvSetting{
    static ENVIRONMENT = CURRENT_ENVIRONMENT;

    static IS_PROD(){
        return EnvSetting.ENVIRONMENT === PROD;
    }

    static IS_DEV(){
        return EnvSetting.ENVIRONMENT === DEV;
    }

    static IS_LOCAL(){
        return EnvSetting.ENVIRONMENT === LOCAL;
    }
}


export default EnvSetting;




