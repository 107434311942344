//this is super important because of circular dependency to go after export

import objectPath from "object-path";
import ArrayUtil from "./general/ArrayUtil";

/** 
 * Class helper to have all park and booking logic in one place 
 * this shared folder is also watched and copied to 
 * functions folder to be reused there
*/
class ParkStateUtil {


  /**
   * Check if park states are in conflict (meaning the same company,date,parkId,lockType)
   *
   * @param {parkState} a - parkState from firestore
   * @param {parkState} b - parkState from firestore
   * @param {boolean} includeId - check id of items
   * @return {boolean}
   * 
   */
   static areInConflict(a, b, includeId = false) {
      var sameProps = a.companyId === b.companyId
      &&
      a.date === b.date
      &&
      a.parkId === b.parkId
      &&
      a.lockType === b.lockType;

      if(includeId){
        return sameProps && a.id && b.id;
      }
      
      return sameProps;
  }


  static getBookingDuplicates(parkStates){
    var duplicatesToRemove = [];
    var duplicatesToKeep = [];
    var found = [];
    
    if(ArrayUtil.isNonEmptyArray(parkStates)){
        parkStates.sort((a,b) => a.updatedUnix - b.updatedUnix).forEach(f => {
        var foundAlready = found.find(x => f.lockType === true && ParkStateUtil.areInConflict(x,f));
        if(foundAlready){
            duplicatesToRemove.push(f);
            if(duplicatesToKeep.findIndex(x => x.id === foundAlready.id) === -1){
              duplicatesToKeep.push(foundAlready);
            } 
        } else {
            found.push(f);
        }
        });
    }
    
    return {
      duplicatesToKeep,
      duplicatesToRemove
    };
  }


  static getProceededByParkState(parkState, profiles = []){
    return ArrayUtil.isNonEmptyArray(profiles) && objectPath.get(parkState,'proceededBy', null) ? profiles.find(x => x.id !== null && x.id === parkState.proceededBy) : null
  }


}


export default ParkStateUtil;





