import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Alert } from '@material-ui/lab';
import { Box, Paper, TableSortLabel } from '@material-ui/core';
import objectPath from 'object-path';

import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../shared/utils/general/ArrayUtil';

export default function AppSortableTable(props) {


    const { headers, rows, hasActions, actionHeader, emptyMessage, defaultSortBy, listingInfo } = props;

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState(defaultSortBy);

    function descendingComparator(a, b, orderBy) {
      if(!(objectPath.has(a,orderBy) && objectPath.has(b,orderBy))){
        if(!objectPath.has(a,orderBy) && objectPath.has(b,orderBy)) return -1;
        if(objectPath.has(a,orderBy) && !objectPath.has(b,orderBy)) return 1;
        return 0;
      }
      if(!(a[orderBy] !== null && b[orderBy] !== null)){
        if(a[orderBy] === null && b[orderBy] !== null) return -1;
        if(a[orderBy] !== null && b[orderBy] === null) return 1;
        return 0;
      }
      if(isNumeric(orderBy)){
        if (objectPath.get(b,orderBy,0) < objectPath.get(a,orderBy,0)) {
          return -1;
        }
        if (objectPath.get(b,orderBy,0) > objectPath.get(a,orderBy,0)) {
          return 1;
        }
        return 0;
      } else {
          if (objectPath.get(a,orderBy,'').toString().toLowerCase() < objectPath.get(b,orderBy,'').toString().toLowerCase()) return -1;
          if (objectPath.get(a,orderBy,'').toString().toLowerCase() > objectPath.get(b,orderBy,'').toString().toLowerCase()) return 1;
          return 0;
      }
      
    }

    function isNumeric(orderBy){
      return objectPath.get(headers.find(x => x.id === orderBy), 'numeric', false);
    }

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
    
    function getComparator(order, orderBy) {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0].item, b[0].item);
        if (order !== 0) return order;
        return a[1].item - b[1].item;
      });
      return stabilizedThis.map((el) => el[0]);
    }

    function getListingInfo(){
      return ArrayUtil.isNonEmptyArray(rows) ? listingInfo ? listingInfo : TranslatorUtil.t("Listing")+ " " + rows.length + " " + TranslatorUtil.t("rows") : '';
    }

  return <Paper> {ArrayUtil.isNonEmptyArray(rows) ? (
    <React.Fragment>
    <Box className="text-right table-listing-info"><small>{getListingInfo()}</small></Box>
    <TableContainer className="app-table hover-table">
      <Table>
        { ArrayUtil.isNonEmptyArray(headers) ? 
            <TableHead>
            <TableRow>
                {
                    headers.map((hi,index) => 
                        <TableCell component="th" key={index} className={objectPath.get(hi,'additionalClasses','')} align={hi.align ? hi.align : "left"}
                        sortDirection={orderBy === hi.id ? order : false}
                        >
                          {
                            objectPath.get(hi,'sortable',true) ? <TableSortLabel
                            active={orderBy === hi.id}
                            direction={orderBy === hi.id ? order : 'asc'}
                            onClick={(event) => handleRequestSort(event, hi.id) }
                          >
                            {hi.text}
                          </TableSortLabel> : <span>{hi.text}</span>
                          }
                          
                          </TableCell>
                    )
                }
                { hasActions ? <TableCell component="th" key={"actions"}>{actionHeader}</TableCell> : null}
            </TableRow>
          </TableHead> : null
        }
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map((row,index) => (
            <TableRow key={index}>
                {
                    row.items.map((rowItem,rowItemIndex) => 
                        <TableCell key={rowItemIndex} className={objectPath.get(rowItem,'additionalClasses','')} align={rowItem.align ? rowItem.align : "left"}>{rowItem.text}</TableCell>
                    )
                }
                { hasActions ? <TableCell key={"actions"}>{row.actions}</TableCell> : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {/* <Typography variant="caption">{TranslatorUtil.t("Displaying")+" "+rows.length+" "+TranslatorUtil.t("rows")}</Typography> */}
    </React.Fragment>
  ) : <Alert severity={"info"}>
  {emptyMessage ? emptyMessage : TranslatorUtil.t("No items found!")}
</Alert>}
</Paper>
}
