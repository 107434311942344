import React from 'react';

import { connect } from "react-redux";
import EnvSetting from '../../shared/config/env';
import TimeDebug from './TimeDebug';



class EnvBar extends React.Component {



  render() {
    return (!EnvSetting.IS_PROD()) ? <div className="env-bar"><span>{EnvSetting.ENVIRONMENT}</span><TimeDebug /></div> : null;
  }
}

  function mapStateToProps(state) {
    return {
      userInfo: state.userInfo,
    };
  }
  
  
  
  export default connect(
    mapStateToProps
  )(EnvBar);






