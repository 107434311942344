import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreUtil from '../shared/utils/general/FirestoreUtil';
import DateUtil from "../shared/utils/general/DateUtil.js";
import ParkUtil from "../shared/utils/ParkUtil.js";


const collectionName = 'parkStates';
const tolerance = 3600000 * 24; // 24 hrs



  export function listenParksStatesByCompanyIdAndDates(companyId,dates ,callback) {
    return function(dispatch) {
      console.log('listenParksByCompanyIdAndDates called');
      return firestoreApi.collection(collectionName)
      .where('companyId', '==', companyId)
      .where('dateUnix', '>=', DateUtil.getStartUnixFromDates(dates) - tolerance)
      .where('dateUnix', '<=', DateUtil.getEndUnixFromDates(dates) + tolerance)
      .onSnapshot(
        response => {
          callback();
          return dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response),  types.GET_PARK_STATUSES))
        },
          error => {
            callback();
            return dispatch(ajaxCalls.ajaxCallError(error, types.GET_PARK_STATUSES))
          }
        );
    };
  }

  export function listenParksStatesByParkIdMonthYear(parkId, month, year, callback) {
    return function(dispatch) {
      console.log('listenParksStatesByParkIdMonthYear called', parkId, month, year);
      return firestoreApi.collection(collectionName)
      .where('parkId', '==', parkId)
      .where('month', '==', month)
      .where('year', '==', year)
      .onSnapshot(
        response => {
          callback();
          return dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response),  types.GET_PARK_STATUSES))
        },
          error => {
            callback();
            return dispatch(ajaxCalls.ajaxCallError(error, types.GET_PARK_STATUSES))
          }
        );
    };
  }

  export function removeParkStatesByParkId(parkId){
    return function(dispatch) {
      return firestoreApi.collection(collectionName).where("parkId", "==", parkId).get().then((result) => { 
        if(Array.isArray(result.docs)){
          return Promise.all(result.docs.map(document => firestoreApi.collection(collectionName).doc(document.id).delete())).then(
            response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_PARK_STATUSES)),
            error => dispatch(ajaxCalls.ajaxCallError(error, types.DELETE_PARK_STATUSES))
          );
        }
      }); 
    }
  }

  export function deleteParkStateById(id) {
    return function(dispatch) {
      console.log('deleteParkStateById');
      return firestoreApi.collection(collectionName).doc(id).delete()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_PARK_STATE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.DELETE_PARK_STATE))
        );
    };
  }

  export function postParkState(data) {
    return function(dispatch) {
      console.log('postParkState');
      return firestoreApi.collection(collectionName).doc(ParkUtil.getParkStateFirestoreDocId(data)).set(data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_PARK_STATE)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_PARK_STATE))
        );
    };
  }

  export function getParksStatesByCompanyIdAndDates(companyId,dates) {
    return function(dispatch) {
    console.log('getParksStatesByCompanyIdAndDates called');
    
    return firestoreApi.collection(
      collectionName
    )
    .where('companyId', '==', companyId)
    .where('dateUnix', '>=', DateUtil.getStartUnixFromDates(dates) - tolerance)
    .where('dateUnix', '<=', DateUtil.getEndUnixFromDates(dates) + tolerance)
    .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_PARK_STATUSES))
    );
    }
}

export function getParksStatesByCompanyIdAndBetweenDates(companyId,fromUnix,toUnix) {
  return function(dispatch) {
  console.log('getParksStatesByCompanyIdAndBetweenDates called');
  return firestoreApi.collection(
    collectionName
  )
  .where('companyId', '==', companyId)
  .where('dateUnix', '>=', fromUnix - tolerance)
  .where('dateUnix', '<=', toUnix + tolerance)
  .get()
    .then(
      response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_PARK_STATUSES))
  );
  }
}


export function getParksStatesByCompanyIdAndMonthYear(companyId,month,year) {
  return function(dispatch) {
  console.log('getParksStatesByCompanyIdAndMonthYear called',companyId,month,year);
  
  return firestoreApi.collection(
    collectionName
  )
  .where('companyId', '==', companyId)
  .where('month', '==', month)
  .where('year', '==', year)
  .get()
    .then(
      response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_PARK_STATUSES))
  );
  }
}
