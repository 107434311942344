import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreUtil from '../shared/utils/general/FirestoreUtil';


const collectionName = 'parkOwnerships';



  export function listenParksOwnershipsByCompanyId(companyId) {
    return function(dispatch) {
      console.log('listenParksOwnershipsByCompanyId called');
      return firestoreApi.collection(collectionName)
      .where('companyId', '==', companyId)
      .onSnapshot(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response),  types.GET_PARK_OWNERSHIPS)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PARK_OWNERSHIPS))
        );
    };
  }

  export function removeParkOwnershipsByParkId(parkId){
    return function(dispatch) {
      return firestoreApi.collection(collectionName).where("parkId", "==", parkId).get().then((result) => { 
        if(Array.isArray(result.docs)){
          return Promise.all(result.docs.map(document => firestoreApi.collection(collectionName).doc(document.id).delete())).then(
            response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.DELETE_PARK_OWNERSHIPS)),
            error => dispatch(ajaxCalls.ajaxCallError(error, types.DELETE_PARK_OWNERSHIPS))
          );
        }
      }); 
    }
  }

  export function postParkOwnership(newParkOwnership){
    return function(dispatch) {
      return firestoreApi.collection(collectionName).add(newParkOwnership)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_PARK_OWNERSHIP)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_PARK_OWNERSHIP))
        );
    }
  } 

  export function endParkOwnership(id,endTime){
    return function(dispatch) {
      return firestoreApi.collection(collectionName).doc(id).update({endedUnix: endTime})
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.END_PARK_OWNERSHIP)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.END_PARK_OWNERSHIP))
        );
    }
  } 

  export function getParksOwnershipsByCompanyId(companyId) {
    return function(dispatch) {
    console.log('getParksOwnershipsByCompanyId called');
    
    return firestoreApi.collection(
      collectionName
    )
    .where('companyId', '==', companyId)
    .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreUtil.parseCollectionData(response), types.GET_PARK_OWNERSHIPS))
    );
    }
}

  

 

