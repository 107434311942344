import * as types from '../constants/actionTypes';
import ParkSelectionUtil from '../utils/ParkSelectionUtil';




export function getRosterSelection(state = [], action) {
    if (action.type === types.ADD_ROSTER_SELECTION) {
        return [...state, action.data];
    }
    if (action.type === types.REMOVE_ROSTER_SELECTION) {
        let newState = [...state];
        let indexToRemove = ParkSelectionUtil.getSelectedItemIndex(newState,action.data.park,action.data.date);
        if(indexToRemove !== -1){
            newState.splice(indexToRemove, 1);
        }
        return newState;
    }
    if (action.type === types.REMOVE_ROSTER_SELECTIONS) {
        return [];
    }
    return state;
}

