import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import * as profileActions from "../../../../actions/profile";
import * as invitationActions from "../../../../actions/invitation";
import ComponentLoading from '../../../../components/ComponentLoading';
import PageHeading from '../../../../components/PageHeading';
import SnackBarUtil from '../../../../utils/SnackBarUtil';
import FairUseForm from '../../../../forms/company/fairuse/form';
import { withSnackbar } from "notistack";
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';

import urls from '../../../../shared/constants/urls';
import UserUtil from '../../../../shared/utils/UserUtil';
import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import CompanyUtil from '../../../../shared/utils/CompanyUtil';
import { Box } from '@material-ui/core';
import ParkingSecondaryActions from '../../../../components/park/ParkingSecondaryActions';

class FairUse extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
    }   
  }

  componentDidMount() {
    const { actions, userInfo } = this.props;
    const company = UserUtil.getCompany(userInfo);
    let _this = this;
    if(company){
      Promise.all([
        actions.getProfilesByCompanyId(company.companyId),
        actions.getInvitationsByCompanyId(company.companyId),
      ]).then(()=>{
        console.log('loading done');
        _this.setState({listDataLoading: false});

        //catch errors
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
    }
          
        
  }

  handleResponse = response => {
    const { enqueueSnackbar } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = TranslatorUtil.t("Updated");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      document.location.href = urls.INDEX_URL;
    }
    
  }



  handleSubmit = (values) => {
    const { actions, userInfo } = this.props;


    return actions.updateCompanyFairUse({
      id: values.id,
      nextPriorityUserEmail: values.nextPriorityUserEmail,
      priorityQueue: values.priorityQueue,
      visibleDays: values.visibleDays,
      visibleDaytime: values.visibleDaytime,
      maximumParksPerUser: values.maximumParksPerUser
    }).then(response => {
      actions.getCurrentUserCompanyByCompanyId(UserUtil.getCompanyId(userInfo));
      this.handleResponse(response);
    });
  };



  
  render() {

    const { companyProfiles, userInfo, companyInvitations } = this.props;
    const { listDataLoading } = this.state;
    var company = UserUtil.getCompany(userInfo);

    return <div className="app-container-wrap">
        <PageHeading icon={<ThumbUpAltOutlinedIcon />} title={TranslatorUtil.t("Fair use")} />

        <ParkingSecondaryActions />
        <Box py={2}></Box>
        { company === null || listDataLoading ? <ComponentLoading /> : 
          <FairUseForm proceedSubmit={this.handleSubmit} 
          initialValues={company} 
          priorityQueueUsers={CompanyUtil.getPriorityQueueUsers(company,companyProfiles,companyInvitations)} 
          companyUsers={CompanyUtil.getUsersAndInvitations(companyProfiles,companyInvitations)}
          nextPriorityUserEmail={CompanyUtil.getNextPriorityUserEmail(company)} 
          userInfo={userInfo} 
          />
        }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    companyProfiles: state.companyProfiles,
    userInfo: state.userInfo,
    companyInvitations: state.companyInvitations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...profileActions,
        ...invitationActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
    )(FairUse)
));
