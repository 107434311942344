import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as repeatingRosterActions from '../../actions/repeatingRoster';
import * as rosterSelectionActions from '../../actions/rosterSelection';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, Typography } from '@material-ui/core';
import objectPath from 'object-path';
import ParkSelectionUtil from '../../utils/ParkSelectionUtil';

import DateUtil from '../../shared/utils/general/DateUtil';
import UserUtil from '../../shared/utils/UserUtil';
import repeatingRosterConstants from '../../shared/constants/repeatingRoster';
import ParkRosterCell from './ParkRosterCell';
import ParkUtil from '../../shared/utils/ParkUtil';
import CompanyUtil from '../../shared/utils/CompanyUtil';

class ParkingWeeksSelectionTable extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      statusesLoading: false,
      parkStatesListener: null
    }   
  }

  componentDidMount() {
    this.listenCompanyRoster();
  }

  componentWillUnmount(){
    this.unsubscribeListeners();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var day = objectPath.get(this,'props.day',null);
    var prevDay = objectPath.get(prevProps,'day',null);
    if (day && prevDay && day !== prevDay) {
      //this.unsubscribeListeners();
      this.listenCompanyRoster();
    }
  }

  unsubscribeListeners = () => {
     
  }

  listenCompanyRoster = () => {
    const { userInfo, actions } = this.props;
    this.setState({dataLoading:true});
    actions.listenRepeatingRosterSettingByCompanyId(
      UserUtil.getCompanyUid(userInfo),
      () => {this.setState({dataLoading:false})}
    );
  }

  getWeekDaysCount = () => {
    const { userInfo } = this.props;
    return CompanyUtil.includeWeekend(UserUtil.getCompany(userInfo)) ? 7 : 5
  }

  renderParkButton = (p,d,index) => {
    const { dataLoading } = this.state;
    const { userInfo } = this.props;
    const { frequency } = this.props;
    return <ParkRosterCell 
    disableActions={frequency === repeatingRosterConstants.WEEKLY && index >= this.getWeekDaysCount() ? true : false}
    park={p} 
    userInfo={userInfo}
    selectedDate={d} 
    loading={dataLoading}
    frequency={frequency}
  /> 
  }

  selectAllDays = (companyPark) => {
      const { days, actions, rosterSelection } = this.props;
      days.forEach(d => {
          if(!ParkSelectionUtil.isSelected(rosterSelection,companyPark,d)){
            actions.addRosterSelection(companyPark, d);
          }
      });
  }

  getCellClass = (day, index) => {
    return index < this.getWeekDaysCount() ? 'first-week' : 'second-week';
  }

  getParkButton = (companyPark) => {
    const { rosterSelection,
      companyProfiles
      // , appAdminView
     } = this.props;

      var isDisabled = ParkUtil.hasOwnerAssigned(companyPark, companyProfiles) || !ParkSelectionUtil.isInSelectedRow(rosterSelection, companyPark);
      return <Button disabled={isDisabled} onClick={() => this.selectAllDays(companyPark)} variant="contained" color="primary" className="park-btn">
          {companyPark.parkId}
      </Button>
    
  }

  renderDayText = (day) => {
    const { frequency } = this.props;
    return <React.Fragment>
        {frequency === repeatingRosterConstants.FORNIGHTLY ? <Typography variant='caption' className={DateUtil.isStartOfWeek(day) ? 'text-dark' : 'hidden-text'}>{
         DateUtil.isEvenWeek(day) ? "Even week" : "Odd week"
        }</Typography> : null }
        <Typography variant='body1'>{DateUtil.formatDayOfWeekOnly(day.date)}</Typography>
        {/* <Typography variant="caption" className='text-muted'>{DateUtil.dbFormatDay(day.date)}</Typography> */}
        </React.Fragment>;
  }
  
  render() {

    const { days, companyParks, userInfo } = this.props;
    

    return <TableContainer className={"week-view-table-wrap week-view-selection-table"}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
            <TableCell></TableCell>
            {
                days.map((day,index) => index !== UserUtil.getCompanyDelimiterDayIndex(userInfo) ? <TableCell className={this.getCellClass(day,index)} key={index}>{this.renderDayText(day)}</TableCell> : <React.Fragment key={index}>
                    <TableCell className="week-divider-cell"></TableCell>
                    <TableCell className={this.getCellClass(day,index)}>{this.renderDayText(day)}</TableCell>
                </React.Fragment>)
            }
        </TableRow>
      </TableHead>
      <TableBody>
        {companyParks.map((companyPark,companyParkIndex) => (
          <TableRow key={companyParkIndex}>
            <TableCell component="th" align="left">
              {this.getParkButton(companyPark)}
                
            </TableCell>
            {
                days.map((day,index) =>  index !== UserUtil.getCompanyDelimiterDayIndex(userInfo) ? <TableCell className={"table-btn-group-cell "+this.getCellClass(day,index)} key={index}>{this.renderParkButton(companyPark,day, index)}</TableCell> : <React.Fragment  key={index}>
                <TableCell className="week-divider-cell"></TableCell>
                <TableCell className={"table-btn-group-cell "+this.getCellClass(day,index)}>{this.renderParkButton(companyPark,day, index)}</TableCell>
            </React.Fragment>)
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    companyParks: state.companyParks,
    companyProfiles: state.companyProfiles,
    repeatingRosterSetting: state.repeatingRosterSetting,
    parkOwnerships: state.parkOwnerships,
    rosterSelection: state.rosterSelection
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...repeatingRosterActions,
        ...rosterSelectionActions
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkingWeeksSelectionTable));
