
import ValidationUtil from '../../../utils/ValidationUtil';

const validate = values => {
  let errors = {};
  const requiredFields = [
    "userEmail",
    "plan",
    "userId"
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);

  errors = ValidationUtil.checkStringMaxLength(['displayName'],values,errors,150);

  errors = ValidationUtil.isEmailFormat(['userEmail'],values,errors);

  return errors;
};

export default validate;
