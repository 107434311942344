

import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Avatar from '@material-ui/core/Avatar';
import UserConstants from '../../shared/constants/users';

export default class RoleIcon extends React.Component {

    

render() {

    
    const { roleType } = this.props;
    return (
        <Avatar className={"roleType"+roleType+"Avatar"}>
        { roleType === UserConstants.NORMAL_USER ? <PersonIcon /> : null }
        { roleType === UserConstants.COMPANY_MANAGER ? <SupervisorAccountIcon /> : null }
        { roleType === UserConstants.COMPANY_ADMIN ? <AccountBalanceIcon /> : null }
        </Avatar>
    );
}
}                    