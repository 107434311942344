import constants from '../../constants/constants';


class FormatterUtil {
  
  static isEmpty(variable) {
    return ((typeof variable === "undefined") || (!variable) || (variable && variable.length < 1)) ? true : false;
  }

  static parseInteger(input) {
    return parseInt(input, 10);
  }

  static bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  static getDeepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static parseJsonSafe(obj) {
    try {
      return JSON.parse(obj);
    } catch (ex) {
      return null;
    }
  }

  static truncateIfNeeded(text, truncateIfOver) {
    text = FormatterUtil.stripHtml(text);
    if (text && text.length >= truncateIfOver) {
      return text.toString().substring(0, truncateIfOver) + '...';
    }
    return text;
  }

  static isTruncateNeeded(text, truncateIfOver) {
    text = FormatterUtil.stripHtml(text);
    if (text && text.length >= truncateIfOver) {
      return true
    }
    return false;
  }

  static stripHtml(text, strict = false) {
    var stripText = text.replace(/<(?:.|\n)*?>/gm, ' ');

    if (strict) {

      stripText = stripText.replace(/(&.+;)/ig, ' ');//remove HTML entities
      stripText = stripText.replace('&', ' ');//remove &
      stripText = stripText.replace(/ +(?= )/g, '');//remove multiple spaces

    }
    return stripText;
  }

  static formatNZDollar(number) {
    let result = number.toLocaleString(constants.DEFAULT_LOCALE,
      {
        style: "currency",
        currency: constants.DEFAULT_CURRENCY,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
        //maximumSignificantDigits: 3
      });
    return result;

  }

  static formatPercentage(number, decimalPlaces = 0) {
    var output = number;
    if (number >= 0) {
      output = decimalPlaces > 0 ? parseFloat(number).toFixed(decimalPlaces) : Math.floor(number);
    } else {
      output = decimalPlaces > 0 ? parseFloat(number).toFixed(decimalPlaces) : Math.ceil(number);
    }
    return output.toString() + '%';
  }

  static camelToNormalForm(string) {
    return FormatterUtil.trimLeft(string
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([a-z])/g, ' $1$2')
      .replace(/ +/g, ' '))
  }

  static trimLeft(string) {
    if (string === null) return string;
    return string.replace(/^\s+/g, '');
  }

  static getIconText(string) {
    if (string === null) return ' ';
    return string.trim().charAt(0).toUpperCase();
  }

  static formatActionNameForErrorMessage(actionName) {
    actionName = FormatterUtil.replaceAll(actionName, 'GET_', '');
    actionName = FormatterUtil.replaceAll(actionName, '_', ' ');
    return actionName.toLowerCase();
  }

  static replaceAll(str, find, replace) {
    return str.toString().replace(new RegExp(find, 'g'), replace);
  }


  static hashCode(str) {
    var hash = 0, i, chr;
    if (str.length === 0) return str;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  static hashCodeShort(str, length = 6) {
    return str ? btoa(str).substring(0, length) : str;
  }

  static guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = Math.random() * 16 | 0;
      var v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }


  static getFileExtension(fileName) {
    return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
  }

  static getPercentage (total, number){
    var percetage = ((100/total)*number);
    return percetage > 0 ? percetage.toFixed(0) : 0;
  }

  static compareStrings (str1, str2, caseInsensitive = true){
    if(caseInsensitive){
      return String(str1).toLocaleLowerCase() === String(str2).toLocaleLowerCase();
    }
    return String(str1) === String(str2)
  }

}


export default FormatterUtil;

