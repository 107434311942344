import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import { IconButton, InputAdornment, Box, TextField, FormGroup } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../shared/utils/general/ArrayUtil';

export default class UserSelectDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: ''
        }
      }

    handleClose = () => {
      this.props.onClose(this.props.selectedValue);
    };
  
    handleListItemClick = (user) => {
      this.props.userSelected(user);
    };

    handleChange = (event) => {
        this.setState({ searchText : event.target.value });
      };

      clearSearchText = () => {
        this.setState({ searchText : '' });
      }

      filterUsers = (user) => {
        const { searchText } = this.state;
        var st = searchText.toLowerCase();
        return st.length === 0 
        || 
        (st.length 
          && 
          (user.displayName.toLowerCase().includes(st) || user.email.toLowerCase().includes(st))
        )
      }
  
    render() {
      const { searchText } = this.state;
      const { users } = this.props;

      const filteredUsers = ArrayUtil.sortObjectsAlphabetically(users,'email').filter(this.filterUsers);

      return (
        <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={true}>
          <DialogTitle id="simple-dialog-title">
          <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            {TranslatorUtil.t("Select user")}</DialogTitle>
          <div>
            <Box p={2} pt={0}>
            <FormGroup>
            <TextField label="Search" onChange={this.handleChange} value={searchText} InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton
                      onClick={this.clearSearchText}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
            }} />
            </FormGroup>
            </Box>
            <List dense>
              {filteredUsers && filteredUsers.length ? filteredUsers.map((user,index) => (
                <ListItem button onClick={() => this.handleListItemClick(user)} key={index}>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={user.displayName+ " - "+user.email} />
                </ListItem>
              )) : <ListItem>{TranslatorUtil.t("No users found")}</ListItem>
            }
            </List>
          </div>
        </Dialog>
      );
    }
  }