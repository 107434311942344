import LocalStorageUtil from './LocalStorageUtil';
import UserUtil from '../shared/utils/UserUtil';

export default class SuperAdminUtil {

    static isSuperAdmin(userInfo) {
      return UserUtil.isSuperAdminUser(userInfo) && LocalStorageUtil.getTyped('admin') > 0;
    }

  }
  