import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import * as invitationActions from "../../../../actions/invitation";
import ComponentLoading from '../../../../components/ComponentLoading';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import AppTable from '../../../../components/table/AppTable';
import CheckIcon from '@material-ui/icons/Check';
import PageHeading from '../../../../components/PageHeading';
import objectPath from 'object-path';
import AdminCompanyMenu from '../../../../components/admin/AdminCompanyMenu';

import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../../../shared/utils/general/ArrayUtil';

class CompanyInvitations extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
    }   
  }

  componentDidMount() {
    const { actions, match } = this.props;
    let _this = this;
        this.mountCompany().then((response) => {
          var company = objectPath.get(response,'data');
        if(objectPath.get(match,'params.id') === objectPath.get(company,'id')){
          Promise.all([
            actions.getInvitationsByCompanyId(company.companyId),
          ]).then(()=>{
            console.log('loading done');
            _this.setState({listDataLoading: false});
  
            //catch errors
          }).catch(function(err) {
            console.log('loading error',err);
            _this.setState({listDataLoading: false});
          });
        } else {
          _this.setState({listDataLoading: false});
        }
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  mountCompany = () =>{
    const { actions, match, company } = this.props;
    if(objectPath.get(match,'params.id') && objectPath.get(match,'params.id') !== objectPath.get(company,'id')){
      return actions.getCompany(objectPath.get(match,'params.id'));
    } else {
      return Promise.resolve().then(() => {return {data: company}});
    }
  }



  getTableRows = () => {
    const { companyInvitations } = this.props;
    return ArrayUtil.isNonEmptyArray(companyInvitations) ? companyInvitations.map( invitation => { return {
      items: [
        {
          text: invitation.email,
        },
        {
          text: invitation.accepted === true ? <CheckIcon /> : ' ', 
        },
        {
          text: invitation.id,
        }
      ]
    }}) : [];
  }

  getTableHeaders = () => {
    return [
      { text: TranslatorUtil.t("Email") },
      { text: TranslatorUtil.t("Accepted") },
      { text: TranslatorUtil.t("id") },
    ];
  }


  
  render() {

    const { company } = this.props;
    const { listDataLoading } = this.state;

    return <div className="app-container-wrap app-admin">
        <PageHeading icon={<InsertInvitationIcon />} title={TranslatorUtil.t("Company invitations")} />

        <AdminCompanyMenu company={company} />

        { listDataLoading ? <ComponentLoading /> : <AppTable headers={this.getTableHeaders()} rows={this.getTableRows()} /> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    company: state.company,
    companyInvitations: state.companyInvitations,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...invitationActions,
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyInvitations));
