import * as types from '../constants/actionTypes';

export default function userInfo(state = {}, action) {
    if (action.type === types.SET_USER_INFO_SUCCESS) {
        console.log('userInfo ',types.SET_USER_INFO_SUCCESS)
        return action.data;
    }
    if (action.type === types.GET_USER_PROFILE) {
        console.log('put current user profile')
        return {
            ...state,
            profile: action.data
          }
    }
    if (action.type === types.GET_USER_COMPANY) {
        console.log('put current user company')
        return {
            ...state,
            company: action.data
          }
    }
    if (action.type === types.SET_USER_INFO_ERROR) {
        return 'error';
    }
    return state;
}
