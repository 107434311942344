import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as companyActions from "../../../../actions/company";
import * as parkActions from "../../../../actions/park";
import * as profileActions from "../../../../actions/profile";
import ComponentLoading from '../../../../components/ComponentLoading';
import CarIcon from '@material-ui/icons/DirectionsCar';
import AppTable from '../../../../components/table/AppTable';

import PageHeading from '../../../../components/PageHeading';
import objectPath from 'object-path';
import AdminCompanyMenu from '../../../../components/admin/AdminCompanyMenu';

import UserUtil from '../../../../shared/utils/UserUtil';
import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../../../shared/utils/general/ArrayUtil';

class CompanyParks extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      listDataLoading: true,
    }   
  }

  componentDidMount() {
    const { actions, match } = this.props;
    let _this = this;
        this.mountCompany().then((response) => {
          var company = objectPath.get(response,'data');
        if(objectPath.get(match,'params.id') === objectPath.get(company,'id')){
          Promise.all([
            actions.getParksByCompanyId(company.companyId),
            actions.getProfilesByCompanyId(company.companyId),
          ]).then(()=>{
            console.log('loading done');
            _this.setState({listDataLoading: false});
  
            //catch errors
          }).catch(function(err) {
            console.log('loading error',err);
            _this.setState({listDataLoading: false});
          });
        } else {
          _this.setState({listDataLoading: false});
        }
      }).catch(function(err) {
        console.log('loading error',err);
        _this.setState({listDataLoading: false});
      });
  }

  mountCompany = () =>{
    const { actions, match, company } = this.props;
    if(objectPath.get(match,'params.id') && objectPath.get(match,'params.id') !== objectPath.get(company,'id')){
      return actions.getCompany(objectPath.get(match,'params.id'));
    } else {
      return Promise.resolve().then(() => {return {data: company}});
    }
  }



  getTableRows = () => {
    const { companyParks, companyProfiles } = this.props;
    return ArrayUtil.isNonEmptyArray(companyParks) ? companyParks.map( park => { return {
      items: [
        {
          text: park.parkId
        },
        {
          text: UserUtil.getCurrentOwnerEmailFromProfilesByPark(companyProfiles, park)
        },
        {
          text: park.id
        }
      ]
    }}) : [];
  }

  getTableHeaders = () => {
    return [
      { text: TranslatorUtil.t("Park ID") },
      { text: TranslatorUtil.t("Current owner") },
      { text: TranslatorUtil.t("id") },
    ];
  }


  
  render() {

    const { company } = this.props;
    const { listDataLoading } = this.state;

    return <div className="app-container-wrap app-admin">
        <PageHeading icon={<CarIcon />} title={TranslatorUtil.t("Company parks")} />
        <AdminCompanyMenu company={company} />

        { listDataLoading ? <ComponentLoading /> : <AppTable hasActions={false} rows={this.getTableRows()} headers={this.getTableHeaders()} /> }
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    company: state.company,
    companyProfiles: state.companyProfiles,
    companyParks: state.companyParks,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...companyActions,
        ...parkActions,
        ...profileActions,
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyParks));
