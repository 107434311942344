import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, Button, ButtonGroup } from '@material-ui/core';
import * as rosterSelectionActions from '../../actions/rosterSelection';
import * as repeatingRosterActions from '../../actions/repeatingRoster';
import UserSelectDialog from '../form/UserSelectDialog';
import ParkSelectionUtil from '../../utils/ParkSelectionUtil';

import UserUtil from '../../shared/utils/UserUtil';
import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';
import CompanyUtil from '../../shared/utils/CompanyUtil';
import RepeatingRosterUtil from '../../shared/utils/RepeatingRosterUtil';
import objectPath from 'object-path';
import DateUtil from '../../shared/utils/general/DateUtil';

class RosterBulkActions extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      showUserSelect: false
    }   
  }

  proceedBulkScheduleAction = (user) => {
    const {userInfo, actions,rosterSelection,repeatingRosterSetting, startLoading, finishLoading} = this.props;

    //start loading
    startLoading();
    let bulkActions = [];
    rosterSelection.forEach((selection) => {
      let rosterSetting = RepeatingRosterUtil.getScheduledSettingByParkAndDate(selection.park, selection.date, repeatingRosterSetting);
      if(rosterSetting !== null){
        //unlock first if needed
        bulkActions.push(() => {
          return actions.removeRepeatingRosterSetting(UserUtil.getCompanyUid(userInfo), rosterSetting.id);
        });
        
      }
      bulkActions.push(() => {
        return actions.addRepeatingRosterSetting(
          UserUtil.getCompanyUid(userInfo),
          RepeatingRosterUtil.createSettingObject(selection.park, selection.date, user)
        );
      });
    });
    bulkActions.push(() => this.updateRepeatingRoster());

    

    this.setState({showUserSelect: false});
    //run bulk actions
    Promise.all(bulkActions.map( ba => ba())).then((values) => {
      //empty selection
      finishLoading();
      actions.removeRosterSelections();
      
    });
  }

  updateRepeatingRoster = () => {
    const {userInfo, actions, frequency, repeatingRoster} = this.props;
      var updateRoster = {
        'lastRunUnix' : objectPath.get(repeatingRoster,'lastRunUnix', 0),
        'lastDateProcessed' : objectPath.get(repeatingRoster,'lastDateProcessed', ""),
        'disabled' : false,
        'frequency' : frequency,
        'unconfirmedUpdate': true, 
        'updatedUnix' : DateUtil.getCurrentUnix()
      };
      
      return RepeatingRosterUtil.hasLastRunUnix(repeatingRoster) ? 
      actions.putRepeatingRoster(UserUtil.getCompanyUid(userInfo),updateRoster)
      :
      actions.setRepeatingRoster(UserUtil.getCompanyUid(userInfo),updateRoster)
      ;
  
  }



  proceedBulkRemoveScheduledAction = () => {
    const {actions,rosterSelection,userInfo,repeatingRosterSetting,startLoading, finishLoading} = this.props;

    //start loading
    startLoading();

    let bulkActions = [];
    rosterSelection.forEach((selection) => {
      let rosterSetting = RepeatingRosterUtil.getScheduledSettingByParkAndDate(selection.park, selection.date, repeatingRosterSetting);
      if(rosterSetting !== null){
        bulkActions.push(() => {
          return actions.removeRepeatingRosterSetting(UserUtil.getCompanyUid(userInfo), rosterSetting.id);
        }); 
      }
    });

    bulkActions.push(() => this.updateRepeatingRoster());
    

    this.setState({showUserSelect: false});
    //run bulk actions
    Promise.all(bulkActions.map( ba => ba())).then((values) => {
      //empty selection
      finishLoading();
      actions.removeRosterSelections();
      
    });
  }




  
  render() {

    const {actions,userInfo,repeatingRosterSetting,rosterSelection,companyProfiles,companyInvitations} = this.props;
    const {showUserSelect} = this.state;

    
    

  return UserUtil.isAtLeastCompanyManager(userInfo) ? <Box className="bulkActions text-right">
      {rosterSelection.length ? <Box mt={2}>
    <ButtonGroup variant="contained" color="default" aria-label="button group">
      
      <Button color="primary" onClick={() => this.setState({showUserSelect : true})}>
      {TranslatorUtil.t("Select user")}
      </Button>
      {ParkSelectionUtil.hasSelectionScheduledRosterPark(rosterSelection, repeatingRosterSetting, CompanyUtil.getUsersAndInvitations(companyProfiles,companyInvitations), null) ? <Button color="primary"  className="error-btn" onClick={() => this.proceedBulkRemoveScheduledAction()}>
      {TranslatorUtil.t("Remove scheduled")}
      </Button> : null } 
      <Button color="default" onClick={() => actions.removeRosterSelections()}>
      {TranslatorUtil.t("Remove selection")}
      </Button>
      </ButtonGroup>
      {showUserSelect ? <UserSelectDialog onClose={() => this.setState({showUserSelect : false})} users={companyProfiles} userSelected={this.proceedBulkScheduleAction} /> : null }
      </Box> : null }
  </Box> : null
  }
}



function mapStateToProps(state) {
  return {
    companyProfiles: state.companyProfiles,
    companyInvitations: state.companyInvitations,
    userInfo: state.userInfo,
    parkOwnerships: state.parkOwnerships,
    repeatingRosterSetting: state.repeatingRosterSetting,
    repeatingRoster: state.repeatingRoster,
    rosterSelection: state.rosterSelection
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...rosterSelectionActions,
        ...repeatingRosterActions
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterBulkActions));
