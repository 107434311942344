import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as firebaseUserActions from "../../../actions/firebaseUser";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import LoginForm from '../../../forms/user/login/form';
import { Paper, Typography, Box, Button } from "@material-ui/core";
import LinkRef from '../../../components/LinkRef';

import urls from '../../../shared/constants/urls';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
class Login extends React.Component {

  handleResponse = response => {
    const { enqueueSnackbar, history } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      var successMessage = TranslatorUtil.t("Logged in");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      history.push(urls.INDEX_URL);
    }
  }



  handleSubmit = (values) => {
    const { actions } = this.props;
    return actions.loginWithEmail(values).then(response => this.handleResponse(response));
  };

  render() {
    
    

    return (<div className="maxWidthPart">
      <Box className="intro text-center" py={2} pb={3}>
  <Typography variant="h5">{TranslatorUtil.t("Welcome back!")}</Typography>
      </Box>
      
      <Paper className="paper">
        <LoginForm proceedSubmit={this.handleSubmit} />
      </Paper>
      <Box className="text-center secondaryActions" py={2}>
            <Button variant="text" color="default" component={LinkRef} to={urls.RESET_PASSWORD_URL}>{TranslatorUtil.t("Forgotten password?")}</Button>
            <Button variant="text" color="default" component={LinkRef} to={urls.CREATE_ACCOUNT_URL}>{TranslatorUtil.t("Create An Account")}</Button>
      </Box>
      <Box className="text-center claim" py={2}>
            {TranslatorUtil.t("By using this application you agree to our")}<br/> <a href={urls.PRIVACY_POLICY_EXTERNAL_URL} target="_blank" rel="noopener noreferrer">{TranslatorUtil.t("Privacy Policy")}</a> {TranslatorUtil.t("and")} <a href={urls.TERMS_OF_USE_EXTERNAL_URL} target="_blank" rel="noopener noreferrer">{TranslatorUtil.t("Terms of Use")}</a>.
      </Box>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = dispatch =>
({
  actions: bindActionCreators(
    {
      ...firebaseUserActions,
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
));