import React from 'react';
import DisallowedParkStatus from './DisallowedParkStatus';
import PastParkStatus from './PastParkStatus';
import AllowedParkStatus from './AllowedParkStatus';
import { LinearProgress } from '@material-ui/core';

import UserUtil from '../../shared/utils/UserUtil';
import ServerTimeUtil from '../../utils/ServerTimeUtil';
import CompanyUtil from '../../shared/utils/CompanyUtil';


export default class ParkStatus extends React.Component {

  render() {
    const {selectedDate,loading,userInfo} = this.props;
    if(loading){
      return <LinearProgress className="parkStateLoading" />
    }
    else if(UserUtil.isDisallowedFuture(selectedDate.date, userInfo, ServerTimeUtil.getUserCompanyTimezone(), ServerTimeUtil.getServerTimeOffset())){
        return <DisallowedParkStatus {...this.props} />
    }else if(CompanyUtil.isCompanyInvisiblePastDate(selectedDate.date, UserUtil.getCompany(userInfo), ServerTimeUtil.getServerTimeOffset())){
        return <PastParkStatus {...this.props} />
    }else if(selectedDate.date){
        return <AllowedParkStatus {...this.props} />
    }
  }
}



