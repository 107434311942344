import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Alert } from '@material-ui/lab';
import { Paper } from '@material-ui/core';
import objectPath from 'object-path';

import TranslatorUtil from '../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../shared/utils/general/ArrayUtil';

export default function AppTable(props) {


    const { headers, rows, hasActions, actionHeader, emptyMessage } = props;

  return <Paper> {ArrayUtil.isNonEmptyArray(rows) ? (
    <React.Fragment>
    <TableContainer className="app-table">
      <Table>
        { ArrayUtil.isNonEmptyArray(headers) ? 
            <TableHead>
            <TableRow>
                {
                    headers.map((hi,index) => 
                        <TableCell component="th" key={index} className={objectPath.get(hi,'additionalClasses','')} align={hi.align ? hi.align : "left"}>{hi.text}</TableCell>
                    )
                }
                { hasActions ? <TableCell component="th" key={"actions"}>{actionHeader}</TableCell> : null}
            </TableRow>
          </TableHead> : null
        }
        <TableBody>
          {rows.map((row,index) => (
            <TableRow key={index}>
                {
                    row.items.map((rowItem,rowItemIndex) => 
                        <TableCell key={rowItemIndex} className={objectPath.get(rowItem,'additionalClasses','')} align={rowItem.align ? rowItem.align : "left"}>{rowItem.text}</TableCell>
                    )
                }
                { hasActions ? <TableCell key={"actions"}>{row.actions}</TableCell> : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {/* <Typography variant="caption">{TranslatorUtil.t("Displaying")+" "+rows.length+" "+TranslatorUtil.t("rows")}</Typography> */}
    </React.Fragment>
  ) : <Alert severity={"info"}>
  {emptyMessage ? emptyMessage : TranslatorUtil.t("No items found!")}
</Alert>}
</Paper>
}
