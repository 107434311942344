import { Typography } from '@material-ui/core';
import React from 'react';


function FeatureNotEnabled(props) {
  return <Typography variant="body2" className="error-text">
  This feature is disabled for your company account
</Typography>;
}


export default FeatureNotEnabled;