
import objectPath from "object-path";


import ParkUtil from '../shared/utils/ParkUtil';
import DateUtil from '../shared/utils/general/DateUtil';
import RepeatingRosterUtil from "../shared/utils/RepeatingRosterUtil";
import ArrayUtil from "../shared/utils/general/ArrayUtil";

export default class ParkSelectionUtil {
  
      static getSelectedItemIndex(selection, park, selectedDate){
        return selection.findIndex(x => 
            objectPath.get(x,'park.id',null) === park.id 
            && objectPath.get(x,'date.date',null) && DateUtil.dbFormat(x.date.date) === DateUtil.dbFormat(selectedDate.date))
      }

      static isSelected(selection, park, selectedDate){
        return ParkSelectionUtil.getSelectedItemIndex(selection, park, selectedDate) !== -1;
      }

      static isInSelectedRow(selection, park){
        return selection.length < 1 || (selection.length && objectPath.get(selection,'0.park.id',null) === objectPath.get(park,'id',null));
      }

      static hasSelectionBookedPark(selection, parkStates, parkOwnerships, companyProfiles){
        var bookeParkFound = false;
        if(ArrayUtil.isNonEmptyArray(selection)){
          bookeParkFound = selection.findIndex(x => ParkUtil.isBooked(x.park,x.date,parkStates,parkOwnerships,companyProfiles)) !== -1 ? true : false;
        }
        return bookeParkFound;
      }

      static hasSelectionScheduledRosterPark(selection, repeatingRosterSetting, users,repeatingRoster){
        return ArrayUtil.isNonEmptyArray(selection) && selection.findIndex(s => {
          return RepeatingRosterUtil.isScheduledForBooking(objectPath.get(s,'park',null),objectPath.get(s,'date',null),repeatingRosterSetting, users,repeatingRoster)
        }) !== -1;
        
      }

}