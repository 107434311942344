import React from "react";
import { Route, Switch, Redirect } from "react-router";



import Dashboard from "../containers/pages/app/Dashboard";
import Park from "../containers/pages/app/Park";
import NotFound from "../containers/pages/public/static/NotFound";
import ResetPassword from "../containers/pages/user/ResetPassword";
import Profile from "../containers/pages/user/Profile";
import CompanyProfile from "../containers/pages/app/company/Profile";
import CompanyUsers from "../containers/pages/app/company/Users";
// import CompanyBilling from "../containers/pages/app/company/Billing";
import CompanyStats from "../containers/pages/app/company/Stats";

import HowItWorks from "../containers/pages/public/static/HowItWorks";
import TermsAndConditions from "../containers/pages/public/static/TermsAndConditions";
import PrivacyPolicy from "../containers/pages/public/static/PrivacyPolicy";
import FairUse from "../containers/pages/app/company/FairUse";
import RepeatingRoster from "../containers/pages/app/company/RepeatingRoster";


import urls from '../shared/constants/urls';
import UserUtil from '../shared/utils/UserUtil';

const AppRoutes = (props) => {

  const { userInfo } = props;


  const isCompanyAdmin = UserUtil.isCompanyAdmin(userInfo);
  const isAtLeastCompanyManager = UserUtil.isAtLeastCompanyManager(userInfo);

  const adminRoutes = [
    {
      path : urls.COMPANY_SETTING_URL,
      component: CompanyProfile
    },
    // {
    //   path : urls.COMPANY_BILLING_URL,
    //   component: CompanyBilling
    // },
  ];

  const managerRoutes = [
    {
      path : urls.COMPANY_USERS_URL,
      component: CompanyUsers
    },
    {
      path : urls.COMPANY_INSIGHTS_URL,
      component: CompanyStats
    },
    {
      path : urls.COMPANY_FAIRUSE_URL,
      component: FairUse
    },
    {
      path : urls.COMPANY_REPEATING_ROSTER_URL,
      component: RepeatingRoster
    },
  ];

  return (
    <Switch>

          <Route exact path={urls.HOW_IT_WORKS_URL} component={HowItWorks} />
          <Route exact path={urls.PRIVACY_POLICY_URL}  component={PrivacyPolicy} />
          <Route exact path={urls.TAC_URL} component={TermsAndConditions} />


          <Route exact path={urls.INDEX_URL} component={Dashboard} />
          <Route exact path={urls.DASHBOARD_URL()} component={Dashboard} />
          <Route exact path={urls.PROFILE_URL} component={Profile} />
          <Route exact path={urls.PARK_URL()} component={Park} />

          <Route exact path={urls.RESET_PASSWORD_URL} component={ResetPassword} />

          <Route exact path={urls.CREATE_ACCOUNT_URL} component={() => <Redirect to="/" />} />
          <Route exact path={urls.LOGIN_URL} component={() => <Redirect to="/" />} />

          {
            isAtLeastCompanyManager ? managerRoutes.map((r,i) => <Route key={i} exact path={r.path} component={r.component} />)
            : null
          }

          {
            isCompanyAdmin ? adminRoutes.map((r,i) => <Route key={i} exact path={r.path} component={r.component} />)
             : null
          }

          
          
          

          
          <Route component={NotFound} />
      
    </Switch>
  );
};

export default AppRoutes;
