import * as types from '../constants/actionTypes';

const initState = {
    form: null
};

export default function userInfo(state = initState, action) {
    if (action.type === types.SET_FORM_SUBMITTING_STARTED) {
        return {
            ...state,
            form: "submitting"
        };
    }
    if (action.type === types.SET_FORM_SUBMITTING_FINISHED) {
        return {
            ...state,
            form: null
        };
    }
    return state;
}
