import React from "react";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../theme/styles";
import * as formNames from "../../constants/forms";

import validate from "./validate";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import {
  Grid,
  Box,
  ButtonGroup,
  Typography,
  IconButton,
  CardContent,
  Card,
  Collapse,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";

import { TextInput } from "../../components/form/inputs";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import ValidationUtil from "../../utils/ValidationUtil";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import objectPath from "object-path";

import UserUtil from "../../shared/utils/UserUtil";
import TranslatorUtil from "../../shared/utils/general/TranslatorUtil";
import ArrayUtil from "../../shared/utils/general/ArrayUtil";
import ParkUtil from "../../shared/utils/ParkUtil";
import { Alert } from "@material-ui/lab";

const formName = formNames.PARK_FORM;
const filter = createFilterOptions();

class ParkForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignedInfoCollapsed: false,
      dialogValue: "",
      dialogError: "",
      dialogOpen: false,
      autocompleteValue: null,
    };
  }

  setOnHoldEmail = (email) => {
    const { changeFieldValue } = this.props;
    changeFieldValue(formNames.PARK_FORM, "invitedOwnerEmail", email);
    changeFieldValue(formNames.PARK_FORM, "ownerId", null);
    this.setState({ autocompleteValue: null });
  };

  setOwnerId = (id) => {
    const { changeFieldValue } = this.props;

    changeFieldValue(formNames.PARK_FORM, "invitedOwnerEmail", null);
    changeFieldValue(formNames.PARK_FORM, "ownerId", id);
    this.setState({ autocompleteValue: null });
  };

  handleClose = () => {
    this.setState({
      dialogValue: "",
      dialogError: "",
      dialogOpen: false,
    });
  };

  autocompleteChanged = (event, newValue) => {
    console.log("on change fired", { event, newValue });
    if (objectPath.get(newValue, "add", false)) {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        this.setState({
          dialogOpen: true,
          dialogValue: newValue.value,
        });
        if (newValue.value) {
          this.validateNewUser(newValue.value);
        }
      });
    } else {
      this.setOwnerId(newValue.value);
      this.setState({
        autocompleteValue: newValue,
      });
    }
  };

  autocompleteFilter = (options, params) => {
    const filtered = filter(options, params);

    filtered.push({
      add: true,
      value: params.inputValue ? params.inputValue : "",
      label: `Add ${
        params.inputValue ? '"' + params.inputValue + '"' : "new user"
      }`,
    });

    return filtered;
  };

  handleAddUserSubmit = () => {
    if (
      this.state.dialogValue &&
      ValidationUtil.isValidEmail(this.state.dialogValue)
    ) {
      this.setOnHoldEmail(this.state.dialogValue);
      this.setState({
        dialogValue: "",
        dialogError: "",
      });
      this.handleClose();
    } else {
      this.setState({});
    }
  };

  emailAlreadyExists = (email) => {
    const { companyProfiles } = this.props;
    var emails = ArrayUtil.isNonEmptyArray(companyProfiles)
      ? companyProfiles.filter((x) => x.email).map((x) => x.email.toLowerCase())
      : [];
    return emails.indexOf(email.toLowerCase()) > -1;
  };

  validateNewUser = (value) => {
    let dialogError = "";
    if (value) {
      if (!ValidationUtil.isValidEmail(value)) {
        dialogError = TranslatorUtil.t("Invalid format");
      } else if (this.emailAlreadyExists(value)) {
        dialogError = TranslatorUtil.t("User already exists");
      }
    }
    this.setState({
      dialogValue: value,
      dialogError,
    });
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      proceedSubmit,
      optionsData,
      invitedOwnerEmail,
      ownerId,
      userInfo,
      companyProfiles,
      parkRepeatingRosterSetting,
    } = this.props;

    var ownedBy = ParkUtil.getCurrentParkOwner(
      {
        invitedOwnerEmail: invitedOwnerEmail,
        ownerId: ownerId,
      },
      companyProfiles
    );

    const formReadOnly = typeof proceedSubmit === "function" ? false : true;
    const disableSubmit = pristine || submitting;
    const submitButton = formReadOnly ? null : (
      <Button
        variant="contained"
        color="primary"
        type="submit"
        size="large"
        disabled={disableSubmit}
      >
        {TranslatorUtil.t("Save")}
      </Button>
    );

    const {
      assignedInfoCollapsed,
      dialogOpen,
      dialogValue,
      autocompleteValue,
      dialogError,
    } = this.state;

    return (
      <Box pb={2} pt={1}>
        <form
          onSubmit={
            formReadOnly
              ? () => {}
              : handleSubmit((values) => proceedSubmit(values))
          }
          className={classes.customForm}
        >
          <div className="maxWidthPart">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        name="parkId"
                        component={TextInput}
                        label={TranslatorUtil.t("Park ID")}
                        placeholder={TranslatorUtil.t("Enter park name/number")}
                        required
                        disabled={formReadOnly}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        name="description"
                        component={TextInput}
                        label={TranslatorUtil.t("Park description (optional)")}
                        placeholder={TranslatorUtil.t(
                          "Enter any features to help users identify this park"
                        )}
                        disabled={formReadOnly}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <br />
                  </Grid>
                </Grid>
                {ArrayUtil.isNonEmptyArray(parkRepeatingRosterSetting) ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Alert severity="info">{TranslatorUtil.t(
                      "Park cannot be assigned as it is part of repeating roster schedule"
                    )}</Alert>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {!UserUtil.isAtLeastCompanyManager(userInfo)
                          ? TranslatorUtil.t("Assigned / open park")
                          : TranslatorUtil.t(
                              "Assign a user to this park or leave empty to create an open park"
                            )}{" "}
                        <IconButton
                          size="small"
                          color={!assignedInfoCollapsed ? "primary" : "default"}
                          onClick={() =>
                            this.setState({
                              assignedInfoCollapsed:
                                !this.state.assignedInfoCollapsed,
                            })
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </Typography>
                      <Collapse
                        in={assignedInfoCollapsed}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Card>
                          <CardContent>
                            <Typography
                              color="secondary"
                              variant="caption"
                              component="div"
                            >
                              Assigned Park
                            </Typography>
                            <Typography color="default" variant="caption">
                              An assigned park is where a user has "ownership"
                              of the park. The user will be booked in to the
                              park everyday until they choose to release their
                              park for other users. Assigned parks cannot be part of repeating roster.
                            </Typography>
                            <br />
                            <br />
                            <Typography
                              color="secondary"
                              variant="caption"
                              component="div"
                            >
                              Open Park
                            </Typography>
                            <Typography color="default" variant="caption">
                              An open park is where there is no assigned
                              "ownership". The park will be available everyday
                              for all users to book.
                            </Typography>
                          </CardContent>
                        </Card>
                      </Collapse>
                    </Grid>

                    <div className="hidden">
                      <Field name="invitedOwnerEmail" component={TextField} />
                      <Field name="ownerId" component={TextField} />
                    </div>

                    {invitedOwnerEmail || ownerId ? (
                      <Grid item xs={12}>
                        <ButtonGroup>
                          <Button disabled>
                            {ownedBy && ownedBy.id
                              ? TranslatorUtil.t("Assigned to user") +
                                " : " +
                                objectPath.get(ownedBy, "email", " - ")
                              : invitedOwnerEmail
                              ? TranslatorUtil.t("Hold by invitation") +
                                " : " +
                                invitedOwnerEmail
                              : null}
                          </Button>
                          {formReadOnly ? null : (
                            <Button
                              variant="contained"
                              className="error-btn"
                              size="small"
                              onClick={() =>
                                invitedOwnerEmail
                                  ? this.setOnHoldEmail("")
                                  : this.setOwnerId("")
                              }
                            >
                              <DeleteIcon />
                            </Button>
                          )}
                        </ButtonGroup>
                      </Grid>
                    ) : formReadOnly ? (
                      <Grid item xs={12}>
                        Open park
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Autocomplete
                          value={autocompleteValue}
                          onChange={this.autocompleteChanged}
                          filterOptions={this.autocompleteFilter}
                          options={optionsData.profiles}
                          getOptionLabel={(option) => {
                            // e.g value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option.label) {
                              return option.label;
                            }
                            return " - ";
                          }}
                          selectOnFocus
                          renderOption={(option) =>
                            option.add ? (
                              <Button variant="contained" color="primary">
                                {option.label}
                              </Button>
                            ) : (
                              option.label
                            )
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Search users" />
                          )}
                        />
                        <Dialog
                          open={dialogOpen}
                          onClose={this.handleClose}
                          aria-labelledby="form-dialog-title"
                        >
                          <DialogTitle id="form-dialog-title">
                            Add a new user
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              We will automatically send invitation to the user.
                            </DialogContentText>
                            <FormGroup>
                              <TextField
                                autoFocus
                                margin="dense"
                                placeholder="Email address"
                                value={dialogValue}
                                error={dialogError ? true : false}
                                helperText={dialogError}
                                onChange={(event) =>
                                  this.validateNewUser(event.target.value)
                                }
                                label="Email"
                                type="text"
                              />
                            </FormGroup>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleClose} color="default">
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={
                                dialogValue === "" || dialogError !== ""
                              }
                              onClick={() => this.handleAddUserSubmit()}
                            >
                              Assign park
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <Box pt={2} display="flex" flexDirection="row-reverse">
                  {submitButton}
                </Box>
              </Grid>
            </Grid>
          </div>
        </form>
      </Box>
    );
  }
}

const selector = formValueSelector(formName); // select current form
// Decorate form with selected redux form state values
const mapStateToProps = (state) => {
  const invitedOwnerEmail = selector(state, "invitedOwnerEmail");
  const ownerId = selector(state, "ownerId");
  return {
    invitedOwnerEmail,
    ownerId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (formName, field, value) {
    dispatch(change(formName, field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles, { withTheme: true })(
    reduxForm({
      form: formName, // a unique identifier for this form
      validate,
      enableReinitialize: true,
      //asyncValidate
    })(ParkForm),
    formName
  )
);
