import * as types from '../constants/actionTypes';


export function getParkOwnerships(state = [], action) {
    if (action.type === types.GET_PARK_OWNERSHIPS) {
        console.log(types.GET_PARK_OWNERSHIPS+' set in store');
        return action.data;
    }
    return state;
}



