import React from "react";
import { Route, Switch } from "react-router";


import Welcome from "../containers/pages/Welcome";
import Login from "../containers/pages/user/Login";
import SignIn from "../containers/pages/user/SignIn";
import ResetPassword from "../containers/pages/user/ResetPassword";
import NotFound from "../containers/pages/public/static/NotFound";
import HowItWorks from "../containers/pages/public/static/HowItWorks";
import TermsAndConditions from "../containers/pages/public/static/TermsAndConditions";
import PrivacyPolicy from "../containers/pages/public/static/PrivacyPolicy";

import urls from '../shared/constants/urls';


const PublicAppRoutes = (props) => {


  return (
    <Switch>

      <Route exact path={urls.HOW_IT_WORKS_URL} component={HowItWorks} />
      <Route exact path={urls.PRIVACY_POLICY_URL} component={PrivacyPolicy} />
      <Route exact path={urls.TAC_URL} component={TermsAndConditions} />

      <Route exact path={urls.INDEX_URL} component={() => <Welcome><Login /></Welcome>} />

      <Route exact path={urls.LOGIN_URL} component={() => <Welcome><Login /></Welcome>} />
      <Route exact path={urls.RESET_PASSWORD_URL} component={() => <Welcome><ResetPassword /></Welcome>} />
      <Route exact path={urls.CREATE_ACCOUNT_URL} component={() => <Welcome><SignIn /></Welcome>} />

      <Route component={NotFound} />

    </Switch>
  );
};

export default PublicAppRoutes;
