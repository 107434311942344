import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';

export default class HowItWorksContent extends React.Component {


  render() {
    return <Grid container>
    Coming soon...
    </Grid>
  }
}

