import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TranslatorUtil from '../shared/utils/general/TranslatorUtil';
import DateUtil from '../shared/utils/general/DateUtil';
import LinkRef from './LinkRef';
import urls from '../shared/constants/urls';

export default class WeekSelectDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
      }

  

  
    render() {
      const {onClose, selectedDate} = this.props;
      const weeks = DateUtil.getManagableFornightWeeksStarters();

      return (
        <Dialog onClose={onClose} open={true}>
          <DialogTitle>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            {TranslatorUtil.t("Select week")}</DialogTitle>
          <div>
            <List dense>
              {weeks.map((week,index) => (
                <ListItem  button component={LinkRef} to={urls.DASHBOARD_URL(DateUtil.dbFormat(week))} onClick={onClose} key={index} className={
                  "weekLink "+
                  (DateUtil.dbFormat(selectedDate) === DateUtil.dbFormat(week) ? " selected " : "")
                  +
                  (DateUtil.dbFormat(DateUtil.getCurrentStartOfWeek()) === DateUtil.dbFormat(week) ? " currentWeek " : "")
                  }>
                  <ListItemAvatar>
                    <Avatar className="avatar">
                      <DateRangeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={
                    DateUtil.getRangeFormat(week,week.clone().add(11,'day'))
                    } />
                </ListItem>)) }
            </List>
          </div>
        </Dialog>
      );
    }
  }